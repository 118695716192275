<app-pdf-page>
    <app-pdf-page-header [logoSize]="logoSize.LARGE" [repeat]="false" [spacer]="false"></app-pdf-page-header>
    <div class="pdf-page__project-image">
        <img *ngIf="!isEmpty(projectDetails.projectInfo.imageBase64)" [src]="projectDetails.projectInfo.imageBase64">
        <div class="pdf-page__project-intro">
            <h1 class="pdf-page__project-title">{{ projectDetails.name }}</h1>
            <div class="pdf-page__project-description" *ngIf="projectDetails.description && projectDetails.description !== ''">{{ projectDetails.description }}</div>
        </div>
    </div>
    <div class="grid-spacer"></div>
    <div class="grid">
        <div class="grid__row">
            <div class="grid__col grid__col--7">
                <table class="table-info prevent-pagebreak-inside">
                    <tbody>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.location' | translate }}</th>
                            <td>{{ this.getFullAddress() }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.companyName' | translate }}</th>
                            <td>{{ projectDetails.company }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.tender_manager' | translate }}</th>
                            <td>{{ projectDetails.owner | namesFormat }}</td>
                        </tr>
                        <tr *ngIf="projectDetails.riskManager">
                            <th>{{ 'PAGE_PROJECT_DETAILS.project_risk_manager' | translate }}</th>
                            <td>{{ projectDetails.riskManager | namesFormat }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.client' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.client }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.contractType' | translate }}</th>
                            <td><ng-container *ngIf="!isEmpty(projectDetails.projectInfo.contractType)">{{ getTranslationWithFallback('PAGE_PROJECT_DETAILS.contractType', projectDetails.projectInfo.contractType) }}</ng-container></td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.collaboration' | translate }}</th>
                            <td><ng-container *ngIf="!isEmpty(projectDetails.projectInfo.collaboration)">{{ getTranslationWithFallback('PAGE_PROJECT_DETAILS.collaboration', projectDetails.projectInfo.collaboration) }}</ng-container></td>
                        </tr>
                        <tr *ngIf="!isEmpty(projectDetails.projectInfo.collaborationCompanies)">
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.collaborationCompanies' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.collaborationCompanies }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="grid__col grid__col--5">
                <table class="table-info">
                    <tbody>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.termsAndConditions' | translate }}</th>
                            <td>

                                <ng-container *ngIf="!isEmpty(projectDetails.projectInfo.termsAndConditions)">{{ getTranslationWithFallback('PAGE_PROJECT_DETAILS.termsAndConditions', projectDetails.projectInfo.termsAndConditions) }}</ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ projectDetails.projectInfo.constructionCostsLabel }}</th>
                            <td>{{ projectDetails.projectInfo.constructionCosts | priceFormat }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.profitContribution' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.profitContribution | priceFormat }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.workStartsAt' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.workStartsAt | date: ('DATE.short' | translate) }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.workEndsAt' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.workEndsAt| date: ('DATE.short' | translate) }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'PAGE_PROJECT_DETAILS.form.durationInMonths' | translate }}</th>
                            <td>{{ projectDetails.projectInfo.durationInMonths }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
