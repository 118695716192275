import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {QuickscanDefinition} from '../../models';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {IQuickscanDefinitionData} from '../../models/quickscandefinition/quickscandefinition-data';

@Injectable({
  providedIn: 'root'
})
export class QuickscanDefinitionService {

    private cachedQuickscanDefinition: QuickscanDefinition = null;

    constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
    }

    public getQuickscanDefinitions(companyTypeId: number): Promise<QuickscanDefinition[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/quickscandefinition/admin/${companyTypeId}`).then((response: HttpResponse<IQuickscanDefinitionData[]>) => {
                const quickscanDefinitions: QuickscanDefinition[] = response.body.map(data => new QuickscanDefinition(data));
                resolve(quickscanDefinitions);
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.quickscans_not_updated'));
                reject(error);
            });
        });
    }

    public publishQuickscanDefinition(companyTypeId: number, versionId: number): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/quickscandefinition/admin/${companyTypeId}/${versionId}/publish`, {}).then(() => {
                resolve('ok');
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.quickscan_not_published'));
                reject(error);
            });
        });
    }

    public getQuickscanDefinition(companyTypeId: number, versionId: number, forAdministration: boolean = false): Promise<QuickscanDefinition> {
        return new Promise((resolve, reject) => {
            if (this.cachedQuickscanDefinition && this.cachedQuickscanDefinition.id === versionId) {
                resolve(this.cachedQuickscanDefinition);
            } else {
                const baseUrl = '/quickscandefinition' + (forAdministration ? '/admin' : '');
                this.apiService.get(`${baseUrl}/${companyTypeId}/${versionId}`).then((response: HttpResponse<IQuickscanDefinitionData>) => {
                    this.cachedQuickscanDefinition = new QuickscanDefinition(response.body);
                    resolve(this.cachedQuickscanDefinition);
                }).catch((error) => {
                    console.error(error);
                    this.toastService.openError(error, this.translateService.instant('ERRORS.quickscan_not_retrieved'));
                    reject(error);
                });
            }
        });
    }

    public updateQuickscanDefinition(companyTypeId: number, data: QuickscanDefinition): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/quickscandefinition/admin/${companyTypeId}/${data.versionId}`, data).then(() => {
                resolve('ok');
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.quickscan_not_updated'));
                reject(error);
            });
        });
    }
}
