import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {LibraryEntry} from '../../../models';
import {FormUtil} from '../../../utils';
import {TrackingService} from '../../../services';

@Component({
    selector: 'app-create-project-modal',
    templateUrl: './create-update-library-item-modal.component.html',
    styleUrls: ['./create-update-library-item-modal.component.scss']
})
export class CreateUpdateLibraryItemModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public libraryType: string;
    public data?: LibraryEntry;
    public activeTab: string;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; libraryType: string; data?: LibraryEntry; saveText: string }>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.libraryType = modalConfig.data.libraryType;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;
        this.activeTab = 'description';

        this.form = new FormGroup({
            active: new FormControl(this.data ? this.data.active : false),
            subject: new FormControl(this.data ? this.data.subject : '', [ Validators.required, Validators.maxLength(255)]),
            riskDescription: new FormControl(this.data ? this.data.riskDescription : '', [ Validators.required, Validators.maxLength(1024)]),
            causeDescription: new FormControl(this.data ? this.data.causeDescription : '', [ Validators.required, Validators.maxLength(1024)]),
            effectDescription: new FormControl(this.data ? this.data.effectDescription : '', [ Validators.required, Validators.maxLength(1024)]),
            effectCategoryMoney: new FormControl(this.data ? this.data.effectCategoryMoney : false),
            effectCategoryTime: new FormControl(this.data ? this.data.effectCategoryTime : false),
            effectCategoryQuality: new FormControl(this.data ? this.data.effectCategoryQuality : false),
            effectCategorySafety: new FormControl(this.data ? this.data.effectCategorySafety : false),
            effectCategorySurroundings: new FormControl(this.data ? this.data.effectCategorySurroundings : false),
            effectCategoryImage: new FormControl(this.data ? this.data.effectCategoryImage : false),
            effectCategoryReputation: new FormControl(this.data ? this.data.effectCategoryReputation : false),
            relationOtherRisks: new FormControl(this.data ? this.data.relationOtherRisks : '', Validators.maxLength(1024)),
            possibleCounterMeasures: new FormControl(this.data ? this.data.possibleCounterMeasures : '', [ Validators.required, Validators.maxLength(1024)])
        });
    }

    public submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues = {...this.data, ...this.form.value};
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Creating category failed: ', e);
            this.trackingService.exception(e);
        }
    }

    public onTabChange(tab: string) {
        this.activeTab = tab;
    }
}
