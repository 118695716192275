import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'escapeHTML'
})
export class EscapeHTMLPipe implements PipeTransform {

    constructor() {
    }

    transform(html: string): string {
        if (html === undefined || html === null) {
            return html;
        }
        return html
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }

}
