<div class="main-wrapper">
    <app-library-item-filters-with-toggle
        class="filters-toggle"
        [filters]="libraryItemFilters"
        [badge]="filterBadge"
    ></app-library-item-filters-with-toggle>

    <vwui-header>
        {{ 'PAGE_RISK_RAINBOW.title' | translate }}
        <br/>
    </vwui-header>

    <app-library-item-filters-selected
        [filters]="libraryItemFilters"
        (selectionCountChange)="filterSelectionCountChanged($event)"
    ></app-library-item-filters-selected>

    <form [formGroup]="form">
        <div class="grid">
            <div class="grid__row grid__row--spacing-large">
                <div class="grid__col grid__col--6 grid__col--spacing-large">
                    <div class="label">{{ 'PAGE_RISK_RAINBOW.risks.header' | translate }}</div>

                    <app-chart-signal-matrix [large]="true" [config]="risksVsValueMatrix" (itemClicked)="chartItemSelected(allRisksWithScores, $event)"></app-chart-signal-matrix>
                </div>
                <div class="grid__col grid__col--6 grid__col--spacing-large">
                    <div class="label">{{ 'PAGE_RISK_RAINBOW.opportunities.header' | translate }}</div>
                    <app-chart-signal-matrix [large]="true" [signalReversed]="true" [config]="opportunitiesVsValueMatrix" (itemClicked)="chartItemSelected(allOpportunitiesWithScores, $event)"></app-chart-signal-matrix>
                </div>
            </div>
        </div>
        <div class="grid-spacer grid-spacer--large"></div>
        <div class="grid">
            <div class="grid__row grid__row--spacing-large">
                <div class="grid__col grid__col--6 grid__col--spacing-large">
                    <vwui-table [compactable]="false">
                        <div slot="table-title">{{ 'PAGE_RISK_RAINBOW.risks.title' | translate }}</div>

                        <ng-container *ngIf="filteredRisks.length > 0">
                            <vwui-table-row>
                                <vwui-table-header class="table-col">{{ 'PAGE_RISK_RAINBOW.risks.column.title' | translate }}</vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.RISK, SortableColumns.IN_PER, true, this.filteredRisks)" class="table-col table-col--risk-in-per">
                                    {{ 'PAGE_RISK_RAINBOW.risks.column.in_per' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.IN_PER, SortableTypes.RISK)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon>
                                </vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.RISK, SortableColumns.BEST_GUESS, true, this.filteredRisks)" class="table-col table-col--risk-financial-impact">
                                    {{ 'PAGE_RISK_RAINBOW.risks.column.best_guess' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.BEST_GUESS, SortableTypes.RISK)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon></vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.RISK, SortableColumns.SCORE, true, this.filteredRisks)" class="table-col table-col--risk-score">
                                    {{ 'PAGE_RISK_RAINBOW.risks.column.score' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.SCORE, SortableTypes.RISK)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon>
                                </vwui-table-header>
                            </vwui-table-row>
                            <vwui-table-row class="vwui-table-row--with-hover" (click)="openProjectLibraryItem(filteredRisk)" *ngFor="let filteredRisk of filteredRisks">

                            <vwui-table-column class="table-col">{{ filteredRisk.getSubject() }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-in-per">{{ getIncludedInPer(filteredRisk) }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-financial-impact">{{ getFinancialImpact(filteredRisk) }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-score"><app-score-signal-badge [thresholds]="scoreThresholdsRisks" [score]="filteredRisk.getScore() || '?'"></app-score-signal-badge></vwui-table-column>
                            </vwui-table-row>

                            <vwui-table-row class="vwui-table-row">
                                <vwui-table-column class="table-col table-col--bold">{{ 'PAGE_RISK_RAINBOW.risks.totals' | translate }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-in-per">
                                    <small class="total-in-per" *ngIf="getTotalsIncludedInPer(filteredRisks)">
                                        {{ 'PAGE_RISK_RAINBOW.risks.total_in_per' | translate:{amount: (getTotalsIncludedInPer(filteredRisks) | priceFormat)} }}
                                    </small>
                                </vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-financial-impact">
                                    <ng-container *ngIf="getTotalsBestGuess(filteredRisks)">
                                        {{ getTotalsBestGuess(filteredRisks) | priceFormat }}
                                    </ng-container>
                                </vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-score"></vwui-table-column>
                            </vwui-table-row>
                        </ng-container>

                        <vwui-table-row *ngIf="filteredRisks.length === 0">
                            <vwui-table-column>{{ 'PAGE_RISK_RAINBOW.no_risks.message_title' | translate }}</vwui-table-column>
                        </vwui-table-row>
                    </vwui-table>
                </div>

                <div class="grid__col grid__col--6 grid__col--spacing-large">
                    <vwui-table [compactable]="false">
                        <div slot="table-title">{{ 'PAGE_RISK_RAINBOW.opportunities.title' | translate }}</div>

                        <ng-container *ngIf="filteredOpportunities.length > 0">
                            <vwui-table-row>
                                <vwui-table-header class="table-col">{{ 'PAGE_RISK_RAINBOW.opportunities.column.title' | translate }}</vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.OPPORTUNITY, SortableColumns.IN_PER, true, this.filteredOpportunities)" class="table-col table-col--risk-in-per">
                                    {{ 'PAGE_RISK_RAINBOW.opportunities.column.in_per' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.IN_PER, SortableTypes.OPPORTUNITY)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                                </vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.OPPORTUNITY, SortableColumns.BEST_GUESS, true, this.filteredOpportunities)" class="table-col table-col--risk-financial-impact">
                                    {{ 'PAGE_RISK_RAINBOW.opportunities.column.best_guess' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.BEST_GUESS, SortableTypes.OPPORTUNITY)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                                </vwui-table-header>
                                <vwui-table-header (click)="this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.OPPORTUNITY, SortableColumns.SCORE, true, this.filteredOpportunities)" class="table-col table-col--risk-score">
                                    {{ 'PAGE_RISK_RAINBOW.opportunities.column.score' | translate }}
                                    <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.SCORE, SortableTypes.OPPORTUNITY)" class="sortable-label" [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                                </vwui-table-header>
                            </vwui-table-row>

                            <vwui-table-row class="vwui-table-row--with-hover" (click)="openProjectLibraryItem(filteredOpportunity)" *ngFor="let filteredOpportunity of filteredOpportunities">
                                <vwui-table-column class="table-col">{{ filteredOpportunity.getSubject() }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-in-per">{{ getIncludedInPer(filteredOpportunity) }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-financial-impact">{{ getFinancialImpact(filteredOpportunity) }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-score"><app-score-signal-badge [thresholds]="scoreThresholdsOpportunities" [score]="filteredOpportunity.getScore() || '?'"></app-score-signal-badge></vwui-table-column>
                            </vwui-table-row>
                            <vwui-table-row class="vwui-table-row">
                                <vwui-table-column class="table-col table-col--bold">{{ 'PAGE_RISK_RAINBOW.opportunities.totals' | translate }}</vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-in-per">
                                    <small class="total-in-per" *ngIf="getTotalsIncludedInPer(filteredOpportunities)">
                                        {{ 'PAGE_RISK_RAINBOW.opportunities.total_in_per' | translate:{amount: (getTotalsIncludedInPer(filteredOpportunities) | priceFormat)} }}
                                    </small>
                                </vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-financial-impact">
                                    <ng-container *ngIf="getTotalsBestGuess(filteredOpportunities)">
                                        {{ getTotalsBestGuess(filteredOpportunities) | priceFormat }}
                                    </ng-container>
                                </vwui-table-column>
                                <vwui-table-column class="table-col table-col--risk-score"></vwui-table-column>
                            </vwui-table-row>
                        </ng-container>

                        <vwui-table-row *ngIf="filteredOpportunities.length === 0">
                            <vwui-table-column>{{ 'PAGE_RISK_RAINBOW.no_opportunities.message_title' | translate }}</vwui-table-column>
                        </vwui-table-row>
                    </vwui-table>
                </div>
            </div>
        </div>
        <div class="grid">
            <vwui-card>
                <vwui-card-body>
                    <vwui-form-group [label]="'PAGE_RISK_RAINBOW.form.riskAssessmentDescription' | translate" inputId="riskAssessmentDescription">
                        <vwui-textarea inputId="riskAssessmentDescription" formControlName="riskAssessmentDescription"></vwui-textarea>
                    </vwui-form-group>
                </vwui-card-body>
            </vwui-card>
        </div>
    </form>

</div>
