<form [formGroup]="form" class="create-update-modal" a11yFocusTrap a11yAutoFocus>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <vwui-form-group label="{{ 'COMPONENT.library_mgmt.modal.create_category.name' | translate }}" inputId="name">
            <vwui-input inputId="name" formControlName="name" placeholder="naam"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'COMPONENT.library_mgmt.modal.create_category.numberPrefix' | translate }}" inputId="numberPrefix">
            <vwui-input inputId="numberPrefix" formControlName="numberPrefix"></vwui-input>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
