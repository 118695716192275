import {ICategoryData} from './category-data';
import {CategoryType} from './category-type';

export const CategoryDefault: ICategoryData = {
    id: undefined,
    name: '',
    numberPrefix: 'TBD',
    type: CategoryType.RISK,
    weightedScore: 25,
    subCategories: [],
    questions: []
};
