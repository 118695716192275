<div class="quantification__row" *ngIf="!projectHasEmptyField">
    <div class="quantification__column quantification__column--index-0">
        <div class="quantification__column-rows">
            <div class="quantification__column-row" *ngFor="let columnRowIndex of columnRowIndexes;">{{ columnRowIndex }}</div>
        </div>
    </div>
    <div
        *ngFor="let column of columns; let columnIndex = index;"
        [class]="'quantification__column quantification__column--index-' + (columnIndex + 1)"
        [class.quantification__column--disabled]="column.disabled"
    >
        <div class="quantification__column-top">
            <vwui-icon
                *ngIf="columnIndex !== 0"
                class="quantification__column-icon"
                [class.quantification__column-icon--disabled]="column.disabled"
                [iconName]="column.icon"
            ></vwui-icon>
            {{ column.label }}
        </div>
        <div class="quantification__column__values">
            <div class="quantification__column__value"
                 *ngFor="let valueLabel of column.valueLabels, let value = index"
                 (click)="setValue(column, value)"
                 [a11yKeyboardClickable]="canEditProjectLibraryItems()"
                 [class.quantification__column__value--active]="isActiveValue(column, value)"
                 [class.quantification__column__value--disabled]="!canEditProjectLibraryItems()"
            >
                {{ valueLabel }}
            </div>
        </div>
    </div>
</div>

<app-explanation *ngIf="projectHasEmptyField">{{ 'COMPONENT.library_item_quantification.error.message' | translate : { constructionCostsLabel: lastProjectDetailsValue.projectInfo.constructionCostsLabel } }}</app-explanation>
