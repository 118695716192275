import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
    ILibraryItemFiltersData, ILibraryItemFiltersOptionsData,
    Library,
    LibraryItemFilters,
    ProjectDetails,
    RiskAndOpportunityFilters
} from '../../models';
import {Subscription} from 'rxjs';
import {FormGroup} from '@ng-stack/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-project-risk-dossier',
    templateUrl: './project-risk-dossier.component.html',
    styleUrls: ['./project-risk-dossier.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectRiskDossierComponent implements OnInit, OnDestroy {
    public projectDetailsValue: ProjectDetails;
    public libraryItemFilters: LibraryItemFilters;
    private subscriptions: Subscription[] = [];
    public form = new FormGroup({});
    public filterSelectionCount = 0;
    public filterBadge = '';

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();
    public projectFilters: ILibraryItemFiltersData;

    constructor(
        private translateService: TranslateService
    ) {
    }

    @Input()
    set projectDetails(val: ProjectDetails) {
        this.projectDetailsValue = val;
        this.updateForm();
    }

    ngOnInit(): void {
        this.subscriptions.push(this.libraryItemFilters.getControl().valueChanges.subscribe((values) => {
            // RISC-702 - Unset proposed status filtering if the proposals are no longer included
            if (!values.includeProposals && values.statuses.proposed) {
                this.libraryItemFilters.removeProposedStatus();
                values.statuses.proposed.selected = false;
            } else if (values.includeProposals && !values.statuses.proposed) {
                this.libraryItemFilters.addProposedStatus();
            }

            this.projectFilters = values;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public filterSelectionCountChanged(count: number) {
        this.filterSelectionCount = count;
        this.filterBadge = count > 0 ? `${count}` : '';
    }

    private updateForm(): void {
        if (this.projectDetailsValue) {
            this.libraryItemFilters = new LibraryItemFilters(this.projectDetailsValue.getDefaultLibraryItemProjectFilters(this.translateService));
            this.subscriptions.push(this.libraryItemFilters.getControl().valueChanges.subscribe((value: ILibraryItemFiltersData) => {
                this.projectDetailsValue.riskAndOpportunityFilters = new RiskAndOpportunityFilters({
                    riskScore: value.riskScore,
                    opportunityScore: value.opportunityScore,
                    sharedWithPrincipal: value.sharedWithPrincipal,
                    includeProposals: value.includeProposals,
                    selectedOnly: value.selectedOnly,
                    selectedItems: this.getSelectedName(value.selectedItems),
                    actionHolders: this.getSelected(value.actionHolders),
                    themes: this.getSelected(value.themes),
                    bestGuessMin: value.bestGuessMin,
                    bestGuessMax: value.bestGuessMax,
                    statuses: this.getSelected(value.statuses)
                });
                this.projectDetailsChanged.emit(this.projectDetailsValue);
            }));
        }
    }

    private getSelected(themes: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(themes)
            .filter((key, _) => key[1].selected)
            .map((key, _) => key[0]);
    }

    private getSelectedName(selectedItems: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(selectedItems)
            .filter((key, _) => key[1].selected)
            .map((key, _) => key[1].name);
    }
}
