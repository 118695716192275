import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {CompanyType, ICompanyType, IUser} from '../../models/user/user';
import {UserService} from '../user/user.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {ToastService} from '../toast/toast.service';
import {MsAuthService} from '../../module/ms-auth';

@Injectable()
export class CompanyTypeService {
    private userInfo: IUser;
    private activeCompanyType: CompanyType;
    private allCompanyTypes: CompanyType[] = undefined;

    private activeComponyTypeSubject = new BehaviorSubject<CompanyType>(null);

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private toastService: ToastService,
        private authService: MsAuthService
    ) {
        this.userService.onUserInfoChange().subscribe((userInfo) => {
            this.setData(userInfo);
        });
        if (this.authService.isAuthenticated()) {
            this.userService.getUserInfo().then((userInfo) => {
                this.setData(userInfo);
            });
        }
    }

    public async getCompanyTypes(): Promise<CompanyType[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/companytype/all')
                .then((response: HttpResponse<ICompanyType[]>) => {
                    this.allCompanyTypes = response.body.map((data) => new CompanyType(data));
                    resolve(this.allCompanyTypes);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    public deleteItem(companyType: CompanyType): Promise<CompanyType[]> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/companytype/${companyType.id}`).then(async (response: HttpResponse<ICompanyType[]>) => {
                resolve(response.body.map((data) => new CompanyType(data)));
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    reject(error);
                }
            });
        });
    }

    public updateCompanyType(updatedItem: CompanyType): Promise<CompanyType[]> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/companytype/${updatedItem.id}`, updatedItem).then(async (response: HttpResponse<ICompanyType[]>) => {
                resolve(response.body.map((data) => new CompanyType(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public createCompanyType(updatedItem: CompanyType, selectedBaseCompanyTypeId: number): Promise<CompanyType[]> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/companytype`, {
                companyType: updatedItem,
                selectedBaseCompanyTypeId
            }).then(async (response: HttpResponse<ICompanyType[]>) => {
                resolve(response.body.map((data) => new CompanyType(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public async setActiveCompanyTypeId(companyTypeId: number) {
        const allCompanyTypes = await this.getCompanyTypes();
        this.activeCompanyType = allCompanyTypes.find(item => item.id === companyTypeId);
        this.activeComponyTypeSubject.next(this.activeCompanyType);
    }

    public getActiveCompanyTypeSubject(): Subject<CompanyType> {
        return this.activeComponyTypeSubject;
    }

    private setData(userInfo: IUser): void {
        this.userInfo = userInfo;
        if (this.userInfo && this.userInfo.companyTypes.length > 0) {
            this.setActiveCompanyTypeId(this.userInfo.companyTypes[0].id);
        }
    }

    public async getCompanyType(companyTypeId: number): Promise<CompanyType> {
        return this.getCompanyTypes().then((allCompanyTypes) => allCompanyTypes.find((type) => type.id === companyTypeId));
    }
}
