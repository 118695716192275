<ng-container *ngFor="let category of categories">
    <div class="mt-lg spacing">
        <vwui-table [compactable]="false">
            <div slot="table-title">{{ category.name }}</div>
            <div slot="table-actions">
                <vwui-btn-icon icon="edit" (click)="openUpdateCategoryModal(category)"></vwui-btn-icon>
                <vwui-btn-icon *ngIf="category.entries.length === 0" icon="vwui-trash" (click)="openDeleteCategoryModal(category)"></vwui-btn-icon>
            </div>
            <vwui-table-row>
                <vwui-table-header class="category-col category-col--nr">{{ 'COMPONENT.library_mgmt.item.entryNumber' | translate }}</vwui-table-header>
                <vwui-table-header class="category-col category-col--subject">{{ 'COMPONENT.library_mgmt.item.subject' | translate }}</vwui-table-header>
                <vwui-table-header class="category-col category-col--cause">{{ 'COMPONENT.library_mgmt.item.risk' | translate }}</vwui-table-header>
                <vwui-table-header class="category-col category-col--active">{{ 'COMPONENT.library_mgmt.item.is_active'| translate }}</vwui-table-header>
                <vwui-table-header class="category-col category-col--options"></vwui-table-header>
            </vwui-table-row>
            <vwui-table-row *ngFor="let libraryEntry of category.entries">
                <vwui-table-column class="category-col category-col--nr">{{ category.numberPrefix + "." + libraryEntry.entryNumber }}</vwui-table-column>
                <vwui-table-column class="category-col category-col--subject">{{ libraryEntry.subject }}</vwui-table-column>
                <vwui-table-column class="category-col category-col--cause">{{ libraryEntry.riskDescription }}</vwui-table-column>
                <vwui-table-column class="category-col category-col--active">
                    <vwui-checkbox [disabled]="true" [checked]="libraryEntry.active" class="active-checkbox"></vwui-checkbox>
                </vwui-table-column>
                <vwui-table-column class="category-col category-col--options">
                    <vwui-btn-icon icon="edit" (click)="openEditItemModal(category, libraryEntry)"></vwui-btn-icon>
                    <vwui-btn-icon icon="vwui-trash" (click)="openDeleteItemModal(category, libraryEntry)"></vwui-btn-icon>
                </vwui-table-column>
            </vwui-table-row>
            <vwui-table-row>
                <vwui-table-column></vwui-table-column>
                <vwui-table-column>
                    <vwui-button class="is-secondary" (click)="openCreateItemModal(category)">{{ "COMPONENT.library_mgmt.library_type." + library.type + ".create_item.title" | translate }}</vwui-button>
                </vwui-table-column>
                <vwui-table-column></vwui-table-column>
                <vwui-table-column></vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </div>
</ng-container>
<vwui-button class="is-secondary" (click)="openCreateCategory()">
    {{ 'COMPONENT.library_mgmt.create_category' | translate }}
</vwui-button>
