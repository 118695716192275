import {Component, Input, ViewEncapsulation} from '@angular/core';
import {LogoSizes} from '../pdf-page-header/pdf-page-header.data';
import {ProjectDetails} from '../../models';
import {TranslationService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-pdf-page-project-info',
    templateUrl: './pdf-page-project-info.component.html',
    styleUrls: ['./pdf-page-project-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageProjectInfoComponent {

    @Input() projectDetails: ProjectDetails;

    public logoSize = LogoSizes;

    constructor(
        private translationService: TranslationService,
        private translateService: TranslateService
    ) {
    }

    public isEmpty(property: string): boolean {
        return !property || property.trim() === '';
    }

    public getTranslationWithFallback(keyPrefix: string, value: string): string {
        const translationKey = `${keyPrefix}.${value}`.trim();
        const translation = this.translateService.instant(translationKey);

        return translation === translationKey ? value : translation;
    }

    /**
     * Retrieve the full readable address
     * ( Used for the PDF details )
     */
    public getFullAddress(): string {
        let fullAddress = '';
        if ( this.projectDetails ) {
            if ( this.projectDetails.address ) {
                fullAddress += this.projectDetails.address;

                if ( this.projectDetails.city || this.projectDetails.country ) {
                    fullAddress += ', ';
                }
            }

            if ( this.projectDetails.city ) {
                fullAddress += this.projectDetails.city;

                if ( this.projectDetails.country ) {
                    fullAddress += ' ' + this.translationService.getCountryName( this.projectDetails.country );
                }
            }
        }

        return fullAddress;
    }

}
