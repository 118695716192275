import {Pipe, PipeTransform} from '@angular/core';
import {Category, QuickscanDefinition, TisDefinition} from '../models';
import {Question} from '../models/definition/question';
import {BehaviorSubject, Observable} from 'rxjs';

@Pipe({
    name: 'categoryGetAllSubCategoryQuestions'
})
export class CategoryGetAllSubCategoryQuestionsPipe implements PipeTransform {

    transform(category: Category): Observable<Question[]> {
        const questions$ = new BehaviorSubject<Question[]>(category.getAllSubCategoryQuestions());
        const parent = category.getParent();

        if (parent instanceof QuickscanDefinition || parent instanceof TisDefinition) {
            parent.onChange.subscribe(() => {
                questions$.next(category.getAllSubCategoryQuestions());
            });
        }

        return questions$.asObservable();
    }

}
