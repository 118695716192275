<div class="quickscan">
    <vwui-header [id]="'ThemeTitle'">
        {{ getPageName() + '.title' | translate : { tisLabelShort : projectDetails.projectInfo.tisLabelShort } }}
        <small class="header-subtitle">{{ 'PAGE_FILL_QUICKSCAN.version' | translate }}: {{ quickscanDefinition?.versionId }}</small>
    </vwui-header>

    <div class="quickscan-theme"
         *ngFor="let quickscanCategory of quickscanDefinition?.categories; let categoryIndex = index; trackBy: trackQuickscanCategory">
        <a [name]="'Theme' + quickscanCategory.id" [id]="'Theme' + quickscanCategory.id" tabindex="-1"></a>
        <vwui-table [compactable]="false">
            <div slot="table-title" [id]="'ThemeTitle' + quickscanCategory.id">{{ categoryIndex + 1 }}. {{ quickscanCategory.name }}</div>
            <vwui-table-row>
                <vwui-table-header
                    class="quickscan-col quickscan-col--nr">{{ 'PAGE_FILL_QUICKSCAN.table.column.nr' | translate }}</vwui-table-header>
                <vwui-table-header
                    class="quickscan-col quickscan-col--question">{{ 'PAGE_FILL_QUICKSCAN.table.column.question' | translate }}</vwui-table-header>
                <vwui-table-header
                    class="quickscan-col quickscan-col--answer">{{ 'PAGE_FILL_QUICKSCAN.table.column.answer' | translate }}</vwui-table-header>
                <vwui-table-header
                    class="quickscan-col quickscan-col--remark">{{ 'PAGE_FILL_QUICKSCAN.table.column.remark' | translate }}</vwui-table-header>
                <vwui-table-header
                    class="quickscan-col quickscan-col--score">
                    <vwui-tooltip [label]="'PAGE_FILL_QUICKSCAN.table.column.score_tooltip' | translate" position="left">
                        <vwui-icon class="info" iconName="info"></vwui-icon>{{ 'PAGE_FILL_QUICKSCAN.table.column.score' | translate }}
                    </vwui-tooltip>
                </vwui-table-header>
            </vwui-table-row>
            <vwui-table-row
                *ngFor="let question of quickscanCategory | categoryGetAllSubCategoryQuestions | async; let questionIndex = index; trackBy: trackSubCategoryQuestion">
                <vwui-table-column class="quickscan-col quickscan-col--nr">{{ categoryIndex + 1 }}.{{ questionIndex + 1 }} </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--question">
                    <div class="quickscan-subject">{{ question.getCategoryName() }}</div>
                    {{ question.name }}
                    <ng-container *ngIf="question.info">
                        <vwui-tooltip [label]="question.info"><vwui-icon class="info" iconName="info"></vwui-icon></vwui-tooltip>
                    </ng-container>
                    <vwui-icon *ngIf="hasRedFlagForQuestion(question)" class="red-flag" iconName="flag"></vwui-icon>
                    <vwui-icon *ngIf="canHaveRedFlagForQuestion(question) && !hasRedFlagForQuestion(question)" class="flag-outline" iconName="flag-outline"></vwui-icon>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--answer">
                    <app-question-input
                        [question]="question"
                        [ngModel]="getAnswerIdForQuestion(question)"
                        (valueChanged)="answerChanged($event, question)"
                        [readonly]="disabled"
                    >
                    </app-question-input>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--remark">
                    <vwui-textarea autosize class="vwui-textarea--table-column" (change)="doChange($event.target, question)" [ngModel]="getRemarkForQuestion(question)" [disabled]="disabled"></vwui-textarea>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--score">
                    {{ getWeightedScoreForQuestion(question, quickscanCategory) }}
                </vwui-table-column>
            </vwui-table-row>
            <vwui-table-row>
                <vwui-table-column class="quickscan-col quickscan-col--nr">
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--question">
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--answer">
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--remark quickscan-col--right">
                    <ng-container *ngIf="quickscanCategory.type === 'risk'">
                        <vwui-badge class="badge" [ngClass]="{'badge--red': getIndicatorForCategory(quickscanCategory) === 'red', 'badge--green': getIndicatorForCategory(quickscanCategory) === 'green'}"> {{ 'CLASSIFICATION.' + getIndicatorForCategory(quickscanCategory) | translate }} </vwui-badge>
                        <vwui-badge class="badge">{{ getRedFlagsForCategory(quickscanCategory) }}</vwui-badge>
                    </ng-container>
                    <vwui-badge class="badge badge--with-hover" [ngClass]="{'badge--has-remark': hasCategoryRemark(quickscanCategory)}" (click)="addCategoryRemark(quickscanCategory)"><vwui-icon iconName="chat"></vwui-icon></vwui-badge>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--score">
                    <vwui-tooltip [label]="'PAGE_FILL_QUICKSCAN.table.column.scoreresult_tooltip' | translate" position="left">
                        {{ getWeightedScoreForCategory(quickscanCategory) }}
                    </vwui-tooltip>
                </vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </div>
</div>
