<div class="pdf-page__header-body" [ngClass]="{'pdf-page__header-body--has-spacer': spacer, 'pdf-page__header-body--repeat': repeat}">
    <div class="pdf-page__header-content">
        <div class="pdf-page__project-subtitle"><ng-content></ng-content></div>
        <small>{{ dateNow | date: ('DATE.long' | translate) }}</small>
    </div>
    <img
        class="pdf-page__logo"
        [ngClass]="{'pdf-page__logo--large': logoSize === logoSizes.LARGE}"
        src="/assets/images/logo.svg"
    />
</div>
