import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {LibraryType} from '../../enums/library-type/library-type';
import {Library, LibraryCategory, LibraryEntry} from '../../models';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ILibraryData} from '../../models/';

@Injectable()
export class LibraryService {

    constructor(
        private apiService: ApiService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {
    }

    public addCategory(companyTypeId: number, library: Library, data: LibraryCategory): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/library/${companyTypeId}/${library.type}/category`, data).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                reject(error);
            });
        });
    }

    public updateCategory(companyTypeId: number, library: Library, data: LibraryCategory): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/library/${companyTypeId}/${library.type}/category/${data.id}`, data).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                reject(error);
            });
        });
    }

    public deleteCategory(companyTypeId: number, library: Library, category: LibraryCategory): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/library/${companyTypeId}/${library.type}/category/${category.id}`).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                reject(error);
            });
        });
    }

    public addItem(companyTypeId: number, library: Library, category: LibraryCategory, data: LibraryEntry): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/library/${companyTypeId}/${library.type}/category/${category.id}/entry`, data).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                reject(error);
            });
        });
    }

    public updateItem(companyTypeId: number, library: Library, category: LibraryCategory, entry: LibraryEntry): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/library/${companyTypeId}/${library.type}/category/${category.id}/entry/${entry.id}`, entry).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                reject(error);
            });
        });
    }

    public deleteItem(companyTypeId: number, library: Library, category: LibraryCategory, entry: LibraryEntry): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/library/${companyTypeId}/${library.type}/category/${category.id}/entry/${entry.id}`).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getLibrary(companyTypeId: number, libraryType: LibraryType): Promise<Library> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/library/${companyTypeId}/${libraryType}`).then((response: HttpResponse<ILibraryData>) => {
                resolve(new Library(response.body));
            }).catch((error) => {
                this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_retrieved'));
                reject(error);
            });
        });
    }
}
