import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-input-hint',
    templateUrl: './input-hint.component.html',
    styleUrls: ['./input-hint.component.scss']
})
export class InputHintComponent {

    @Input() control?: AbstractControl;

}
