<form [formGroup]="form" class="create-edit-form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <ng-container>
            <vwui-tab-bar [selected]="activeTab" (vwuiTabChange)="onTabChange($event.detail)" [a11yTablistControls]="activeTab">
                <vwui-tab tabId="description"> {{ 'COMPONENT.library_mgmt.item.description' | translate }} </vwui-tab>
                <vwui-tab tabId="quantification" *ngIf="libraryType === 'risks'"> {{ 'COMPONENT.library_mgmt.item.quantification' | translate }} </vwui-tab>
                <vwui-tab tabId="riskMitigation"> {{ 'COMPONENT.library_mgmt.item.riskMitigation' | translate }} </vwui-tab>
            </vwui-tab-bar>

            <div ngSwitch="{{ activeTab }}" class="modal-tab">
                <app-item-description-tab *ngSwitchCase="'description'" [entry]="data" [libraryType]="libraryType" [formGroup]="form"> </app-item-description-tab>
                <app-item-quantification-tab *ngSwitchCase="'quantification'" [entry]="data" [libraryType]="libraryType" [formGroup]="form"> </app-item-quantification-tab>
                <app-item-risk-mitigation-tab *ngSwitchCase="'riskMitigation'" [entry]="data" [libraryType]="libraryType" [formGroup]="form"> </app-item-risk-mitigation-tab>
            </div>
        </ng-container>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
