import {ILibraryItemFiltersOptionsData, ILibraryItemFiltersData} from './filter-data';
import {FormControl, FormGroup} from '@ng-stack/forms';

export class LibraryItemFilters {

    private readonly filterControl: FormGroup<ILibraryItemFiltersData>;
    private readonly initialFilters: ILibraryItemFiltersData;

    constructor(
        filterValues: ILibraryItemFiltersData
    ) {
        this.filterControl = new FormGroup<ILibraryItemFiltersData>({
            riskScore: new FormControl(filterValues.riskScore),
            opportunityScore: new FormControl(filterValues.opportunityScore),
            sharedWithPrincipal: new FormControl(filterValues.sharedWithPrincipal),
            includeProposals: new FormControl(filterValues.includeProposals),
            selectedOnly: new FormControl(filterValues.selectedOnly),
            selectedItems: this.generateOptionControls(filterValues.selectedItems),
            actionHolders: this.generateOptionControls(filterValues.actionHolders),
            themes: this.generateOptionControls(filterValues.themes),
            bestGuessMin: new FormControl(filterValues.bestGuessMin),
            bestGuessMax: new FormControl(filterValues.bestGuessMax),
            statuses: this.generateOptionControls(filterValues.statuses)
        });
        this.initialFilters = JSON.parse(JSON.stringify(filterValues));
    }

    public getControl(): FormGroup<ILibraryItemFiltersData> {
        return this.filterControl;
    }

    public addProposedStatus() {
        const currentStatus = this.filterControl.value.statuses;

        if (this.initialFilters.statuses.proposed) {
            currentStatus.proposed = this.initialFilters.statuses.proposed;
            this.filterControl.setControl('statuses', this.generateOptionControls(currentStatus));
        }
    }

    public removeProposedStatus() {
        this.filterControl.controls.statuses.removeControl('proposed');
    }

    public removeSelectedItemControls(itemIds: number[]): void {
        itemIds.forEach((itemId) => {
            this.filterControl.controls.selectedItems.removeControl(`item-${itemId}`);
        });
    }

    public composeSelectedItemControls(itemIds: number[]): void {
        const itemKeys = itemIds.map((itemId) => `item-${itemId}`);

        Object.keys(this.filterControl.controls.selectedItems.controls).forEach((activeItemKey) => {
            if (!itemKeys.includes(activeItemKey)) {
                this.filterControl.controls.selectedItems.removeControl(activeItemKey);
            }
        });

        itemIds.forEach((itemId) => {
            const itemKey = `item-${itemId}`;

            if (!this.filterControl.controls.selectedItems.get(itemKey)) {
                this.filterControl.controls.selectedItems.addControl(itemKey, new FormGroup({
                    name: new FormControl(itemId.toString()),
                    selected: new FormControl(false)
                }));
            }
        });
    }

    private generateOptionControls(options: ILibraryItemFiltersOptionsData): FormGroup<ILibraryItemFiltersOptionsData> {
        const optionControls = {};

        Object.keys(options).map((key) => {
            optionControls[key] = new FormGroup({
                name: new FormControl(options[key].name.toString()),
                selected: new FormControl(options[key].selected)
            });
        });

        return new FormGroup(optionControls);
    }

}
