<div class="main-wrapper">
    <vwui-header>{{ 'PAGE_PROJECT_DETAILS.title' | translate }}</vwui-header>
    <vwui-spinner *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>
    <div class="grid" *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <div class="grid__row grid__row--spacing-large">
        <div class="grid__col grid__col--6 grid__col--spacing-large">
            <div class="processing-thumbnail-spinner" [class.processing-thumbnail-spinner--show]="isProcessingThumbnail">
                <svg viewBox="0 0 64 64"><circle r="26" transform="translate(32,32)"></circle></svg>
                {{ 'PAGE_PROJECT_DETAILS.processing_thumbnail' | translate }}
            </div>
            <vwui-form-group [ngClass]="{'image-disabled': !canEditDetails() }">
                <vwui-image-input class="large" [(ngModel)]="imageBase64" (ngModelChange)="changeImageBase64()" [disabled]="!canEditDetails()" ></vwui-image-input>
            </vwui-form-group>

            <div class="grid-spacer"></div>
            <vwui-card [formGroup]="projectDetailsForm">
                <vwui-card-header>{{ 'PAGE_PROJECT_DETAILS.general_title' | translate }}</vwui-card-header>
                <vwui-card-body>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.name' | translate }}" required>
                        <vwui-input formControlName="name"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.address' | translate }}" required>
                        <vwui-input formControlName="address"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.city' | translate }}" required>
                        <vwui-input formControlName="city"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.country' | translate }}" required>
                        <ng-select [items]="countries"
                                   [selectOnTab]="true"
                                   bindLabel="name"
                                   bindValue="id"
                                   labelForId="country"
                                   required="true"
                                   placeholder="{{ 'PAGE_PROJECT_DETAILS.placeholder.country' | translate }}"
                                   formControlName="country"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.companyName' | translate }}">
                        <ng-select [items]="companyNames"
                                   [selectOnTab]="true"
                                   bindValue="company"
                                   labelForId="company"
                                   required="true"
                                   placeholder="{{ 'PAGE_PROJECT_DETAILS.placeholder.companyName' | translate }}"
                                   formControlName="company"
                                   >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.owner' | translate }}" required>
                        <app-user-identity-input
                            [control]="projectDetailsForm.controls.newOwner"
                            [companyType]="companyType"
                            [appDisabledReason]="!ownerChangeable ? ('PAGE_PROJECT_DETAILS.form.owner_disabled' | translate) : ''"
                        ></app-user-identity-input>
                        <span class="string--limit_reached" *ngIf="ownerChangeable && !!projectDetailsForm.getError('email', 'newOwner')">{{ 'PAGE_PROJECT_DETAILS.form.emailInvalid' | translate }}</span>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.risk_manager' | translate }}">
                        <app-user-identity-input
                            [control]="projectDetailsForm.controls.newRiskManager"
                            [companyType]="companyType"
                            [appDisabledReason]="!riskManagerChangeable ? ('PAGE_PROJECT_DETAILS.form.risk_manager_disabled' | translate) : ''"
                        ></app-user-identity-input>
                        <span class="string--limit_reached" *ngIf="riskManagerChangeable && !!projectDetailsForm.getError('email', 'riskManager')">{{ 'PAGE_PROJECT_DETAILS.form.emailInvalid' | translate }}</span>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.description.title' | translate }}">
                        <vwui-textarea formControlName="description"></vwui-textarea>
                        <span *ngIf="this.canEditDetails()" [ngClass]="stringLimitReached('description') ? 'string--limit_reached' : 'string--limit'">{{stringLimitText('description')}}</span>
                    </vwui-form-group>
                </vwui-card-body>
            </vwui-card>
        </div>
        <div class="grid__col grid__col--6 grid__col--spacing-large">
            <vwui-card>
                <vwui-card-header>{{ 'PAGE_PROJECT_DETAILS.contract_title' | translate }}</vwui-card-header>
                <vwui-card-body>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.client' | translate }}">
                        <vwui-input [ngClass]="stringLimitReached('client') ? 'input-limit-reached' : ''"
                                    [(ngModel)]="projectDetailsValue.projectInfo.client"
                                    [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.client' | translate"
                                    [disabled]="!this.canEditDetails()"></vwui-input>
                        <span *ngIf="this.canEditDetails()"
                              [ngClass]="stringLimitReached('client') ? 'string--limit_reached' : 'string--limit'">{{stringLimitText('client')}}</span>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.contractType' | translate }}">
                        <ng-select [items]="contractTypes"
                                   [selectOnTab]="true"
                                   bindLabel="value"
                                   bindValue="key"
                                   labelForId="contractType"
                                   required="false"
                                   placeholder="{{ 'PAGE_PROJECT_DETAILS.placeholder.contractType' | translate }}"
                                   [(ngModel)]="contractType"
                                   (change)="updateContractType()"
                                   [disabled]="!this.canEditDetails()"
                                   >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group *ngIf="isContractTypeOther()" label="{{ 'PAGE_PROJECT_DETAILS.form.contractTypeOther' | translate }}">
                        <vwui-input
                            type="text"
                            [(ngModel)]="this.projectDetailsValue.projectInfo.contractType"
                            [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.contractTypeOther' | translate"
                            [disabled]="!this.canEditDetails()"
                            [ngClass]="{'ng-invalid': stringLimitReached('contractType')}"
                        ></vwui-input>
                        <span *ngIf="this.canEditDetails()" [ngClass]="stringLimitReached('contractTypeOther') ? 'string--limit_reached' : 'string--limit'">{{stringLimitText('contractTypeOther')}}</span>
                    </vwui-form-group>

                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.termsAndConditions' | translate }}">
                        <ng-select [items]="termsAndConditions"
                                   [selectOnTab]="true"
                                   bindLabel="value"
                                   bindValue="key"
                                   labelForId="termsAndConditions"
                                   required="false"
                                   placeholder="{{ 'PAGE_PROJECT_DETAILS.placeholder.termsAndConditions' | translate }}"
                                   [(ngModel)]="termsAndCondition"
                                   (change)="updateTermsAndConditions()"
                                   [disabled]="!this.canEditDetails()">
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group *ngIf="isTermsAndConditionsOther()" label="{{ 'PAGE_PROJECT_DETAILS.form.termsAndConditionsOther' | translate }}">
                        <vwui-input
                            type="text"
                            [(ngModel)]="this.projectDetailsValue.projectInfo.termsAndConditions"
                            [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.termsAndConditionsOther' | translate"
                            [disabled]="!this.canEditDetails()"
                            [ngClass]="{'ng-invalid': stringLimitReached('termsAndConditionsOther')}"
                        ></vwui-input>
                        <span *ngIf="this.canEditDetails()" [ngClass]="stringLimitReached('termsAndConditionsOther') ? 'string--limit_reached' : 'string--limit'">{{stringLimitText('termsAndConditionsOther')}}</span>
                    </vwui-form-group>

                    <ng-container [formGroup]="costsForm">
                        <vwui-form-group label="{{ this.projectDetailsValue.projectInfo.constructionCostsLabel }}">
                            <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input" [ngClass]="{'disabled': !canEditDetails() }">
                                <input class="sc-vwui-input" type="text" formControlName="constructionCosts" [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.constructionCosts' | translate" [readOnly]="!this.canEditDetails()" [disabled]="!this.canEditDetails()" />
                            </div>
                        </vwui-form-group>
                        <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.profitContribution' | translate }}">
                            <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input" [ngClass]="{'disabled': !canEditDetails() }">
                                <input class="sc-vwui-input" type="text" formControlName="profitContribution" [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.profitContribution' | translate" [readOnly]="!this.canEditDetails()" [disabled]="!this.canEditDetails()" />
                            </div>
                        </vwui-form-group>
                    </ng-container>

                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.workStartsAt' | translate }}">
                        <vwui-datepicker
                            [ngClass]="{'disabled': !canEditDetails()}"
                            [disabled]="!this.canEditDetails()"
                            [flatpickrOptions]="{dateFormat: 'Y-m-dTH:i', altFormat: 'd-m-Y', altInput: true, convertModelValue: true, allowInput: true, locale: 'nl'}"
                            [(ngModel)]="startDate"
                            [maxDate]="endDate"
                        ></vwui-datepicker>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.workEndsAt' | translate }}">
                        <vwui-datepicker
                            [ngClass]="{'disabled': !canEditDetails()}"
                            [disabled]="!this.canEditDetails()"
                            [flatpickrOptions]="{dateFormat: 'Y-m-dTH:i', altFormat: 'd-m-Y', altInput: true, convertModelValue: true, allowInput: true, locale: 'nl'}"
                            [(ngModel)]="endDate"
                            [minDate]="startDate"
                        ></vwui-datepicker>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.durationInMonths' | translate }}">
                        <vwui-input [(ngModel)]="durationInMonths" disabled="true"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'PAGE_PROJECT_DETAILS.form.collaboration' | translate }}">
                        <ng-select [items]="collaborations"
                                   [selectOnTab]="true"
                                   bindLabel="value"
                                   bindValue="key"
                                   labelForId="collaboration"
                                   required="true"
                                   placeholder="{{ 'PAGE_PROJECT_DETAILS.placeholder.collaboration' | translate }}"
                                   [(ngModel)]="collaboration"
                                   (change)="updateCollaboration()"
                                   [disabled]="!this.canEditDetails()"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group *ngIf="isCollaborationSpecified()" label="{{ 'PAGE_PROJECT_DETAILS.form.collaborationCompanies' | translate }}">
                        <vwui-input [ngClass]="stringLimitReached('collaborationCompanies') ? 'input-limit-reached' : ''" type="text" [(ngModel)]="this.projectDetailsValue.projectInfo.collaborationCompanies" [placeholder]="'PAGE_PROJECT_DETAILS.placeholder.collaborationCompanies' | translate" [disabled]="!this.canEditDetails()"></vwui-input>
                        <span *ngIf="this.canEditDetails()" [ngClass]="stringLimitReached('collaborationCompanies') ? 'string--limit_reached' : 'string--limit'">{{stringLimitText('collaborationCompanies')}}</span>
                    </vwui-form-group>
                </vwui-card-body>
            </vwui-card>
        </div>
    </div>
</div>
</div>
