import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as i18ncountries from 'i18n-iso-countries';
import * as i18ncountriesLocaleNl from 'i18n-iso-countries/langs/nl.json';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private translations;
    private i18ncountries;
    private onTranslationChangedSubject = new BehaviorSubject<string>(undefined);

    constructor(
        private translateService: TranslateService
    ) {
        this.i18ncountries = i18ncountries;
        this.i18ncountries.registerLocale(i18ncountriesLocaleNl);
    }

    public add(translations: {[key: string]: object}): void {
        this.translations = translations;
        this.translateService.addLangs(Object.keys(this.translations));
    }

    public set(language: string): void {
        this.translateService.setDefaultLang(language);
        this.translateService.setTranslation(language, this.translations[language]);
        this.onTranslationChangedSubject.next(language);
    }

    public onTranslationChanged(): Observable<string> {
        return this.onTranslationChangedSubject.asObservable();
    }

    public addCountries(): void {
        const keyValueList = this.i18ncountries.getNames( this.translateService.getDefaultLang() );
        const keyValueListWithCountryNames = {};
        Object.keys(keyValueList).forEach((countryCode: string) => {
            keyValueListWithCountryNames['country.' + countryCode] = keyValueList[countryCode];
        });
        this.add(keyValueListWithCountryNames);
    }

    public getCountryName( countryCode: string ): void {
        return this.i18ncountries.getName( countryCode, this.translateService.getDefaultLang() );
    }

    public getCountriesAsKeyValue(): {id: string; name: string}[]  {
        const keyValueList = this.i18ncountries.getNames( this.translateService.getDefaultLang() );
        const keyValueListWithCountryNames = [];
        Object.keys(keyValueList).forEach((countryCode: string) => {
            keyValueListWithCountryNames.push({id: countryCode, name: keyValueList[countryCode]});
        });
        return keyValueListWithCountryNames;
    }

}
