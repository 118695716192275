import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectDetails, ProjectMandateDetails} from '../../models';
import {
    LoadingSpinnerService,
    ToastService,
    ProjectMandateService,
    ProjectService,
    UserService, CompanyTypeService
} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ProjectStatus, ProjectSteps} from '../../enums';
import {Router} from '@angular/router';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {DeleteProjectModalComponent, ExecuteProjectModalComponent} from '..';

@Component({
    selector: 'app-project-mandating',
    templateUrl: './project-mandating.component.html',
    styleUrls: ['./project-mandating.component.scss']
})
export class ProjectMandatingComponent {
    public isSaving = false;
    public isSavingForArchived = false;
    public startMandateDisabledReason: string;
    public mandateDetails: ProjectMandateDetails | null = null;
    public companyMandatingText: string;
    private projectDetailsValue: ProjectDetails | null = null;

    constructor(
        private projectMandateService: ProjectMandateService,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private toastService: ToastService,
        public loadingSpinnerService: LoadingSpinnerService,
        private router: Router,
        private userService: UserService,
        private modalService: VwuiModalService,
        private companyTypeService: CompanyTypeService
    ) {
    }

    get projectDetails(): ProjectDetails {
        return this.projectDetailsValue;
    }

    @Input()
    set projectDetails(val: ProjectDetails) {
        this.projectDetailsValue = val;
        this.updateMandateDetails();
    }

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    public async updateMandateDetails(): Promise<void> {
        this.isSaving = false;
        this.startMandateDisabledReason = 'COMPONENT.mandating.cannot_start.project_details_not_loaded';

        if (this.projectDetails) {
            this.loadingSpinnerService.show();
            await this.projectMandateService.getMandateDetails(this.projectDetails)
                .then((mandateDetails) => {
                    this.mandateDetails = mandateDetails;
                    if (this.projectDetailsValue.isOnHold()) {
                        this.startMandateDisabledReason = 'DISABLED.project_on_hold';
                    } else if (this.projectDetailsValue.isClosed()) {
                        this.startMandateDisabledReason = 'DISABLED.project_closed';
                    } else if (!this.projectService.isOwner(this.projectDetails)) {
                        this.startMandateDisabledReason = 'DISABLED.not_owner';
                    } else if (!this.projectService.canEditProject(this.projectDetails)) {
                        this.startMandateDisabledReason = 'DISABLED.no_edit_rights';
                    } else if (this.mandateDetails.canMandate()) {
                        this.startMandateDisabledReason = '';
                    } else {
                        this.startMandateDisabledReason = 'COMPONENT.mandating.cannot_start.conditions_not_met';
                    }
                }).catch((error) => {
                    this.startMandateDisabledReason = 'ERRORS.project_mandate_details_not_retrieved';
                    this.toastService.handleError(error, this.startMandateDisabledReason);
                }).finally(() => {
                    this.loadingSpinnerService.hide();
                });

            this.companyTypeService.getCompanyType(this.projectDetailsValue.companyTypeId).then((companyType) => {
                this.companyMandatingText = companyType.companyMandatingInfo;
            });
        }
    }

    public async restartMandateProject() {
        await this.doMandateProject();
    }

    public async mandateProject() {
        if (this.startMandateDisabledReason !== '' && this.mandateDetails.flowResult !== undefined && this.mandateDetails.flowResult !== false) {
            return;
        }
        await this.doMandateProject();
    }

    public getUriForStep(stepId: ProjectSteps): string {
        return `/project/${this.projectDetails.id}/step/${stepId}`;
    }

    public showStartMandatingButton(): boolean {
        return !this.showRestartMandatingButton()
            && !this.showInExecutionButton()
            && this.mandateDetails && this.mandateDetails.preconditions.mandateProcessAlreadyStarted.satisfied;
    }

    public showRestartMandatingButton(): boolean {
        return this.projectDetails
            && this.projectService.isOwner(this.projectDetails)
            && this.projectDetails.status === ProjectStatus.MANDATING_FAILED;
    }

    public showInExecutionButton(): boolean {
        return this.projectDetails
            && this.projectService.isOwner(this.projectDetails)
            && this.projectDetails.status === ProjectStatus.MANDATING_SUCCEEDED;
    }

    public showMandatingStatus(): boolean {
        return (this.mandateDetails && !this.mandateDetails.preconditions.mandateProcessAlreadyStarted.satisfied)
            || this.showRestartMandatingButton()
            || this.showInExecutionButton();
    }

    public getStartDateMandate() {
        return this.mandateDetails && this.mandateDetails.mandateSteps[0].createdAt;
    }

    public async setProjectInExecution(): Promise<void> {
        const modal = this.modalService.open(
            ExecuteProjectModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    projectDetails: this.projectDetails
                }
            }
        );
        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.router.navigate(['projecten']);
            }
        });
    }

    public restartMandateDisabledReason() {
        if (this.projectDetailsValue.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetailsValue.isClosed()) {
            return 'DISABLED.project_closed';
        }

        return '';
    }

    public inExecutionDisabledReason() {
        if (this.projectDetailsValue.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetailsValue.isClosed()) {
            return 'DISABLED.project_closed';
        }
        return '';
    }

    public disabledForTendermanagers() {
        if (this.projectDetailsValue.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetailsValue.isClosed()) {
            return 'DISABLED.project_closed';
        }

        if (!this.userService.isTenderManager()) {
            return 'DISABLED.not_tender_manager';
        }

        return '';
    }

    public setProjectToArchived() {
        const modal = this.modalService.open(
            DeleteProjectModalComponent,
            {
                data: {
                    projectDetails: this.projectDetails
                }
            }
        );
        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.router.navigate(['projecten']);
            }
        });
    }

    private async doMandateProject() {
        this.isSaving = true;
        await this.projectMandateService.startMandate(this.projectDetails).then((newStatus) => {
            this.mandateDetails = newStatus;
            return this.projectService.getProjectDetails(this.projectDetailsValue.id);
        }).then((details) => {
            this.projectDetailsChanged.emit(details);
            this.projectDetailsValue = details;
            const message = this.translateService.instant('COMPONENT.mandating.mandate_started');
            this.toastService.openSuccess(message);
            return this.updateMandateDetails();
        })
            .catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.start_mandate_failed'));
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

}
