import {Component, OnInit} from '@angular/core';
import {MsAuthService} from './service';

@Component({
    selector: 'app-msal-auth',
    template: ''
})
export class MsAuthComponent implements OnInit {

    constructor(
        private msAuthService: MsAuthService
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.msAuthService.isReady();

        if (this.msAuthService.isAuthenticated()) {
            await this.msAuthService.redirectAfterAuthentication();
        } else {
            await this.msAuthService.redirectAfterFailedAuthentication();
        }
    }

}
