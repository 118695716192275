<vwui-modal-header>
    {{ modalConfig.data.title }}
</vwui-modal-header>
<vwui-modal-body>
    <div class="mce-content-body mce-content-body--no-padding" [innerHTML]="modalConfig.data.message"></div>
</vwui-modal-body>
<vwui-modal-footer a11yAutoFocus a11yFocusTrap>
    <ng-container *ngIf="modalConfig.data.canDismiss && !modalConfig.data.isPreview">
        <vwui-button class="is-primary" (click)="modalRef.close(true)">
            {{ 'BUTTON.dismiss' | translate }}
        </vwui-button>
    </ng-container>
    <ng-container *ngIf="!modalConfig.data.canDismiss && !modalConfig.data.isPreview">
        <vwui-button class="is-primary" (click)="modalRef.close(false)">
            {{ 'BUTTON.close' | translate }}
        </vwui-button>
    </ng-container>
    <ng-container *ngIf="modalConfig.data.isPreview">
        <vwui-button class="is-primary" (click)="modalRef.close(false)">
            {{ 'BUTTON.close_example' | translate }}
        </vwui-button>
    </ng-container>
</vwui-modal-footer>
