// NOTE: these keys are stored in the database. DO NOT change them lightly :-)
export enum EffectCategory {
    PROBABILITY = 'probability',
    MONEY = 'money',
    TIME = 'time',
    QUALITY = 'quality',
    SAFETY = 'safety',
    SURROUNDINGS = 'surroundings',
    IMAGE = 'image',
    REPUTATION = 'reputation',
}

export const quantificationCategories: { key: EffectCategory; effectCategory: string; fieldName: string }[] = [
    {key: EffectCategory.MONEY, effectCategory: 'effectCategoryMoney', fieldName: 'money'},
    {key: EffectCategory.TIME, effectCategory: 'effectCategoryTime', fieldName: 'time'},
    {key: EffectCategory.QUALITY, effectCategory: 'effectCategoryQuality', fieldName: 'quality'},
    {key: EffectCategory.SAFETY, effectCategory: 'effectCategorySafety', fieldName: 'safety'},
    {key: EffectCategory.SURROUNDINGS, effectCategory: 'effectCategorySurroundings', fieldName: 'surroundings'},
    {key: EffectCategory.IMAGE, effectCategory: 'effectCategoryImage', fieldName: 'image'},
    {key: EffectCategory.REPUTATION, effectCategory: 'effectCategoryReputation', fieldName: 'reputation'}
];
