import {Directive, ElementRef, HostListener, OnInit, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Directive({
    selector: 'vwui-input[appFormatEuroCents]'
})
export class FormatEuroCentsDirective implements OnInit {

    constructor(
        @Self() private ngControl: NgControl,
        private elementRef: ElementRef,
        private translate: TranslateService
    ) {
    }

    public static convertToNumber(value: string | number): number {
        const stringValue = (`${value}`).replace('€', '').trim();
        const normalizedValue = stringValue.replace(/[^0-9]/g, '');
        const numberValue = Number(normalizedValue);
        const isNegative = stringValue.startsWith('-');

        if (isNegative && normalizedValue === '' || numberValue === 0 && normalizedValue === '' || stringValue === '-') {
            return null;
        }

        if (isNegative && numberValue === 0) {
            return -0;
        }

        return isNegative ? (0 - numberValue) : numberValue;
    }

    public static convertNumberToEuros(value: string | number, localeString: string): string {
        const numberValue = FormatEuroCentsDirective.convertToNumber(value);
        const formattedValue = numberValue ? numberValue.toLocaleString(localeString) : '';

        if (value !== '' && value !== null && !isNaN(numberValue)) {
            return '€ ' + formattedValue;
        }

        return '';
    }

    ngOnInit() {
        this.formatInput();
    }

    @HostListener('input') onInput() {
        this.formatInput();
    }

    private formatInput(): void {
        const control = this.ngControl.control;
        const value = FormatEuroCentsDirective.convertToNumber((`${control.value}`).trim());
        if (!isNaN(value)) {
            const formattedEuro = FormatEuroCentsDirective.convertNumberToEuros( value, this.translate.getDefaultLang() );
            control.setValue(formattedEuro);
        } else {
            control.setValue('');
        }
    }

}
