<div class="current-progress" [ngStyle]="{'width': progress + '%'}"></div>
<ng-container *ngIf="steps.length > 0">
    <div
        *ngFor="let step of steps; let i = index;"
        class="step"
        [routerLink]="step.urlSlugs"
        [ngStyle]="{'left': step.progress + '%'}"
        [ngClass]="{'step--active': step.active}"
        appClickBlur
        a11yKeyboardClickable
    >
        <vwui-tooltip class="step__tooltip" position="bottom" [label]="step.tooltip">
            <div class="step__text" [attr.data-label]="step.tooltip">{{ (i + 1) }}</div>
            <div class="step__blink" *ngIf="step.blink"></div>
        </vwui-tooltip>
    </div>
</ng-container>
