import {Component} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Company, ICompany, IUser, User} from '../../models';
import {CompanyService, ToastService, UserService} from '../../services';
import {ObjectUtil} from '../../utils';
import {CompanyType, ICompanyRights, IUserIdentity, IUserRights} from '../../models/user/user';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-change-user-modal',
    templateUrl: './change-user-modal.component.html',
    styleUrls: ['./change-user-modal.component.scss']
})
export class ChangeUserModalComponent {

    public form = new FormGroup({
        userIdentity: new FormControl([], [Validators.required]),
        adminCompanies: new FormControl([], []),
        manageableCompanies: new FormControl([], []),
        editableCompanies: new FormControl([], []),
        viewableCompanies: new FormControl([], [])
    });

    private readonly userInfo?: IUserRights;
    public saving: boolean;
    public companies$: Promise<ICompanyRights[]>;
    public companyType?: CompanyType;
    public newCompanyUser: boolean;
    private subscriptions: Subscription[] = [];
    private atLeastOneCompanyPresent: ValidatorFn = (control) => {
        if (control.value.adminCompanies.length === 0
            && control.value.manageableCompanies.length === 0
            && control.value.editableCompanies.length === 0
            && control.value.viewableCompanies.length === 0) {
            return {
                atLeastOneCompanyPresent: true
            };
        }
        return null;
    };

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{
            companyType?: CompanyType;
            userRights: IUserRights;
        }>,
        private companyService: CompanyService,
        private userService: UserService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        this.userInfo = modalConfig.data.userRights;
        this.companyType = modalConfig.data.companyType;
        this.companies$ = companyService.getCompanies().then(
            companies => companies.map(
                company => {
                    return {
                        id: company.id,
                        name: company.name,
                        admin: false,
                        tenderManager: false,
                        editor: false,
                        viewer: false
                    };
                }
            )
        );
        this.newCompanyUser = !modalConfig.data.userRights;
        this.initForm();
    }

    public submit() {
        this.saving = true;
        if (this.form.valid) {
            const formValue = this.form.value;

            this.form.disable();

            const rightsForSubmission: IUserRights = ObjectUtil.cloneObject(this.userInfo || this.rightsFromIdentity(formValue.userIdentity));
            rightsForSubmission.companyRights.length = 0;
            formValue.adminCompanies.forEach((adminCompany: ICompanyRights) => {
                const companyForSubmission = this.companyRightsForSubmission(rightsForSubmission, adminCompany);
                companyForSubmission.admin = true;
            });
            formValue.manageableCompanies.forEach((manageableCompany: ICompanyRights) => {
                const companyForSubmission = this.companyRightsForSubmission(rightsForSubmission, manageableCompany);
                companyForSubmission.tenderManager = true;
            });
            formValue.editableCompanies.forEach((editableCompany: ICompanyRights) => {
                const companyForSubmission = this.companyRightsForSubmission(rightsForSubmission, editableCompany);
                companyForSubmission.editor = true;
            });
            formValue.viewableCompanies.forEach((viewableCompany: ICompanyRights) => {
                const companyForSubmission = this.companyRightsForSubmission(rightsForSubmission, viewableCompany);
                companyForSubmission.viewer = true;
            });

            this.userService.saveUserRights(rightsForSubmission)
                .then((userInfo) => {
                    this.toastService.openSuccess(this.translateService.instant('COMPONENT.change_user.toast.success'));
                    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
                    this.subscriptions = [];
                    this.modalRef.close(userInfo);
                })
                .catch((e) => this.toastService.openError(e, this.translateService.instant('COMPONENT.change_user.toast.error')))
                .finally(() => {
                    this.form.enable();
                    this.saving = false;
                });
        }
    }

    private companyRightsForSubmission(userRights: IUserRights, adminCompany: ICompanyRights) {
        const existingCompanyRightsForSubmission: ICompanyRights = userRights
                .companyRights
                .find(viewableCompany => viewableCompany.id === adminCompany.id);
        if (existingCompanyRightsForSubmission) {
            return existingCompanyRightsForSubmission;
        } else {
            const companyRightsForSubmission = {
                id: adminCompany.id,
                name: adminCompany.name,
                admin: false,
                tenderManager: false,
                editor: false,
                viewer: false
            };
            userRights.companyRights.push(companyRightsForSubmission);
            return companyRightsForSubmission;
        }
    }

    private initForm() {
        this.form.setValue({
            userIdentity: this.userInfo ? {
                name: this.userInfo.fullName || this.userInfo.uniqueName,
                emailAddress: this.userInfo.uniqueName
            } : null,
            adminCompanies: this.userInfo?.companyRights.filter(company => company.admin) || [],
            manageableCompanies: this.userInfo?.companyRights.filter(company => company.tenderManager) || [],
            editableCompanies: this.userInfo?.companyRights.filter(company => company.editor) || [],
            viewableCompanies: this.userInfo?.companyRights.filter(company => company.viewer) || []
        });
        if (this.userInfo) {
            this.form.controls.userIdentity.disable();
            this.form.removeValidators(this.atLeastOneCompanyPresent);
        } else {
            this.form.addValidators(this.atLeastOneCompanyPresent);
        }

        // RISC-773 - When adding an admin company, make sure to remove it from editable companies
        this.subscriptions.push(this.form.controls.adminCompanies.valueChanges.subscribe((formValues) => {
            let editableCompanies = this.form.controls.editableCompanies.value;
            const previousEditableCompanies = editableCompanies.length;
            editableCompanies = editableCompanies.filter((editableCompany) => {
                return !formValues.find((company) => {
                    return company.id === editableCompany.id;
                });
            });

            if (editableCompanies.length !== previousEditableCompanies) {
                this.form.controls.editableCompanies.setValue(editableCompanies);
            }
        }));

        // RISC-773 - When adding an editable company, make sure to remove it from admin companies
        this.subscriptions.push(this.form.controls.editableCompanies.valueChanges.subscribe((formValues) => {
            let adminCompanies = this.form.controls.adminCompanies.value;
            const previousAdminCompanyLength = adminCompanies.length;
            adminCompanies = adminCompanies.filter((adminCompany) => {
                return !formValues.find((company) => {
                    return company.id === adminCompany.id;
                });
            });

            if (adminCompanies.length !== previousAdminCompanyLength) {
                this.form.controls.adminCompanies.setValue(adminCompanies);
            }
        }));
    }

    private rightsFromIdentity(userIdentity: IUserIdentity): IUserRights {
        return {
            fullName: userIdentity.name,
            uniqueName: userIdentity.emailAddress,
            companyTypeRights: [],
            companyRights: []
        };
    }
}
