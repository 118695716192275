import {EventEmitter} from '@angular/core';
import {Category} from '../definition/category';
import {ICategoryData, ITisDefinitionData} from '..';
import {ObjectUtil} from '../../utils';
import {IDefinition} from '../definition/definition';
import {Question} from '../definition/question';

export class TisDefinition implements IDefinition {
    id: number;
    versionId: number;
    createdAt: Date;
    isPublished: boolean;
    publishedAt: Date;
    categories: Category[] = [];

    public onChange: EventEmitter<IDefinition>;

    public get questions(): Question[] {
        return this.categories.flatMap(c => c.questions);
    }

    constructor(data: ITisDefinitionData) {
        this.id = data.id;
        this.versionId = data.versionId;
        this.createdAt = new Date(data.createdAt);
        this.isPublished = data.isPublished;
        this.publishedAt = new Date(data.publishedAt);
        this.categories = data.categories.map((category) => new Category(category, this));

        this.onChange = new EventEmitter();
    }

    public getDefinition(): IDefinition {
        return this;
    }

    public cloneData(): TisDefinition {
        return ObjectUtil.cloneObject(this);
    }

    public broadcastChanges(): void {
        this.onChange.emit(this.getDefinition());
    }

    public getCategories(): Category[] {
        return this.categories;
    }

    public addCategory(data: ICategoryData): Category {
        const newCategory = new Category(data, this);
        this.categories.push(newCategory);
        this.broadcastChanges();

        return newCategory;
    }

    public getSubCategories(): Category[] {
        const subCategories = [];

        this.categories.forEach((category) => {
            category.subCategories.forEach((subCategory) => {
                subCategories.push(subCategory);
            });
        });

        return subCategories;
    }

    /**
     * Get all the unique subcategory names sorted ascending
     */
    public getSubCategoryNames(): string[] {
        return this.getSubCategories().map((subCategory) => subCategory.name).filter((value, index, self) => self.indexOf(value) === index).sort();
    }

    public deleteCategory(category: Category): void {
        this.categories = this.categories.filter((theme) => theme !== category);
        this.broadcastChanges();
    }

    public moveCategoryUp(category: Category) {
        const index = ObjectUtil.findIndexForEqualObject(category, this.categories);
        const newIndex = Math.max(0, index - 1);

        this.swapCategories(index, newIndex);
    }

    public moveCategoryDown(category: Category) {
        const index = ObjectUtil.findIndexForEqualObject(category, this.categories);
        const newIndex = Math.min(this.categories.length - 1, index + 1);

        this.swapCategories(index, newIndex);
    }

    protected swapCategories(index1: number, index2: number) {
        const item1 = this.categories[index1];
        const item2 = this.categories[index2];

        this.categories[index1] = item2;
        this.categories[index2] = item1;
    }

}
