<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails?.projectDetails.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ 'PAGE_RISK_RAINBOW.tables.titles.risks_and_opportunities' | translate }}</h1>
    <div *ngIf="!clientOnly">
        {{ 'PAGE_RISK_RAINBOW.all_risks.explanation' | translate }}
    </div>
    <div *ngIf="options && options.includes('filtered')">
        {{ 'PAGE_RISK_RAINBOW.filtered.explanation' | translate }}
    </div>

    <div class="grid-spacer grid-spacer--large"></div>
    <app-pdf-page-risks-and-opportunities-content
        [projectDetails]="pdfDetails?.projectDetails"
        [forPdf]="true"
        [clientOnly]="clientOnly"
        [options]="options"
        [filters]="getFilters()"
    ></app-pdf-page-risks-and-opportunities-content>

    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
