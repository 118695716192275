import {Component, Input} from '@angular/core';
import {UserSearchService} from '../../services';
import {ICompanyType, IUserIdentity} from '../../models/user/user';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {AbstractControl} from '@angular/forms';
import {fromPromise} from 'rxjs/internal-compatibility';
import {FormUtil} from '../../utils';

@Component({
    selector: 'app-user-identity-input',
    templateUrl: './user-identity-input.component.html',
    styleUrls: ['./user-identity-input.component.scss']
})
export class UserIdentityInputComponent {

    @Input() public control: AbstractControl;
    @Input() public companyType: ICompanyType;
    @Input() public multiple = false;
    @Input() set identitiesToExclude(observable: Observable<IUserIdentity[]>) {
        // RISC-822 - Set the combined observable after the exclusions has been initialized
        // Otherwise the previous unchanging observable will be used
        this.items$ = this.getItems(observable);
    }

    private searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public items$ = this.getItems(of([]));

    constructor(
        private userSearchService: UserSearchService
    ) {
    }

    public addOption(emailAddress: string): IUserIdentity | boolean {
        if (FormUtil.isValidEmailAddress(emailAddress)) {
            this.reset();

            return {
                name: emailAddress,
                emailAddress
            };
        }

        return false;
    }

    public startSearchUsers(term: string): void {
        this.searchTerm$.next(term);
    }

    public searchThroughUsers(term: string, item: IUserIdentity): boolean {
        return item.name && item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
            || item.emailAddress && item.emailAddress.toLocaleLowerCase().includes(term.toLocaleLowerCase());
    }

    public reset(): void {
        this.searchTerm$.next('');
    }

    private getItems(observable: Observable<IUserIdentity[]>) {
        return combineLatest([
            this.searchTerm$.pipe(
                debounceTime(300)
            ),
            observable
        ]).pipe(
            switchMap(([term, excludedIdentities]) => {
                if (!this.companyType?.azureAdRegistration) {
                    return of([]);
                }

                return fromPromise(
                    this.userSearchService.searchUsers(term, this.companyType)
                        .then((items) => {
                            return items.filter((item) => !excludedIdentities.find((excludedIdentity) => {
                                return excludedIdentity.emailAddress.toLocaleLowerCase() === item.emailAddress.toLocaleLowerCase();
                            }));
                        })
                );
            })
        );

    }

}
