<vwui-spinner class="vwui-spinner--with-margin" *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div class="main-wrapper" *ngIf="!quickscanSubmitted()">
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotFilledIn.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.quickscan_not_filled' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotSubmitted.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.project_not_classified' | translate }}</app-explanation>
</div>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible() && quickscanSubmitted()">
    <app-scroll-navigation [navItems]="navItems"></app-scroll-navigation>

    <div class="tis">
        <vwui-header>
            {{ 'PDF.modal.tis' | translate : { tisLabelShort: projectDetails.projectInfo.tisLabelShort } }}
            <small class="header-subtitle">{{ 'PAGE_FILL_TIS.version' | translate : { tisLabelShort: projectDetails.projectInfo.tisLabelShort } }}
                : {{ tisDefinition?.versionId }}</small>
            <small class="header-subtitle">{{ 'PAGE_FILL_TIS.project_id' | translate }}: {{ projectDetails.id }}</small>
        </vwui-header>

        <div class="label spacing-mb" [class.text-warning]="hasEmptyRequiredQuestion() && isPreviousUrlMandating">{{ 'PAGE_FILL_TIS.required_fields' | translate}}</div>
        <div class="tis-theme"
             *ngFor="let tisCategory of tisDefinition?.categories; let categoryIndex = index">
            <a [name]="'Theme' + tisCategory.id" [id]="'Theme' + tisCategory.id"></a>
            <vwui-table [compactable]="false">
                <div slot="table-title" [id]="'ThemeTitleTIS' + tisCategory.id">{{ categoryIndex + 1 }}. {{ tisCategory.name }}</div>
                <vwui-table-row>
                    <vwui-table-header
                        class="tis-col tis-col--question">{{ 'PAGE_FILL_TIS.table.column.question' | translate }}</vwui-table-header>
                    <vwui-table-header
                        class="tis-col tis-col--answer">{{ 'PAGE_FILL_TIS.table.column.answer' | translate }}</vwui-table-header>
                </vwui-table-row>
                <ng-container *ngFor="let question of tisCategory.questions">
                    <vwui-table-row *ngIf="isDependencyMet(question)" [ngClass]="{'tis-col tis-col--with-attachments': question.allowAttachments}">
                        <vwui-table-column class="tis-col tis-col--question">
                            <span [class.question--required]="!question.isOptionalOrCalculated">{{ question.name }}</span>
                            <ng-container *ngIf="question.info">
                                <vwui-tooltip [label]="question.info">
                                    <vwui-icon class="info" iconName="info"></vwui-icon>
                                </vwui-tooltip>
                            </ng-container>
                        </vwui-table-column>
                        <vwui-table-column class="tis-col tis-col--answer">
                            <app-question-input
                                [question]="question"
                                [ngModel]="getInitialValue(question)"
                                (valueChanged)="answerChanged($event, question)"
                                [readonly]="!canEditTis()"
                                [departments]="departments"
                            >
                            </app-question-input>
                        </vwui-table-column>
                    </vwui-table-row>
                    <vwui-table-row *ngIf="question.allowAttachments" class="tis-col tis-col--attachments">
                        <vwui-table-column class="tis-col tis-col--attachments-label">
                            {{ 'PAGE_FILL_TIS.table.column.attachments' | translate }}
                        </vwui-table-column>
                        <vwui-table-column class="tis-col tis-col--attachments-list">
                            <app-question-attachments
                                [question]="question"
                                [attachments]="getQuestionAttachments(question)"
                                (valueChanged)="attachmentsChanged($event, question)"
                                [readonly]="!canEditTis()"
                            >
                            </app-question-attachments>
                        </vwui-table-column>
                    </vwui-table-row>
                </ng-container>
            </vwui-table>
        </div>
    </div>
</div>
