<div
    class="chart-signal-matrix"
    [ngClass]="{
        'chart-signal-matrix--large': large,
        'chart-signal-matrix--signal-reversed': signalReversed
    }"
>
    <div class="chart-signal-matrix__wrapper" [ngClass]="{'chart-signal-matrix__wrapper--horizontal-title': configuration.horizontal.title, 'chart-signal-matrix__wrapper--vertical-title': configuration.vertical.title}">
        <ng-container *ngIf="configuration.targetValue">
            <div class="chart-signal-matrix__target" [ngStyle]="{'top.%': getVerticalPositionValue(configuration.targetValue.vertical), 'left.%': getHorizontalPositionValue(configuration.targetValue.horizontal)}">
                <div class="chart-signal-matrix__target-dot"></div>
            </div>

            <div class="chart-signal-matrix__target-line-horizontal" [ngStyle]="{'top.%': getVerticalPositionValue(configuration.targetValue.vertical)}"></div>
            <div class="chart-signal-matrix__target-line-vertical" [ngStyle]="{'left.%': getHorizontalPositionValue(configuration.targetValue.horizontal)}"></div>
        </ng-container>

        <ng-container *ngIf="configuration.values?.length > 0">
            <div class="chart-signal-matrix__dots">
                <ng-container *ngFor="let value of configuration.values">
                    <div
                        class="chart-signal-matrix__dot"
                        [ngStyle]="{
                            'top.%': getVerticalPositionValue(value.vertical),
                            'left.%': getHorizontalPositionValue(value.horizontal),
                            'marginLeft.px': value.offset?.x,
                            'marginTop.px': value.offset?.y
                        }"
                        [ngClass]="{'chart-signal-matrix__dot--inactive': !value.inFilter}"
                        (click)="valueClicked(value)"
                        a11yKeyboardClickable
                    >
                        <vwui-tooltip *ngIf="value.tooltip" [label]="value.tooltip" position="bottom">
                            <div class="chart-signal-matrix__dotsize">##</div>
                        </vwui-tooltip>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngFor="let label of labelsHorizontal">
            <div class="chart-signal-matrix__label-horizontal" [ngStyle]="{'left.%': label.position}" [ngClass]="{'chart-signal-matrix__label-horizontal--selected': label.position === targetPositionHorizontal}">{{ label.label }}</div>
        </ng-container>
        <ng-container *ngFor="let label of labelsVertical">
            <div class="chart-signal-matrix__label-vertical" [ngStyle]="{'top.%': label.position}" [ngClass]="{'chart-signal-matrix__label-vertical--selected': label.position === targetPositionVertical}">{{ label.label }}</div>
        </ng-container>

        <ng-container *ngIf="configuration.horizontal.title">
            <div class="chart-signal-matrix__label-horizontal-title">{{ configuration.horizontal.title }}</div>
        </ng-container>
        <ng-container *ngIf="configuration.vertical.title">
            <div class="chart-signal-matrix__label-vertical-title">{{ configuration.vertical.title }}</div>
        </ng-container>
    </div>
</div>
