import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    private static readonly DEFAULT_PRESENTATION_FORMAT = 'DD-MM-YYYY HH:mm';
    private static readonly SUPPORTED_FORMATS = [
        'YYYY-MM-DDTHH:mm:ss'
    ];

    /**
     * Takes a value and formats date
     */
    transform(value?: number, format: string = null, momentType: 'default'|'timestamp' = 'default') {
        if (!value) {
            return '';
        }

        moment.locale('nl');
        const date = momentType === 'timestamp' ? moment.unix(value) : moment(value, DateFormatPipe.SUPPORTED_FORMATS);
        if (date.isValid()) {
            return date.format(format || DateFormatPipe.DEFAULT_PRESENTATION_FORMAT);
        } else {
            console.warn('Cannot format date: ', value);
            return value;
        }
    }
}
