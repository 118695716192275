export const TRANSLATION_NL = {
    APPLICATION: {
        title: 'Project Risico Monitor',
        welcome: 'Welkom bij de Project Risico Monitor'
    },
    YES_OR_NO: {
        yes: 'Ja',
        no: 'Nee'
    },
    MENU: {
        labels: {
            management: 'Beheer definities',
            management_users: 'Beheer overig',
            projects: 'Projecten',
            quickscan_versions: 'Quickscan versies',
            tis_versions: 'TIS',
            risk_library: 'Risico bibliotheek',
            opportunity_library: 'Kansen bibliotheek',
            task_library: 'Taken bibliotheek',
            tender_manager_mgmt: 'Tendermanagers',
            company_mgmt: 'Werkmaatschappijen',
            company_type_mgmt: 'Bedrijfstypes',
            notifications: 'Meldingen',
            user_mgmt: 'Gebruikersbeheer',
            accessible_step_mgmt: 'Stappenbeheer'
        }
    },
    BUTTON: {
        logout: 'Uitloggen',
        update: 'Wijzigen',
        create: 'Toevoegen',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
        save: 'Opslaan',
        close: 'Sluiten',
        archive: 'Verwijderen',
        filters: 'Filteren',
        dismiss: 'Verbergen',
        close_example: 'Voorbeeld sluiten',
        change_status: 'Status aanpassen'
    },
    PROJECT_STATUS: {
        initial: 'In behandeling',
        quickscan_saved: 'Quickscan definitief',
        mandating: 'In goedkeuringsflow',
        mandating_failed: '{{ tisLabelShort }} afgekeurd',
        mandating_succeeded: '{{ tisLabelShort }} goedgekeurd',
        in_execution: 'In uitvoering',
        archived: 'Verwijderd',
        on_hold: 'On hold',
        closed: 'Afgesloten'
    },
    PROJECT_MENU: {
        download: 'Download PDF',
        share: 'Delen van project',
        on_hold: 'Project on hold zetten',
        on_resume: 'Project hervatten',
        archive: 'Verwijder project',
        close: 'Project afsluiten'
    },
    CLOSE_PROJECT: {
        modal: {
            title: 'Project afsluiten',
            question: 'Weet je zeker dat je dit project wil aflsuiten? Na afsluiten kun je geen aanpassingen meer doen’.',
            success: 'Het project is succesvol afgesloten',
            close_button: 'Afsluiten'
        }
    },
    DELETE_PROJECT: {
        modal: {
            title: 'Project verwijderen',
            question: 'Weet je zeker dat je dit project wil verwijderen? Na de uitvoeringsfase kun je het afsluiten. Dan blijft het project beschikbaar binnen de applicatie.',
            success: 'Het project is succesvol verwijderd'
        }
    },
    PROJECT_ON_HOLD: {
        modal: {
            title: 'Project status',
            question: 'Weet u zeker dat u de status van dit project op "On hold" wilt zetten?',
            question_resume: 'Weet u zeker dat u de status van dit project van "On hold" naar "{{beforeStatus}}" wilt aanpassen?',
            success: 'Het project is succesvol op on hold gezet',
            success_resume: 'Het project is succesvol hervat',
            error: 'Het is helaas niet gelukt om het project op hold te zetten. Probeer het later nogmaals.',
            error_resume: 'Het is helaas niet gelukt om het project te hervatten. Probeer het later nogmaals.'
        }
    },
    EXECUTE_PROJECT: {
        modal: {
            title: 'Project uitvoeren',
            description: 'Hiermee wordt het project naar de status \'in uitvoer\' geplaatst. Tevens wordt de projectleider eigenaar van dit project binnen PRM. Geef het e-mailadres op van de projectleider die het project gaat uitvoeren.',
            project_lead: 'E-mailadres projectleider'
        },
        BUTTON: {
            assign: 'Toewijzen en in uitvoer plaatsen'
        }
    },
    PROJECT_QUICKSCAN_CHANGED: {
        modal: {
            title: 'Antwoorden aangepast van ingediende quickscan',
            question: 'U heeft één of meerdere antwoorden aangepast in de quickscan. Hierdoor zal u de quickscan opnieuw moeten indienen.\n\nWilt u de gewijzigde antwoorden alsnog opslaan?'
        }
    },
    COMPONENT: {
        classification: {
            label: 'Classificatie',
            value: 'Strikt vertrouwelijk'
        },
        tendermanager: {
            unique_name: 'Persoon',
            company_names: {
                label: 'Werkmaatschappij(en)',
                placeholder: 'Naam werkmaatschappij'
            }
        },
        USER_INPUT: {
            userPlaceholder: 'Selecteer persoon',
            userNotFound: 'Geen persoon gevonden.',
            emailPlaceholder: 'E-mailadres',
            emailValidation: 'Vul een geldig e-mailadres in.',
            emailAddOption: 'toevoegen'
        },
        company: {
            name: 'Naam werkmaatschappij',
            placeholder: 'Naam werkmaatschappij',
            type: 'Bedrijfstype',
            companyCode: 'Werkmaatschappij code',
            apiProjectCreation: {
                label: 'Projecten aanmaken',
                on: 'Mogelijk via het Projectenportaal en via PRM',
                off: 'Enkel via PRM',
                only: 'Enkel via het Projectenportaal'
            },
            table: {
                permissions: 'Toegangsrechten',
                column: {
                    name: 'Persoon',
                    edit_rights: 'Bewerker',
                    admin_rights: 'PRM-Expert'
                }
            }
        },
        company_type: {
            name: 'Naam bedrijfstype',
            construction_costs_label: 'Naamgeving bouwkosten in stap 1',
            tis_label_short: 'Naamgeving tbv TIS (afkorting)',
            tis_label_long: 'Naamgeving tbv TIS (voluit)',
            placeholder: {
                name: 'Naam bedrijfstype',
                construction_costs_label: 'Naamgeving bouwkosten in stap 1',
                tis_label_short: 'Naamgeving tbv TIS (afkorting)',
                tis_label_long: 'Naamgeving tbv TIS (voluit)'
            },
            base_company_type: {
                label: 'Neem gepubliceerde quickscan / TIS / bibliotheken over van type',
                placeholder: 'Basis bedrijfstype',
                not_found: 'Nog geen basis bedrijfstype toegevoegd.'
            },
            contract_types: {
                label: 'Contract types (naast Overig)',
                placeholder: 'Specificeer de contracttypes',
                not_found: 'Nog geen contracttype toegevoegd.'
            },
            predefined_contracttype: {
                integrated: 'Geïntegreerd',
                traditional: 'Traditioneel'
            },
            additional_collaborations: {
                label: 'Additionele samenwerkingsvormen',
                placeholder: 'Specificeer de samenwerkingsvorm',
                not_found: 'Specificeer de samenwerkingsvorm.'
            },
            quantification_categories_risks: {
                label: 'Kwantificatiecategorieën(risico\'s)',
                placeholder: 'Selecteer categorie(ën)',
                not_found: 'Selecteer categorie(ën)'
            },
            quantification_categories_opportunities: {
                label: 'Kwantificatiecategorieën(kansen)',
                placeholder: 'Selecteer categorie(ën)',
                not_found: 'Selecteer categorie(ën)'
            },
            quantification_categories_tasks: {
                label: 'Kwantificatiecategorieën(taken)',
                placeholder: 'Selecteer categorie(ën)',
                not_found: 'Selecteer categorie(ën)'
            },
            risk_managers: {
                label: 'Riskmanagers',
                placeholder: 'Selecteer een riskmanager',
                not_found: 'Selecteer een riskmanager'
            },
            mandating_description: {
                label: 'Omschrijving mandateringsflow'
            },
            ad_registration: {
                label: 'Azure gebruikers',
                placeholder: 'Geen Azure registratie gekoppeld',
                not_found: 'Geen Azure registratie gekoppeld'
            }
        },
        library_item_filters: {
            title: 'Filteren',
            filter: {
                score: 'Score',
                shared_with_principal: 'Gedeeld met opdrachtgever',
                include_proposals: 'Voorstellen tonen',
                selected_only: 'Toon enkel aangevinkt',
                action_holder: 'Actiehouder',
                theme: 'Thema',
                best_guess: 'Best guess',
                status: 'Status'
            },
            label: {
                risks: 'Risico\'s',
                opportunities: 'Kansen',
                larger_than: 'Groter dan',
                min: 'Min.',
                max: 'Max.'
            }
        },
        library_item_filters_selected: {
            button: {
                risk_score: 'Min. score risico\'s: {{score}}',
                opportunity_score: 'Min. score kansen: {{score}}',
                shared_with_principal: 'Gedeeld met opdrachtgever',
                include_proposals: 'Voorstellen tonen',
                selected_only: 'Toon enkel aangevinkt',
                best_guess_min: 'Min. best guess: {{price}}',
                best_guess_max: 'Max. best guess: {{price}}'
            }
        },
        library_mgmt: {
            category: 'Categorie: ',
            create_category: 'Voeg categorie toe',
            delete_category: 'Verwijder categorie',
            update_category: 'Bewerk categorie',
            library_type: {
                risks: {
                    item_type: 'Risico',
                    create_item: {
                        title: 'Voeg risico toe'
                    },
                    update_item: {
                        title: 'Pas bestaande risico aan'
                    },
                    delete_item: {
                        title: 'Verwijder risico',
                        description: 'Weet u zeker dat u dit risico uit de bibiliotheek wilt verwijderen?'
                    },
                    item: {
                        subject: 'Naam',
                        category: 'Categorie',
                        riskDescription: 'Risico',
                        causeDescription: 'Oorzaak',
                        effectDescription: 'Gevolg',
                        effectCategoryMoney: 'Geld',
                        effectCategoryTime: 'Tijd',
                        effectCategoryQuality: 'Kwaliteit',
                        effectCategorySafety: 'Veiligheid',
                        effectCategorySurroundings: 'Omgeving',
                        effectCategoryImage: 'Imago',
                        effectCategoryReputation: 'Reputatie',
                        relationOtherRisks: 'Relatie met andere risico(s)',
                        possibleCounterMeasures: 'Mogelijke beheersmaatregelen'
                    },
                    project_specific: {
                        create_item: {
                            title: 'Voeg projectspecifiek risico toe'
                        },
                        update_item: {
                            title: 'Pas projectspecifiek bestaand risico aan'
                        },
                        delete_item: {
                            title: 'Verwijder projectspecifiek risico',
                            description: 'Weet u zeker dat u dit projectspecifieke risico voor dit project wilt verwijderen?'
                        },
                        duplicate_item: {
                            info: 'Dit risico is gedupliceerd. Pas de gegevens aan en sla de wijzigingen op om het gedupliceerde risico toe te voegen aan het huidige project.'
                        }
                    }
                },
                opportunities: {
                    item_type: 'Kans',
                    create_item: {
                        title: 'Voeg kans toe'
                    },
                    update_item: {
                        title: 'Pas bestaande kans aan'
                    },
                    delete_item: {
                        title: 'Verwijder kans',
                        description: 'Weet u zeker dat u deze kans uit de bibiliotheek wilt verwijderen?'
                    },
                    item: {
                        subject: 'Beschrijving',
                        category: 'Categorie',
                        riskDescription: 'Kans',
                        causeDescription: 'Oorzaak',
                        effectDescription: 'Gevolg',
                        effectCategoryMoney: 'Geld',
                        effectCategoryTime: 'Tijd',
                        effectCategoryQuality: 'Kwaliteit',
                        effectCategorySafety: 'Veiligheid',
                        effectCategorySurroundings: 'Omgeving',
                        effectCategoryImage: 'Imago',
                        effectCategoryReputation: 'Reputatie',
                        relationOtherRisks: 'Relatie met andere kansen',
                        possibleCounterMeasures: 'Hoe kans te benutten'
                    },
                    project_specific: {
                        create_item: {
                            title: 'Voeg projectspecifieke kans toe'
                        },
                        update_item: {
                            title: 'Pas projectspecifieke kans aan'
                        },
                        delete_item: {
                            title: 'Verwijder projectspecifieke kans',
                            description: 'Weet u zeker dat u deze projectspecifieke kans uit dit project wilt verwijderen?'
                        },
                        duplicate_item: {
                            info: 'Deze kans is gedupliceerd. Pas de gegevens aan en sla de wijzigingen op om de gepubliceerde kans toe te voegen aan het huidige project.'
                        }
                    }
                },
                tasks: {
                    item_type: 'Taak',
                    create_item: {
                        title: 'Voeg taak toe'
                    },
                    update_item: {
                        title: 'Pas bestaande taak aan'
                    },
                    delete_item: {
                        title: 'Verwijder taak',
                        description: 'Weet u zeker dat u deze taak uit de bibiliotheek wilt verwijderen?'
                    },
                    item: {
                        subject: 'Beschrijving',
                        category: 'Categorie',
                        riskDescription: 'Taak',
                        causeDescription: 'Oorzaak',
                        effectDescription: 'Gevolg',
                        effectCategoryMoney: 'Geld',
                        effectCategoryTime: 'Tijd',
                        effectCategoryQuality: 'Kwaliteit',
                        effectCategorySafety: 'Veiligheid',
                        effectCategorySurroundings: 'Omgeving',
                        effectCategoryImage: 'Imago',
                        effectCategoryReputation: 'Reputatie',
                        relationOtherRisks: 'Relatie met andere taken',
                        possibleCounterMeasures: 'Rekening houden met'
                    },
                    project_specific: {
                        create_item: {
                            title: 'Voeg projectspecifieke taak toe'
                        },
                        update_item: {
                            title: 'Pas projectspecifieke taak aan'
                        },
                        delete_item: {
                            title: 'Verwijder projectspecifieke taak',
                            description: 'Weet u zeker dat u deze projectspecifieke taak uit dit project wilt verwijderen?'
                        },
                        duplicate_item: {
                            info: 'Deze taak is gedupliceerd. Pas de gegevens aan en sla de wijzigingen op om de gepubliceerde taak toe te voegen aan het huidige project.'
                        }
                    }
                }
            },
            modal: {
                create_category: {
                    title: 'Voeg categorie toe',
                    name: 'Categorienaam',
                    numberPrefix: 'Nummer-prefix'
                },
                update_category: {
                    title: 'Bewerk categorie',
                    name: 'Categorienaam',
                    numberPrefix: 'Nummer-prefix'
                },
                delete_category: {
                    title: 'Verwijder categorie',
                    description: 'Weet u zeker dat u deze categorie wilt verwijderen?'
                }
            },
            item: {
                is_active: 'Actief?',
                active: 'Kan worden toegevoegd aan een project',
                entryNumber: 'Nummer',
                subject: 'Naam',
                risk: 'Omschrijving',
                cause: 'Oorzaak',
                description: 'Omschrijving',
                quantification: 'Kwantificatie',
                quantification_description: 'Geef aan op welk onderde(e)l(en) dit betrekking heeft. Let op: alleen categorieën aangevink op bedrijfstype niveau zijn zichtbaar in een project.',
                riskMitigation: 'Beheersmaatregel'
            }
        },
        quickscan: {
            create_category: 'Thema toevoegen',
            create_question: 'Vraag toevoegen',
            create_answer: 'Antwoord toevoegen',
            table: {
                header: {
                    weighted_score: 'Gewogen score'
                },
                column: {
                    nr: 'Nr.',
                    question: 'Vraag',
                    maxscore: 'Max score'
                }
            },
            modal: {
                create_category: {
                    title: 'Thema toevoegen',
                    field: {
                        name: 'Naam',
                        type: 'Type',
                        weightedScore: 'Gewogen score',
                        type_value_risk: 'Risico',
                        type_value_value: 'Waarde'
                    }
                },
                update_category: {
                    title: 'Thema bewerken'
                },
                delete_category: {
                    title: 'Thema verwijderen',
                    description: 'Weet u zeker dat u dit thema wilt verwijderen?'
                },
                delete_question: {
                    title: 'Vraag verwijderen',
                    description: 'Weet u zeker dat u deze vraag wilt verwijderen?'
                },
                create_question: {
                    title: 'Vraag toevoegen',
                    field: {
                        category: 'Onderwerp',
                        category_add: 'Nieuw onderwerp toevoegen',
                        category_placeholder: 'Selecteer een onderwerp',
                        name: 'Naam',
                        info: 'Toelichting',
                        answer: 'Antwoord',
                        score: 'Score',
                        red_flag: 'Red Flag',
                        library_items: 'Bibliotheekitems die automatisch worden toegevoegd wanneer bovenstaand antwoord wordt gegeven.'
                    },
                    placeholder: {
                        library_items: 'Selecteer de bibliotheekitems.'
                    }
                },
                update_question: {
                    title: 'Vraag bewerken'
                }
            }
        },
        tis: {
            create_category: 'Categorie toevoegen',
            create_question: 'Vraag toevoegen',
            create_answer: 'Antwoord toevoegen',
            table: {
                column: {
                    nr: 'Nr.',
                    question: 'Vraag'
                }
            },
            modal: {
                create_category: {
                    title: 'Categorie toevoegen',
                    field: {
                        name: 'Naam',
                        type: 'Type',
                        type_value_risk: 'Risico',
                        type_value_value: 'Waarde'
                    }
                },
                update_category: {
                    title: 'Categorie bewerken'
                },
                delete_category: {
                    title: 'Categorie verwijderen',
                    description: 'Weet u zeker dat u deze categorie wilt verwijderen?'
                },
                delete_question: {
                    title: 'Vraag verwijderen',
                    description: 'Weet u zeker dat u deze vraag wilt verwijderen?',
                    delete_dependent_description: 'Berekende vragen die afhankelijk zijn van deze vraag worden ook verwijderd.'
                },
                create_question: {
                    title: 'Vraag toevoegen',
                    add_question: 'Antwoord toevoegen',
                    placeholder: {
                        dependentQuestion: 'Geen afhankelijkheid',
                        dependentAnswer: 'Kies antwoord',
                        dependentBool: 'Kies antwoord'
                    },
                    notFound: {
                        dependentQuestion: 'Geen geschikte vragen gevonden.'
                    },
                    field: {
                        name: 'Naam',
                        questionType: 'Vraagtype',
                        textAnswerLength: 'Aantal karakters (1 - 16384)',
                        answer: 'Antwoord',
                        allowAttachments: 'Bijlagen toestaan',
                        isOptional: 'Optioneel'
                    },
                    type: {
                        text: 'Tekst',
                        date: 'Datum',
                        integer: 'Numeriek',
                        bool: 'Ja of nee',
                        percentage: 'Percentage',
                        multiplechoice: 'Meerkeuze',
                        department: 'Vestiging',
                        calculation: 'Berekening'
                    }
                },
                update_question: {
                    title: 'Vraag bewerken'
                }
            }
        },
        EDIT_REMARK: {
            title: 'Pas opmerking aan',
            remark: 'Opmerking'
        },
        quantify_supplement_project_library: {
            tab: {
                description: 'Omschrijving',
                quantification: 'Kwantificatie',
                riskMitigation: 'Beheersmaatregel',
                remarks: 'Opmerking(en)'
            },
            form: {
                riskDescription: 'Omschrijving',
                riskClient: 'Risico mag gedeeld worden op opdrachtgeverslijst',
                causeDescription: 'Oorzaak',
                effectDescription: 'Gevolg',
                category: {
                    title: 'Risico/Kans categorie',
                    risks: 'Risico',
                    opportunities: 'Kans',
                    project_specific: {
                        risks: 'Projectspecifiek risico',
                        opportunities: 'Projectspecifieke kans',
                        tasks: 'Projectspecifieke taak'
                    }
                },
                itemOwner: 'Eigenaar',
                status: 'Status',
                status_proposed: 'Neem contact op met de projectverantwoordelijke om dit voorstel toe te voegen.',
                included_in_per: 'Opgenomen in PER',
                counterMeasuresActionHolder: 'Actiehouder',
                counterMeasures: 'Beheersmaatregel',
                financialImpact: 'Financiële impact',
                minCosts_risks: 'Best case (in euros)',
                minCosts_opportunities: 'Worst case (in euros)',
                minCosts_tasks: 'Worst case (in euros)',
                bestGuessCosts: 'Best guess (in euros)',
                maxCosts_risks: 'Worst case (in euros)',
                maxCosts_opportunities: 'Best case (in euros)',
                maxCosts_tasks: 'Best case (in euros)',
                perCosts: 'Opgenomen in PER (in euros)',
                counterMeasuresCosts: 'Kosten beheersmaatregel (in euros)',
                counterMeasuresDeadline: 'Deadline',
                remarks: 'Opmerkingen en/of gemaakte afspraken',
                add_new_remark: 'Voeg toe',
                add_remark: 'Voeg eerst een nieuwe opmerking en/of gemaakte afspraak toe'
            },
            remarks_logbook: {
                title: 'Logboek opmerkingen en/of gemaakte afspraken'
            }
        },
        mandating: {
            start_mandating: 'Start goedkeuringsflow',
            restart_mandating: 'Herstart goedkeuringsflow',
            in_execution: 'De projectstatus is succesvol aangepast naar de status: In uitvoering.',
            mandate_start: 'gestart',
            mandate_restart: 'herstarten',
            mandate_started: 'De mandateringsflow voor dit project is gestart.',
            to_execution: 'Project in uitvoering plaatsen',
            to_archived: 'Project verwijderen',
            failed: 'De mandatering is afgekeurd. Pas eventueel de gegevens aan en herstart de mandatering.',
            info_card: {
                mandating_flow: 'Mandateringsflow',
                mandating_header: 'Mandatering'
            },
            cannot_start: {
                project_details_not_loaded: 'De projectgegevens zijn (nog) niet ingeladen.',
                conditions_not_met: 'Vul eerst alle gevraagde gegevens aan, zoals hierboven aangemerkt.',
                project_not_classified: 'Quickscan is nog niet definitief. Maak deze eerst definitief alvorens de goedkeuring te starten.',
                tis_not_classified: '{{ tisLabelShort }} quickscan is nog niet ingediend. Dien deze eerst in alvorens de goedkeuring te starten.',
                tis_quickscan_outdated: 'De {{ tisLabelShort }} quickscan dient opnieuw te worden ingediend, aangezien de antwoorden zijn aangepast.',
                quickscan_not_filled: 'Vul eerst de quickscan volledig in.',
                tis_not_filled: 'Vul eerst de aanvullende vragen volledig in alvorens de goedkeuringsflow te starten.',
                mandate_already_started: 'De mandatering was reeds gestart cq is reeds afgerond.',
                nr_risks_not_sufficient: 'Op basis van de classificiatie dient er tenminste 1 risico geregistreerd te zijn.',
                nr_opportunities_not_sufficient: 'Op basis van de classificiatie dient er tenminste 1 kans geregistreerd te zijn.',
                construction_costs_not_specified: 'De velden "{{ constructionCostsLabel }}", "Start werk" en/of "Werk gereed" zijn niet ingevuld.'
            }
        },
        QUESTION_INPUT: {
            selectAnswer: 'Selecteer antwoord',
            selectDepartment: 'Selecteer vestiging',
            noDepartmentsFound: 'Geen vestiging gevonden',
            noAnswersFound: 'Geen antwoord gevonden',
            charactersRemaining: '{{ charactersRemaining }} karakters resterend',
            boolean: {
                true: 'Ja',
                false: 'Nee'
            }
        },
        QUESTION_ATTACHMENT: {
            filesize_error: 'De bijlage is te groot. De bijlage kan maximaal 3 MB zijn.',
            error: 'Er is iets fout gegaan bij het uploaden van de bijlage. Probeer het nogmaals.',
            fileextension_error: 'Dit bestandstype wordt niet ondersteund.',
            attachments_limit_reached: 'Het maximum aantal bijlagen van 3 is overschreden.'
        },
        library_item_quantification: {
            error: {
                message: 'U moet eerst de {{ constructionCostsLabel }}, startdatum en de einddatum van het project invullen.'
            },
            probability: {
                label: 'Waarschijnlijkheid',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Nihil',
                    2: 'Gering',
                    3: 'Misschien',
                    4: 'Waarschijnlijk',
                    5: 'Vrijwel zeker'
                }
            },
            money: {
                label: 'Geld',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    range: '€{{from}} - €{{to}}',
                    5: '> €{{number}}'
                }
            },
            time: {
                label: 'Tijd',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: '< {{number}} weken',
                    range: '{{from}} > {{to}} weken',
                    5: '> {{number}} weken'
                }
            },
            quality: {
                label: 'Kwaliteit',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Afwijking t.o.v. intern document',
                    2: 'Voldoet niet aan eis',
                    3: 'Afwijking niet reparable',
                    4: 'Verminderde prestatie',
                    5: 'Blijvende afwijking'
                }
            },
            safety: {
                label: 'Veiligheid',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Lichte verwonding',
                    2: 'Meerdere lichte verwondingen',
                    3: 'Licht ongeval',
                    4: 'Zwaar ongeval',
                    5: 'Dodelijk ongeval'
                }
            },
            surroundings: {
                label: 'Omgeving',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Opgemerkt door omgeving',
                    2: 'Klacht',
                    3: 'Herhaaldelijke klachten',
                    4: 'Ernstige hinder',
                    5: 'Ingreep bevoegd gezag'
                }
            },
            image: {
                label: 'Imago',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Buurt',
                    2: 'Wijk',
                    3: 'Gemeentelijk',
                    4: 'Provinciaal',
                    5: 'Nationaal'
                }
            },
            reputation: {
                label: 'Reputatie',
                values: {
                    not_required: {
                        0: 'N.V.T'
                    },
                    0: 'Kan niet',
                    1: 'Buurt',
                    2: 'Wijk',
                    3: 'Gemeentelijk',
                    4: 'Provinciaal',
                    5: 'Nationaal'
                }
            }
        },
        share_project: {
            table: {
                title: 'Toegangsrechten',
                email_add: 'E-mailadres',
                column: {
                    email: 'E-mailadres',
                    email_empty: 'Er is nog geen e-mailadres toegevoegd.',
                    quickscan: 'Quickscan',
                    project_library_items: 'Risico\'s en Kansen',
                    tis: 'TIS',
                    options: 'Opties'
                }
            },
            title: 'Delen van project',
            owner: 'Projecteigenaar',
            succeeded: 'De deelrechten van het project zijn succesvol aangepast.'
        },
        update_notification_modal: {
            title: 'Melding bewerken',
            form: {
                title: 'Titel in modal en knoptekst',
                message: 'Inhoud',
                message_hint: 'tekst inclusief opmaak:\xa0',
                publication_start: 'Toon vanaf',
                publication_start_placeholder: '',
                publication_start_format: 'd-m-Y \\o\\m H:i'
            },
            toast: {
                success: 'De wijzigingen voor de melding zijn succesvol opgeslagen!',
                error: 'Er ging iets fout bij het opslaan van de melding. Probeer het later nog eens.'
            }
        },
        input_counter: {
            hint: '{{count}}/{{max}}',
            error: '{{count}}/{{max}} tekens ({{diff}} teveel)'
        },
        change_user: {
            user: 'Gebruiker:',
            admin_companies: 'PRM-Expert bij:',
            manageable_companies: 'Tendermanager bij:',
            editable_companies: 'Bewerker bij:',
            viewable_companies: 'Raadpleger bij:',
            title: 'Gebruikersrechten beheren',
            at_least_one_company: 'Voeg ten minste een werkmaatschappij toe om de gebuiker toe te voegen',
            toast: {
                success: 'Rechten succesvol gewijzigd',
                error: 'Er ging iets fout bij het wijzigen van de rechten. Probeer het later nog eens.'
            }
        },
        version: 'Versie {{version}}'
    },
    PAGE_NO_ACCESS: {
        title: 'Geen toegang',
        message: 'U heeft geen toegang tot deze applicatie.'
    },
    PAGE_ERROR: {
        title: 'Fout tijdens authenticatie-proces',
        message: 'Er is iets fout gegaan bij het authenticeren binnen de applicatie. Probeer het op een later moment nogmaals. Indien het probleem blijft optreden, neem dan contact op met uw beheerder.'
    },
    PAGE_PROJECTEN: {
        title: 'Projecten',
        new_project: 'Nieuw project',
        generate_csv: 'CSV-export',
        filter: {
            status: {
                placeholder: 'Status'
            },
            classification: {
                placeholder: 'Classificatie'
            },
            companies: {
                placeholder: 'Werkmaatschappij'
            },
            constructionCosts: {
                placeholder: 'Bouwkosten'
            }
        },
        table: {
            column: {
                name: 'Projectnaam',
                modifiedAt: 'Laatste wijziging',
                company: 'Werkmaatschappij',
                owner: 'Verantwoordelijke',
                status: 'Status',
                classification: 'Classificatie',
                classification_tooltip: 'De eerste waarde is de classificatie op basis van de initiële Quickscan. De tweede waarde is de huidige waarde op basis van de bijgewerkte Quickscan'
            }
        },
        form: {
            name: 'Projectnaam',
            address: 'Adres',
            city: 'Plaatsnaam',
            country: 'Land',
            companyName: 'Werkmaatschappij'
        },
        placeholder: {
            name: 'Naam van je project',
            address: 'Adres',
            city: 'Plaats',
            country: 'Land',
            companyName: 'Selecteer werkmaatschappij'
        },
        no_projects: {
            message_title: 'Er zijn op dit moment geen projecten actief.',
            search_title: 'Er zijn geen projecten gevonden op basis van de zoekcriteria.'
        }
    },
    PAGE_PROJECT_DETAILS: {
        title: 'Projectgegevens',
        general_title: 'Algemene gegevens',
        contract_title: 'Contract gegevens',
        processing_thumbnail: 'Bezig met het verwerken van de afbeelding.',
        form: {
            name: 'Projectnaam',
            address: 'Adres',
            city: 'Plaatsnaam',
            country: 'Land',
            companyName: 'Werkmaatschappij',
            owner: 'Verantwoordelijke',
            owner_disabled: 'De project verantwoordelijke mag enkel worden aangepast door de PRM-expert, beheerders en door risk managers',
            risk_manager: 'Projectrisicomanager',
            risk_manager_disabled: 'De projectrisicomanager mag enkel worden aangepast door de PRM-expert, beheerders en door risk managers',
            description: {
                title: 'Korte beschrijving project',
                charactersLeft: 'Maximaal {{limit}} karakters. ({{count}} over)',
                charactersOverLimit: 'Maximaal {{limit}} karakters. ({{count}} teveel)'
            },
            client: 'Opdrachtgever',
            contractType: 'Contracttype',
            contractTypeOther: 'Specificeer contracttype',
            termsAndConditions: 'Algemene voorwaarden',
            termsAndConditionsOther: 'Specificeer algemene voorwaarden',
            profitContribution: 'Winstbijdrage',
            workStartsAt: 'Start werk',
            workEndsAt: 'Werk gereed',
            durationInMonths: 'Duur project in maanden',
            collaboration: 'Samenwerking',
            collaborationCompanies: 'Bedrijven waarmee wordt samengewerkt',
            emailInvalid: 'Vul een geldig e-mailadres in.'
        },
        placeholder: {
            name: 'Naam van je project',
            address: 'Adres',
            city: 'Plaats',
            country: 'Land',
            companyName: 'Selecteer werkmaatschappij',
            client: 'Opdrachtgever',
            contractType: 'Selecteer contracttype',
            contractTypeOther: 'Contracttype',
            termsAndConditions: 'Selecteer algemene voorwaarden',
            termsAndConditionsOther: 'Algemene voorwaarden',
            constructionCosts: 'Bedrag, in euros',
            profitContribution: 'Winstbijdrage, in euros',
            workStartsAt: 'Start werk',
            workEndsAt: 'Werk gereed',
            durationInMonths: 'Duur project in maanden',
            collaboration: 'Selecteer samenwerking',
            collaborationCompanies: 'Bedrijven waarmee wordt samengewerkt'
        },
        contractType: {
            traditional: 'Traditioneel',
            integrated: 'Geïntegreerd',
            other: 'Overig'
        },
        termsAndConditions: {
            UAV: 'UAV',
            'UAV-GC': 'UAV-GC',
            DNR: 'DNR',
            other: 'Overig'
        },
        collaboration: {
            BV: 'BV',
            VOF: 'VOF',
            'BV/CV': 'BV/CV',
            subcontractor: 'Onderaanneming',
            not_applicable: 'N.v.t.'
        },
        start_project: 'Start project',
        project_name: 'Projectnaam',
        owner: 'Verantwoordelijk',
        status: 'Status',
        classification: 'Classificatie',
        location: 'Locatie',
        project_number: 'Projectnummer',
        message_updated: 'De wijzigingen voor de projectgegevens zijn succesvol opgeslagen!',
        message_moved: 'De project verantwoordelijke en de wijzigingen voor de projectgegevens zijn succesvol opgeslagen!',
        error_message: 'Er ging iets fout bij het opslaan van het project. Probeer het later nog eens.',
        tender_manager: 'Tendermanager',
        project_risk_manager: 'Projectrisicomanager'
    },
    PAGE_QUICKSCAN_VERSIONS: {
        title: 'Quickscan versies',
        published: 'Gepubliceerd',
        concept: 'Concept',
        publish: 'Publiceren',
        edit: 'Bewerken',
        table: {
            title: 'Quickscan versies',
            versionId: 'Versienummer',
            status: 'Status',
            publishedAt: 'Gepubliceerd op'
        },
        modal: {
            title: 'Publiceer versie',
            question: 'Weet u zeker dat u deze quickscan-versie wilt publiceren?',
            publish: 'Publiceer'
        }
    },
    PAGE_TENDER_MANAGERS: {
        title: 'Beheer tendermanagers',
        create_tender_manager: 'Voeg tendermanager toe',
        table: {
            header: 'Tendermanagers',
            column: {
                name: 'Persoon',
                companies: 'Werkmaatschappij(en)'
            }
        },
        delete_item: {
            title: 'Verwijder tendermanager',
            description: 'Weet u zeker dat u deze tendermanager wilt verwijderen?'
        },
        update_item: {
            title: 'Bewerk tendermanager',
            save: 'Wijzigen'
        },
        create_item: {
            title: 'Voeg tendermanager toe',
            save: 'Toevoegen'
        },
        tender_manager_removed: 'Deze gebruiker heeft geen toegang meer alszijnde tendermanager.',
        tender_manager_added: 'De gebruiker is toegevoegd alszijnde tendermanager.',
        tender_manager_modified: 'De tendermanager is bijgewerkt.'
    },
    PAGE_ACCESSIBLE_STEPS: {
        title: 'Stappenbeheer',
        set_to_default_btn: 'Standaard instellingen overnemen',
        accessible_steps_title: 'Actieve stappen',
        remaining_steps_title: 'Niet gebruikte stappen',
        empty_state_message: 'Alle stappen staan op actief.',
        toast: {
            success: 'De stappen zijn succesvol opgeslagen.',
            error: 'Er ging iets fout bij het opslaan van de stappen. Probeer het later nog eens.'
        }
    },
    PAGE_COMPANIES: {
        title: 'Beheer werkmaatschappijen',
        create_company: 'Voeg werkmaatschappij toe',
        table: {
            header: 'Werkmaatschappijen',
            column: {
                name: 'Naam werkmaatschappij',
                company_type: 'Bedrijfstype',
                editors: 'Bewerkers',
                viewers: 'Raadplegers',
                admin: 'PRM-Expert'
            }
        },
        delete_item: {
            title: 'Verwijder werkmaatschappij',
            description: 'Weet u zeker dat u deze werkmaatschappij wilt verwijderen?'
        },
        update_item: {
            title: 'Bewerk werkmaatschappij',
            save: 'Wijzigen'
        },
        create_item: {
            title: 'Voeg werkmaatschappij toe',
            save: 'Toevoegen'
        },
        company_removed: 'De werkmaatschappij is verwijderd.',
        company_added: 'De werkmaatschappij is toegevoegd.',
        company_modified: 'De werkmaatschappij is bijgewerkt.'
    },
    PAGE_COMPANY_TYPES: {
        title: 'Beheer bedrijfstypes',
        create_type: 'Voeg bedrijfstype toe',
        table: {
            header: 'Bedrijfstypes',
            column: {
                name: 'Naam',
                contract_types: 'Contracttypes (naast overig)',
                risk_managers: 'Risk managers'
            }
        },
        delete_item: {
            title: 'Verwijder bedrijfstype',
            description: 'Weet u zeker dat u dit bedrijfstype wilt verwijderen?'
        },
        update_item: {
            title: 'Bewerk type',
            save: 'Wijzigen'
        },
        create_item: {
            title: 'Voeg bedrijfstype toe',
            save: 'Toevoegen'
        },
        type_removed: 'Het bedrijfstype is verwijderd.',
        type_added: 'Het bedrijfstype is toegevoegd.',
        type_modified: 'Het bedrijfstype is bijgewerkt.',
        placeholder: {
            active_company_type: 'Selecteer actieve bedrijfstype'
        }
    },
    PAGE_QUICKSCAN_EDIT: {
        title: 'Quickscan bewerken',
        updated: 'De quickscan is succesvol opgeslagen',
        property: {
            redflagTreshold: 'Red flag als thema score hoger is dan dit percentage'
        },
        error: {
            property: {
                redflagTreshold: 'Percentage voor red fkag dient te liggen tussen 0 en 100'
            }
        }
    },
    PAGE_TIS_VERSIONS: {
        title: '{{ tisLabelLong }} ({{ tisLabelShort }}) versies',
        published: 'Gepubliceerd',
        concept: 'Concept',
        publish: 'Publiceren',
        edit: 'Bewerken',
        table: {
            title: '{{ tisLabelShort }} versies',
            versionId: 'Versienummer',
            status: 'Status',
            publishedAt: 'Gepubliceerd op'
        },
        modal: {
            title: 'Publiceer {{ tisLabelShort }}-versie',
            question: 'Weet u zeker dat u deze {{ tisLabelShort }}-versie wilt publiceren?',
            publish: 'Publiceer'
        }
    },
    PAGE_TIS_VERSION: {
        title: '{{ tisLabelLong }} ({{ tisLabelShort}}) bewerken',
        updated: 'De {{tisLabelShort}}-definitie is opgeslagen.'
    },
    PAGE_RISK_LIBRARY: {
        title: 'Risico bibliotheek'
    },
    PAGE_RISK_DOSSIER: {
        title: 'Risicodossier'
    },
    PAGE_OPPORTUNITY_LIBRARY: {
        title: 'Kansen bibliotheek'
    },
    PAGE_TASK_LIBRARY: {
        title: 'Taken bibliotheek'
    },
    PAGE_LOGOUT: {
        title: 'U bent uitgelogd',
        message: 'U bent succesvol uitgelogd uit de applicatie. U wordt doorgeleid naar de loginpagina'
    },
    PAGE_FILL_QUICKSCAN: {
        title: 'Quickscan',
        quickscan_version: 'Quickscan versie',
        version: 'Versie',
        save_project: 'Opslaan',
        red_flags: 'red flags',
        table: {
            column: {
                nr: 'Nr.',
                question: 'Vraag',
                answer: 'Antwoord',
                remark: 'Toelichting',
                score: 'Score',
                score_tooltip: 'Deze kolom bevat een gewogen score per vraag',
                scoreresult_tooltip: 'Dit is de gewogen totaalscore voor deze categorie'
            }
        }
    },
    PAGE_FILL_TIS_QUICKSCAN: {
        title: '{{ tisLabelShort }} quickscan'
    },
    PAGE_FILL_TIS: {
        title: '{{ tisLabelShort }} Aanvullende vragen',
        tis_version: '{{ tisLabelShort }} versie',
        version: 'Versie',
        project_id: 'Project ID',
        attachments_pdf: 'Bijlage(n) (zie bijgevoegd zip bestand):',
        save_project: 'Opslaan',
        required_fields: 'Let op: alle verplichte velden moeten ingevuld worden.',
        table: {
            column: {
                number: 'Nr.',
                question: 'Vraag',
                answer: 'Antwoord',
                attachments: 'Bijlage(n), max 3'
            }
        }
    },
    PAGE_PROJECT_LIBRARY_ITEMS: {
        filter: {
            search: {
                label: 'Zoeken'
            },
            theme: {
                placeholder: 'Thema'
            },
            status: {
                placeholder: 'Status'
            },
            actionholder: {
                placeholder: 'Actiehouder',
                no_items: 'Er zijn nog geen actiehouders'
            },
            error: {
                message: 'Er zijn geen resultaten gevonden voor \'{{ search_query }}\''
            },
            filledIn: {
                placeholder: '(Niet) Volledig ingevuld',
                optionNames: {
                    FILLED_IN: 'Volledig ingevuld',
                    NOT_FILLED_IN: 'Niet volledig ingevuld'
                },
                tooltip: 'Nog niet alle verplichte velden zijn ingevuld'
            }
        },
        library_type: {
            risks: {
                title: 'Projectrisico\'s',
                library: '+  Risico bibliotheek',
                specific_risks: '+  Specifieke risico\'s',
                empty: {
                    messageTitle: 'Nog geen risico\'s aanwezig',
                    message: 'Kies een risico uit de bibliotheek of voeg een projectspecifieke risico toe.'
                },
                create_item: {
                    title: 'Risico toevoegen'
                },
                updated: 'De geselecteerde risico\'s zijn toegevoegd',
                status: {
                    proposed: 'Voorstel',
                    new: 'Nieuw',
                    current: 'Actueel',
                    settled: 'Afgehandeld',
                    incident: 'Incident',
                    risk_client: 'Risico voor opdrachtgever',
                    risk_included_in_calc: 'Risico opgenomen in calculatie',
                    risc_insured: 'Risico verzekerd',
                    risc_supplier: 'Risico bij leverancier'
                },
                theme: {
                    projectspecific: 'Projectspecifiek'
                },
                no_items: 'Alle mogelijke risico\'s in deze categorie zijn reeds toegevoegd.'
            },
            opportunities: {
                title: 'Projectkansen',
                library: '+  Kansen bibliotheek',
                specific_risks: '+  Specifieke kansen',
                empty: {
                    messageTitle: 'Nog geen kansen aanwezig',
                    message: 'Kies een kans uit de bibliotheek of voeg een projectspecifieke kans toe.'
                },
                create_item: {
                    title: 'Kans toevoegen'
                },
                updated: 'De geselecteerde kansen zijn toegevoegd',
                status: {
                    proposed: 'Voorstel',
                    new: 'Nieuw',
                    current: 'Actueel',
                    settled: 'Afgehandeld',
                    incident: 'Incident',
                    risk_client: 'Risico voor opdrachtgever',
                    risk_included_in_calc: 'Risico opgenomen in calculatie',
                    risc_insured: 'Risico verzekerd',
                    risc_supplier: 'Risico bij leverancier'
                },
                no_items: 'Alle mogelijke kansen in deze categorie zijn reeds toegevoegd.'
            },
            tasks: {
                title: 'Projecttaken',
                library: '+  Taken bibliotheek',
                specific_risks: '+  Specifieke taken',
                empty: {
                    messageTitle: 'Nog geen taken aanwezig',
                    message: 'Kies een taak uit de bibliotheek of voeg een projectspecifieke taak toe.'
                },
                create_item: {
                    title: 'Taak toevoegen'
                },
                updated: 'De geselecteerde taken zijn toegevoegd',
                status: {
                    proposed: 'Voorstel',
                    new: 'Nieuw',
                    current: 'Actueel',
                    settled: 'Afgehandeld',
                    incident: 'Incident',
                    risk_client: 'Risico voor opdrachtgever',
                    risk_included_in_calc: 'Risico opgenomen in calculatie',
                    risc_insured: 'Risico verzekerd',
                    risc_supplier: 'Risico bij leverancier'
                },
                no_items: 'Alle mogelijke taken in deze categorie zijn reeds toegevoegd.'
            }

        }
    },
    PAGE_USER_MANAGEMENT: {
        new_user: 'Gebruiker toevoegen',
        title: 'Gebruikersbeheer',
        filter: {
            companies: {
                placeholder: 'Werkmaatschappij'
            },
            search: {
                placeholder: 'Gebruiker'
            }
        },
        headers: {
            company: 'Werkmaatschappij',
            admin: 'PRM-Expert',
            tender_manager: 'Tendermanager',
            edit: 'Bewerken',
            view: 'Raadplegen'
        }
    },
    ADD_PROJECT_LIBRARY_ITEMS: {
        library_type: {
            risks: {
                nr_selected: 'Risico\'s geselecteerd: ',
                deselect: 'Deselecteren',
                updated: 'De geselecteerde risico\'s zijn toegevoegd'
            },
            opportunities: {
                nr_selected: 'Kansen geselecteerd: ',
                deselect: 'Deselecteren',
                updated: 'De geselecteerde kansen zijn toegevoegd'
            },
            tasks: {
                nr_selected: 'Taken geselecteerd: ',
                deselect: 'Deselecteren',
                updated: 'De geselecteerde taken zijn toegevoegd'
            }
        }
    },
    PAGE_QUICKSCAN_RESULT: {
        preliminaryExplanation: 'Dit is een voorlopig resultaat aan de hand van de ingevulde quickscan. Maak de quickscan definitief om de classificatie op te slaan.',
        cannotBePersistedExplanation: 'Vul de quickscan volledig in om de uiteindelijke classificatie te kunnen bepalen. Dien daarna de quickscan in om de classificatie op te slaan.',
        message_updated: 'De quickscan is definitief gemaakt.',
        classified_at: 'Definitief gemaakt op {{ date }}',
        riskVsValue: {
            title: 'Risico vs Waarde'
        },
        button: {
            save: 'Quickscan definitief'
        },
        result: {
            title: 'Resultaat quickscan',
            risc: 'Risicoscore',
            value: 'Waardescore',
            redFlags: 'Red Flags',
            classification: 'Classificatie'
        },
        evaluation: {
            title: 'Evaluatie'
        },
        statement: {
            title: 'Verklaring gebruiker'
        },
        disabled: {
            not_filled_in: 'Vul eerst de quickscan volledig in.'
        }
    },
    PAGE_TIS_QUICKSCAN_RESULT: {
        preliminaryExplanation: 'Dit is een voorlopig resultaat aan de hand van de ingevulde {{ tisLabelShort }} quickscan. Dien de {{ tisLabelShort }} quickscan in om de classificatie op te slaan.',
        cannotBePersistedExplanation: 'Vul de {{ tisLabelShort }} quickscan volledig in om de classificatie te kunnen bepalen. Dien daarna de {{ tisLabelShort}} quickscan in om de classificatie op te slaan.',
        message_updated: 'De {{ tisLabelShort }} quickscan is ingediend.',
        button: {
            save: '{{ tisLabelShort }} quickscan indienen'
        },
        result: {
            title: 'Resultaat {{ tisLabelShort }}',
            risc: 'Risicoscore',
            value: 'Waardescore',
            redFlags: 'Red Flags',
            classification: 'Classificatie'
        },
        disabled: {
            not_filled_in: 'Vul eerst de {{ tisLabelShort }} quickscan volledig in.'
        }
    },
    PAGE_CLIENT_LIST: {
        title: 'Risico’s opdrachtgever'
    },
    PAGE_FORECAST_END_RESULT: {
        title: 'Prognose eindresultaat',
        table: {
            risks: {
                title: 'Risico\'s',
                no_items: 'Geen risico\'s opgenomen in PER.',
                column: {
                    name: 'Naam',
                    score: 'Score',
                    per_costs: 'PER',
                    best_guess: 'Best guess',
                    min_costs: 'Best case',
                    max_costs: 'Worst case',
                    total: 'Totaal',
                    included_in_per: 'In PER'
                }
            },
            opportunities: {
                title: 'Kansen',
                no_items: 'Geen kansen opgenomen in PER.',
                column: {
                    name: 'Naam',
                    score: 'Score',
                    per_costs: 'PER',
                    best_guess: 'Best guess',
                    min_costs: 'Worst case',
                    max_costs: 'Best case',
                    total: 'Totaal',
                    included_in_per: 'In PER'
                }
            },
            tasks: {
                title: 'Taken',
                no_items: 'Geen taken opgenomen in PER.',
                column: {
                    name: 'Naam',
                    score: 'Score',
                    per_costs: 'PER',
                    best_guess: 'Best guess',
                    min_costs: 'Worst case',
                    max_costs: 'Best case',
                    total: 'Totaal',
                    included_in_per: 'In PER'
                }
            }
        }
    },
    PAGE_MANDATING_FLOW: {
        title: 'Mandateringsflow'
    },
    PAGE_RISK_RAINBOW: {
        title: 'Risico Dashboard',
        remarks_title: 'Opmerkingen',
        all_risks: {
            explanation: 'Dit zijn alle opgevoerde risico\'s voor zowel de opdrachtgever alsook de opdrachtnemer.'
        },
        filtered: {
            explanation: 'Dit dossier is gefiltered en bevat mogelijk niet alle risico\'s en kansen.'
        },
        risks: {
            title: `Top risico's`,
            header: 'Risico\'s',
            totals: 'Totaal (obv filter)',
            column: {
                title: 'Risico',
                min_costs: 'Best case',
                best_guess: 'Best guess',
                max_costs: 'Worst case',
                score: 'Score',
                in_per: 'In PER'
            },
            total_in_per: 'In per {{amount}}'
        },
        no_risks: {
            message_title: `Er zijn geen risico's die boven de drempelwaarde vallen.`
        },
        opportunities: {
            title: 'Top kansen',
            header: 'Kansen',
            totals: 'Totaal (obv filter)',
            column: {
                title: 'Kans',
                best_guess: 'Best guess',
                score: 'Score',
                in_per: 'In PER'
            },
            total_in_per: 'In per {{amount}}'
        },
        no_opportunities: {
            message_title: 'Er zijn geen kansen die boven de drempelwaarde vallen.'
        },
        tasks: {
            title: 'Top taken',
            header: 'Taken',
            totals: 'Totaal (obv filter)',
            column: {
                title: 'Kans',
                best_guess: 'Best guess',
                score: 'Score',
                in_per: 'In PER'
            },
            total_in_per: 'In per {{amount}}'
        },
        no_tasks: {
            message_title: 'Er zijn geen taken die boven de drempelwaarde vallen.'
        },
        form: {
            riskAssessmentDescription: 'Opmerkingen',
            threshold: 'Groter dan'
        },
        matrix: {
            impact: 'Impact',
            probability: 'Waarschijnlijkheid'
        },
        financial_overview: {
            title: 'Financieel overzicht',
            total: {
                costs_risks: 'Totale verwachtte kosten risico\'s',
                value_opportunities: 'Totale verwachtte opbrengsten kansen',
                costs_risk_mitigations: 'Kosten beheersmaatregelen',
                incidents: 'Aantal incidenten'
            }
        },
        tables: {
            titles: {
                risks_and_opportunities: 'Risicodossier',
                risks: 'Risico\'s',
                risks_none: 'Er zijn (nog) geen risico\'s toegevoegd.',
                opportunities: 'Kansen',
                opportunities_none: 'Er zijn (nog) geen kansen\'s toegevoegd.'
            },
            columns: {
                number: 'Volgnummer',
                description: 'Omschrijving',
                subject: 'Onderwerp',
                cause: 'Oorzaak',
                effect: 'Gevolg',
                category: 'Categorie',
                owner: 'Risico-eigenaar',
                quantification: 'Kwantificatie',
                probability: 'Waarschijnlijkheid',
                money: 'Geld',
                time: 'Tijd',
                quality: 'Kwaliteit',
                in_per: 'In PER',
                safety: 'Veiligheid',
                surroundings: 'Omgeving',
                image: 'Imago',
                reputation: 'Reputatie',
                score: 'Score',
                counter_measures: 'Beheersmaatregel',
                status: 'Status',
                min_costs: 'Best case',
                best_guess: 'Best guess',
                max_costs: 'Worst case',
                action_holder: 'Actiehouder',
                counter_measures_costs: 'Kosten beheersmaatregel',
                deadline: 'Deadline',
                remarks: 'Opmerkingen'
            },
            note: {
                more_remarks: '<strong>{{max}}</strong> van de <strong>{{count}}</strong> opmerkingen worden weergegeven.'
            }
        },
        included_in_per: {
            yes: 'Ja',
            no: '-'
        }
    },
    PAGE_NOTIFICATIONS: {
        title: 'Meldingen',
        table: {
            title: 'Titel',
            published: 'Toon vanaf'
        },
        tooltip: {
            preview: 'Voorbeeld',
            edit: 'Bewerken'
        }
    },
    LIBRARY_ITEM_FILTERS_SELECTED: {
        filtered_on: 'Gefilterd op: '
    },
    STEPS: {
        1: 'Projectgegevens',
        2: 'Quickscan',
        3: 'Projectrisico\'s',
        4: 'Projectkansen',
        5: 'Risicodossier',
        6: 'Risico Dashboard',
        7: '{{ tisLabelShort }} quickscan',
        8: '{{ tisLabelShort }} aanvullende vragen',
        9: 'Mandatering',
        10: 'Projecttaken'
    },
    STEPS_BLINK: {
        single_proposal: ' voorstel',
        multiple_proposals: ' voorstellen'
    },
    DATE: {
        long: 'dd-MM-yyyy \'om\' HH:mm',
        short: 'dd-MM-yyyy'
    },
    PDF: {
        modal: {
            title: 'PDF samenstellen',
            download: 'Samenstellen en downloaden',
            project_details: 'Projectgegevens',
            quickscan_questions: 'Quickscan',
            tis_quickscan_questions: '{{ tisLabelShort }} quickscan',
            tis: '{{ tisLabelShort }} aanvullende vragen',
            risk_rainbow: 'Risico Dashboard',
            risk_rainbow_client: 'Risico Dashboard - opdrachtgever',
            risks_opportunities: 'Risicodossier',
            filters_complete: 'Compleet dossier',
            filters_only: 'Gefilterd dossier',
            risks_opportunities_client: 'Risicodossier - opdrachtgever',
            pdf_types: 'Voorselectie',
            risks_opportunities_list: 'Risico’s opdrachtgever',
            forecast_end_result_list: 'Prognose eindresultaat',
            mandating_flow: 'Mandateringsflow',
            proposals: 'Inclusief voorstellen'
        },
        PDF_TYPE_DEFAULT: 'Standaard PDF',
        PDF_TYPE_CLIENT_LIST: 'Risico’s opdrachtgever',
        PDF_TYPE_FORECAST_END_RESULT_LIST: 'Prognose eindresultaat'
    },
    ERRORS: {
        no_items_found_for_type: 'Voor dit type zijn geen items gevonden.',
        no_items_added_for_type: 'Voor dit type zijn geen items toegevoegd.',
        filesize_too_high: 'Het bestand is te groot, gelieve een bestand te uploaden dat minder is dan 10MB',
        risks: {
            min_higher_than_max: 'De ingevulde best case is hoger dan de worst case.',
            min_higher_than_best_guess: 'De ingevulde best case is hoger dan de best guess.',
            best_guess_higher_than_max: 'De ingevulde best guess is hoger dan de worst case.',
            min_max_but_no_best_guess: 'Er is geen best guess ingevuld, terwijl er wel een worst case en best case zijn ingevuld.'
        },
        opportunities: {
            min_higher_than_max: 'De ingevulde worst case is hoger dan de best case.',
            min_higher_than_best_guess: 'De ingevulde worst case is hoger dan de best guess.',
            best_guess_higher_than_max: 'De ingevulde best guess is hoger dan de best case.',
            min_max_but_no_best_guess: 'Er is geen best guess ingevuld, terwijl er wel een worst case en best case zijn ingevuld.'
        },
        tasks: {
            min_higher_than_max: 'De ingevulde worst case is hoger dan de best case.',
            min_higher_than_best_guess: 'De ingevulde worst case is hoger dan de best guess.',
            best_guess_higher_than_max: 'De ingevulde best guess is hoger dan de best case.',
            min_max_but_no_best_guess: 'Er is geen best guess ingevuld, terwijl er wel een worst case en best case zijn ingevuld.'
        },
        too_many_euros: 'Het ingevulde bedrag is te hoog.',
        project_not_found: 'Het project kon niet gevonden worden.',
        csv_generation_failed: 'Er is iets fout gegaan bij het genereren van de CSV-export.',
        pdf_generation_failed: 'Er is iets fout gegaan bij het genereren van de PDF voor dit project.',
        projects_not_updated: 'De lijst met projecten kon niet worden opgehaald.',
        project_not_created: 'Het project kon niet worden aangemaakt.',
        library_not_updated: 'De bibliotheek kon niet worden bijgewerkt.',
        library_not_retrieved: 'De bibliotheek kon niet worden opgehaald.',
        quickscans_not_updated: 'De lijst met quickscans kon niet worden opgehaald.',
        quickscan_not_published: 'De quickscan kon niet worden gepubliceerd.',
        quickscan_not_updated: 'De quickscan kon niet worden bijgewerkt.',
        quickscan_not_retrieved: 'De quickscan kon niet worden opgehaald.',
        quickscan_not_calculated: 'De classificatie voor de quickscan kon niet worden bepaald.',
        quickscan_result_not_retrieved: 'Het resultaat van de quickscan kon niet worden opgehaald.',
        tis_not_updated: 'De lijst met {{ tisLabelShort }}-definities kon niet worden opgehaald.',
        tis_not_published: 'De {{ tisLabelShort }}-definitie kon niet worden gepubliceerd.',
        tis_not_retrieved: 'De {{ tisLabelShort }}-definitie kon niet worden opgehaald.',
        project_not_updated: 'Het project kon niet worden opgeslagen.',
        project_mandate_details_not_retrieved: 'De mandateringsstatus voor het project kon niet worden bepaald.',
        project_mandate_details_nok: 'De mandatering kon helaas niet gestart worden. Probeer het later nogmaals.',
        start_mandate_failed: 'Het starten van de mandatering is mislukt.',
        project_to_execution_nok: 'Het is helaas niet gelukt om de projectstatus aan te passen. Probeer het later nogmaals.',
        project_to_closed_nok: 'Het is helaas niet gelukt om het project af te sluiten. Probeer het later nogmaals.',
        project_to_archived_nok: 'Het is helaas niet gelukt om het project te verwijderen. Probeer het later nogmaals.',
        project_sharing_failed: 'Het is helaas niet gelukt om de instellingen van het project rondom het delen op te slaan. Probeer het later nogmaals.',
        tender_managers_not_retrieved: 'Het is helaas niet gelukt om de lijst met tendermanagers op te halen. Probeer het later nogmaals.',
        tender_manager_not_deleted: 'Het is helaas niet gelukt om de tendermanager te verwijderen. Probeer het later nogmaals.',
        tender_manager_not_added: 'Het is helaas niet gelukt om de tendermanager toe te voegen. Probeer het later nogmaals.',
        tender_manager_not_modified: 'Het is helaas niet gelukt om de tendermanager bij te werken. Probeer het later nogmaals.',
        tender_manager_already_exists: 'Er is al een tendermanager met hetzelfde e-mailadres toegevoegd.',
        companies_not_retrieved: 'Het is helaas niet gelukt om de lijst met werkmaatschappijen op te halen. Probeer het later nogmaals.',
        company_not_deleted: 'Het is helaas niet gelukt om de werkmaatschappij te verwijderen. Probeer het later nogmaals.',
        company_not_add: 'Het is helaas niet gelukt om de werkmaatschappij toe te voegen. Probeer het later nogmaals.',
        company_not_modified: 'Het is helaas niet gelukt om de werkmaatschappij bij te werken. Probeer het later nogmaals.',
        company_already_exists: 'Er is al een werkmaatschappij met dezelfde naam toegevoegd.',
        types_not_retrieved: 'Het is helaas niet gelukt om de lijst met bedrijfstypes op te halen. Probeer het later nogmaals.',
        type_not_deleted: 'Het is helaas niet gelukt om het bedrijfstype te verwijderen. Probeer het later nogmaals.',
        type_not_added: 'Het is helaas niet gelukt om het bedrijfstype toe te voegen.',
        type_not_modified: 'Het is helaas niet gelukt om het bedrijfstype bij te werken. Probeer het later nogmaals.',
        type_already_exists: 'Er is al een bedrijfstype met dezelfde naam toegevoegd.',
        library_type: {
            risks: {
                not_updated: 'De risico\'s konden niet worden opgeslagen.',
                not_deleted: 'Het risico kon niet worden verwijderd.'
            },
            opportunities: {
                not_updated: 'De kans kon niet worden opgeslagen.',
                not_deleted: 'De kans kon niet worden verwijderd.'
            },
            tasks: {
                not_updated: 'De taak kon niet worden opgeslagen.',
                not_deleted: 'De taak kon niet worden verwijderd.'
            }
        },
        error_code: {
            generic: 'Er is een onverwachte fout opgetreden.',
            quickscan_not_published: 'Er is nog geen quickscan gepubliceerd. Neem contact op met de riskmanager.',
            tis_not_published: 'Er is nog geen TIS gepubliceerd. Neem contact op met de riskmanager.',
            cannot_mandate_project: 'Dit project kan niet gemandateerd worden op dit moment.',
            flow_not_started: 'De flow kon niet worden verstuurd richting Power Automate',
            cannot_execute_project: 'Dit project kan niet verplaatst worden naar de status: In uitvoer.',
            cannot_delete_tender_manager: 'De tendermanager kan niet worden verwijderd, aangezien deze nog actieve projecten heeft.',
            cannot_delete_company: 'De werkmaatschappij kan niet worden verwijderd, aangezien deze nog actieve tender managers en/of projecten heeft.',
            type_in_use: 'Dit bedrijfstype kan niet meer worden verwijderd, aangezien deze al in gebruik is.',
            project_members_not_unique: 'De lijst met projectdeelnemers bevat één of meerdere dubbele e-mailadressen',
            library_entry_used_in_answers: 'Dit bibliotheekitem wordt reeds gerefereerd vanuit een quickscan.',
            risk_used_in_project: 'Bibliotheekitem is in gebruik en kan niet verwijderd worden.'
        }
    },
    DISABLED: {
        not_owner: 'Alleen de eigenaar van dit project kan deze actie uitvoeren.',
        not_item_owner: 'Alleen de eigenaar van dit voorstel kan deze actie uitvoeren.',
        not_tender_manager: 'Alleen tendermanagers kunnen deze actie uitvoeren.',
        no_edit_rights: 'U heeft alleen leesrechten op dit gedeelte van het project.',
        quickscan_finalized: 'De quickscan is reeds definitief.',
        form_invalid: 'Oeps, het formulier bevat onjuiste velden. Wilt u dit controleren?',
        in_mandate: 'Deze actie kan niet worden uitgevoerd aangezien de mandatering is gestart.',
        no_on_hold_rights: 'Alleen tendermanagers en/of de eigenaar van het project kunnen deze actie uitvoeren.',
        project_on_hold: 'Het project is on hold en kan niet aangepast worden.',
        project_closed: 'Het project is afgesloten en kan niet aangepast worden.'
    },
    NGSELECT: {
        add: 'Voeg toe',
        clear: 'Leegmaken'
    },
    NAV: {
        project: {
            all: 'Alle projecten'
        }
    },
    CLASSIFICATION: {
        color: {
            initial: 'N.n.b.',
            red: 'H',
            green: 'L',
            orange: 'G'
        },
        initial: 'N.n.b.',
        red: 'Hoog',
        green: 'Laag',
        orange: 'Gemiddeld'
    },
    FILTER_CONSTRUCTION_COSTS: {
        less_than_10m: '< 10.000K euro',
        between_10m_and_25m: '10.000K - 24.999K euro',
        between_25m_and_50m: '25.000K - 50.000K euro',
        greater_than_50m: '> 50.000K euro'
    },
    A11Y: {
        go_to_content: 'Ga naar inhoud'
    }
};
