import {ILibraryCategoryData, ILibraryData, ILibraryEntryData} from './library-data';

export class Library {
    type: string;
    categories: LibraryCategory[];

    constructor(data: ILibraryData) {
        this.type = data.type;
        this.categories = data.categories.map(category => new LibraryCategory(category));
    }

    public getLibraryCategories(): LibraryCategory[] {
        return this.categories;
    }

    public getLibraryCategoryById(categoryId: number): LibraryCategory | undefined {
        return this.getLibraryCategories().find((libraryCategory) => libraryCategory.id === categoryId);
    }

    public getLibraryCategoryByEntryId(entryId: number): LibraryCategory | undefined {
        return this.getLibraryCategories().find((libraryCategory) => !!libraryCategory.entries.find((libraryEntry) => libraryEntry.id === entryId));
    }

    public getLibraryEntryById(entryId: number): LibraryEntry | undefined {
        return this.getLibraryEntries().find((libraryEntry) => libraryEntry.id === entryId);
    }

    public getLibraryEntries(): LibraryEntry[] {
        return [].concat.apply([], this.getLibraryCategories().map((category) => category.entries));
    }

}

export class LibraryCategory {
    id: number;
    name: string;
    numberPrefix: string;
    entries: LibraryEntry[];

    constructor(data: ILibraryCategoryData) {
        this.id = data.id;
        this.name = data.name;
        this.numberPrefix = data.numberPrefix;
        this.entries = data.entries.map(entry => new LibraryEntry(this, entry));
    }
}

export class LibraryEntry {
    category: LibraryCategory;
    id: number;
    active: boolean;
    entryNumber: number;
    subject: string;
    riskDescription: string;
    riskClient: boolean;
    causeDescription: string;
    effectDescription: string;
    effectCategoryMoney?: boolean;
    effectCategoryTime?: boolean;
    effectCategoryQuality?: boolean;
    effectCategorySafety?: boolean;
    effectCategorySurroundings?: boolean;
    effectCategoryImage?: boolean;
    effectCategoryReputation?: boolean;
    relationOtherRisks: string;
    possibleCounterMeasures: string;

    constructor(category: LibraryCategory, data: ILibraryEntryData) {
        this.category = category;
        this.id = data.id;
        this.active = data.active;
        this.entryNumber = data.entryNumber;
        this.subject = data.subject;
        this.riskDescription = data.riskDescription;
        this.riskClient = data.riskClient;
        this.causeDescription = data.causeDescription;
        this.effectDescription = data.effectDescription;
        this.effectCategoryMoney = data.effectCategoryMoney;
        this.effectCategoryTime = data.effectCategoryTime;
        this.effectCategoryQuality = data.effectCategoryQuality;
        this.effectCategorySafety = data.effectCategorySafety;
        this.effectCategorySurroundings = data.effectCategorySurroundings;
        this.effectCategoryImage = data.effectCategoryImage;
        this.effectCategoryReputation = data.effectCategoryReputation;
        this.relationOtherRisks = data.relationOtherRisks;
        this.possibleCounterMeasures = data.possibleCounterMeasures;
    }

    /** JSON representation: it removes the category circular reference property. */
    public toJSON() {
        return {
            id: this.id,
            active: this.active,
            entryNumber: this.entryNumber,
            subject: this.subject,
            riskDescription: this.riskDescription,
            riskClient: this.riskClient,
            causeDescription: this.causeDescription,
            effectDescription: this.effectDescription,
            effectCategoryMoney: this.effectCategoryMoney,
            effectCategoryTime: this.effectCategoryTime,
            effectCategoryQuality: this.effectCategoryQuality,
            effectCategorySafety: this.effectCategorySafety,
            effectCategorySurroundings: this.effectCategorySurroundings,
            effectCategoryImage: this.effectCategoryImage,
            effectCategoryReputation: this.effectCategoryReputation,
            relationOtherRisks: this.relationOtherRisks,
            possibleCounterMeasures: this.possibleCounterMeasures
        };
    }

    public getTitle(): string {
        return `${this.category.numberPrefix}.${this.entryNumber} ${this.subject}`;
    }

}
