import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {ICompanyType, IUserIdentity} from '../../models/user/user';

@Injectable()
export class UserSearchService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public async searchUsers(searchTerm: string, companyType: ICompanyType): Promise<IUserIdentity[]> {
        const url = companyType != null ? `/azure/${companyType.id}/users` : `/azure/users`;
        return new Promise((resolve, reject) => {
            this.apiService.get(url + `?search=${searchTerm}`)
                .then((response: HttpResponse<IUserIdentity[]>) => {
                    resolve(response.body);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

}
