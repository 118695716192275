import {Component, Input} from '@angular/core';
import {FormControl} from '@ng-stack/forms';

@Component({
    selector: 'app-library-item-filters-selected-button',
    templateUrl: './library-item-filters-selected-button.component.html',
    styleUrls: ['./library-item-filters-selected-button.component.scss']
})
export class LibraryItemFiltersSelectedButtonComponent {

    @Input() public control: FormControl;
    @Input() public defaultValue: number | boolean;
    @Input() public withinPdf: boolean;

    public resetControlValue(): void {
        this.control.setValue(this.defaultValue);
    }

}
