import {IQuestionData} from './question-data';
import {IQuestionType} from './question-type';

export const QuestionDefault: IQuestionData = {
    id: undefined,
    tempId: '',
    name: '',
    info: '',
    type: IQuestionType.TEXT,
    textAnswerLength: 1024,
    allowAttachments: false,
    isRequired: true,
    possibleAnswers: [],
    dependentQuestion: null
};
