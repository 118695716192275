import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails, ProjectLibraryItem} from '../../models';
import {EffectCategory, quantificationCategories} from '../../enums/effect-category/effect-category';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IQuantification} from '../../models/project/project-data';
import {ProjectService} from '../../services';
import {LibraryType} from '../../enums/library-type/library-type';

interface IColumn {
    label: string;
    key: EffectCategory;
    icon: string;
    buildValues?: (ProjectDetails) => string[];
    valueLabels?: string[];
    disabled?: boolean;
}

type ChangeFunction = (quantification: IQuantification) => void;

@Component({
    selector: 'app-library-item-quantification-grid',
    templateUrl: './library-item-quantification-grid.component.html',
    styleUrls: ['./library-item-quantification-grid.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LibraryItemQuantificationGridComponent),
            multi: true
        }
    ]
})
export class LibraryItemQuantificationGridComponent implements OnInit, ControlValueAccessor {

    @Input() overruleCanEdit: boolean; // Added as legend view for the PDF

    public columns: IColumn[] = [];
    public columnRowIndexes = [];
    public projectHasEmptyField = false;
    public canFreelyChangeAllCategories = false;
    private lastEntryValue: ProjectLibraryItem;
    private lastProjectDetailsValue: ProjectDetails;
    private readonly required = [EffectCategory.PROBABILITY];
    private readonly ALL_COLUMNS: IColumn[] = [
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.PROBABILITY}.label`),
            key: EffectCategory.PROBABILITY,
            icon: 'effect-category-probability',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.probability.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.probability.values.${i}`);
                    }
                })
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.MONEY}.label`),
            key: EffectCategory.MONEY,
            icon: 'effect-category-money',
            buildValues: (details: ProjectDetails) => {
                const baseTranslationKey = 'COMPONENT.library_item_quantification.money.values';
                const costs = details.projectInfo.constructionCosts;

                return (new Array(6)).fill(0).map((zero, i) => {
                    if (i === 0) { // start
                        return this.canFreelyChangeAllCategories ?
                            this.translationService.instant(`${baseTranslationKey}.not_required.0`) :
                            this.translationService.instant(`${baseTranslationKey}.0`);
                    } else if (i === 5) { // end
                        const norm = costs * 0.02;

                        return this.translationService.instant(`${baseTranslationKey}.5`, {
                            number: LibraryItemQuantificationGridComponent.humanReadableMoneyLabel(norm)
                        });
                    } else {
                        let percentFrom;
                        let percentTill;

                        // there is no linear or exponential relation between these variables
                        if (i === 1) {
                            percentFrom = 0;
                            percentTill = 0.2;
                        } else if (i === 2) {
                            percentFrom = 0.2;
                            percentTill = 0.5;
                        } else if (i === 3) {
                            percentFrom = 0.5;
                            percentTill = 1;
                        } else if (i === 4) {
                            percentFrom = 1;
                            percentTill = 2;
                        }

                        const normFrom = costs * (percentFrom / 100);
                        const normTo = costs * (percentTill / 100);

                        return this.translationService.instant(`${baseTranslationKey}.range`, {
                            from: LibraryItemQuantificationGridComponent.humanReadableMoneyLabel(normFrom),
                            to: LibraryItemQuantificationGridComponent.humanReadableMoneyLabel(normTo)
                        });
                    }
                });
            }
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.TIME}.label`),
            key: EffectCategory.TIME,
            icon: 'effect-category-time',
            buildValues: (details: ProjectDetails) => {
                const baseTranslationKey = 'COMPONENT.library_item_quantification.time.values';
                const durationInDays = details.projectInfo.durationInMonths * 20;

                return (new Array(6)).fill(0).map((zero, i) => {
                    if (i === 0) { // start
                        return this.canFreelyChangeAllCategories ?
                            this.translationService.instant(`${baseTranslationKey}.not_required.0`) :
                            this.translationService.instant(`${baseTranslationKey}.0`);
                    } else if (i === 1) { // second
                        const norm = Math.round(durationInDays * 0.0025);

                        return this.translationService.instant(`${baseTranslationKey}.1`, {
                            number: norm
                        });
                    } else if (i === 5) { // last item
                        const norm = Math.round(durationInDays * 0.02);

                        return this.translationService.instant(`${baseTranslationKey}.5`, {
                            number: norm
                        });
                    } else {
                        const percentFrom = (0.25 * Math.pow(2, i - 2));
                        const percentTill = (0.5 * Math.pow(2, i - 2));

                        const normFrom = Math.round(durationInDays * (percentFrom / 100));
                        const normTo = Math.round(durationInDays * (percentTill / 100));

                        return this.translationService.instant(`${baseTranslationKey}.range`, {
                            from: normFrom,
                            to: normTo
                        });
                    }
                });
            }
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.QUALITY}.label`),
            key: EffectCategory.QUALITY,
            icon: 'effect-category-quality',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.quality.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.quality.values.${i}`);
                    }
                })
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.SAFETY}.label`),
            key: EffectCategory.SAFETY,
            icon: 'effect-category-safety',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.safety.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.safety.values.${i}`);
                    }
                })
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.SURROUNDINGS}.label`),
            key: EffectCategory.SURROUNDINGS,
            icon: 'effect-category-surroundings',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.surroundings.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.surroundings.values.${i}`);
                    }
                })
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.IMAGE}.label`),
            key: EffectCategory.IMAGE,
            icon: 'effect-category-image',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.image.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.image.values.${i}`);
                    }
                })
        },
        {
            label: this.translationService.instant(`COMPONENT.library_item_quantification.${EffectCategory.REPUTATION}.label`),
            key: EffectCategory.REPUTATION,
            icon: 'effect-category-image',
            buildValues: () => (new Array(6)).fill(0).map((zero, i) => {
                    if (this.canFreelyChangeAllCategories && i === 0) {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.reputation.values.not_required.${i}`);
                    } else {
                        return this.translationService.instant(`COMPONENT.library_item_quantification.reputation.values.${i}`);
                    }
                })
        }
    ];
    private values: IQuantification = {};
    private onChangeFn: ChangeFunction;
    private onTouchedFn: ChangeFunction;
    private quantificationCategoriesRisks: { key: EffectCategory; label: string }[];
    private quantificationCategoriesOpportunities: { key: EffectCategory; label: string }[];
    private quantificationCategoriesTasks: { key: EffectCategory; label: string }[];

    constructor(
        private translationService: TranslateService,
        private projectService: ProjectService
    ) {
    }

    @Input()
    public set entry(item: ProjectLibraryItem) {
        this.lastEntryValue = item;

        this.canFreelyChangeAllCategories = item.isProjectSpecific && item.libraryType !== LibraryType.OPPORTUNITIES && item.libraryType !== LibraryType.TASKS;

        this.buildColumns();
    }

    @Input()
    public set projectDetails(details: ProjectDetails) {
        this.lastProjectDetailsValue = details;
        this.quantificationCategoriesRisks = this.getQuantificationCategories(details, 'quantificationCategoriesRisks');
        this.quantificationCategoriesOpportunities = this.getQuantificationCategories(details, 'quantificationCategoriesOpportunities');
        this.quantificationCategoriesTasks = this.getQuantificationCategories(details, 'quantificationCategoriesTasks');

        this.buildColumns();
    }

    private getQuantificationCategories(details: ProjectDetails, propertyName: 'quantificationCategoriesRisks' | 'quantificationCategoriesOpportunities' | 'quantificationCategoriesTasks') {
        return [{key: EffectCategory.PROBABILITY, label: this.translationService.instant('COMPONENT.library_item_quantification.probability.label`')}]
            .concat(details.projectInfo[propertyName]
                .filter((item) =>
                    quantificationCategories.find((quantificationItem) => quantificationItem.key === item)
                )
                .map((item) => {
                    const category = quantificationCategories.find((quantificationItem) => quantificationItem.key === item);
                    return {key: category.key, label: this.translationService.instant(`COMPONENT.library_item_quantification.${category}.label`)};
                })
            );
    }

    private static humanReadableMoneyLabel(euros: number) {
        const normalizedEuros = Math.abs(euros);
        const minusSign = euros < 0 ? '-' : '';

        if (normalizedEuros >= 1e7) {
            return `${minusSign}${Math.round(normalizedEuros / 1e6)}m`;
        } else if (normalizedEuros >= 1e6) {
            return `${minusSign}${Math.round((normalizedEuros / 1e6) * 10) / 10}m`;
        } else if (normalizedEuros >= 1e4) {
            return `${minusSign}${Math.round(normalizedEuros / 1e3)}k`;
        } else if (normalizedEuros >= 1e3) {
            return `${minusSign}${Math.round((normalizedEuros / 1e3) * 10) / 10}k`;
        } else {
            return `${minusSign}${Math.round(normalizedEuros)}`;
        }
    }

    /** thanks Google for copy & paste */
    private static capitalizeFirstLetter(source: string): string {
        return source.charAt(0).toUpperCase() + source.slice(1);
    }

    ngOnInit() {
        this.canSetQuantification();
    }

    public canSetQuantification() {
        const projectInfo = this.lastProjectDetailsValue.projectInfo;
        const requiredProjectFields = ['constructionCosts', 'workStartsAt', 'workEndsAt'];

        if (!this.projectHasEmptyField) {
            requiredProjectFields.forEach((requiredField) => {
                if (projectInfo[requiredField] === null || projectInfo[requiredField] === 0 || projectInfo[requiredField] === '') {
                    this.projectHasEmptyField = true;
                }
            });
        }
    }

    public setValue(column: IColumn, value: number) {

        if (this.canEditProjectLibraryItems()) {
            this.values[column.key] = value;

            this.onTouchedFn(this.values);
            this.onChangeFn(this.values);
        }
    }

    public isActiveValue(column: IColumn, value: number): boolean {
        return this.values[column.key] === value;
    }

    public registerOnChange(fn: ChangeFunction): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: ChangeFunction): void {
        this.onTouchedFn = fn;
    }

    public writeValue(value: IQuantification): void {
        this.values = value || {};
    }

    get quantificationCategories(): { key: EffectCategory; label: string }[] {
        switch (this.lastEntryValue.libraryType) {
            case LibraryType.RISKS:
                return this.quantificationCategoriesRisks;
            case LibraryType.OPPORTUNITIES:
                return this.quantificationCategoriesOpportunities;
            case LibraryType.TASKS:
                return this.quantificationCategoriesTasks;
        }
    }

    private buildColumns() {
        if (!this.lastProjectDetailsValue || !this.lastEntryValue || !this.quantificationCategoriesRisks) {
            return;
        }


        this.columns = this.quantificationCategories.map(category => this.ALL_COLUMNS.find((column) => column.key === category.key));
        this.columns.forEach(column => {
            if (this.canFreelyChangeAllCategories) {
                column.disabled = false;
            } else if (!!this.lastEntryValue && this.lastEntryValue.libraryType === 'opportunities' && ['money', 'time'].indexOf(column.key) >= 0) {
                column.disabled = false;
            } else {
                column.disabled = !this.lastEntryValue[`effectCategory${LibraryItemQuantificationGridComponent.capitalizeFirstLetter(column.key)}`]
                    && this.required.indexOf(column.key) === -1;
            }
            column.valueLabels = column.buildValues(this.lastProjectDetailsValue);
        });

        this.columnRowIndexes = Array.from(Array(this.columns[0].valueLabels.length).keys());
    }

    public canEditProjectLibraryItems() {
        return this.overruleCanEdit || this.projectService.canEditProjectLibraryItems(this.lastProjectDetailsValue);
    }
}
