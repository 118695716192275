import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@ng-stack/forms';
import {ILibraryItemFiltersData, LibraryItemFilters} from '../../models';

@Component({
    selector: 'app-library-item-filters-selected',
    templateUrl: './library-item-filters-selected.component.html',
    styleUrls: ['./library-item-filters-selected.component.scss']
})
export class LibraryItemFiltersSelectedComponent implements AfterViewChecked {

    public filterControl: FormGroup<ILibraryItemFiltersData>;

    constructor(
        private hostRef: ElementRef
    ) {
    }

    @Input() set filters(libraryItemFilters: LibraryItemFilters) {
        this.filterControl = libraryItemFilters.getControl();
    }

    @Input()
    public withinPdf = false;

    @Output()
    public selectionCountChange = new EventEmitter<number>();
    private selectionCount = 0;

    ngAfterViewChecked(): void {
        this.updateSelectionCount();
    }

    private updateSelectionCount(): void {
        const currentSelectionCount = this.hostRef.nativeElement.querySelectorAll('vwui-button')?.length || 0;

        if (this.selectionCount !== currentSelectionCount) {
            this.selectionCount = currentSelectionCount;
            this.selectionCountChange.emit(this.selectionCount);
        }
    }

}
