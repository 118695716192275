import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {MsAuthService} from './service';

@Injectable()
export class MsAuthInterceptor implements HttpInterceptor {

    constructor(
        private msAuthService: MsAuthService
    ) {
    }

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return from(this.handle<T>(request, next));
    }

    private async handle<T>(request: HttpRequest<T>, next: HttpHandler): Promise<HttpEvent<T>> {
        if (this.msAuthService.isAuthenticated()) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.msAuthService.getAccessToken()}`
                }
            });
        }

        return next.handle(request).toPromise();
    }

}
