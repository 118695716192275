import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@ng-stack/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {INotificationData} from '../../models';
import {FormUtil, RouteUtil} from '../../utils';
import {NotificationService, ToastService, TrackingService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-update-notification-modal',
    templateUrl: './update-notification-modal.component.html',
    styleUrls: ['./update-notification-modal.component.scss']
})
export class UpdateNotificationModalComponent implements OnInit {

    public form: FormGroup<INotificationData>;
    public isUpdating = false;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<INotificationData>,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private trackingService: TrackingService
    ) {
    }

    ngOnInit(): void {
        this.form = new FormGroup<INotificationData>({
            id: new FormControl<number>(this.modalConfig.data.id),
            title: new FormControl<string>(this.modalConfig.data.title, [Validators.required, Validators.maxLength(32)]), // Database max length = 255
            message: new FormControl<string>(this.modalConfig.data.message, [Validators.required, Validators.maxLength(12000)]), // Database max length = 65535
            publicationStart: new FormControl<string>(this.modalConfig.data.publicationStart),
            dismissed: new FormControl<boolean>(this.modalConfig.data.dismissed)
        });
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const notificationData = this.form.value as INotificationData;

                this.isUpdating = true;
                this.form.disable();
                await this.notificationService.saveNotification(notificationData)
                    .then(() => {
                        this.modalRef.close();
                        this.toastService.openSuccess(this.translateService.instant('COMPONENT.update_notification_modal.toast.success'));
                    })
                    .catch((error) => {
                        this.isUpdating = false;
                        this.form.enable();
                        this.toastService.openError(RouteUtil.getErrorCode(error), this.translateService.instant('COMPONENT.update_notification_modal.toast.error'));

                        throw error;
                    });
            }
        } catch (e) {
            console.error('Updating notification failed: ', e);
            this.trackingService.exception(e);
        }
    }

    public clearPublicationStart(event: Event): void {
        event.preventDefault();

        this.form.patchValue({
            publicationStart: ''
        });
    }

}
