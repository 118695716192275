import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@ng-stack/forms';
import {ILibraryItemFiltersOptionsData} from '../../models';

@Component({
    selector: 'app-library-item-filters-options',
    templateUrl: './library-item-filters-options.component.html',
    styleUrls: ['./library-item-filters-options.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LibraryItemFiltersOptionsComponent {
    @Input() optionsControl: FormGroup<ILibraryItemFiltersOptionsData>;
}
