<vwui-header>
    {{ 'PAGE_NOTIFICATIONS.title' | translate }}
</vwui-header>

<div class="mt-lg">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ 'PAGE_NOTIFICATIONS.title' | translate }}</div>

        <vwui-table-row>
            <vwui-table-header class="table-col table-col--title">{{ 'PAGE_NOTIFICATIONS.table.title' | translate }}</vwui-table-header>
            <vwui-table-header class="table-col table-col--published">{{ 'PAGE_NOTIFICATIONS.table.published' | translate }}</vwui-table-header>
            <vwui-table-header class="table-col table-col--actions"></vwui-table-header>
        </vwui-table-row>

        <vwui-table-row *ngFor="let notification of notifications$ | async">
            <vwui-table-column class="table-col table-col--title">{{ notification.title }}</vwui-table-column>
            <vwui-table-column class="table-col table-col--published">{{ notification.publicationStartDate !== null ? (notification.publicationStartDate | date: ('DATE.long' | translate)) : '-' }}</vwui-table-column>
            <vwui-table-column class="table-col table-col--actions">
                <vwui-tooltip [label]="'PAGE_NOTIFICATIONS.tooltip.preview' | translate"><vwui-btn-icon icon="preview" (click)="notification.showPreview()"></vwui-btn-icon></vwui-tooltip>
                <vwui-tooltip [label]="'PAGE_NOTIFICATIONS.tooltip.edit' | translate"><vwui-btn-icon icon="edit" (click)="openUpdateNotificationModal(notification)"></vwui-btn-icon></vwui-tooltip>
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</div>
