import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from '../../services';
import {UserRoles} from '../../enums';

@Injectable({
    providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {
    constructor(private userService: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.userService.userHasAnyRole(route.data.roles as UserRoles[]);
    }
}
