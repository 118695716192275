<vwui-spinner class="vwui-spinner--with-margin" *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <vwui-header>
        {{ 'PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + libraryType + '.title' | translate }}
        &nbsp;
        <vwui-badge class="badge badge--header">{{ getNumberOfItems() }}</vwui-badge>
        <vwui-button slot="action" class="is-secondary" [appDisabledReason]="getDisabledReason() | translate"
                     (click)="addSpecificItem()">{{ 'PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + libraryType + '.specific_risks' | translate }}</vwui-button>
        <vwui-button slot="action" class="is-primary" [appDisabledReason]="getDisabledReason() | translate"
                     (click)="openLibraryItems()">{{ 'PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + libraryType + '.library' | translate }}</vwui-button>
    </vwui-header>

    <vwui-header-divider-bar class="header">
        <vwui-search
            [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.search.label' | translate"
            (vwuiChange)="search($event.detail.value)"
            a11yHotkeyFocusSearch
        ></vwui-search>
        <ng-select
            class="search-theme"
            [items]="categories"
            bindValue="id"
            bindLabel="name"
            [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.theme.placeholder' | translate"
            (change)="searchCategoryChanged($event)"
        ></ng-select>
        <ng-select
            class="search-status"
            [items]="statuses"
            bindValue="id"
            bindLabel="name"
            [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.status.placeholder' | translate"
            (change)="searchStatusChanged($event)"
        ></ng-select>
        <ng-select
            class="search-action-holder"
            [items]="actionholders"
            bindValue="id"
            bindLabel="name"
            [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.actionholder.placeholder' | translate"
            (change)="searchActionHolderChanged($event)"
            [notFoundText]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.actionholder.no_items' | translate"
        ></ng-select>
        <ng-select
            class="search-filled-in"
            [items]="filledInFilterItems"
            bindValue="id"
            bindLabel="name"
            [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.filledIn.placeholder' | translate"
            (change)="searchFilledInChanged($event)"
        ></ng-select>
    </vwui-header-divider-bar>

    <div *ngIf="getNumberOfItems() > 0" class="grid-layout" a11yFocusBlock>
        <ng-container *ngFor="let projectItem of visibleItems">
            <app-item-card
                class="item-card--size-md item-card--clickable"
                (click)="openLibraryItem(projectItem.item)" a11yKeyboardClickable
                [ngClass]="{'item-card--proposal-changeable': canChangeStatus && projectItem.item.status === proposalStatus}"
            >
                <vwui-badge class="badge badge--right" *ngIf="projectItem.item.itemOwner">{{ getInitials(projectItem.item.itemOwner) }}</vwui-badge>
                <div class="item-card__warning item-card__warning--right" *ngIf="projectItem && !projectItem.filledIn">
                    <vwui-tooltip [label]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.filledIn.tooltip' | translate"><vwui-icon iconName="circle"></vwui-icon></vwui-tooltip>
                </div>
                <div class="item-card__title">{{ projectItem.item.getTitle(getAllItems())}}</div>

                <div class="item-card__description">{{ projectItem.item.riskDescription }}</div>
                <div class="item-card__label" *ngIf="projectItem.item.causeDescription">{{ 'COMPONENT.library_mgmt.library_type.risks.item.causeDescription' | translate }}</div>
                <div class="item-card__description" *ngIf="projectItem.item.causeDescription">{{ projectItem.item.causeDescription }}</div>
                <ng-container footer>
                    <div class="footer-actions">

                        <vwui-badge class="badge" *ngIf="projectItem.item.counterMeasuresActionHolder" (click)="openLibraryItem(projectItem.item, RISK_MITIGATION_TAB, $event)">
                            <vwui-tooltip [label]="projectItem.item.counterMeasuresActionHolder"><vwui-icon iconName="vwui-user"></vwui-icon></vwui-tooltip>
                        </vwui-badge>
                        <vwui-badge class="badge" (click)="openLibraryItem(projectItem.item, QUANTIFICATION_TAB, $event)">{{ getScoreLabelForEntry(projectItem.item) }}</vwui-badge>
                        <vwui-badge class="badge" (click)="openLibraryItem(projectItem.item, REMARKS_TAB, $event)">
                            <vwui-icon iconName="chat"></vwui-icon>
                        </vwui-badge>
                    </div>
                    <div class="footer-status" [ngClass]="'footer-status--' + getEntryStatus(projectItem.item)" (click)="openLibraryItem(projectItem.item, RISK_MITIGATION_TAB, $event)">
                        {{ 'PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + libraryType + '.status.' + getEntryStatus(projectItem.item) | translate }}
                    </div>
                </ng-container>
            </app-item-card>
        </ng-container>
    </div>
</div>
