import {ITisAnswerData, ITisAnswerValue} from './project-data';

export class TisAnswer {
    questionId?: number;
    value?: ITisAnswerValue;

    constructor(data: ITisAnswerData) {
        this.questionId = data.questionId;
        this.value = data.value;
    }

    public setValue(value: ITisAnswerValue) {
        this.value = value;
    }
}
