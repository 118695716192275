import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CategoryDefault, ICategoryData} from '../../models';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';

@Component({
    selector: 'app-quickscan-create-update-category-modal',
    templateUrl: './tis-create-update-category-modal.component.html',
    styleUrls: ['./tis-create-update-category-modal.component.scss']
})
export class TisCreateUpdateCategoryModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    private data?: ICategoryData;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; data?: ICategoryData; saveText: string }>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;

        const nameValue = this.data && this.data.name ? this.data.name : CategoryDefault.name;

        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required)
        });
    }

    public submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues: ICategoryData = {...this.data, ...this.form.value};
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Creating category failed: ', e);
            this.trackingService.exception(e);
        }
    }

}
