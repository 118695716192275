import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IQuickscanResultData, QuickscanDefinition, QuickscanResult} from '../../models';
import {IChartSignalMatrixConfig} from '../chart-signal-matrix/chart-signal-matrix.data';
import {Colors} from '../../enums';
import {PdfDetails} from '../../models/pdf/pdf-details';

@Component({
    selector: 'app-pdf-page-quickscan-result',
    templateUrl: './pdf-page-quickscan-result.component.html',
    styleUrls: ['./pdf-page-quickscan-result.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageQuickscanResultComponent implements OnInit {

    @Input() pdfDetails: PdfDetails;

    @Input() quickscanResult: QuickscanResult;

    @Input() quickscanDefinition: QuickscanDefinition;

    @Input() tis = false;

    public colors = Colors;
    public redFlags: string[];
    public risksVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]},
        vertical: {labels: [0, 2, 4, 6, 8, 10]},
        targetValue: {
            horizontal: 0,
            vertical: 0
        }
    };

    constructor(
    ) {
    }

    ngOnInit() {
        this.determineRisksVsValueMatrix(this.quickscanResult);
        this.setRedFlags();
    }

    public determineClassificationColor(classification): string {
        if (classification === 'green') {
            return Colors.GREEN;
        } else if (classification === 'orange') {
            return Colors.ORANGE;
        } else if (classification === 'red') {
            return Colors.RED;
        } else {
            return Colors.WHITE;
        }
    }

    public determineClassificationTextColor(classification): string {
        if (classification === 'initial') {
            return Colors.DARK;
        } else {
            return Colors.WHITE;
        }
    }

    public determineRisksVsValueMatrix(result: IQuickscanResultData): void {
        const horizontalNotches = 10;
        const horizontalLabels = [0];
        const horizontalInterval = Math.round(result.risks.potential / horizontalNotches);
        for (let i = 0; i < horizontalNotches; i++) {
            horizontalLabels.push((i + 1) * horizontalInterval);
        }

        const verticalLabels = [0];
        const verticalNotches = 5;
        const verticalInterval = Math.round(result.values.potential / verticalNotches);
        for (let i = 0; i < verticalNotches; i++) {
            verticalLabels.push((i + 1) * verticalInterval);
        }

        this.risksVsValueMatrix = {
            horizontal: {labels: horizontalLabels},
            vertical: {labels: verticalLabels},
            targetValue: {
                horizontal: result.risks.score,
                vertical: result.values.score
            }
        };
    }

    public setRedFlags(): void {
        const redFlags = [];

        this.quickscanResult.redFlags.values.forEach((redFlag) => {
            let redFlagString = '';

            this.quickscanDefinition.getCategories().forEach((category) => {
                if (redFlag.type === 'category' && category.id === redFlag.id) {
                    redFlagString = category.name;
                } else if (redFlag.type === 'answer') {
                    category.subCategories.forEach((subCategory) => {
                        subCategory.getAllSubCategoryQuestions().forEach((question) => {
                            question.possibleAnswers.forEach((possibleAnswer) => {
                                if (possibleAnswer.id === redFlag.id) {
                                    redFlagString = subCategory.name;
                                }
                            });
                        });
                    });
                }
            });

            if (redFlagString !== '') {
                redFlags.push(redFlagString);
            }
        });

        this.redFlags = redFlags;
    }

    getPageName() {
        return this.tis ? 'PAGE_TIS_QUICKSCAN_RESULT' : 'PAGE_QUICKSCAN_RESULT';
    }
}
