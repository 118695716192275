import {Component, Input} from '@angular/core';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {ProjectDetails, ProjectLibraryItem} from '../../models';
import {ProjectLibraryItemStatus} from '../../models/project/project-libary-item';

@Component({
    selector: 'app-pdf-page-forecast-end-result-list',
    templateUrl: './pdf-page-forecast-end-result-list.component.html',
    styleUrls: ['./pdf-page-forecast-end-result-list.component.scss']
})
export class PdfPageForecastEndResultListComponent {

    public projectDetails: ProjectDetails;
    public risksInPer: ProjectLibraryItem[] = [];
    public opportunitiesInPer: ProjectLibraryItem[] = [];

    @Input()
    set pdfDetails(val: PdfDetails) {
        this.risksInPer = this.filterAndSort(val, 'risks');
        this.opportunitiesInPer = this.filterAndSort(val, 'opportunities');
        this.projectDetails = val.projectDetails;
    }

    private filterAndSort(pdfDetails: PdfDetails, libraryType: string) {
        return pdfDetails.projectDetails.projectLibraryItems
            .filter(item => item.libraryType === libraryType)
            .filter(item => item.includedInPer)
            .filter(item => item.status !== ProjectLibraryItemStatus.PROPOSED)
            .sort((a, b) => b.bestGuessCosts - a.bestGuessCosts);
    }
}
