<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails.projectDetails.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ 'PAGE_FILL_QUICKSCAN.title' | translate }}</h1>
    <small>{{ 'PAGE_FILL_QUICKSCAN.version' | translate }}: {{ pdfDetails.quickscanDefinition.versionId }}</small>

    <div class="grid-spacer grid-spacer--large"></div>
    <app-pdf-page-quickscan-list
        [quickscanDefinition]="pdfDetails.quickscanDefinition"
        [quickscanAnswers]="pdfDetails.projectDetails.quickscanAnswers"
        [categoryInfo]="pdfDetails.projectDetails.categoryInfo"
    >
    </app-pdf-page-quickscan-list>

    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
<app-pdf-pagebreak></app-pdf-pagebreak>
<app-pdf-page-quickscan-result
    [pdfDetails]="pdfDetails"
    [quickscanResult]="pdfDetails.quickscanResult"
    [quickscanDefinition]="pdfDetails.quickscanDefinition"
    [tis]="false"
>
</app-pdf-page-quickscan-result>
