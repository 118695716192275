import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectDetails} from '../../models';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-project-opportunities',
    templateUrl: './project-opportunities.component.html',
    styleUrls: ['./project-opportunities.component.scss']
})
export class ProjectOpportunitiesComponent {

    readonly libraryType = LibraryType.OPPORTUNITIES;

    @Input()
    public projectDetails: ProjectDetails;

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    constructor() {
    }

    public opportunityDetailsChanged($event: ProjectDetails) {
        this.projectDetailsChanged.emit($event);
    }
}
