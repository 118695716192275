import {Component, OnInit} from '@angular/core';
import {MsAuthService} from '../../module/ms-auth';

@Component({
    selector: 'app-page-logout',
    templateUrl: './page-logout.component.html',
    styleUrls: ['./page-logout.component.scss']
})
export class PageLogoutComponent implements OnInit {

    constructor(private authService: MsAuthService) {
    }

    ngOnInit(): void {
        this.authService.logout();
    }
}
