import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@ng-stack/forms';
import {FormatEuroCentsDirective} from '../../directives/format-euro-cents.directive';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from 'ngx-webstorage';
import {debounceTime} from 'rxjs/operators';

import {
    ILibraryItemFiltersCollapsedData,
    ILibraryItemFiltersData,
    LibraryItemFilters,
    LibraryItemFiltersCollapsedDefaultData
} from '../../models';

@Component({
    selector: 'app-library-item-filters',
    templateUrl: './library-item-filters.component.html',
    styleUrls: ['./library-item-filters.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LibraryItemFiltersComponent implements OnDestroy {

    public filterControl: FormGroup<ILibraryItemFiltersData>;
    public filterControlBestGuessMinFormatted = new FormControl<string>();
    public filterControlBestGuessMaxFormatted = new FormControl<string>();

    @LocalStorage('LIBRARY_ITEM_FILTER_COLLAPSED_STATE', LibraryItemFiltersCollapsedDefaultData)
    public collapsedState: ILibraryItemFiltersCollapsedData;

    private subscriptions: Subscription[] = [];

    constructor(
        private translateService: TranslateService
    ) {
    }

    @Input() set filters(libraryItemFilters: LibraryItemFilters) {
        this.filterControl = libraryItemFilters.getControl();

        this.clearSubscriptions();

        this.handlePriceFormat(this.filterControl.controls.bestGuessMin, this.filterControlBestGuessMinFormatted);
        this.handlePriceFormat(this.filterControl.controls.bestGuessMax, this.filterControlBestGuessMaxFormatted);
    }

    ngOnDestroy() {
        this.clearSubscriptions();
    }

    public updateCollapsedState(event: Event, collapsedKey: string): void {
        const target = event.target as HTMLElement;
        const clickedCollapseTrigger = !!target.closest('.vwui-collapse__trigger');

        if (clickedCollapseTrigger) {
            this.collapsedState = {
                ...this.collapsedState,
                [collapsedKey]: !this.collapsedState[collapsedKey]
            };
        }
    }

    private clearSubscriptions(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private handlePriceFormat(numberControl: FormControl, formattedControl: FormControl): void {
        const updatePrices = (price: number | string) => {
            const priceFormats = this.getPriceFormats(price);

            if (numberControl.value !== priceFormats.numberValue) {
                numberControl.setValue(priceFormats.numberValue);
            }
            if (formattedControl.value !== priceFormats.formattedValue) {
                formattedControl.setValue(priceFormats.formattedValue);
            }
        };

        this.subscriptions.push(numberControl.valueChanges.subscribe(updatePrices));
        this.subscriptions.push(formattedControl.valueChanges.pipe(debounceTime(300)).subscribe(updatePrices));

        formattedControl.setValue(this.getPriceFormats(numberControl.value).formattedValue);
    }

    private getPriceFormats(price: number | string): { numberValue?: number; formattedValue: string } {
        if (price === null || price === '' || price === undefined) {
            return { numberValue: undefined, formattedValue: '' };
        }
        const minValue = 1;
        const numberValue = Math.max(FormatEuroCentsDirective.convertToNumber(price), minValue);
        const formattedValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

        return {numberValue, formattedValue};
    }

}
