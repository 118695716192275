import {ObjectUtil} from '../../utils';
import ITisPossibleAnswerData from './tis-possible-answer-data';
import {TisPossibleAnswerDefault} from './tis-possible-answer-default';

export class TisPossibleAnswer {
    public id: number;
    public tempId: string;
    public answer: string;

    constructor(config: ITisPossibleAnswerData) {
        const settings = ObjectUtil.mergeObjects<ITisPossibleAnswerData>([TisPossibleAnswerDefault, config]);

        this.id = settings.id;
        this.tempId = settings.tempId;
        this.answer = settings.answer;
    }
}
