import {ErrorHandler, NgModule} from '@angular/core';
import {TrackingService, TrackingErrorHandler} from './tracking.service';

@NgModule({
    providers: [
        TrackingService,
        {
            provide: ErrorHandler,
            useClass: TrackingErrorHandler
        }
    ]
})
export class TrackingModule {

    constructor(
        private trackingService: TrackingService
    ) {
        this.trackingService.init();
    }

}
