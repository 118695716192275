import {Component, Input} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastService, PdfService, ProjectService} from '../../services';
import {PDFPageKeys, PdfType} from '../../enums';
import {ProjectDetails} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

interface DefaultConfigType {
    keys: {
        [key: string]: boolean; // we already use the enumerated PDF keys, so this can be a string
    };
    projectDetails: ProjectDetails;
}

interface SelectedPdfType {
    id: PdfType;
}

@Component({
    selector: 'app-download-pdf-modal',
    templateUrl: './download-pdf-modal.component.html',
    styleUrls: [ './download-pdf-modal.component.scss']
})

export class DownloadPDFModalComponent {
    @Input() prefilledKeys;

    public PDFPageKeys = PDFPageKeys;
    public PdfType = PdfType;
    public form: FormGroup;
    public isDownloading = false;
    public projectDetails: ProjectDetails;
    public pdfTypes = [
        {id: PdfType.DEFAULT, name: this.translateService.instant('PDF.PDF_TYPE_DEFAULT')},
        {id: PdfType.CLIENT_LIST, name: this.translateService.instant('PDF.PDF_TYPE_CLIENT_LIST')},
        {id: PdfType.FORECAST_END_RESULT_LIST, name: this.translateService.instant('PDF.PDF_TYPE_FORECAST_END_RESULT_LIST')}
    ];

    public FILTERED_ONLY = PdfService.FILTERED_ONLY;
    public INCLUDE_PROPOSALS = PdfService.INCLUDE_PROPOSALS;
    public PDF_PAGE_RISK_OPPORTUNITIES_FILTER = PDFPageKeys.RISKS_OPPORTUNITIES  + '-' + this.FILTERED_ONLY;
    public PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER = PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT  + '-' + this.FILTERED_ONLY;

    public pdfType = PdfType.DEFAULT;
    public showRiskDossierFilters = false;
    public showRiskDossierClientFilters = false;
    private subscriptions: Subscription[] = [];

    public riskOpportunityFilterItems = [{
        title: 'PDF.modal.filters_complete',
        value: false
    }, {
        title: 'PDF.modal.filters_only',
        value: true
    }];
    public riskOpportunityClientFilterItems = [{
        title: 'PDF.modal.filters_complete',
        value: false
    }, {
        title: 'PDF.modal.filters_only',
        value: true
    }];

    constructor(
        private projectService: ProjectService,
        private pdfService: PdfService,
        private fb: FormBuilder,
        private toastService: ToastService,
        private translateService: TranslateService,
        public modalConfig: VwuiModalConfig<DefaultConfigType>,
        public modalRef: VwuiModalRef
    ) {
        this.projectDetails = modalConfig.data.projectDetails;
        const includedPages = modalConfig.data.keys;

        this.form = this.fb.group({
            pdfType: new FormControl(PdfType.DEFAULT, Validators.required),
            [PDFPageKeys.PROJECT_DETAILS]: [includedPages[PDFPageKeys.PROJECT_DETAILS] || false],
            [PDFPageKeys.QUICKSCAN]: [includedPages[PDFPageKeys.QUICKSCAN] || false],
            [PDFPageKeys.TIS_QUICKSCAN]: [includedPages[PDFPageKeys.TIS_QUICKSCAN] || false],
            [PDFPageKeys.TIS]: [includedPages[PDFPageKeys.TIS] || false],
            [PDFPageKeys.RISK_RAINBOW]: [includedPages[PDFPageKeys.RISK_RAINBOW] || false],
            [this.INCLUDE_PROPOSALS]: [false],
            [PDFPageKeys.RISK_RAINBOW_CLIENT]: [includedPages[PDFPageKeys.RISK_RAINBOW_CLIENT] || false],
            [PDFPageKeys.RISKS_OPPORTUNITIES]: [includedPages[PDFPageKeys.RISKS_OPPORTUNITIES] || false],
            [this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER]: [false],
            [PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT]: [includedPages[PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT] || false],
            [this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER]: [false],
            [PDFPageKeys.FORECAST_END_RESULT_LIST]: [includedPages[PDFPageKeys.FORECAST_END_RESULT_LIST] || false],
            [PDFPageKeys.MANDATING_FLOW]: [includedPages[PDFPageKeys.MANDATING_FLOW] || false]
        });
        this.updateFormOptions({id: PdfType.DEFAULT} );

        // RISC-810 - Toggle filtered option for risk dossier whether it is selected
        if (includedPages[PDFPageKeys.RISKS_OPPORTUNITIES]) {
            this.showRiskDossierFilters = true;
            this.form.get(this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER).setValue(false);
        }
        if (includedPages[PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT]) {
            this.showRiskDossierClientFilters = true;
            this.form.get(this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER).setValue(false);
        }

        this.subscriptions.push(this.form.valueChanges.subscribe((value) => {
            this.showRiskDossierFilters = value[PDFPageKeys.RISKS_OPPORTUNITIES];
            if (!this.showRiskDossierFilters) {
                this.form.get(this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER).setValue(false, {emitEvent: true, onlySelf: true});
            }

            this.showRiskDossierClientFilters = value[PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT];
            if (!this.showRiskDossierClientFilters) {
                this.form.get(this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER).setValue(false, {emitEvent: true, onlySelf: true});
            }
        }));
    }

    close() {
        this.modalRef.close({submit: true});
    }

    async submit() {
        this.isDownloading = true;

        // Save the project first, IF we have edit rights.. so we have the latest and greatest data within the PDF.
        if (this.projectService.canEditProject(this.projectDetails)) {
            await this.projectService.saveProjectDetails(this.projectDetails);
        }

        this.pdfService.downloadPdf(this.projectDetails, this.projectDetails.name, this.getIncludedPages())
            .then(() => {
                this.modalRef.close({submit: true});
            })
            .catch(() => {
                // We could give different toasts for different errors, its a nice to have.. (i.e projectDetails incorrect)
                this.toastService.openError(400, this.translateService.instant('ERRORS.pdf_generation_failed'));
            })
            .finally(() => {
                this.isDownloading = false;
            });
    }

    getIncludedPages() {
        let pages = Object.keys(this.form.value)
            .filter((key) => this.form.value[key] === true);

        // Make sure that if there are filters attached to the risk dossier page that the base page is filtered out
        // To prevent duplications / bugs later on
        if (pages.includes(this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER) && pages.includes(PDFPageKeys.RISKS_OPPORTUNITIES)) {
            pages = pages.filter((page) => {
                return page !== PDFPageKeys.RISKS_OPPORTUNITIES;
            });
        }

        if (pages.includes(this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER) && pages.includes(PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT)) {
            pages = pages.filter((page) => {
                return page !== PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT;
            });
        }

        return pages;
    }

    hasNoCheckedBoxes() {
        return this.getIncludedPages().length === 0;
    }

    setRadioValue(formControlName: string, value: boolean) {
        this.form.get(formControlName).setValue(value);
    }

    updateFormOptions(pdfType: SelectedPdfType): void {
        Object.keys(this.form.controls).forEach(key => {
            if (pdfType.id === PdfType.DEFAULT) {
                this.form.controls[key].enable();
                this.form.controls[key].setValue(null);
                if ([this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER, this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER].includes(key)) {
                    this.form.controls[key].setValue(false);
                }
            } else if (pdfType.id === PdfType.CLIENT_LIST) {
                this.form.controls[key].setValue(null);
                this.form.controls[key].disable();
                this.form.controls[PDFPageKeys.RISK_RAINBOW_CLIENT].setValue(true);
                this.form.controls[PDFPageKeys.RISK_RAINBOW_CLIENT].enable();
                this.form.controls[this.PDF_PAGE_RISK_OPPORTUNITIES_FILTER].setValue(false);
                this.form.controls[PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT].setValue(true);
                this.form.controls[PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT].enable();
                this.form.controls[this.PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER].setValue(false);
            } else if (pdfType.id === PdfType.FORECAST_END_RESULT_LIST) {
                this.form.controls[key].setValue(null);
                this.form.controls[key].disable();
                this.form.controls[PDFPageKeys.FORECAST_END_RESULT_LIST].setValue(true);
                this.form.controls[PDFPageKeys.FORECAST_END_RESULT_LIST].enable();
            }
        });

        if (!(this.projectDetails.isMandated() || this.projectDetails.isInMandateProcess())) {
            this.form.controls[PDFPageKeys.MANDATING_FLOW].setValue(null);
            this.form.controls[PDFPageKeys.MANDATING_FLOW].disable();
        }

        for (const [item, value] of Object.entries(this.modalConfig.data.keys)) {
            this.form.controls[item].setValue(value);
        }

        this.form.controls.pdfType.enable();
        this.form.controls.pdfType.setValue(pdfType.id);
        this.pdfType = pdfType.id;
    }
}
