import {ObjectUtil} from '../../utils';
import {IPossibleAnswerData} from './possibleanswer-data';
import {PossibleAnswerDefault} from './possibleanswer-default';

export class PossibleAnswer {

    public id: number;
    public answer: string;
    public score: number;
    public triggersRedFlag: boolean;
    public libraryEntryIds: number[];

    constructor(
        config: IPossibleAnswerData
    ) {
        const settings = ObjectUtil.mergeObjects<IPossibleAnswerData>([PossibleAnswerDefault, config]);

        this.id = settings.id;
        this.answer = settings.answer;
        this.score = settings.score;
        this.triggersRedFlag = settings.triggersRedFlag;
        this.libraryEntryIds = settings.libraryEntryIds;
    }

}
