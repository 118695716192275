<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_USER_MANAGEMENT.title' | translate}}</h2>

    <div class="button-group" slot="action">
        <vwui-button
            class="is-primary"
            (click)="newUserRights()"
            a11yHotkeyCreate
        >{{ 'PAGE_USER_MANAGEMENT.new_user' | translate }}</vwui-button>
    </div>
</vwui-header>

<vwui-header-divider-bar [formGroup]="filterForm">
    <vwui-search
        class="user-management__filters__search-users"
        formControlName="search"
        [placeholder]="'PAGE_USER_MANAGEMENT.filter.search.placeholder' | translate"
        a11yHotkeyFocusSearch
    ></vwui-search>
    <ng-container *ngIf="companies$ | async as companies">
        <ng-select *ngIf="companies.length > 0"
                   formControlName="company"
                   bindLabel="name"
                   class="user-management__filters__search-company"
                   [items]="companies$ | async"
                   [placeholder]="'PAGE_USER_MANAGEMENT.filter.companies.placeholder' | translate"
        ></ng-select>
    </ng-container>
</vwui-header-divider-bar>

<ng-container *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <vwui-table fixedLayout="true" class="user-management__users" compactable="false" compactMode="compact">
        <vwui-pagination [currentPage]="pageable.number + 1"
                         [firstItem]="(pageable.size * pageable.number) + 1"
                         [lastItem]="(pageable.size * pageable.number) + pageable.numberOfElements"
                         [totalPages]="pageable.totalPages"
                         [totalItems]="pageable.totalElements"
                         (vwuiPaginationChange)="changePage($event)"
                         slot="table-pagination"></vwui-pagination>
        <vwui-table-row *ngFor="let userRights of usersRights; trackBy: userRightsTrackByFn">
            <vwui-table-column>
                <details class="user-management__users__user" [attr.data-user-unique-name]="userRights.uniqueName">
                    <!-- For each user: Expandable header -->
                    <summary>
                        <ng-container
                            *ngIf="userRights.fullName && userRights.fullName !== userRights.uniqueName; else uniqueNameOnly">
                            <!-- subtly show unique name in vertical space -->
                            {{userRights.fullName}}
                            <vwui-badge [small]="true">{{userRights.uniqueName}}</vwui-badge>
                        </ng-container>
                        <ng-template #uniqueNameOnly>
                            {{userRights.uniqueName}}
                        </ng-template>
                        <vwui-icon class="user-management__users__user__chevron" iconName="chevron-down"></vwui-icon>
                        <vwui-btn-icon class="user-management__users__user__edit-button" icon="edit"
                                       (click)="editUserRights(userRights)"></vwui-btn-icon>
                        <span
                            class="user-management__users__user__company-summary">{{userRights.companyRights | namesFormat}}</span>
                    </summary>
                    <!-- Body will be expanded -->
                    <table class="user-management__users__user__companies">
                        <tr>
                            <th class="user-management__users__user__companies__header">
                                {{ 'PAGE_USER_MANAGEMENT.headers.company' | translate }}
                            </th>
                            <th class="user-management__users__user__companies__header user-management__users__user__companies__header__right-column">
                                {{ 'PAGE_USER_MANAGEMENT.headers.admin' | translate }}
                            </th>
                            <th class="user-management__users__user__companies__header user-management__users__user__companies__header__right-column">
                                {{ 'PAGE_USER_MANAGEMENT.headers.tender_manager' | translate }}
                            </th>
                            <th class="user-management__users__user__companies__header user-management__users__user__companies__header__right-column">
                                {{ 'PAGE_USER_MANAGEMENT.headers.edit' | translate }}
                            </th>
                            <th class="user-management__users__user__companies__header user-management__users__user__companies__header__right-column">
                                {{ 'PAGE_USER_MANAGEMENT.headers.view' | translate }}
                            </th>
                        </tr>
                        <!-- Companies expanded -->
                        <tr *ngFor="let company of userRights.companyRights">
                            <!-- For each company: Display the users rights at that level. Edit button here -->
                            <td>{{ company | namesFormat }}</td>
                            <td class="user-management__users__user__companies__right-column">
                                <vwui-icon *ngIf="company.admin" iconName="success"></vwui-icon>
                            </td>
                            <td class="user-management__users__user__companies__right-column">
                                <vwui-icon *ngIf="company.tenderManager" iconName="success"></vwui-icon>
                            </td>
                            <td class="user-management__users__user__companies__right-column">
                                <vwui-icon *ngIf="company.editor" iconName="success"></vwui-icon>
                            </td>
                            <td class="user-management__users__user__companies__right-column">
                                <vwui-icon *ngIf="company.viewer" iconName="success"></vwui-icon>
                            </td>
                        </tr>
                    </table>
                </details>
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</ng-container>
