import {Injectable} from '@angular/core';
import {NgSelectConfig} from '@ng-select/ng-select';
import {TranslationService} from '../translation/translation.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NgSelectService {

    constructor(
        private translateService: TranslateService,
        private translationService: TranslationService,
        private ngSelectConfig: NgSelectConfig
    ) {
    }

    public init(): void {
        // Extend the default ng select config
        this.ngSelectConfig.appendTo = 'body';

        this.translationService.onTranslationChanged().subscribe(() => {
            // Extend the default ng select config when language is set or changed
            this.ngSelectConfig.clearAllText = this.translateService.instant('NGSELECT.clear');
            this.ngSelectConfig.addTagText = this.translateService.instant('NGSELECT.add');
        });
    }

}
