import {AfterViewChecked, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService, PdfService} from '../../services';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {TimerUtil, Timer, PageUtil} from '../../utils';
import {PDFPageKeys} from '../../enums';
import {ProjectMandateDetails} from '../../models';

@Component({
    selector: 'app-page-pdf',
    templateUrl: './page-pdf-portrait.component.html',
    styleUrls: ['./page-pdf-portrait.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PagePdfPortraitComponent implements OnInit, AfterViewChecked, OnDestroy {

    public uuid: string;
    public PDFPageKeys = PDFPageKeys;
    public pdfDetails: PdfDetails;
    public cssClasses = ['pdf-page-a4-portrait'];
    public mandateDetails: ProjectMandateDetails;
    private timer: Timer;

    public includedPages = new Map([
        [PDFPageKeys.PROJECT_DETAILS, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.PROJECT_DETAILS, 1)
        }],
        [PDFPageKeys.QUICKSCAN, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.QUICKSCAN, 2)
        }],
        [PDFPageKeys.TIS_QUICKSCAN, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.TIS_QUICKSCAN, 3)
        }],
        [PDFPageKeys.TIS, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.TIS, 4)
        }],
        [PDFPageKeys.RISK_RAINBOW, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.RISK_RAINBOW, 5)
        }],
        [PDFPageKeys.RISK_RAINBOW_CLIENT, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.RISK_RAINBOW_CLIENT, 6)
        }],
        [PDFPageKeys.FORECAST_END_RESULT_LIST, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.FORECAST_END_RESULT_LIST, 7)
        }],
        [PDFPageKeys.MANDATING_FLOW, {
            active: false,
            options: [],
            needPageBreak: () => this.needPageBreak(PDFPageKeys.MANDATING_FLOW, 8)
        }]
    ]); // A Map holds the index, so this keeps our ordering in the PagePDF , useful for checking if we need a pagebreak!

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pdfService: PdfService,
        private toastService: ToastService
    ) {
        if (route.snapshot.params.includedPages) {
            if (route.snapshot.params.includedPages === 'mandate') {
                this.setMandatePages();
            } else {
                route.snapshot.params.includedPages.split('--').forEach((key) => {
                    const {pageName, options} = pdfService.getPdfPageWithOptions(key);
                    if (route.snapshot.params.includedPages.split('--').includes(PdfService.INCLUDE_PROPOSALS)) {
                        options.push(PdfService.INCLUDE_PROPOSALS);
                    }

                    if (this.includedPages.has(pageName)) {
                        this.includedPages.get(pageName).active = true;
                        this.includedPages.get(pageName).options = options;
                    }
                });
            }
        }

        // When we are on PDF pages, we do not want error tooltips in our PDF
        this.toastService.disableToasts();
        this.uuid = route.snapshot.params.uuid;
        this.pdfService.getPdfData(this.uuid).then((pdfDetails) => {
            this.timer.start();
            this.pdfDetails = pdfDetails;
        });
    }

    ngOnInit() {
        PageUtil.scrollToTop();
        PageUtil.addCssClasses(this.cssClasses);

        this.timer = TimerUtil.runCallbackWhenTimerIsIdle(() => {
            this.timer.stop();
            this.pdfService.pageReadyForPdfGeneration();
        }, 1000);
    }

    ngAfterViewChecked() {
        this.timer.tick();
    }

    ngOnDestroy() {
        PageUtil.removeCssClasses(this.cssClasses);
        this.pdfService.pageCleanupAfterPdfGeneration();
    }

    pageIncluded(key) {
        return this.includedPages.get(key).active;
    }

    pageOptions(key) {
        return this.includedPages.get(key).options;
    }

    needPageBreak(key, index?: number) {
        if (!index) {
            return this.includedPages.get(key).needPageBreak();
        } else {
            const pageKeys = this.includedPages.keys();
            for (let i = 0; i < index; i++) {
                const currentKey = pageKeys.next();
                if (currentKey && this.includedPages.get(currentKey.value).active) {
                    return true;
                }
            }
            return false;
        }
    }

    setMandatePages() {
        // RISC-371: do NOT include the original quickscan, rather include the full TIS including updated quickscan questions).
        this.includedPages.get(PDFPageKeys.PROJECT_DETAILS).active = true;
        this.includedPages.get(PDFPageKeys.TIS_QUICKSCAN).active = true;
        this.includedPages.get(PDFPageKeys.TIS).active = true;
        this.includedPages.get(PDFPageKeys.RISK_RAINBOW).active = true;
    }
}
