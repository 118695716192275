<div class="library-item-filters">
    <vwui-box class="library-item-filters__box" spacing="lg">
        <vwui-title class="library-item-filters__title" fontSize="lg">{{ 'COMPONENT.library_item_filters.title' | translate }}</vwui-title>

        <div class="library-item-filters__columns">
            <div class="library-item-filters__column">
                <vwui-collapse (click)="updateCollapsedState($event, 'score')" [collapseTitle]="'COMPONENT.library_item_filters.filter.score' | translate" [collapsed]="collapsedState.score">
                    <div class="filter-input">
                        <div class="filter-input__label">{{ 'COMPONENT.library_item_filters.label.risks' | translate }}</div>
                        <vwui-form-group class="filter-input__group filter-input__group--sublabel" [label]="'COMPONENT.library_item_filters.label.larger_than' | translate">
                            <vwui-input class="filter-input__number" type="number" [min]="0" [max]="100" [formControl]="filterControl.controls.riskScore"></vwui-input>
                        </vwui-form-group>
                    </div>
                    <div class="filter-input">
                        <div class="filter-input__label">{{ 'COMPONENT.library_item_filters.label.opportunities' | translate }}</div>
                        <vwui-form-group class="filter-input__group filter-input__group--sublabel" [label]="'COMPONENT.library_item_filters.label.larger_than' | translate">
                            <vwui-input class="filter-input__number" type="number" [min]="0" [max]="100" [formControl]="filterControl.controls.opportunityScore"></vwui-input>
                        </vwui-form-group>
                    </div>
                </vwui-collapse>
                <vwui-divider></vwui-divider>
                <div class="filter-input">
                    <vwui-checkbox [formControl]="filterControl.controls.sharedWithPrincipal">
                        {{ 'COMPONENT.library_item_filters.filter.shared_with_principal' | translate }}
                    </vwui-checkbox>
                    <vwui-checkbox [formControl]="filterControl.controls.includeProposals">
                        {{ 'COMPONENT.library_item_filters.filter.include_proposals' | translate }}
                    </vwui-checkbox>
                </div>
                <div class="filter-input">
                    <vwui-checkbox [formControl]="filterControl.controls.selectedOnly">
                        {{ 'COMPONENT.library_item_filters.filter.selected_only' | translate }}
                    </vwui-checkbox>
                </div>
            </div>

            <div class="library-item-filters__column">
                <vwui-collapse (click)="updateCollapsedState($event, 'actionHolder')" [collapseTitle]="'COMPONENT.library_item_filters.filter.action_holder' | translate" [collapsed]="collapsedState.actionHolder">
                    <app-library-item-filters-options
                        [optionsControl]="filterControl.controls.actionHolders"
                    ></app-library-item-filters-options>
                </vwui-collapse>
                <vwui-divider></vwui-divider>
                <vwui-collapse (click)="updateCollapsedState($event, 'theme')" [collapseTitle]="'COMPONENT.library_item_filters.filter.theme' | translate" [collapsed]="collapsedState.theme">
                    <app-library-item-filters-options
                        [optionsControl]="filterControl.controls.themes"
                    ></app-library-item-filters-options>
                </vwui-collapse>
            </div>

            <div class="library-item-filters__column">
                <vwui-collapse (click)="updateCollapsedState($event, 'bestGuess')" [collapseTitle]="'COMPONENT.library_item_filters.filter.best_guess' | translate" [collapsed]="collapsedState.bestGuess">
                    <div class="filter-input">
                        <vwui-form-group class="filter-input__group filter-input__group--sublabel" [label]="'COMPONENT.library_item_filters.label.min' | translate">
                            <vwui-input class="filter-input__number filter-input__number--price" [formControl]="filterControlBestGuessMinFormatted"></vwui-input>
                        </vwui-form-group>
                        <vwui-form-group class="filter-input__group filter-input__group--sublabel" [label]="'COMPONENT.library_item_filters.label.max' | translate">
                            <vwui-input class="filter-input__number filter-input__number--price" [formControl]="filterControlBestGuessMaxFormatted"></vwui-input>
                        </vwui-form-group>
                    </div>
                </vwui-collapse>
                <vwui-divider></vwui-divider>
                <vwui-collapse (click)="updateCollapsedState($event, 'statuses')" [collapseTitle]="'COMPONENT.library_item_filters.filter.status' | translate" [collapsed]="collapsedState.status">
                    <app-library-item-filters-options
                        [optionsControl]="filterControl.controls.statuses"
                    ></app-library-item-filters-options>
                </vwui-collapse>
            </div>
        </div>
    </vwui-box>
</div>
