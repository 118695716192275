export interface ILibraryItemSortOptionsData {
    opportunityColumn: string;
    opportunityOption: string;
    riskColumn: string;
    riskOption: string;
    taskColumn: string;
    taskOption: string;
}

export enum SortingColumns {
    IN_PER= 'in_per',
    SCORE= 'score',
    BEST_GUESS= 'best_guess',
}

export enum SortableTypes {
    RISK = 'risk',
    OPPORTUNITY = 'opportunity',
    TASK = 'task'
}


export enum ItemSorting {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}
