<form [formGroup]="form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2" class="header-title">
            {{ 'COMPONENT.change_user.title' | translate }}
        </h2>
    </vwui-modal-header>

    <vwui-modal-body class="body" a11yAutoFocus>
        <vwui-form-group [label]="'COMPONENT.change_user.user' | translate">
            <app-user-identity-input
                [control]="form.controls.userIdentity"
                [companyType]="companyType"
            ></app-user-identity-input>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.change_user.admin_companies' | translate">
            <ng-select
                [items]="companies$ | async"
                bindLabel="name"
                formControlName="adminCompanies"
                [multiple]="true"
            ></ng-select>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.change_user.manageable_companies' | translate">
            <ng-select
                [items]="companies$ | async"
                bindLabel="name"
                formControlName="manageableCompanies"
                [multiple]="true"
            ></ng-select>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.change_user.editable_companies' | translate">
            <ng-select
                [items]="companies$ | async"
                bindLabel="name"
                formControlName="editableCompanies"
                [multiple]="true"
            ></ng-select>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.change_user.viewable_companies' | translate">
            <ng-select
                [items]="companies$ | async"
                bindLabel="name"
                formControlName="viewableCompanies"
                [multiple]="true"
            ></ng-select>
        </vwui-form-group>
        <div class="notification" *ngIf="!form.valid && form.errors?.atLeastOneCompanyPresent">
            <vwui-icon iconName="alert"></vwui-icon>
            {{ 'COMPONENT.change_user.at_least_one_company' | translate }}
        </div>
    </vwui-modal-body>

    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close(false)">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [disabled]="!form.valid" [loading]="saving" (click)="submit()">
            {{ newCompanyUser ? ('BUTTON.create' | translate) : ('BUTTON.save' | translate) }}
        </vwui-button>
    </vwui-modal-footer>
</form>
