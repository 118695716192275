<form [formGroup]="form" (submit)="submit()" a11yFocusTrap>
    <vwui-modal-header>
        {{"EXECUTE_PROJECT.modal.title" | translate}}
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <p>{{"EXECUTE_PROJECT.modal.description" | translate}}</p>
        <br/>

        <vwui-form-group label="{{ 'EXECUTE_PROJECT.modal.project_lead' | translate }}" inputId="projectLead">
            <app-user-identity-input [companyType]="companyType" [control]="form.controls.projectLead"></app-user-identity-input>
        </vwui-form-group>

    </vwui-modal-body>
    <vwui-modal-footer>
        <div class="button-group">
            <vwui-button class="is-secondary"
                         (click)="modalRef.close(false)">{{"BUTTON.cancel" | translate}}</vwui-button>
            <vwui-button class="is-primary" [loading]="loading"
                         (click)="submit()">{{"EXECUTE_PROJECT.BUTTON.assign" | translate}}</vwui-button>
        </div>
    </vwui-modal-footer>
</form>

