<form [formGroup]="form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <p>{{ description | translate }}</p>
        <p *ngIf="extraDescription" class="extra-description">{{ extraDescription | translate }}</p>
    </vwui-modal-body>
    <vwui-modal-footer a11yAutoFocus>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" (click)="submit()">
            {{ 'BUTTON.delete' | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
