import {Component, Input} from '@angular/core';
import {IBlockStatsConfig} from './block-stats.data';

@Component({
    selector: 'app-block-stats',
    templateUrl: './block-stats.component.html',
    styleUrls: ['./block-stats.component.scss']
})
export class BlockStatsComponent {

    @Input() config: IBlockStatsConfig;

    constructor() {
    }

}
