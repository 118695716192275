import {Component} from '@angular/core';

@Component({
    selector: 'app-classification',
    templateUrl: './classification.component.html',
    styleUrls: ['./classification.component.scss']
})
export class ClassificationComponent {

    constructor() {
    }

}
