<form [formGroup]="form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>

        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.category' | translate" inputId="category">
            <ng-select
                [items]="subCategories"
                [selectOnTab]="true"
                [multiple]="false"
                [required]="true"
                [addTag]="true"
                [addTagText]="'COMPONENT.quickscan.modal.create_question.field.category_add' | translate"
                [placeholder]="'COMPONENT.quickscan.modal.create_question.field.category_placeholder' | translate"
                formControlName="categoryName"
            ></ng-select>
        </vwui-form-group>

        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.name' | translate" inputId="name">
            <vwui-input inputId="name" formControlName="name"></vwui-input>
        </vwui-form-group>

        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.info' | translate" inputId="info">
            <vwui-input inputId="info" formControlName="info"></vwui-input>
        </vwui-form-group>

        <div class="answer" *ngFor="let answer of getAnswersArray().controls; let answerIndex = index">
            <div class="answer__fields">
                <div class="answer__field">
                    <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.answer' | translate">
                        <vwui-input type="text" [formControl]="answer.controls.answer"></vwui-input>
                    </vwui-form-group>
                </div>
                <div class="answer__field answer__field--score">
                    <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.score' | translate">
                        <vwui-input type="number" [formControl]="answer.controls.score"></vwui-input>
                    </vwui-form-group>
                </div>
                <div class="answer__field answer__field--red-flag">
                    <vwui-checkbox [formControl]="answer.controls.triggersRedFlag">{{ 'COMPONENT.quickscan.modal.create_question.field.red_flag' | translate }}</vwui-checkbox>
                </div>
                <div class="answer__field answer__field--remove">
                    <vwui-btn-icon icon="vwui-trash" (click)="removeAnswer(answerIndex)"></vwui-btn-icon>
                </div>
            </div>
            <div class="answer__library_items">
                <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_question.field.library_items' | translate">
                    <ng-select
                        class="library-items"
                        [formControl]="answer.controls.libraryEntryIds"
                        [items]="allRiskEntries"
                        bindValue="id"
                        bindLabel="title"
                        [compareWith]="compareEntry"
                        [placeholder]="'COMPONENT.quickscan.modal.create_question.placeholder.library_items' | translate"
                        multiple="true"
                    >
                    </ng-select>
                </vwui-form-group>
            </div>
        </div>
        <vwui-button class="is-secondary" (click)="addAnswer()">
            {{ 'COMPONENT.quickscan.create_answer' | translate }}
        </vwui-button>

    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
