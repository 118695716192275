<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails.projectDetails.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ 'PAGE_MANDATING_FLOW.title' | translate }}</h1>
    <div class="main-wrapper">
        <div class="mandate-status">
            <vwui-button class="is-outline">
                {{ getStartDateMandate() | date: ('DATE.short' | translate) }} {{ 'COMPONENT.mandating.mandate_start' | translate }}
            </vwui-button>
            <div class="mandate-steps">
                <ng-container *ngFor="let mandateStep of pdfDetails.mandateDetails?.mandateSteps">
                    <div class="mandate-step">
                        <div class="mandate-step__content">
                            <app-item-status [status]="mandateStep.stepResult">
                                <div class="item-user">
                                    <vwui-tooltip [label]="mandateStep.actionholderEmail">
                                        <vwui-icon iconName="vwui-user"></vwui-icon>
                                    </vwui-tooltip>
                                </div>
                                <div class="item-title">{{ mandateStep.stepTitle }}</div>
                                <div class="item-date">{{ mandateStep.stepResultSubmittedAt | date: ('DATE.long' | translate) }}</div>
                                <ng-container *ngIf="mandateStep.remark">
                                    <div class="item-remark">{{ mandateStep.remark }}</div>
                                </ng-container>
                            </app-item-status>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</app-pdf-page>
