import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PdfDetails} from '../../models/pdf/pdf-details';

@Component({
    selector: 'app-pdf-page-tis-quickscan',
    templateUrl: './pdf-page-tis-quickscan.component.html',
    styleUrls: ['./pdf-page-tis-quickscan.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageTisQuickscanComponent {

    @Input() pdfDetails: PdfDetails;

}
