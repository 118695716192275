<div class="prevent-pagebreak-inside">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.title' | translate }}</div>

        <vwui-table-row>
            <vwui-table-header class="table-col table-col--name">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.name' | translate }}</vwui-table-header>
            <vwui-table-header class="table-col table-col--score">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.score' | translate }}</vwui-table-header>
            <vwui-table-header class="table-col table-col--per-costs">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.per_costs' | translate }}</vwui-table-header>
            <vwui-table-header class="table-col table-col--best-guess">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.best_guess' | translate }}</vwui-table-header>
            <ng-container *ngIf="tableType=== 'risks'">
                <vwui-table-header class="table-col table-col--max-costs">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.max_costs' | translate }}</vwui-table-header>
            </ng-container>
            <vwui-table-header class="table-col table-col--min-costs">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.min_costs' | translate }}</vwui-table-header>
            <ng-container *ngIf="tableType !== 'risks'">
                <vwui-table-header class="table-col table-col--max-costs">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.max_costs' | translate }}</vwui-table-header>
            </ng-container>
        </vwui-table-row>

        <ng-container *ngIf="projectLibraryItems.length > 0">
            <vwui-table-row *ngFor="let item of projectLibraryItems">
                <vwui-table-column class="table-col table-col--name">{{ item.getTitle(projectLibraryItems) }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--score">
                    <vwui-badge class="badge" *ngIf="item.getScore() > 0">{{ item.getScore() }}</vwui-badge>
                </vwui-table-column>
                <vwui-table-column class="table-col table-col--best-guess">{{ item.perCosts | priceFormat }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--best-guess">{{ item.bestGuessCosts | priceFormat }}</vwui-table-column>
                <ng-container *ngIf="tableType=== 'risks'">
                    <vwui-table-column class="table-col table-col--max-costs">{{ item.maxCosts | priceFormat }}</vwui-table-column>
                </ng-container>
                <vwui-table-column class="table-col table-col--min-costs">{{ item.minCosts | priceFormat }}</vwui-table-column>
                <ng-container *ngIf="tableType !== 'risks'">
                    <vwui-table-column class="table-col table-col--max-costs">{{ item.maxCosts | priceFormat }}</vwui-table-column>
                </ng-container>
            </vwui-table-row>

            <vwui-table-row>
                <vwui-table-column class="table-col table-col--name table-col--bold">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.column.total' | translate }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--score table-col--bold"></vwui-table-column>
                <vwui-table-column class="table-col table-col--best-guess table-col--bold">{{ getTotalPer() | priceFormat }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--best-guess table-col--bold">{{ getTotalBestGuess() | priceFormat }}</vwui-table-column>
                <ng-container *ngIf="tableType=== 'risks'">
                    <vwui-table-column class="table-col table-col--max-costs table-col--bold">{{ getTotalMaxCosts() | priceFormat }}</vwui-table-column>
                </ng-container>
                <vwui-table-column class="table-col table-col--min-costs table-col--bold">{{ getTotalMinCosts() | priceFormat }}</vwui-table-column>
                <ng-container *ngIf="tableType !== 'risks'">
                    <vwui-table-column class="table-col table-col--max-costs table-col--bold">{{ getTotalMaxCosts() | priceFormat }}</vwui-table-column>
                </ng-container>
            </vwui-table-row>
        </ng-container>
        <vwui-table-row *ngIf="projectLibraryItems.length === 0">
            <vwui-table-column class="table-col table-col--name">{{ 'PAGE_FORECAST_END_RESULT.table.' + tableType + '.no_items' | translate }}</vwui-table-column>
        </vwui-table-row>

    </vwui-table>
    <div class="grid-spacer grid-spacer--large"></div>
</div>
