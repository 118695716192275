import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ICurrentStepState} from '../../interfaces';
import {TranslateService} from '@ngx-translate/core';
import {AccessibleStepsService} from '../accessible-steps/accessible-steps.service';
import {ProjectSteps} from '../../enums';

@Injectable({
    providedIn: 'root'
})
export class StepService {

    // Are we going to use @SessionStorage()?
    private currentStep = ProjectSteps.DETAILS;
    private currentStepChanged: Subject<ICurrentStepState> = new Subject<ICurrentStepState>();

    private get accessibleSteps(): ProjectSteps[] {
        return this.accessibleStepsService.accessibleSteps;
    }

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private accessibleStepsService: AccessibleStepsService
    ) {

    }

    public setCurrentStep(currentStep: ProjectSteps) {
        this.currentStep = currentStep;
        this.currentStepChanged.next(this.getCurrentStepState());
    }

    public getCurrentStepState(): ICurrentStepState {
        // if something add skipSteps
        return {
            currentStep: this.currentStep,
            accessibleSteps: this.accessibleSteps
        };
    }

    public getCurrentStep(): ProjectSteps {
        return this.currentStep;
    }

    public getCurrentStepIndex(): number {
        return this.accessibleSteps.indexOf(this.getCurrentStep());
    }

    public getStepCount(): number {
        return this.accessibleSteps.length;
    }

    public onCurrentStepChanged(): Subject<ICurrentStepState> {
        return this.currentStepChanged;
    }

    public isValidStep(stepId: ProjectSteps) {
        return this.accessibleStepsService.isStepAccessible(stepId);
    }

    public getTitle(stepId: ProjectSteps): string {
        return this.translateService.instant(`STEPS.${stepId}`, { tisLabelShort: 'TIS' });
    }

    private navigate(stepPrefix: string) {
        this.router.navigate([stepPrefix, this.currentStep]);
    }
}
