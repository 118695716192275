import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {LoadingSpinnerService, ProjectService, QuickscanDefinitionService} from '../../services';
import {ProjectDetails, QuickscanDefinition} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {IScrollNavigationItem} from '../scroll-navigation/scroll-navigation.data';
import {ProjectStatus} from '../../enums';

@Component({
    selector: 'app-project-quickscan',
    templateUrl: './project-quickscan.component.html',
    styleUrls: ['./project-quickscan.component.scss']
})
export class ProjectQuickscanComponent implements OnInit {

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    public navItems: IScrollNavigationItem[] = [];
    @Input()
    public projectDetails: ProjectDetails;
    public quickscanDefinition?: QuickscanDefinition;

    constructor(
        private projectService: ProjectService,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private modalService: VwuiModalService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit() {
        this.loadingSpinnerService.show();
        this.quickscanDefinition = await this.quickscanDefinitionService.getQuickscanDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.quickscanDefinitionVersionId
        );

        this.navItems.push(...this.navItemsForQuickscan());
        this.navItems.push(this.navItemForQuickscanResult());
    }

    public canEditQuickscan(): boolean {
        return this.projectDetails.status === ProjectStatus.INITIAL
            && !this.projectDetails.isOnHold()
            && !this.projectDetails.isClosed()
            && this.projectService.canEditQuickscan(this.projectDetails);
    }

    private navItemsForQuickscan(): IScrollNavigationItem[] {
        return [
            {
                label: this.translateService.instant('PAGE_FILL_QUICKSCAN.title'),
                elementId: 'ThemeTitle',
                groupHeader: true
            },
            ...this.quickscanDefinition.categories.map((category, index) => {
                this.loadingSpinnerService.hide();
                return {
                    label: '' + (index + 1) + '. ' + category.name,
                    elementId: 'ThemeTitle' + category.id
                };
            })
        ];
    }

    private navItemForQuickscanResult(): IScrollNavigationItem {
        return { label: this.translateService.instant('PAGE_QUICKSCAN_RESULT.result.title'), elementId: 'ThemeTitleResult', groupHeader: true, active: this.projectDetails.status !== ProjectStatus.INITIAL };
    }
}
