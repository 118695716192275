<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ "PAGE_TIS_VERSIONS.title" | translate : { tisLabelShort: companyType?.tisLabelShort, tisLabelLong: companyType?.tisLabelLong } }}</h2>
</vwui-header>

<div class="mt-lg">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ tableConfig.title }}</div>
        <vwui-table-row>
            <vwui-table-header *ngFor="let column of tableConfig.columns">
                {{ column.header}}
            </vwui-table-header>
        </vwui-table-row>

        <vwui-table-row *ngIf="loadingSpinnerService.isSpinnerVisible()">
            <vwui-spinner *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>
        </vwui-table-row>

        <vwui-table-row *ngFor="let item of allTisDefinitions; trackBy: trackTisDefinition" (click)="onMenuSelect($event)">
            <vwui-table-column>
                {{ item.versionId }}
            </vwui-table-column>
            <vwui-table-column>
                {{ (item.isPublished ? 'PAGE_QUICKSCAN_VERSIONS.published' : 'PAGE_QUICKSCAN_VERSIONS.concept') | translate }}
            </vwui-table-column>
            <vwui-table-column *ngIf="item.isPublished">
                {{ item.publishedAt | date: ('DATE.long' | translate) }}
            </vwui-table-column>
            <vwui-table-column *ngIf="!item.isPublished">
                <slot></slot>
            </vwui-table-column>
            <vwui-table-column>
                <div class="button-group" *ngIf="!item.isPublished">
                    <vwui-button (click)="openEditVersionModal(item)">
                        {{ 'PAGE_QUICKSCAN_VERSIONS.publish' | translate }}
                    </vwui-button>
                    <vwui-button (click)="editTis(item)">
                        {{ 'PAGE_QUICKSCAN_VERSIONS.edit' | translate }}
                    </vwui-button>
                </div>
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</div>
