<ng-select
    class="select-user"
    [formControl]="control"
    [items]="items$ | async"
    [multiple]="multiple"
    [selectOnTab]="true"
    [addTag]="companyType?.azureAdRegistration ? false : addOption.bind(this)"
    [placeholder]="(companyType?.azureAdRegistration ? 'COMPONENT.USER_INPUT.userPlaceholder' : 'COMPONENT.USER_INPUT.emailPlaceholder') | translate"
    [notFoundText]="(companyType?.azureAdRegistration ? 'COMPONENT.USER_INPUT.userNotFound' : 'COMPONENT.USER_INPUT.emailValidation') | translate"
    (search)="startSearchUsers($event.term)"
    [searchFn]="searchThroughUsers.bind(this)"
    [readonly]="control.disabled"
    [clearable]="true"
    (clear)="reset()"
    bindLabel="emailAddress"
>
    <ng-template ng-option-tmp let-item="item">
        <div class="option option--vertical">
            {{ item.emailAddress }}
            <small class="text-light" *ngIf="item.name && item.name !== item.emailAddress">
                {{ item.name }}
            </small>
        </div>
    </ng-template>
    <ng-template ng-tag-tmp let-searchTerm="searchTerm">
        <div class="option">
            {{ 'COMPONENT.USER_INPUT.emailPlaceholder' | translate }}: {{ searchTerm }}
            <vwui-badge *ngIf="searchTerm | isValidEmailAddress" class="badge badge--primary">
                {{ 'COMPONENT.USER_INPUT.emailAddOption' | translate }}
            </vwui-badge>
        </div>
    </ng-template>
</ng-select>
