export interface IProjectMandateDetails {
    preconditions?: IProjectMandatePreconditions;
    mandateSteps: IProjectMandateStep[];
    flowResult: boolean;
}

export class ProjectMandateDetails implements IProjectMandateDetails {
    preconditions?: ProjectMandatePreconditions;
    mandateSteps: ProjectMandateStep[];
    flowResult: boolean;

    constructor(data: IProjectMandateDetails) {
        this.preconditions = data.preconditions ? new ProjectMandatePreconditions(data.preconditions) : undefined;
        this.mandateSteps = data.mandateSteps.map((item) => new ProjectMandateStep(item));
        this.flowResult = data.flowResult;
    }

    public canMandate(): boolean {
        return this.preconditions === null || this.preconditions.satisfied();
    }
}

export interface ProjectMandatePrecondition {
    required: boolean;
    satisfied: boolean;
    satisfiedOrOptional: boolean;
}

export interface IProjectMandatePreconditions {
    quickscanNotFilledIn: ProjectMandatePrecondition;
    quickscanNotSubmitted: ProjectMandatePrecondition;
    tisQuickscanNotSubmitted: ProjectMandatePrecondition;
    tisQuickscanOutdated: ProjectMandatePrecondition;
    tisNotFilledIn: ProjectMandatePrecondition;
    nrOfRisksNotCompliant: ProjectMandatePrecondition;
    nrOfOpportunitiesNotCompliant: ProjectMandatePrecondition;
    constructionCostsNotSpecified: ProjectMandatePrecondition;
    mandateProcessAlreadyStarted: ProjectMandatePrecondition;
}

export class ProjectMandatePreconditions implements IProjectMandatePreconditions {
    quickscanNotFilledIn: ProjectMandatePrecondition;
    quickscanNotSubmitted: ProjectMandatePrecondition;
    tisQuickscanNotSubmitted: ProjectMandatePrecondition;
    tisQuickscanOutdated: ProjectMandatePrecondition;
    tisNotFilledIn: ProjectMandatePrecondition;
    nrOfRisksNotCompliant: ProjectMandatePrecondition;
    nrOfOpportunitiesNotCompliant: ProjectMandatePrecondition;
    constructionCostsNotSpecified: ProjectMandatePrecondition;
    mandateProcessAlreadyStarted: ProjectMandatePrecondition;

    constructor(data: IProjectMandatePreconditions) {
        this.quickscanNotFilledIn = data.quickscanNotFilledIn;
        this.quickscanNotSubmitted = data.quickscanNotSubmitted;
        this.tisQuickscanNotSubmitted = data.tisQuickscanNotSubmitted;
        this.tisQuickscanOutdated = data.tisQuickscanOutdated;
        this.tisNotFilledIn = data.tisNotFilledIn;
        this.nrOfRisksNotCompliant = data.nrOfRisksNotCompliant;
        this.nrOfOpportunitiesNotCompliant = data.nrOfOpportunitiesNotCompliant;
        this.constructionCostsNotSpecified = data.constructionCostsNotSpecified;
        this.mandateProcessAlreadyStarted = data.mandateProcessAlreadyStarted;
    }

    public satisfied(): boolean {
        return this.quickscanNotFilledIn.satisfiedOrOptional
            && this.quickscanNotSubmitted.satisfiedOrOptional
            && this.tisQuickscanNotSubmitted.satisfiedOrOptional
            && this.tisQuickscanOutdated.satisfiedOrOptional
            && this.nrOfOpportunitiesNotCompliant.satisfiedOrOptional
            && this.nrOfRisksNotCompliant.satisfiedOrOptional
            && this.tisNotFilledIn.satisfiedOrOptional
            && this.constructionCostsNotSpecified.satisfiedOrOptional
            && this.mandateProcessAlreadyStarted.satisfiedOrOptional;
    }
}


export class IProjectMandateStep {
    createdAt: Date;
    stepTitle: string;
    actionholderName: string;
    actionholderEmail: string;
    stepResult?: boolean;
    stepResultSubmittedAt?: Date;
    remark?: string;
}

export class ProjectMandateStep implements IProjectMandateStep {
    createdAt: Date;
    stepTitle: string;
    actionholderName: string;
    actionholderEmail: string;
    stepResult?: boolean;
    stepResultSubmittedAt?: Date;
    remark?: string;

    constructor(data: IProjectMandateStep) {
        this.createdAt = new Date(data.createdAt);
        this.stepTitle = data.stepTitle;
        this.actionholderName = data.actionholderName;
        this.actionholderEmail = data.actionholderEmail;
        this.stepResult = data.stepResult;
        this.stepResultSubmittedAt = data.stepResultSubmittedAt ? new Date(data.stepResultSubmittedAt) : null;
        this.remark = data.remark ? data.remark : null;

        if (this.actionholderEmail !== this.actionholderName) {
            this.actionholderName = this.actionholderName + ' (' + this.actionholderEmail + ')';
        } else {
            this.actionholderName = this.actionholderEmail;
        }
    }
}

