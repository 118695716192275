<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_PROJECTEN.title' | translate}}</h2>

    <div class="button-group" slot="action">
        <vwui-button *ngFor="let notification of publishedNotifications$ | async" (click)="notification.show(false, notificationService)">{{ notification.title }}</vwui-button>

        <vwui-button
            *ngIf="isTenderManager()"
            icon="vwui-download"
            class="is-primary"
            [disabled]="generatingCsv"
            [loading]="generatingCsv"
            (click)="downloadCsvExport()">
            {{'PAGE_PROJECTEN.generate_csv' | translate}}
        </vwui-button>

        <vwui-button
            *ngIf="isTenderManager() && hasCompaniesWithRegularFlow"
            class="is-primary"
            (click)="openCreateProjectModal()"
            a11yHotkeyCreate
        >{{ 'PAGE_PROJECTEN.new_project' | translate }}</vwui-button>
    </div>
</vwui-header>

<vwui-header-divider-bar>
    <vwui-search
        class="search-projects"
        [ngModel]="searchValue"
        [placeholder]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.search.label' | translate"
        (vwuiChange)="search($event.detail.value)"
        a11yHotkeyFocusSearch
    ></vwui-search>
    <ng-select *ngIf="companyNames?.length > 1"
        [ngModel]="searchCompanyName"
        class="search-company"
        [items]="companyNames"
        [placeholder]="'PAGE_PROJECTEN.filter.companies.placeholder' | translate"
        (change)="searchCompanyChanged($event)"
    ></ng-select>
    <ng-select
        class="search-status"
        [ngModel]="searchStatuses"
        [items]="statuses"
        bindValue="id"
        bindLabel="name"
        [placeholder]="'PAGE_PROJECTEN.filter.status.placeholder' | translate"
        (change)="searchStatusChanged($event)"
        multiple="true"
    >
    </ng-select>
    <ng-select
        class="search-classification"
        [ngModel]="searchClassifications"
        [items]="classifications"
        bindValue="id"
        bindLabel="name"
        [placeholder]="'PAGE_PROJECTEN.filter.classification.placeholder' | translate"
        (change)="searchCategoryChanged($event)"
        multiple="true"
    ></ng-select>
    <ng-select
        class="search-construction-costs"
        [ngModel]="searchConstructionCosts"
        [items]="constructionCostOptions"
        bindValue="id"
        bindLabel="name"
        [placeholder]="'PAGE_PROJECTEN.filter.constructionCosts.placeholder' | translate"
        (change)="searchConstructionCostsChanged($event)"
        [multiple]="false"
    ></ng-select>
</vwui-header-divider-bar>

<div class="mt-lg">
    <vwui-empty-state
        *ngIf="projects?.length === 0 && !hasSearchFilter()"
        [messageTitle]="'PAGE_PROJECTEN.no_projects.message_title' | translate"
    ></vwui-empty-state>

    <vwui-table [compactable]="false" *ngIf="projects?.length !== 0 || hasSearchFilter()">
        <div slot="table-title">{{ tableConfig.title }}</div>
        <vwui-pagination
            slot="table-pagination"
            [totalItems]="currentPageData?.totalElements"
            [totalPages]="currentPageData?.totalPages"
            [currentPage]="currentPageData?.number + 1"
            [firstItem]="firstItem()"
            [lastItem]="lastItem()"
            (vwuiPaginationChange)="setPage($event.detail)"
        ></vwui-pagination>

        <vwui-table-row>
            <vwui-table-header *ngFor="let column of tableConfig.columns">
                <vwui-tooltip *ngIf="column.tooltip !== undefined" [label]="column.tooltip | translate"> {{ column.header }}</vwui-tooltip>
                <ng-container *ngIf="column.tooltip === undefined"> {{ column.header }} </ng-container>
            </vwui-table-header>
        </vwui-table-row>

        <vwui-table-row *ngIf="loadingSpinnerService.isSpinnerVisible()">
            <vwui-spinner></vwui-spinner>
        </vwui-table-row>

        <vwui-table-row *ngIf="projects?.length === 0 && !loadingSpinnerService.isSpinnerVisible()">
            <div class="table-row__text">{{ 'PAGE_PROJECTEN.no_projects.search_title' | translate }}</div>
        </vwui-table-row>

        <ng-container *ngIf="!loadingSpinnerService.isSpinnerVisible()">
            <vwui-table-row class="vwui-table-row--with-hover"  *ngFor="let item of projects" (click)="onProjectClick(item)" a11yKeyboardClickable>
                <vwui-table-column class="project-details project-details--name">
                    <div class="thumbnail-wrapper">
                        <img
                            class="thumbnail"
                            [src]="item.thumbnailBase64 || '/assets/images/project-image-thumb-placeholder.png'"
                        />
                        {{ item.name }}
                        <div class="project-details project-details--small">
                            {{ item.city }}
                        </div>
                    </div>
                </vwui-table-column>
                <vwui-table-column class="project-details project-details--company">
                    {{ item.company }}
                    <div class="project-details project-details--small">
                        {{ item.owner | namesFormat }}
                    </div>
                </vwui-table-column>
                <vwui-table-column>
                    <div class="project-details project-details--small">
                        {{ item.modifiedAt | date: ('DATE.long' | translate) }}
                    </div>
                </vwui-table-column>
                <vwui-table-column>
                    <app-project-status [project]="item"></app-project-status>
                </vwui-table-column>
                <vwui-table-column>
                    <vwui-badge
                        class="badge badge--{{item.classification}}"
                        [small]="true"
                    >{{ 'CLASSIFICATION.color.' + item.classification | translate }}</vwui-badge>
                    <vwui-badge
                        *ngIf="item.tisClassification && item.tisClassification !== 'initial'"
                        class="badge badge--{{item.tisClassification}}"
                        [small]="true"
                    >{{ 'CLASSIFICATION.color.' + item.tisClassification | translate }}</vwui-badge>
                </vwui-table-column>
            </vwui-table-row>
        </ng-container>
    </vwui-table>
</div>
