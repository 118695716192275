import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectDetails} from '../../models';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-project-risks',
    templateUrl: './project-risks.component.html',
    styleUrls: ['./project-risks.component.scss']
})
export class ProjectRisksComponent {

    readonly libraryType = LibraryType.RISKS;

    @Input()
    public projectDetails: ProjectDetails;

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    constructor() {
    }

    public riskDetailsChanged($event: ProjectDetails) {
        this.projectDetailsChanged.emit($event);
    }
}
