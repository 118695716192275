import {ModuleWithProviders, NgModule} from '@angular/core';
import {SkipLinkComponent} from './components/skip-link/skip-link.component';
import {AccessibilityService} from './services/accessibility.service';
import {FocusBlockDirective} from './directives/focus-block.directive';
import {FocusTrapDirective} from './directives/focus-trap.directive';
import {HotkeyConfirmDirective} from './directives/hotkey-confirm.directive';
import {HotkeyCreateDirective} from './directives/hotkey-create.directive';
import {HotkeyDeleteDirective} from './directives/hotkey-delete.directive';
import {HotkeyFocusSearchDirective} from './directives/hotkey-focus-search.directive';
import {KeyboardClickableDirective} from './directives/keyboard-clickable.directive';
import {PageTitleLevelDirective} from './directives/page-title-level.directive';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {FormFieldMessageDirective} from './directives/form-field-message';
import {TablistControlsDirective} from './directives/tablist-controls.directive';
import {AutoFocusDirective} from './directives/auto-focus.directive';

@NgModule({
    declarations: [
        SkipLinkComponent,
        FocusBlockDirective,
        FocusTrapDirective,
        HotkeyConfirmDirective,
        HotkeyCreateDirective,
        HotkeyDeleteDirective,
        HotkeyFocusSearchDirective,
        KeyboardClickableDirective,
        AutoFocusDirective,
        PageTitleLevelDirective,
        TablistControlsDirective,
        FormFieldMessageDirective
    ],
    providers: [
        AccessibilityService
    ],
    imports: [
        NgxWebstorageModule.forRoot({
            prefix: 'UI-ACCESSIBILITY',
            separator: '_',
            caseSensitive: true
        })
    ],
    exports: [
        SkipLinkComponent,
        FocusBlockDirective,
        FocusTrapDirective,
        HotkeyConfirmDirective,
        HotkeyCreateDirective,
        HotkeyDeleteDirective,
        HotkeyFocusSearchDirective,
        KeyboardClickableDirective,
        AutoFocusDirective,
        PageTitleLevelDirective,
        TablistControlsDirective,
        FormFieldMessageDirective
    ]
})
export class UiAccessibilityModule {
    static WhitelistedRoutes = [];

    constructor(
        private accessibilityService: AccessibilityService
    ) {
        this.accessibilityService.init(UiAccessibilityModule.WhitelistedRoutes);
    }

    static forRoot(whitelistedRoutes: string[] = []): ModuleWithProviders<UiAccessibilityModule> {
        UiAccessibilityModule.WhitelistedRoutes = whitelistedRoutes;

        return {
            ngModule: UiAccessibilityModule,
            providers: [
                AccessibilityService
            ]
        };
    }
}
