import {Directive, HostListener, Output, EventEmitter, ElementRef, AfterViewInit} from '@angular/core';
import {AccessibilityService, HotkeyMark, HotkeyType} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yHotkeyDelete]'
})
export class HotkeyDeleteDirective implements AfterViewInit {

    constructor(private accessibilityService: AccessibilityService, private elementRef: ElementRef) {
    }

    // Delete hotkey - Defaults to CTRL-DELETE
    @Output() hotkeyDelete = new EventEmitter<string>();

    ngAfterViewInit() {
        setTimeout(() => {
            FocusUtil.applyHotkeyMarker(this.elementRef.nativeElement, HotkeyMark.hotkeyDelete);
        }, 500); // TODO AC-109
    }

    @HostListener('keydown', ['$event']) onDelete($event){
        if (this.accessibilityService.checkHotkey($event, HotkeyType.DELETE)) {
            $event.preventDefault();
            this.hotkeyDelete.emit();
        }
    }
}
