import {AfterViewChecked, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {IScrollNavigationItem} from './scroll-navigation.data';

@Component({
    selector: 'app-scroll-navigation',
    templateUrl: './scroll-navigation.component.html',
    styleUrls: ['./scroll-navigation.component.scss']
})
export class ScrollNavigationComponent implements AfterViewChecked, OnDestroy {

    // NOTE - This assumes a sorted list with element references in the order of scrolling
    public navItemValues: IScrollNavigationItem[];
    private initialActiveNavItem?: IScrollNavigationItem;
    private initialNavigation = false;

    @Input()
    set navItems(navItems: IScrollNavigationItem[]) {
        this.initialActiveNavItem = navItems.find(navItem => navItem.active);
        this.initialNavigation = true;
        this.navItemValues = navItems;
        this.updateNavSelection();
    }

    constructor() {
    }

    public ngOnDestroy() {
        this.initialNavigation = false;
    }

    public ngAfterViewChecked(): void {
        if (this.initialNavigation) {
            if (this.initialActiveNavItem && this.initialNavigation) {
                setTimeout(() => {
                    this.navigateTo(this.initialActiveNavItem);
                }, 1000);
            } else {
                document.scrollingElement.scrollIntoView(true);
            }
            this.initialNavigation = false;
        }
    }

    @HostListener('window:scroll', ['$event']) // window scroll events
    onScroll() {
        this.updateNavSelection();
    }

    public navigateTo(navItem: IScrollNavigationItem): void {
        this.navItemValues.forEach((currentNavItem: IScrollNavigationItem, index: number) => {
            // Note - This scroll padding is made specifically for a the quickscan page
            // This logic needs to be made better if we want to make this a universal component
            const scrollPadding = index !== this.navItemValues.length - 1 ? -120 : 0;

            if (currentNavItem.elementId === navItem.elementId) {
                const element = document.getElementById(navItem.elementId);
                if (element === null) {
                    return;
                }

                element.scrollIntoView(true);
                if (scrollPadding !== 0) {
                    window.scrollBy(0, scrollPadding);
                }
            }
        });
    }

    private updateNavSelection(): void {
        let lastElementIndexInView = 0;
        if (this.navItemValues && this.navItemValues.length > 0) {
            this.navItemValues.forEach((navItemValue, index) => {

                const element = document.getElementById(navItemValue.elementId);
                if (element && this.elementInViewPort(element) ) {
                    lastElementIndexInView = index;
                }
            });
        }

        if (this.navItemValues) {
            this.navItemValues.map((navItem, index) => {
                navItem.active = index === lastElementIndexInView;
                return navItem;
            });
        }
    }

    private elementInViewPort(elem: Element): boolean {
        const bounding = elem.getBoundingClientRect();

        const activeThreshold = ( window.innerHeight || document.documentElement.clientHeight ) / 2;
        return bounding.bottom <= activeThreshold;
    }

}
