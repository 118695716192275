export interface IRiskAndOpportunityFilters {
    riskScore?: number;
    opportunityScore?: number;
    sharedWithPrincipal?: boolean;
    includeProposals?: boolean;
    selectedOnly?: boolean;
    selectedItems?: string[];
    actionHolders: string[];
    themes: string[];
    bestGuessMin?: number;
    bestGuessMax?: number;
    statuses: string[];
}

export class RiskAndOpportunityFilters implements IRiskAndOpportunityFilters {
    riskScore?: number;
    opportunityScore?: number;
    sharedWithPrincipal?: boolean;
    includeProposals?: boolean;
    selectedOnly?: boolean;
    selectedItems: string[];
    actionHolders: string[];
    themes: string[];
    bestGuessMin?: number;
    bestGuessMax?: number;
    statuses: string[];

    constructor(data: IRiskAndOpportunityFilters) {
        this.riskScore = data.riskScore || 0;
        this.opportunityScore = data.opportunityScore || 0;
        this.sharedWithPrincipal = data.sharedWithPrincipal || false;
        this.includeProposals = data.includeProposals || false;
        this.selectedOnly = data.selectedOnly || false;
        this.selectedItems = data.selectedItems || [];
        this.actionHolders = data.actionHolders || [];
        this.themes = data.themes || [];
        this.bestGuessMin = data.bestGuessMin || undefined;
        this.bestGuessMax = data.bestGuessMax || undefined;
        this.statuses = data.statuses || [];
    }
}
