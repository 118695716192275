import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {RichTextEditorConfigPreset} from './models';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class RichTextEditorComponent implements OnInit, AfterViewInit {

    @Input() public controller: AbstractControl;
    @Input() public config = RichTextEditorConfigPreset.SIMPLE;

    public ready: boolean;
    public hasFocus: boolean;

    constructor(
    ) {
    }

    ngOnInit(): void {
        this.ready = false;
    }

    ngAfterViewInit(): void {
        // Run in next render frame for Safari to initialize multiple instances.
        requestAnimationFrame(() => {
            // Timeout required to initialize clean tinymce instance... :(
            setTimeout(() => {
                this.ready = true;
            }, 0);
        });
    }

    public setFocus(hasFocus: boolean): void {
        this.hasFocus = hasFocus;
    }

}
