import {Component, Input, OnInit} from '@angular/core';
import {Library, ProjectDetails, ProjectLibraryItem} from '../../models';

@Component({
    selector: 'app-pdf-page-forecast-end-result-list-table',
    templateUrl: './pdf-page-forecast-end-result-list-table.component.html',
    styleUrls: ['./pdf-page-forecast-end-result-list-table.component.scss']
})
export class PdfPageForecastEndResultListTableComponent implements OnInit {

    @Input()
    public projectDetails: ProjectDetails;
    @Input()
    public projectLibraryItems: ProjectLibraryItem[];

    @Input()
    public tableType: string;

    ngOnInit() {
    }

    public getTotalPer(): number {
        return this.projectLibraryItems.map(item => item.perCosts)
            .reduce((a, b) => a + b, 0);
    }

    public getTotalBestGuess(): number {
        return this.projectLibraryItems.map(item => item.bestGuessCosts)
            .reduce((a, b) => a + b, 0);
    }

    public getTotalMinCosts(): number {
        return this.projectLibraryItems.map(item => item.minCosts)
            .reduce((a, b) => a + b, 0);
    }

    public getTotalMaxCosts(): number {
        return this.projectLibraryItems.map(item => item.maxCosts)
            .reduce((a, b) => a + b, 0);
    }

}
