import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    ILibraryItemFiltersData,
    ILibraryItemFiltersOptionsData,
    LibraryItemFilters,
    ProjectDetails,
    ProjectLibraryItem
} from '../../models';
import {ToastService, ProjectService} from '../../services';
import {IChartSignalMatrixConfig, IChartSignalMatrixItem} from '../chart-signal-matrix/chart-signal-matrix.data';
import {FormControl, FormGroup} from '@ng-stack/forms';
import {Subscription} from 'rxjs';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TranslateService} from '@ngx-translate/core';
import {QuantifySupplementProjectLibraryItemModalComponent} from '..';
import {ScoreThresholdsOpportunities, ScoreThresholdsRisks} from '../score-signal-badge/score-signal-badge.data';
import {ObjectUtil, RiskRainbowUtil} from '../../utils';
import {ProjectLibraryItemStatus} from '../../models/project/project-libary-item';
import {FormatEuroCentsDirective} from '../../directives/format-euro-cents.directive';
import {LibraryItemSort} from '../../models/library/sort';
import {ItemSorting, SortableTypes, SortingColumns} from '../../models/library/sort-data';

@Component({
    selector: 'app-project-risk-rainbow',
    templateUrl: './project-risk-rainbow.component.html',
    styleUrls: ['./project-risk-rainbow.component.scss']
})
export class ProjectRiskRainbowComponent implements OnInit, OnDestroy {

    public libraryItemFilters: LibraryItemFilters;
    public filters: ILibraryItemFiltersData;
    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();
    public allRisksWithScores: { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[] = [];
    public allOpportunitiesWithScores: { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[] = [];
    public filteredRisks: ProjectLibraryItem[] = [];
    public filteredOpportunities: ProjectLibraryItem[] = [];
    public scoreThresholdsRisks = ScoreThresholdsRisks;
    public scoreThresholdsOpportunities = ScoreThresholdsOpportunities;
    public form = new FormGroup({
        riskAssessmentDescription: new FormControl(null)
    });
    public risksVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {
            title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.impact'),
            labels: [0, 1, 2, 3, 4, 5]
        },
        vertical: {
            title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.probability'),
            labels: [0, 1, 2, 3, 4, 5]
        }
    };
    public opportunitiesVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {
            title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.impact'),
            labels: [0, 1, 2, 3, 4, 5]
        },
        vertical: {
            title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.probability'),
            labels: [0, 1, 2, 3, 4, 5]
        }
    };
    public filterSelectionCount = 0;
    public filterBadge = '';

    private projectDetailsValue: ProjectDetails;
    private subscriptions: Subscription[] = [];

    public libraryItemSort: LibraryItemSort;

    public readonly SortableColumns = SortingColumns;
    public readonly SortableTypes = SortableTypes;
    public readonly ItemSorting = ItemSorting;

    constructor(
        private notificationService: ToastService,
        private translateService: TranslateService,
        private modalService: VwuiModalService,
        private projectService: ProjectService
    ) {
    }

    @Input()
    set projectDetails(val: ProjectDetails) {
        this.projectDetailsValue = val;
        this.libraryItemSort = new LibraryItemSort(this.projectDetailsValue.libraryItemSort);
        this.updateForm();
        this.updateFilteredItems();
    }

    public chartItemSelected(allItemsWithScores: { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[], $event: IChartSignalMatrixItem) {
        const selectedItem = allItemsWithScores.find((itemWithScore) => itemWithScore.item.id === $event.itemId);
        if (selectedItem) {
            this.openProjectLibraryItem(selectedItem.item);
        }
    }

    ngOnInit(): void {
        this.subscriptions.push(this.libraryItemFilters.getControl().valueChanges.subscribe((values) => {
            // RISC-702 - Unset proposed status filtering if the proposals are no longer included
            if (!values.includeProposals && values.statuses.proposed) {
                this.libraryItemFilters.removeProposedStatus();
                values.statuses.proposed.selected = false;
            } else if (values.includeProposals && !values.statuses.proposed) {
                this.libraryItemFilters.addProposedStatus();
            }

            this.storeFilters(values);
            this.updateFilteredItems();
        }));

        this.subscriptions.push(this.libraryItemSort.getControl().valueChanges.subscribe(() => {
            this.storeLibraryItemSort();
        }));

        this.subscriptions.push(this.form.valueChanges.subscribe((nextValue) => {
            if (!this.projectDetailsValue) {
                return;
            }

            this.projectDetailsValue.riskAssessmentDescription = nextValue.riskAssessmentDescription;
            this.projectDetailsChanged.emit(this.projectDetailsValue);
            this.updateFilteredItems();
        }));

        this.handleFormControls();
    }

    private storeLibraryItemSort() {
        this.projectDetailsValue.libraryItemSort = this.libraryItemSort.getControl().value;
        this.projectDetailsChanged.emit(this.projectDetailsValue);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public openProjectLibraryItem(item: ProjectLibraryItem): void {
        const modal = this.modalService.open(
            QuantifySupplementProjectLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    projectLibraryItem: item,
                    projectDetails: this.projectDetailsValue
                }
            }
        );

        modal.afterClosed.subscribe((value) => {
            if (value) {
                if (value.action === 'submit' || value.action === 'deleted') {
                    this.projectDetailsValue.projectLibraryItems = this.projectDetailsValue.projectLibraryItems
                        .filter((projectItem) => item.id !== projectItem.id);

                    if (value.action === 'submit') {
                        this.projectDetailsValue.projectLibraryItems.push(value.item);
                    }

                    this.updateFilteredItems();
                }
            }
        });
    }

    // Because input number fields dont automatically bound the typed values to the max set on the input field
    public isProjectOwnerOrRiskManager(): boolean {
        return this.projectService.isOwner(this.projectDetailsValue) || this.projectService.isProjectRiskManager(this.projectDetailsValue);
    }

    public getFinancialImpact(item: ProjectLibraryItem): string {
        if (item.bestGuessCosts) {
            return FormatEuroCentsDirective.convertNumberToEuros(item.bestGuessCosts, this.translateService.getDefaultLang());
        } else {
            return '-';
        }
    }

    public getIncludedInPer(item: ProjectLibraryItem): string {
        if (item.includedInPer && item.perCosts) {
            return FormatEuroCentsDirective.convertNumberToEuros(item.perCosts, this.translateService.getDefaultLang());
        } else {
            return '-';
        }
    }

    public getTotalsIncludedInPer(items: ProjectLibraryItem[]): number {
        return items
            .filter((item) => item.includedInPer)
            .reduce(((previous, current) => previous + (current.perCosts ? current.perCosts : 0)), 0);
    }

    public getTotalsBestGuess(items: ProjectLibraryItem[]): number {
        return items.reduce(((previous, current) => previous + (current.bestGuessCosts ? current.bestGuessCosts : 0)), 0);
    }

    public filterSelectionCountChanged(count: number) {
        this.filterSelectionCount = count;
        this.filterBadge = count > 0 ? `${count}` : '';
    }


    public getCorrectSortOrderIcon(type: SortableTypes): string {
        if (type === SortableTypes.RISK) {
            return this.libraryItemSort.getControl().value.riskOption === ItemSorting.DESCENDING ? 'sort-numeric-down' : 'sort-numeric-up';
        } else if (type === SortableTypes.TASK) {
            return this.libraryItemSort.getControl().value.taskOption === ItemSorting.DESCENDING ? 'sort-numeric-down' : 'sort-numeric-up';
        } else {
            return this.libraryItemSort.getControl().value.opportunityOption === ItemSorting.DESCENDING ? 'sort-numeric-down' : 'sort-numeric-up';
        }
    }

    private updateForm(): void {
        if (this.projectDetailsValue) {
            const value = ObjectUtil.cloneObject(this.projectDetailsValue);

            this.form.patchValue({
                riskAssessmentDescription: value.riskAssessmentDescription
            });
            this.filters = this.projectDetailsValue.getDefaultLibraryItemProjectFilters(this.translateService);
            this.libraryItemFilters = new LibraryItemFilters(this.filters);

            this.handleFormControls();

        }
    }

    private updateFilteredItems(): void {
        if (this.projectDetailsValue) {
            this.filteredRisks = this.sortLibraryItems(this.projectDetailsValue.getRisksWithinFilters(this.filters)
                .filter((risk) => risk.status !== ProjectLibraryItemStatus.SETTLED));
            this.filteredOpportunities = this.sortLibraryItems(this.projectDetailsValue.getOpportunitiesWithinFilters(this.filters));

            this.allRisksWithScores = this.calculateScores(this.projectDetailsValue.getRisks());
            this.allOpportunitiesWithScores = this.calculateScores(this.projectDetailsValue.getOpportunities());

            this.risksVsValueMatrix.values = this.generateMatrixValues('risks');
            this.opportunitiesVsValueMatrix.values = this.generateMatrixValues('opportunties');
            // trigger a refresh on the chart-matrix, it needs a change on the root object
            this.risksVsValueMatrix = ObjectUtil.cloneObject(this.risksVsValueMatrix);
            this.opportunitiesVsValueMatrix = ObjectUtil.cloneObject(this.opportunitiesVsValueMatrix);

            // After filter sort
            this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.RISK, this.libraryItemSort.getRiskColumn(), true, this.filteredRisks);
            this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.OPPORTUNITY, this.libraryItemSort.getOpportunityColumn(), true, this.filteredOpportunities);
        }
    }

    private sortLibraryItems(items: ProjectLibraryItem[]) {
        return items.sort((item1, item2) => {
            if (item1.getScore() === null || item2.getScore() === null) {
                return 1;
            } else {
                return item2.getScore() - item1.getScore();
            }
        });
    }

    private calculateScores(items: ProjectLibraryItem[]): { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[] {
        return RiskRainbowUtil.calculateRainbowScores(items);
    }

    private generateMatrixValues(libraryType: string): IChartSignalMatrixItem[] {
        let allItems: { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[] = [];
        switch (libraryType) {
            case 'opportunties':
                allItems = this.allOpportunitiesWithScores;
                break;
            case 'risks':
                allItems = this.allRisksWithScores;
                break;
        }
        return allItems
            .map((item) => ({
                horizontal: item.impactScore,
                vertical: item.opportunityScore,
                tooltip: item.item.getTitle(this.projectDetailsValue.getRisks()),
                itemId: item.item.id,
                inFilter: [...this.filteredRisks, ...this.filteredOpportunities].includes(item.item)
            }));
    }

    private handleFormControls() {
        this.form.enable();

        if (this.projectDetailsValue.isOnHold() || this.projectDetailsValue.isClosed() || !this.isProjectOwnerOrRiskManager()) {
            this.form.disable();
        }
    }

    private storeFilters(values: ILibraryItemFiltersData) {
        this.filters = values;
        const projectFilters = this.projectDetailsValue.riskAndOpportunityFilters;

        projectFilters.riskScore = values.riskScore;
        projectFilters.opportunityScore = values.opportunityScore;
        projectFilters.sharedWithPrincipal = values.sharedWithPrincipal;
        projectFilters.themes = this.getSelected(values.themes);
        projectFilters.actionHolders = this.getSelected(values.actionHolders);
        projectFilters.bestGuessMin = values.bestGuessMin;
        projectFilters.bestGuessMax = values.bestGuessMax;
        projectFilters.statuses = this.getSelected(values.statuses);
        projectFilters.includeProposals = values.includeProposals;
        projectFilters.selectedOnly = values.selectedOnly;
        projectFilters.selectedItems = this.getSelectedName(values.selectedItems);
        this.projectDetailsValue.riskAndOpportunityFilters = projectFilters;
        this.projectDetailsChanged.emit(this.projectDetailsValue);
    }

    private getSelected(themes: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(themes)
            .filter((key, _) => key[1].selected)
            .map((key, _) => key[0]);
    }

    private getSelectedName(selectedItems: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(selectedItems)
            .filter((key, _) => key[1].selected)
            .map((key, _) => key[1].name);
    }

}
