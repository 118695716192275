<div class="filters-toggle" (appClickOutside)="setFilterVisibility(false)">
    <vwui-button
        icon="filter"
        class="filters-toggle__button"
        [ngClass]="{'filters-toggle__button--active': filtersVisible}"
        (click)="toggleFilterVisibility()"
    >
        {{ 'BUTTON.filters' | translate }}
        <vwui-badge
            *ngIf="badge"
            class="filters-toggle__badge"
        >{{ badge }}</vwui-badge>
    </vwui-button>
    <div
        class="filters-toggle__content"
        [ngClass]="{'filters-toggle__content--visible': filtersVisible}"
    >
        <app-library-item-filters [filters]="filters"></app-library-item-filters>
    </div>
</div>
