import {RawEditorSettings} from 'tinymce';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RichTextEditorConfig extends RawEditorSettings {}

export const RichTextEditorConfigPreset: {[preset: string]: RichTextEditorConfig} = {
    SIMPLE: {
        plugins: 'lists,autoresize,paste,importcss',
        autoresize_bottom_margin: 0,
        autoresize_overflow_padding: 15,
        min_height: 200,
        menubar: '',
        toolbar: 'undo redo bold italic underline | bullist numlist | removeformat',
        toolbar_mode: 'scrolling',
        statusbar: false,
        branding: false,
        paste_block_drop: true,
        content_css: `/${document.querySelector('link[href*="styles."]')?.getAttribute('href')}`,
        importcss_selector_filter: /\.mce-content-body/,
        language: 'nl',
        contextmenu: '',

        // Sanitize - https://www.tiny.cloud/docs/configure/content-filtering/
        valid_elements: 'p,strong,strong/b,em/i,ul,ol,li,br,span,u',
        extended_valid_elements: '',
        invalid_elements : '*',
        valid_children: 'p[strong|em|br|span|u],ul[li],ol[li],li[strong|em|br|span|u],span[u]',
        fix_list_elements: true,
        keep_styles: false,
        valid_classes: {
            '*': ''
        },
        valid_styles: {
            '*': ''
        },
        formats: {
            underline: { inline: 'u', exact : true }
        }
    }
};
