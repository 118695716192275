import {Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AccessibilityService, HotkeyType} from '../services/accessibility.service';
import {AriaUtil} from '../utils/aria.util';

@Directive({
    selector: '[a11yTablistControls]'
})
export class TablistControlsDirective implements OnInit, OnChanges {
    // Directive that gives the proper controls to a tablist and tabs according to the WAI-ARIA Authoring Practices
    // Assumes that the value of the tab is the ID attribute
    // https://www.w3.org/TR/wai-aria-practices-1.1/#tabpanel

    @Input() a11yTablistControls: string | null = '';
    private allowVerticalMovement = false;

    constructor(
        private elementRef: ElementRef,
        private accessibilityService: AccessibilityService
    ) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            const ariaRole = this.elementRef.nativeElement.attributes.getNamedItem('role');
            if (!ariaRole) {
                const roleTablist = document.createAttribute('role');
                roleTablist.value = 'tablist';
                this.elementRef.nativeElement.attributes.setNamedItem(roleTablist);
            }

            const ariaOrientation = this.elementRef.nativeElement.attributes.getNamedItem('aria-orientation');
            if (!ariaOrientation) {
                const ariaOrientationAttribute = document.createAttribute('aria-orientation');
                ariaOrientationAttribute.value = 'horizontal';
                this.elementRef.nativeElement.attributes.setNamedItem(ariaOrientationAttribute);
            } else {
                this.allowVerticalMovement = ariaOrientation.value === 'vertical';
            }

            this.updateTabs();

            // TODO AC-109 - IMPROVE THIS SO THAT FOCUS IS GAINED AFTER PROPER INITIALISATION
        }, 500);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.a11yTablistControls.firstChange) {
            this.updateTabs();
        }
    }

    private updateTabs() {
        const tabElements = AriaUtil.findAvailableTabs(this.elementRef.nativeElement);
        const focussedIndex = this.a11yTablistControls ? Array.from(tabElements).findIndex((focusableElement) => (focusableElement as HTMLElement).id === this.a11yTablistControls) : 0;

        Array.from(tabElements).forEach((tabElement, index) => {
            const tabHtmlElement =  (tabElement as HTMLElement);

            // Add the correct ARIA roles to the tabs if they are not included
            const ariaRole = tabHtmlElement.attributes.getNamedItem('role');
            if (!ariaRole) {
                const roleTab = document.createAttribute('role');
                roleTab.value = 'tab';
                tabHtmlElement.attributes.setNamedItem(roleTab);
            }

            // Add the correct tabindex
            // As per the specification, only the current element is focusable with the tab key
            const attributeTabIndex = document.createAttribute('tabindex');
            attributeTabIndex.value = focussedIndex === index ? '0' : '-1';
            tabHtmlElement.attributes.setNamedItem(attributeTabIndex);

            // Add the correct aria selection attribute
            const ariaSelected = document.createAttribute('aria-selected');
            ariaSelected.value = focussedIndex === index ? 'true' : 'false';
            tabHtmlElement.attributes.setNamedItem(ariaSelected);
        });
    }

    // Selects the tab according to the WAI-ARIA controls
    // Assumes that the click handler on the tab activates the tab and causes the ariaTablistControls value to be set in the process
    @HostListener('keydown', ['$event']) onKeyDown($event){
        const moveToFirstTab = this.accessibilityService.checkHotkey($event, HotkeyType.FOCUS_FIRST);
        const moveToLastTab = this.accessibilityService.checkHotkey($event, HotkeyType.FOCUS_LAST);

        // Left and right arrow keys to move to previous and next tabs
        let moveToPreviousTab = $event.keyCode === 37;
        let moveToNextTab = $event.keyCode === 39;

        // Vertical tabs need Arrow up and down movement as well
        if (this.allowVerticalMovement) {
            moveToPreviousTab = $event.keyCode === 37 || $event.keyCode === 38;
            moveToNextTab = $event.keyCode === 39 || $event.keyCode === 40;
        }

        if (moveToFirstTab || moveToNextTab || moveToLastTab || moveToPreviousTab) {
            const tabElements = AriaUtil.findAvailableTabs(this.elementRef.nativeElement);

            let focusNext = moveToLastTab || moveToFirstTab;

            const tabElementFocus = (tabElement) => {
                const tabHtmlElement = (tabElement as HTMLElement);
                if (focusNext) {
                    tabHtmlElement.focus();
                    tabHtmlElement.click();
                    focusNext = false;
                } else if (tabHtmlElement.id === this.a11yTablistControls) {
                    focusNext = true;
                }
            };

            if (moveToLastTab || moveToPreviousTab) {
                Array.from(tabElements).reverse().forEach(tabElementFocus);
            } else {
                Array.from(tabElements).forEach(tabElementFocus);
            }
            $event.preventDefault();
        }
    }
}
