import {Directive, HostListener, ElementRef, AfterViewInit} from '@angular/core';
import {AccessibilityService, HotkeyMark, HotkeyType} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yHotkeyFocusSearch]'
})
export class HotkeyFocusSearchDirective implements AfterViewInit {
    // Focus search element - Defaults to CTRL-/

    constructor(
        private elementRef: ElementRef,
        private accessibilityService: AccessibilityService
    ) {
    }

    ngAfterViewInit() {
        const roleAttr = document.createAttribute('role');
        roleAttr.value = 'search';
        this.elementRef.nativeElement.attributes.setNamedItem(roleAttr);

        setTimeout(() => {
            FocusUtil.applyHotkeyMarker(this.elementRef.nativeElement, HotkeyMark.hotkeyFocusSearch);
        }, 500); // TODO AC-109
    }

    @HostListener('keydown', ['$event']) onDelete($event){
        if (this.accessibilityService.checkHotkey($event, HotkeyType.FOCUS_SEARCH)) {
            $event.preventDefault();

            const focusableElements = FocusUtil.findCoarseFocusableElements(this.elementRef.nativeElement);
            if (focusableElements) {

                // Make sure to remove elements that have the tabindex set to -1 for regular interactive elements
                // Like a tags that we do not want to be focusable
                const firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

                if (firstFocusableElement) {
                    (firstFocusableElement as HTMLElement).focus();
                    (firstFocusableElement as HTMLElement).click();
                }
            }
        }
    }
}
