import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {LibraryService, LoadingSpinnerService, ProjectService, ToastService} from '../../services';
import {Library, LibraryCategory, LibraryEntry, ProjectDetails, ProjectLibraryItem} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {AddProjectLibraryItemsModalComponent, QuantifySupplementProjectLibraryItemModalComponent} from '..';
import {ProjectLibraryItemDefault, ProjectLibraryItemStatus} from '../../models/project/project-libary-item';
import {SearchUtil} from '../../utils';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-project-library-items',
    templateUrl: './project-library-items.component.html',
    styleUrls: ['./project-library-items.component.scss']
})
export class ProjectLibraryItemsComponent implements OnInit {

    public QUANTIFICATION_TAB = 'quantification';
    public REMARKS_TAB = 'remarks';
    public RISK_MITIGATION_TAB = 'riskMitigation';
    public filledInFilterItems = [
        'FILLED_IN',
        'NOT_FILLED_IN'
    ].map(
        value => {
            return {
                id: value,
                name: this.translateService.instant(`PAGE_PROJECT_LIBRARY_ITEMS.filter.filledIn.optionNames.${value}`)
            };
        }
    );

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();
    public categories: LibraryCategory[];
    public visibleItems: { item: ProjectLibraryItem; filledIn: boolean }[];
    public actionholders: string[];
    @Input()
    private projectDetails: ProjectDetails;
    @Input()
    public libraryType: LibraryType;
    private library: Library;
    public statuses: { id: string; name: string }[];

    private searchString?: string;
    private searchCategory?: LibraryCategory;
    private searchStatus?: string;
    private searchActionHolder: string;
    public proposalStatus = ProjectLibraryItemStatus.PROPOSED;
    private searchFilledIn = '';
    public canChangeStatus = false;

    constructor(
        private libraryService: LibraryService,
        private projectService: ProjectService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.actionholders = [];
        this.loadingSpinnerService.show();
        this.getLibrary(this.libraryType);

        this.canChangeStatus = !this.projectDetails.isOnHold() && (this.projectService.isOwner(this.projectDetails) || this.projectService.isProjectRiskManager(this.projectDetails));
        this.searchString = undefined;
        this.searchCategory = undefined;
        this.searchStatus = undefined;
        this.statuses = Object.values(ProjectLibraryItemStatus).map((status) => ({
            id: status,
            name: this.translateService.instant('PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + this.libraryType + '.status.' + status)
        }));
        this.reapplySearch();
    }

    public getNumberOfItems(): number {
        return this.getAllItems().length;
    }

    public addSpecificItem(): void {
        const item = new ProjectLibraryItem({
            ...ProjectLibraryItemDefault,
            libraryType: this.libraryType
        });
        item.isProjectSpecific = true;

        const modal = this.modalService.open(
            QuantifySupplementProjectLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    projectLibraryItem: item,
                    projectDetails: this.projectDetails
                }
            }
        );

        modal.afterClosed.subscribe((data: { action: string; item: ProjectLibraryItem; items: ProjectLibraryItem[] }) => {
            if (data && data.items) {
                this.projectDetails.projectLibraryItems = data.items;
                this.projectDetailsChanged.emit(this.projectDetails);
                this.reapplySearch();
            }
        });
    }

    public openLibraryItem(item: ProjectLibraryItem, tab?: string, event?: Event): void {
        if (event) {
            event.stopImmediatePropagation();
        }
        const modal = this.modalService.open(
            QuantifySupplementProjectLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    projectLibraryItem: item,
                    projectDetails: this.projectDetails,
                    tab
                }
            }
        );

        modal.afterClosed.subscribe((data: { action: string; item: ProjectLibraryItem; items?: ProjectLibraryItem[] }) => {
            if (data) {
                switch (data.action) {
                    case 'submit':
                        Object.assign(item, data.item);
                        this.projectDetailsChanged.emit(this.projectDetails);
                        this.reapplySearch();
                        break;
                    case 'duplicated':
                        this.projectDetails.projectLibraryItems = data.items;
                        this.projectDetailsChanged.emit(this.projectDetails);
                        this.reapplySearch();
                        break;
                    case 'deleted':
                        this.projectDetails.projectLibraryItems = this.projectDetails.projectLibraryItems
                            .filter((projectItem) => item.id !== projectItem.id);
                        this.projectDetailsChanged.emit(this.projectDetails);
                        this.reapplySearch();
                        break;
                }
            }
        });
    }

    openLibraryItems(): void {
        const modal = this.modalService.open(
            AddProjectLibraryItemsModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    title: `PAGE_PROJECT_LIBRARY_ITEMS.library_type.${this.library.type}.create_item.title`,
                    library: this.library,
                    projectItems: this.projectDetails.projectLibraryItems
                }
            }
        );

        modal.afterClosed.subscribe((data: false | LibraryEntry[]) => {
            if (data !== false) {
                const selectedItems: LibraryEntry[] = data;
                if (selectedItems && selectedItems.length > 0) {
                    this.projectService.addSelectedItems(this.projectDetails, this.libraryType, selectedItems).then((details) => {
                        this.projectDetails = details;
                        this.projectDetailsChanged.emit(this.projectDetails);
                        this.reapplySearch();
                        this.toastService.openSuccess(this.translateService.instant(`PAGE_PROJECT_LIBRARY_ITEMS.library_type.${this.library.type}.updated`));
                    });
                }
            }
        });
    }

    public search(searchValue: string): void {
        this.searchString = searchValue;
        this.reapplySearch();
    }

    public searchCategoryChanged(category: LibraryCategory) {
        this.searchCategory = category;
        this.reapplySearch();
    }

    public searchStatusChanged(status: { id: string; name: string }) {
        this.searchStatus = status ? status.id : undefined;
        this.reapplySearch();
    }

    public getEntryStatus(projectItem: ProjectLibraryItem): string {
        return projectItem.status;
    }

    public getScoreLabelForEntry(projectItem: ProjectLibraryItem): string {
        const score = projectItem.getScore();

        return score !== null ? String(score) : '?';
    }

    public getOwnerForEntry(projectItem: ProjectLibraryItem): string {
        return 'JS';
    }

    public getDisabledReason(): string {
        if (this.projectDetails.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetails.isClosed()) {
            return 'DISABLED.project_closed';
        } else if (this.projectService.canEditProjectLibraryItems(this.projectDetails)) {
            return '';
        }

        return 'DISABLED.no_edit_rights';
    }

    public hasRemark(projectItem: ProjectLibraryItem) {
        return projectItem.remarks.length !== 0;
    }

    public getInitials(name: string) {
        if (name.indexOf('@') > 0) {
            name = name.substr(0, name.indexOf('@'));
        }
        const initials = name.match(/\b\w/g) || [];
        return initials.map(item => item ? item.toUpperCase() : '').join('');
    }

    public searchActionHolderChanged(actionHolder: string) {
        this.searchActionHolder = actionHolder;
        this.reapplySearch();
    }

    public searchFilledInChanged(filledIn?: { id: string; name: string }) {
        this.searchFilledIn = filledIn?.id;
        this.reapplySearch();
    }

    private getLibrary(libraryType: LibraryType) {
        this.libraryService.getLibrary(this.projectDetails.companyTypeId, libraryType).then((library) => {
            this.library = library;
            this.categories = [...this.library.categories];
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }

    private reapplySearch() {
        const allItems = this.getAllItems();
        this.visibleItems = allItems.map((item) => {
            return {item, filledIn: item.isFilledIn()};
        });

        const allActionHolders = this.visibleItems
            .filter((item) => item.item.getActionholder() !== null && item.item.getActionholder().length > 0)
            .map((item) => item.item.getActionholder());

        this.actionholders = [];
        allActionHolders.forEach((item) => {
            if (!this.actionholders.includes(item)) {
                this.actionholders.push(item);
            }
        });

        if (this.searchCategory) {
            this.visibleItems = this.visibleItems.filter((visibleItem) => {
                return this.searchCategory.id === visibleItem.item.libraryCategoryId;
            });
        }
        if (this.searchString) {
            this.visibleItems = this.visibleItems.filter((visibleItem) => SearchUtil.hasMatch(this.searchString, visibleItem.item.getTitle(allItems)));
        }
        if (this.searchStatus) {
            this.visibleItems = this.visibleItems.filter((visibleItem) => visibleItem.item.getStatus() === this.searchStatus);
        }
        if (this.searchActionHolder) {
            this.visibleItems = this.visibleItems.filter((visibleItem) => visibleItem.item.getActionholder() !== null && visibleItem.item.getActionholder().indexOf(this.searchActionHolder) >= 0);
        }
        if (this.searchFilledIn) {
            this.visibleItems = this.visibleItems.filter((visibleItem) => this.searchFilledIn === 'FILLED_IN' ? visibleItem.filledIn : !visibleItem.filledIn);
        }
        this.visibleItems = this.visibleItems.sort((a, b) => {

            // RISC-757: sort by: proposals first, then sort based on title (including the numbering).
            const fullTitleA = (a.item.status === ProjectLibraryItemStatus.PROPOSED ? 'A' : 'B') + a.item.getTitle(allItems);
            const fullTitleB = (b.item.status === ProjectLibraryItemStatus.PROPOSED ? 'A' : 'B') + b.item.getTitle(allItems);

            return fullTitleA.localeCompare(fullTitleB);
        });
    }

    public getAllItems(): ProjectLibraryItem[] {
        return this.projectDetails.projectLibraryItems.filter((item) => item.libraryType === this.libraryType);
    }
}
