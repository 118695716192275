import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {ProjectSteps} from '../../enums';

@Injectable({
    providedIn: 'root'
})
export class AccessibleStepsService {

    private _accessibleSteps: ProjectSteps[] = [];

    public get accessibleSteps(): ProjectSteps[] {
        return this._accessibleSteps;
    }

    private set accessibleSteps(steps: ProjectSteps[]) {
        this._accessibleSteps = steps;
    }

    constructor(private apiService: ApiService) {
    }

    public isStepAccessible(stepId: ProjectSteps): boolean {
        return this.accessibleSteps.includes(stepId);
    }

    public async getAccessibleSteps(): Promise<ProjectSteps[]> {
        try {
            return (await this.apiService.get<HttpResponse<ProjectSteps[]>>('/accessiblestep')).body;
        } catch {
            // If we don't catch here, any error will cause the app to not load at all.
            // Instead, we'll just return the required steps so everything else continues to work.
            return [ProjectSteps.DETAILS, ProjectSteps.MANDATING];
        }
    }

     public async getAndCacheAccessibleSteps(): Promise<void> {
        this.accessibleSteps = await this.getAccessibleSteps();
    }

    public async updateAccessibleSteps(accessibleSteps: ProjectSteps[]): Promise<void> {
        await this.apiService.put<HttpResponse<void>, ProjectSteps[]>('/accessiblestep', accessibleSteps);
        this.accessibleSteps = accessibleSteps;
    }
}
