import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-pdf-pagebreak',
    templateUrl: './pdf-pagebreak.component.html',
    styleUrls: ['./pdf-pagebreak.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPagebreakComponent {

    constructor() {
    }

}
