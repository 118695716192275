<form [formGroup]="form" (submit)="submit()" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>

        <vwui-form-group [label]="'COMPONENT.tis.modal.create_question.field.name' | translate" inputId="name">
            <vwui-input inputId="name" formControlName="name"></vwui-input>
        </vwui-form-group>

        <div class="grid">
            <vwui-checkbox inputId="allowAttachments" formControlName="allowAttachments" class="grid__col">
                {{ 'COMPONENT.tis.modal.create_question.field.allowAttachments' | translate }}
            </vwui-checkbox>

            <vwui-checkbox inputId="isOptional" formControlName="isOptional" class="grid__col">
                {{ 'COMPONENT.tis.modal.create_question.field.isOptional' | translate }}
            </vwui-checkbox>
        </div>

        <div class="horizontal-container" formGroupName="dependentQuestion">
            <vwui-form-group label="Afhankelijk van" class="horizontal-container__child--grow">
                <ng-select formControlName="tempId"
                           [placeholder]="'COMPONENT.tis.modal.create_question.placeholder.dependentQuestion' | translate"
                [notFoundText]="'COMPONENT.tis.modal.create_question.notFound.dependentQuestion' | translate">
                    <ng-option *ngFor="let question of possibleDependentQuestions"
                               [value]="question.tempId">{{question.name}}</ng-option>
                </ng-select>
            </vwui-form-group>

            <vwui-form-group *ngIf="showDependentValueSelect" label="Vereiste waarde"
                             class="horizontal-container__child--grow">
                <ng-container [ngSwitch]="dependentQuestion.type">
                    <ng-select *ngSwitchCase="IQuestionType.MULTIPLECHOICE" formControlName="answerTempId"
                               [placeholder]="'COMPONENT.tis.modal.create_question.placeholder.dependentAnswer' | translate">
                        <ng-option *ngFor="let option of dependentQuestionOptions"
                                   [value]="option.tempId">{{option.answer}}</ng-option>
                    </ng-select>
                    <ng-select *ngSwitchCase="IQuestionType.BOOL" formControlName="boolValue" [placeholder]="'COMPONENT.tis.modal.create_question.placeholder.dependentBool' | translate">
                        <ng-option [value]="true">{{'YES_OR_NO.yes' | translate}}</ng-option>
                        <ng-option [value]="false">{{'YES_OR_NO.no' | translate}}</ng-option>
                    </ng-select>
                </ng-container>
            </vwui-form-group>
        </div>

        <div class="horizontal-container">
            <vwui-form-group [label]="'COMPONENT.tis.modal.create_question.field.questionType' | translate"
                             inputId="type" class="horizontal-container__child--grow">
                <ng-select
                        [items]="questionTypes"
                        [selectOnTab]="true"
                        [multiple]="false"
                        [required]="true"
                        bindValue="value"
                        bindLabel="label"
                        formControlName="type"
                ></ng-select>
            </vwui-form-group>

            <vwui-form-group *ngIf="isText"
                             [label]="'COMPONENT.tis.modal.create_question.field.textAnswerLength' | translate"
                             class="horizontal-container__child--grow">
                <vwui-input formControlName="textAnswerLength" type="number" [min]="1"
                            [max]="maxTextAnswerLength"></vwui-input>
            </vwui-form-group>
        </div>

        <div formArrayName="possibleTisAnswers" class="answers">
            <div *ngFor="let control of possibleAnswersFormArray.controls; let index = index" [formGroupName]="index"
                 class="answer">
                <vwui-input
                        class="answer__input"
                        formControlName="answer"
                        [maxlength]="255">
                </vwui-input>
                <vwui-btn-icon icon="vwui-trash"
                               tabindex="-1"
                               [disabled]="possibleAnswersFormArray.controls.length <= minimumMultipleChoiceAnswers"
                               (click)="removeAnswer(index)">
                </vwui-btn-icon>
            </div>
        </div>

        <vwui-button *ngIf="isMultipleChoice" class="add-answer-button is-secondary" (click)="addAnswer()">
            {{ 'COMPONENT.tis.modal.create_question.add_question' | translate }}
        </vwui-button>

        <div *ngIf="isCalculation" class="horizontal-container" formGroupName="calculation">
            <ng-select formControlName="leftQuestionTempId" class="horizontal-container__child--grow">
                <ng-option *ngFor="let question of integerQuestions"
                           [value]="question.tempId">{{question.name}}</ng-option>
            </ng-select>
            -
            <ng-select formControlName="rightQuestionTempId" class="horizontal-container__child--grow">
                <ng-option *ngFor="let question of integerQuestions"
                           [value]="question.tempId">{{question.name}}</ng-option>
            </ng-select>
        </div>

    </vwui-modal-body>

    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
