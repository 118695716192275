import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../models/definition/question';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PossibleAnswer} from '../../models/definition/possibleanswer';
import {IQuestionType} from '../../models';
import moment from 'moment';
import {RouterService} from '../../services/router/router.service';
import {TisPossibleAnswer} from '../../models/definition/tis-possible-answer';
import {IDepartment} from '../../models/department/department';

import {VWUICheckedEvent} from '@recognizebv/vwui-core';

type QuestionValue = number | string | PossibleAnswer | TisPossibleAnswer | boolean;

type ChangeFunction = (QuestionValueOutput) => void;

interface IVWUIInputvalueChanged {
    target: {
        value: string;
        valueAsDate?: Date;
    };
    stopPropagation: () => void;
}

@Component({
    selector: 'app-question-input',
    templateUrl: './question-input.component.html',
    styleUrls: ['./question-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionInputComponent),
            multi: true
        }
    ]
})
export class QuestionInputComponent implements OnInit, ControlValueAccessor {
    @Input() public question: Question;
    @Input() public readonly: boolean;
    @Output() public valueChanged: EventEmitter<QuestionValue> = new EventEmitter<QuestionValue>();

    /** Used for departments type questions */
    @Input() public departments: IDepartment[] = [];

    public defaultTextAnswerLength = 1_024;

    public valueModel: QuestionValue;
    public QuestionType = IQuestionType;

    private currentTextLength = 0;
    private onChangeFn: ChangeFunction;
    private onTouchedFn: ChangeFunction;

    public isPreviousUrlMandating: boolean;

    public get possibleAnswers(): (PossibleAnswer | TisPossibleAnswer)[] {
        return [...this.question.possibleAnswers, ...this.question.possibleTisAnswers];
    }

    public get questionIsMandatory(): boolean {
        return !this.valueModel && this.isPreviousUrlMandating && this.question.isRequired;
    }

    public get charactersRemaining(): number {
        return this.textAnswerLength - this.currentTextLength;
    }

    public get textAnswerLength(): number {
        return this.question.textAnswerLength ?? this.defaultTextAnswerLength;
    }

    constructor(
        private routerService: RouterService
    ) {
    }

    ngOnInit() {
        this.isPreviousUrlMandating = this.routerService.checkIfFromMandating();
    }

    onTextChange({detail: {value}}): void {
        this.currentTextLength = value.length;
    }

    handleChange(newAnswer: QuestionValue | IVWUIInputvalueChanged | VWUICheckedEvent) {
        if (this.readonly) {
            return;
        }

        const newAnswerEvent = newAnswer as IVWUIInputvalueChanged;

        let formattedAnswer;

        switch (this.question.type) {
            case IQuestionType.DATE:
                formattedAnswer = moment(newAnswerEvent.target.value).format('YYYY-MM-DD');
                newAnswerEvent.stopPropagation();
                this.writeValue(formattedAnswer === 'Invalid date' ? null : formattedAnswer);
                break;
            case IQuestionType.TEXT:
                formattedAnswer = newAnswerEvent.target.value;
                this.writeValue(formattedAnswer);
                newAnswerEvent.stopPropagation();
                break;
            case IQuestionType.PERCENTAGE:
            case IQuestionType.MULTIPLECHOICE:
            case IQuestionType.INTEGER:
            case IQuestionType.CALCULATION:
                formattedAnswer = newAnswer;
                this.writeValue(formattedAnswer);
                break;
            case IQuestionType.BOOL:
                const checkedEvent = newAnswer as VWUICheckedEvent;
                formattedAnswer = this.parseNullableStringAsBool(checkedEvent.value as string | null);
                this.writeValue(formattedAnswer);
                break;
            default:
                formattedAnswer = newAnswer;
                break;
        }

        this.onTouchedFn(formattedAnswer);
        this.onChangeFn(formattedAnswer);

        this.valueChanged.emit(formattedAnswer);
    }

    filterItems(term: string, answerId: number): boolean {
        const {answer} = this.possibleAnswers.find(a => a.id === answerId);
        return answer.toLocaleLowerCase().includes(term.toLocaleLowerCase());
    }

    registerOnChange(fn: ChangeFunction): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: ChangeFunction): void {
        this.onTouchedFn = fn;
    }

    writeValue(value: QuestionValue): void {
        this.valueModel = value;

        if (this.question.type === IQuestionType.TEXT) {
            this.currentTextLength = (value as string | null)?.length ?? 0;
        }

        // For the read only version, keep the answer set
        if (this.readonly && this.question.type === IQuestionType.DATE) {
            const changedValue: string = this.valueModel as string;
            if (!!changedValue) {
                this.valueModel = moment(changedValue).format('DD-MM-YYYY');
            }
        }
    }

    private parseNullableStringAsBool(str: string | null): boolean | null {
        if (str == null) {
            return null;
        }
        return str === 'true';
    }
}
