import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';

@Component({
    selector: 'app-edit-remark-modal',
    templateUrl: './edit-remark-modal.component.html',
    styleUrls: ['./edit-remark-modal.component.scss']

})
export class EditRemarkModalComponent {

    form = new FormGroup({
        remark: new FormControl('')
    });
    public saveText: string;
    public remark: string;
    public readonly: boolean;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ remark?: string; readonly: boolean; saveText: string }>,
        private trackingService: TrackingService
    ) {
        this.saveText = modalConfig.data.saveText;
        this.remark = modalConfig.data.remark;
        this.readonly = modalConfig.data.readonly;

        this.form = new FormGroup({
            remark: new FormControl(this.remark)
        });

        this.handleFormControls();
    }

    private handleFormControls(): void {
        this.form.enable();

        if (this.readonly) {
            this.form.disable();
        }
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                this.modalRef.close(this.form.value);
            }
        } catch (error) {
            this.trackingService.exception(error);
        }
    }
}
