import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {Question} from '../../models/definition/question';
import {Category, QuickscanDefinition} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {QuickscanDefinitionService} from '../../services';
import {QuickscanAnswers} from '../../models/project/quickscan-answer';
import {CategoryInfos} from '../../models/project/category-info';

@Component({
    selector: 'app-pdf-page-quickscan-list',
    templateUrl: './pdf-page-quickscan-list.component.html',
    styleUrls: ['./pdf-page-quickscan-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageQuickscanListComponent {

    @Input()
    public quickscanDefinition: QuickscanDefinition;
    @Input()
    public quickscanAnswers: QuickscanAnswers;
    @Input()
    public categoryInfo: CategoryInfos;

    constructor(
        private translateService: TranslateService
    ) {
    }

    public getQuickscanQuestionRemark(question: Question): string {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);

        return quickscanAnswer && quickscanAnswer.remarks ? quickscanAnswer.remarks.trim() : '';
    }

    public hasQuickscanQuestionRemark(question: Question): boolean {
        return this.getQuickscanQuestionRemark(question) !== '';
    }

    public getQuickscanCategoryRemark(category: Category): string {
        const remark = this.categoryInfo.getRemarkForCategory(category);

        return remark ? remark.trim() : '';
    }

    public hasQuickscanCategoryRemark(category: Category): boolean {
        return this.getQuickscanCategoryRemark(category) !== '';
    }

    public getQuickscanQuestionAnswer(question: Question): string {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);
        const possibleAnswer = question.possibleAnswers.find((answer) => quickscanAnswer && quickscanAnswer.answerId === answer.id);

        return possibleAnswer && possibleAnswer.answer ? possibleAnswer.answer.trim() : '';
    }

    public getWeightedScoreForQuestion(question: Question, category: Category): number {
        const questionScore = this.getScoreForQuestion(question);

        if (questionScore > 0) {
            const maxScoreForCategory = category.getMaxScore();
            if (maxScoreForCategory !== 0) {
                return Math.round(questionScore * (category.weightedScore / category.getMaxScore()));
            }
        }

        return questionScore;
    }

    public getIndicatorForCategory(quickscanCategory: Category): string {
        const answerScore = this.getWeightedScoreForCategory(quickscanCategory);
        const maxScore = quickscanCategory.weightedScore;

        return (answerScore / maxScore) > this.quickscanDefinition.redFlagTresholdCategory / 100 ? 'red' : 'green';
    }

    public getWeightedScoreForCategory(quickscanCategory: Category): number {
        const totalScore = quickscanCategory.getAllSubCategoryQuestions()
            .map((question) => this.getScoreForQuestion(question))
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);

        const maxScoreForCategory = quickscanCategory.getMaxScore();
        if (maxScoreForCategory !== 0) {
            return Math.round(totalScore * (quickscanCategory.weightedScore / quickscanCategory.getMaxScore()));
        }
    }

    public getRedFlagsForCategory(quickscanCategory: Category) {
        let numberOfRedFlags = quickscanCategory.getAllSubCategoryQuestions()
            .map((question): number => this.hasRedFlagForQuestion(question) ? 1 : 0)
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);

        numberOfRedFlags += this.getIndicatorForCategory(quickscanCategory) === 'red' ? 1 : 0;

        return numberOfRedFlags + ' ' + this.translateService.instant('PAGE_FILL_QUICKSCAN.red_flags');
    }

    public trackQuickscanCategory(index: number, category: Category): string {
        return `${category.id}`;
    }

    public trackQuickscanCategoryQuestion(index: number, question: Question): string {
        return `${question.getParent().id}_${question.id}`;
    }

    private hasRedFlagForQuestion(question: Question): boolean {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);

        if (quickscanAnswer.answerId !== undefined) {
            const selectedAnswer = question.possibleAnswers.find((possibleAnswer) => possibleAnswer.id === quickscanAnswer.answerId);

            if (selectedAnswer !== undefined) {
                return selectedAnswer.triggersRedFlag;
            }
        }
        return false;
    }

    private canHaveRedFlagForQuestion(question: Question): boolean {
        return question.possibleAnswers.filter((answer) => answer.triggersRedFlag).length > 0;
    }

    private getScoreForQuestion(question: Question): number {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);

        if (quickscanAnswer.answerId !== undefined) {
            const selectedAnswer = question.possibleAnswers.find((possibleAnswer) => possibleAnswer.id === quickscanAnswer.answerId);

            if (selectedAnswer !== undefined) {
                return selectedAnswer.score;
            }
        }

        return 0;
    }

}
