<form *ngIf="formGroup" [formGroup]="formGroup">
    <vwui-form-group inputId="relationOtherRisks"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.relationOtherRisks' | translate }}">
        <vwui-input inputId="relationOtherRisks" type="text" formControlName="relationOtherRisks"></vwui-input>
    </vwui-form-group>
    <vwui-form-group inputId="possibleCounterMeasures"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.possibleCounterMeasures' | translate }}"
                     required>
        <vwui-textarea inputId="possibleCounterMeasures" formControlName="possibleCounterMeasures"></vwui-textarea>
    </vwui-form-group>
</form>
