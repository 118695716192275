import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-explanation',
    templateUrl: './explanation.component.html',
    styleUrls: ['./explanation.component.scss']
})
export class ExplanationComponent {

    @Input() link: string;

    constructor(
        private router: Router
    ) {
    }

    public navigateToLink(): void {
        if (this.link) {
            this.router.navigate([this.link]);
        }
    }

}
