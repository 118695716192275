import {Component, OnInit} from '@angular/core';
import {AccessibleStepsService} from '../../services/accessible-steps/accessible-steps.service';
import {StepService, ToastService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {environment} from '../../environments/environment';
import {ProjectSteps} from '../../enums';

@Component({
    selector: 'app-page-accessible-step-management',
    templateUrl: './page-accessible-step-management.component.html',
    styleUrls: ['./page-accessible-step-management.component.scss']
})
export class PageAccessibleStepManagementComponent implements OnInit {

    private isDirty = false;

    allSteps = Object.freeze([
        ProjectSteps.DETAILS,
        ProjectSteps.QUICKSCAN,
        ProjectSteps.RISKS,
        ProjectSteps.OPPORTUNITIES,
        ProjectSteps.TASKS,
        ProjectSteps.DOSSIER,
        ProjectSteps.RAINBOW,
        ProjectSteps.TIS_QUICKSCAN,
        ProjectSteps.TIS_QUESTIONS,
        ProjectSteps.MANDATING
    ]);
    accessibleSteps: number[] = [];
    remainingSteps: number[] = [];
    isLoading = true;

    get showSetToDefaultButton(): boolean {
        return environment.features.accessibleSteps.showSetToDefaultButton;
    }

    get isDisabled(): boolean {
        return !this.isDirty || this.isLoading;
    }

    constructor(private accessibleStepsService: AccessibleStepsService, private stepService: StepService, private translateService: TranslateService, private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.accessibleStepsService.getAccessibleSteps()
            .then(steps => {
                this.accessibleSteps = steps;
                this.remainingSteps = this.allSteps.filter(step => !this.accessibleSteps.includes((step)));
            })
            .catch(error => this.openError(error, 'ERRORS.tender_managers_not_retrieved'))
            .finally(() => this.isLoading = false);
    }

    onDrop(event: CdkDragDrop<number[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        this.isDirty = true;
    }

    save() {
        this.isLoading = true;

        this.accessibleStepsService.updateAccessibleSteps(this.accessibleSteps)
            .then(() => {
                this.isDirty = false;
                this.toastService.openSuccess(this.translateService.instant('PAGE_ACCESSIBLE_STEPS.toast.success'));
            })
            .catch(error => this.openError(error, 'PAGE_ACCESSIBLE_STEPS.toast.error'))
            .then(() => this.isLoading = false);
    }

    setToDefault() {
        this.accessibleSteps = [...this.allSteps];
        this.remainingSteps = [];
        this.isDirty = true;
    }

    isStepDisabled(stepId: ProjectSteps): boolean {
        return stepId === ProjectSteps.DETAILS || stepId === ProjectSteps.MANDATING;
    }

    getTitleForStepId(stepId: ProjectSteps): string {
        return this.stepService.getTitle(stepId);
    }

    /**
     * Prevents non-mandatory steps from being positioned into the first / last place.
     */
    sortPredicate(index: number, item: CdkDrag<number>): boolean {
        const itemFromRemainingSteps = item.dropContainer.element.nativeElement.closest('.step-list--remaining-steps') !== null;
        const lastAccessibleStepIndex = itemFromRemainingSteps ?
            this.accessibleSteps.length :
            this.accessibleSteps.length - 1;
        return index !== 0 && index < lastAccessibleStepIndex;
    }

    private openError(error: { status: string | number }, errorMessageId: string) {
        this.toastService.openError(error.status, this.translateService.instant(errorMessageId));
    }
}
