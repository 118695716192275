<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_TENDER_MANAGERS.title' | translate}}</h2>
</vwui-header>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ 'PAGE_TENDER_MANAGERS.table.header' | translate }}</div>
        <vwui-table-row>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--name">{{ 'PAGE_TENDER_MANAGERS.table.column.name' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--companies">{{ 'PAGE_TENDER_MANAGERS.table.column.companies' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--actions">
            </vwui-table-header>
        </vwui-table-row>
        <vwui-table-row
            *ngFor="let tenderManager of tenderManagers">
            <vwui-table-column class="tender-manager-col tender-manager-col--name">
                <ng-container *ngIf="tenderManager.fullName; else email">
                    {{ tenderManager.fullName + " (" + tenderManager.uniqueName + ")" }}
                </ng-container>
                <ng-template #email>
                    {{ tenderManager.uniqueName }}
                </ng-template>
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--companies">
                {{ tenderManager.getManageableCompanies().join(', ') }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--actions">
                <vwui-btn-icon icon="edit" (click)="openUpdateTenderManagerModal(tenderManager)"></vwui-btn-icon>
                <vwui-btn-icon icon="vwui-trash" (click)="openDeleteTenderManagerModal(tenderManager)"></vwui-btn-icon>
            </vwui-table-column>
        </vwui-table-row>
        <vwui-table-row>
            <vwui-table-column class="tender-manager-col tender-manager-col--name">
                <vwui-button class="is-secondary" (click)="openCreateTenderManagerModal()">
                    {{ 'PAGE_TENDER_MANAGERS.create_tender_manager' | translate }}
                </vwui-button>
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--companies">
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--actions">
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</div>
