<app-sticky-header>
    <ng-container left>
        <div class="back-link project-name">
            <vwui-tooltip position="bottom" [label]="'NAV.project.all' | translate">
                <a [routerLink]="['/projecten']"><vwui-icon iconName="back"></vwui-icon> {{ project?.name }}</a>
            </vwui-tooltip>
        </div>
        <span style="display: none;" *ngIf="project" a11yPageTitleLevel="1">{{ project.name }}</span>
    </ng-container>

    <app-progress-bar *ngIf="this.project && !showInExecutionSteps()" [progress]="stepProgress" [steps]="progressBarSteps"></app-progress-bar>
    <vwui-tab-bar *ngIf="this.project && showInExecutionSteps()" [selected]="stepId" (vwuiTabChange)="onTabChange($event.detail)" class="header-tab-bar" a11yTablistControls>
        <vwui-tab *ngIf="isStepAccessible(6)" tabId="6">{{ 'STEPS.6' | translate }}</vwui-tab>
        <vwui-tab *ngIf="isStepAccessible(5)" tabId="5">{{ 'STEPS.5' | translate }}</vwui-tab>
        <vwui-tab *ngIf="isStepAccessible(3)" tabId="3">{{ 'STEPS.3' | translate }}</vwui-tab>
        <vwui-tab *ngIf="isStepAccessible(4)" tabId="4">{{ 'STEPS.4' | translate }}</vwui-tab>
    </vwui-tab-bar>

    <div class="button-group" right>
        <app-project-status class="header-project-status" [project]="project"></app-project-status>

        <vwui-menu class="header-menu" id="projectMenu">
            <vwui-menu-option (click)="downloadResult()" a11yKeyboardClickable>
                <vwui-icon iconName="vwui-file"></vwui-icon>
                {{ 'PROJECT_MENU.download' | translate }}
            </vwui-menu-option>

            <ng-container *ngIf="!isClosed()">
                <ng-container [ngTemplateOutlet]="disabledForNonEditors() ? menuOptionShareDisabled : menuOptionShare"></ng-container>
                <ng-template #menuOptionShare>
                    <vwui-menu-option (click)="shareProject($event)" [ngClass]="{'is-disabled': disabledForNonEditors()}" a11yKeyboardClickable>
                        <vwui-icon iconName="share"></vwui-icon>
                        {{ 'PROJECT_MENU.share' | translate }}
                    </vwui-menu-option>
                </ng-template>
                <ng-template #menuOptionShareDisabled>
                    <vwui-tooltip [label]="disabledForNonEditors() | translate" position="left" class="is-block">
                        <ng-container [ngTemplateOutlet]="menuOptionShare"></ng-container>
                    </vwui-tooltip>
                </ng-template>

                <ng-container [ngTemplateOutlet]="disabledForOnHold() ? menuOptionOnHoldDisabled : menuOptionOnHold"></ng-container>
                <ng-template #menuOptionOnHold>
                    <vwui-menu-option (click)="changeProjectOnHoldStatus($event)" [ngClass]="{'is-disabled': disabledForOnHold()}"  a11yKeyboardClickable>
                        <ng-container *ngIf="!project?.isOnHold(); else viewProjectResume">
                            <vwui-icon iconName="pause-circle"></vwui-icon>
                            {{ 'PROJECT_MENU.on_hold' | translate }}
                        </ng-container>
                        <ng-template #viewProjectResume>
                            <vwui-icon iconName="play-circle"></vwui-icon>
                            {{ 'PROJECT_MENU.on_resume' | translate }}
                        </ng-template>
                    </vwui-menu-option>
                </ng-template>
                <ng-template #menuOptionOnHoldDisabled>
                    <vwui-tooltip [label]="disabledForOnHold() | translate" position="left" class="is-block">
                        <ng-container [ngTemplateOutlet]="menuOptionOnHold"></ng-container>
                    </vwui-tooltip>
                </ng-template>

                <ng-container *ngIf="enabledForClosing()">
                    <vwui-menu-option (click)="setProjectToClosed($event)" a11yKeyboardClickable>
                        <vwui-icon iconName="vwui-close"></vwui-icon>
                        {{ 'PROJECT_MENU.close' | translate }}
                    </vwui-menu-option>
                </ng-container>

                <ng-container [ngTemplateOutlet]="disabledForArchiving() ? menuOptionArchiveDisabled : menuOptionArchive"></ng-container>
                <ng-template #menuOptionArchive>
                    <vwui-menu-option (click)="setProjectToArchived($event)" [ngClass]="{'is-disabled': disabledForArchiving()}" a11yKeyboardClickable>
                        <vwui-icon iconName="vwui-trash"></vwui-icon>
                        {{ 'PROJECT_MENU.archive' | translate }}
                    </vwui-menu-option>
                </ng-template>
                <ng-template #menuOptionArchiveDisabled>
                    <vwui-tooltip [label]="disabledForArchiving() | translate" position="left" class="is-block">
                        <ng-container [ngTemplateOutlet]="menuOptionArchive"></ng-container>
                    </vwui-tooltip>
                </ng-template>
            </ng-container>

        </vwui-menu>

        <vwui-button class="is-primary" [loading]="isSaving" (click)="saveProject()" [appDisabledReason]="disabledSaveReason() | translate">
            {{ 'BUTTON.save' | translate }}
        </vwui-button>
    </div>
</app-sticky-header>

<ng-container *ngIf="project">
    <ng-container *ngIf="stepId === projectSteps.DETAILS"><app-project-details class="skip-element" [projectDetails]="project" (isFormValid)="projectDetailsValid($event)"></app-project-details></ng-container>
    <ng-container *ngIf="stepId === projectSteps.QUICKSCAN && project"><app-project-quickscan class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-quickscan></ng-container>
    <ng-container *ngIf="stepId === projectSteps.RISKS && project"><app-project-risks class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-risks></ng-container>
    <ng-container *ngIf="stepId === projectSteps.OPPORTUNITIES && project"><app-project-opportunities class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-opportunities></ng-container>
    <ng-container *ngIf="stepId === projectSteps.TASKS && project"><app-project-tasks class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-tasks></ng-container>
    <ng-container *ngIf="stepId === projectSteps.DOSSIER && project"><app-project-risk-dossier class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-risk-dossier></ng-container>
    <ng-container *ngIf="stepId === projectSteps.RAINBOW && project"><app-project-risk-rainbow class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-risk-rainbow></ng-container>
    <ng-container *ngIf="stepId === projectSteps.TIS_QUICKSCAN && project"><app-project-tis-quickscan class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-tis-quickscan></ng-container>
    <ng-container *ngIf="stepId === projectSteps.TIS_QUESTIONS && project"><app-project-tis class="skip-element" [projectDetails]="project"></app-project-tis></ng-container>
    <ng-container *ngIf="stepId === projectSteps.MANDATING"><app-project-mandating class="skip-element" [projectDetails]="project" (projectDetailsChanged)="projectDetailsChanged($event)"></app-project-mandating></ng-container>
</ng-container>
