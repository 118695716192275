import {Directive, ElementRef, HostListener, Input, OnInit, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails} from '../models';

@Directive({
    selector: '[appDisabledReason]'
})
export class DisabledReasonDirective implements OnInit {

    private disabledReasonValue = null;

    @Input()
    set appDisabledReason(val: string) {
        this.disabledReasonValue = val;
        this.updateState();
    }

    constructor(
        private elementRef: ElementRef,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.updateState();
    }

    private updateState(): void {
        if (!this.disabledReasonValue) {
            this.elementRef.nativeElement.removeAttribute('title');
            this.elementRef.nativeElement.removeAttribute('disabled');
        } else {
            this.elementRef.nativeElement.setAttribute('title', this.translate.instant(this.disabledReasonValue));
            this.elementRef.nativeElement.setAttribute('disabled', 'disabled');
        }
    }

}
