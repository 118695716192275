<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_COMPANY_TYPES.title' | translate}}</h2>
</vwui-header>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ 'PAGE_COMPANY_TYPES.table.header' | translate }}</div>
        <vwui-table-row>
            <vwui-table-header
                class="company-type-col company-type-col--name">{{ 'PAGE_COMPANY_TYPES.table.column.name' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="company-type-col company-type-col--risk-managers">{{ 'PAGE_COMPANY_TYPES.table.column.risk_managers' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="company-type-col company-type-col--actions">
            </vwui-table-header>
        </vwui-table-row>
        <vwui-table-row
            *ngFor="let companyType of companyTypes">
            <vwui-table-column class="company-type-col company-type-col--name">
                {{ companyType.name }}
            </vwui-table-column>
            <vwui-table-column class="company-type-col company-type-col--risk-managers">
                {{ companyType.riskManagers | namesFormat }}
            </vwui-table-column>
            <vwui-table-column class="company-type-col company-type-col--actions">
                <vwui-btn-icon icon="edit" (click)="openUpdateCompanyTypeModal(companyType)"></vwui-btn-icon>
                <vwui-btn-icon icon="vwui-trash" (click)="openDeleteCompanyTypeModal(companyType)"></vwui-btn-icon>
            </vwui-table-column>
        </vwui-table-row>
        <vwui-table-row>
            <vwui-table-column class="company-type-col company-type-col--name">
                <vwui-button class="is-secondary" (click)="openCreateCompanyTypeModal()">
                    {{ 'PAGE_COMPANY_TYPES.create_type' | translate }}
                </vwui-button>
            </vwui-table-column>
            <vwui-table-column class="company-type-col company-type-col--companies">
            </vwui-table-column>
            <vwui-table-column class="company-type-col company-type-col--actions">
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</div>
