<form [formGroup]="form" (submit)="submit()" a11yFocusTrap>
    <vwui-modal-body a11yAutoFocus>
        <div class="items-nav">
            <nav class="items-nav__items">
                <ng-container *ngFor="let category of library.categories">
                    <app-nav-item
                        (click)="selectCategory(category)"
                        a11yKeyboardClickable
                        [ngClass]="{'is-active': isCategorySelected(category)}"
                    >{{ category.name }}</app-nav-item>
                </ng-container>
            </nav>
        </div>
        <h2 a11yPageTitleLevel="2" class="items-header">{{ title | translate }}</h2>
        <div class="items-body">
            <vwui-header-divider-bar *ngIf="!noItems">
                <vwui-search
                    (vwuiChange)="search($event.detail.value)"
                    [label]="'PAGE_PROJECT_LIBRARY_ITEMS.filter.search.label' | translate"
                    a11yHotkeyFocusSearch
                ></vwui-search>
            </vwui-header-divider-bar>
            <app-explanation *ngIf="searchError">{{ 'PAGE_PROJECT_LIBRARY_ITEMS.filter.error.message' | translate:{ search_query: searchValue } }}</app-explanation>
            <app-explanation *ngIf="noItems">{{ 'PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + library.type + '.no_items' | translate }}</app-explanation>
            <div class="items-grid" a11yFocusBlock>
                <ng-container *ngFor="let selectableItem of filteredSelectableItems">
                    <app-item-card class="item-card--size-md item-card--clickable" [selected]="isItemSelected(selectableItem)" (click)="toggleItem(selectableItem)" a11yKeyboardClickable>
                        <div class="item-card__title">{{ selectableItem.getTitle() }}</div>
                        <div class="item-card__description">{{ selectableItem.riskDescription }}</div>
                        <div class="item-card__label" *ngIf="selectableItem.causeDescription">{{ 'COMPONENT.library_mgmt.library_type.' + library.type + '.item.causeDescription' | translate }}</div>
                        <div class="item-card__description" *ngIf="selectableItem.causeDescription">{{ selectableItem.causeDescription }}</div>
                    </app-item-card>
                </ng-container>
            </div>
        </div>
        <div class="items-footer">
            <div class="items-footer__buttons button-group">
                <vwui-button class="is-light" (click)="modalRef.close(false)">
                    {{ 'BUTTON.cancel' | translate }}
                </vwui-button>
                <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
                    {{ 'BUTTON.save' | translate }}
                </vwui-button>
            </div>

            <div>{{ 'ADD_PROJECT_LIBRARY_ITEMS.library_type.' + library.type + '.nr_selected' | translate }} <vwui-badge>{{ this.selectedItems.length}}</vwui-badge></div>
            <div a11yKeyboardClickable (click)="deselectAllItems()" class="deselect">{{ 'ADD_PROJECT_LIBRARY_ITEMS.library_type.' + library.type + '.deselect' | translate }}</div>
        </div>
    </vwui-modal-body>
</form>
