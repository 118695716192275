import {NgModule} from '@angular/core';
import {RichTextEditorComponent} from './component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        RichTextEditorComponent
    ],
    imports: [
        CommonModule,
        EditorModule,
        ReactiveFormsModule
    ],
    exports: [
        RichTextEditorComponent,
        CommonModule,
        EditorModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue: 'tinymce/tinymce.min.js'
        }
    ]
})
export class RichTextEditorModule {
}
