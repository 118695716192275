import {IQuickScanAnswerData} from './project-data';
import {IQuestionData} from '..';
import {PossibleAnswer} from '../definition/possibleanswer';

export class QuickscanAnswers {
    private quickscanAnswers: QuickscanAnswer[];

    constructor(data: IQuickScanAnswerData[]) {
        this.quickscanAnswers = data.map(answer => new QuickscanAnswer(answer));
    }

    public getQuickscanAnswerForQuestion(question: IQuestionData): QuickscanAnswer {
        let quickscanAnswer = this.quickscanAnswers.find((answer) => answer.questionId === question.id);
        if (quickscanAnswer === undefined) {
            quickscanAnswer = new QuickscanAnswer({questionId: question.id});
            this.quickscanAnswers.push(quickscanAnswer);
        }

        return quickscanAnswer;
    }

    public updateQuickscanAnswer(question: IQuestionData, selectedAnswerId: number | undefined) {
        const quickscanAnswer = this.getQuickscanAnswerForQuestion(question);
        quickscanAnswer.setAnswerId(selectedAnswerId);
    }

    getQuickscanAnswers() {
        return this.quickscanAnswers;
    }
}

export class QuickscanAnswer {
    questionId?: number;
    answerId?: number;
    remarks?: string;

    constructor(data: IQuickScanAnswerData) {
        this.questionId = data.questionId;
        this.answerId = data.answerId;
        this.remarks = data.remarks;
    }

    public setRemarks(remarks: string) {
        this.remarks = remarks;
    }

    public setAnswerId(id: number | undefined) {
        this.answerId = id;
    }
}
