import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {FileSystemEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {Question} from '../../models/definition/question';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {IAttachment} from '../../models/project/project-data';
import {ApiService, ToastService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-question-attachments',
    templateUrl: './question-attachments.component.html',
    styleUrls: ['./question-attachments.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionAttachmentsComponent),
            multi: true
        }
    ]
})
export class QuestionAttachmentsComponent {
    @Input() public question: Question;
    @Input() public attachments: IAttachment[];
    @Input() public readonly: boolean;
    @Output() public valueChanged: EventEmitter<IAttachment[]> = new EventEmitter<IAttachment[]>();

    dropping = false;
    value: IAttachment = null;
    private supportedExtensions = [
        '.pdf',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx'
    ];

    public MAX_ATTACHMENTS = 3;
    private maxExceededWarningGiven = false;

    constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private apiService: ApiService
    ) {
    }

    dropped(files: NgxFileDropEntry[]): void {
        if (this.readonly) {
            return;
        }
        for (const droppedFile of files) {
            this.maxExceededWarningGiven = false;
            if (droppedFile.fileEntry.isFile && this.isSupported(droppedFile.fileEntry)) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    const maxSizeInMb = this.getMaxSizeInMb();
                    if (!this.fileSizeOk(file, maxSizeInMb)) {
                        this.toastService.openError(
                            400,
                            this.translateService.instant('COMPONENT.QUESTION_ATTACHMENT.filesize_error', {maxSizeInMb})
                        );
                        return;
                    }
                    if (this.attachments.length >= this.MAX_ATTACHMENTS) {
                        if (!this.maxExceededWarningGiven) {
                            // show the error only once per drop, even if we're dropping 100+ files at once
                            this.toastService.openError(
                                400,
                                this.translateService.instant('COMPONENT.QUESTION_ATTACHMENT.attachments_limit_reached', {maxAttachments: this.MAX_ATTACHMENTS})
                            );
                            this.maxExceededWarningGiven = true;
                        }
                        return;
                    }
                    this.dropping = true;

                    const formData = new FormData();
                    formData.append('file', file, droppedFile.relativePath);

                    this.apiService.multipartPost<IAttachment>('/attachment/upload', formData)
                        .then((fileUpload) => {
                            this.value = {
                                uniqueId: fileUpload.uniqueId,
                                originalFileName: file.name,
                                contentType: ''
                            };
                            this.attachments.push(this.value);
                            this.dropping = false;
                            this.value = null;

                            this.valueChanged.emit(this.attachments);

                        }, (httpError) => {
                            this.toastService.openError(
                                httpError.status,
                                this.translateService.instant('COMPONENT.QUESTION_ATTACHMENT.error')
                            );
                            this.dropping = false;
                        });
                });
            }
        }
    }

    public getSupportedExtensions(): string {
        return this.supportedExtensions.join(', ');
    }

    private isSupported(fileEntry: FileSystemEntry): boolean {
        const regexExtension = /(?:\.([^.]+))?$/;
        const fileExtension = regexExtension.exec(fileEntry.name);
        const isSupported = this.supportedExtensions.some(extension => extension === fileExtension[0]);

        if (!isSupported) {
            this.toastService.openError(
                400,
                this.translateService.instant('COMPONENT.QUESTION_ATTACHMENT.fileextension_error', {supportedFileExtensions: this.getSupportedExtensions()})
            );
        }

        return isSupported;
    }

    private getMaxSizeInMb(): number {
        return 3;
    }

    private fileSizeOk(file: File, maxSizeInMb: number): boolean {
        return file.size < (maxSizeInMb * 1024 * 1024);
    }

    removeAttachment(attachment: IAttachment) {
        if (!this.readonly) {
            this.attachments = this.attachments.filter(att => att.uniqueId !== attachment.uniqueId);
            this.valueChanged.emit(this.attachments);
        }
    }
}
