import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {ITisDefinitionData, TisDefinition} from '../../models';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse, HttpSentEvent} from '@angular/common/http';
import {CompanyType} from '../../models/user/user';
import {CompanyTypeService} from '../companytype/company-type.service';

@Injectable({
    providedIn: 'root'
})
export class TisDefinitionService {

    constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private companyTypeService: CompanyTypeService,
        private translateService: TranslateService
    ) {
    }

    public getTisDefinitions(companyType: CompanyType): Promise<TisDefinition[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/tisdefinition/${companyType.id}`).then((response: HttpResponse<ITisDefinitionData[]>) => {
                const tisDefinitions: TisDefinition[] = response.body.map(data => new TisDefinition(data));
                resolve(tisDefinitions);
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.tis_not_updated', {tisLabelShort: companyType.tisLabelShort}));
                reject(error);
            });
        });
    }

    public publishTisDefinition(companyType: CompanyType, id: number): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/tisdefinition/${companyType.id}/${id}/publish`, {}).then((response: HttpSentEvent) => {
                resolve('ok');
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.tis_not_published', {tisLabelShort: companyType.tisLabelShort}));
                reject(error);
            });
        });
    }

    public getTisDefinition(companyTypeId: number, tisDefinitionId: number, tisLabelShort: string): Promise<TisDefinition> {
        return new Promise((resolve, reject) => this.apiService.get(`/tisdefinition/${companyTypeId}/${tisDefinitionId}`)
                    .then((response: HttpResponse<ITisDefinitionData>) => {
                        const tisDefinition = new TisDefinition(response.body);
                        resolve(tisDefinition);
                    }).catch((error) => {
                        console.error(error);
                        this.toastService.openError(error, this.translateService.instant('ERRORS.tis_not_retrieved', {tisLabelShort}));
                        reject(error);
                    }));
    }

    public updateTisDefinition(companyType: CompanyType, data: TisDefinition): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/tisdefinition/${companyType.id}/${data.versionId}`, data).then(() => {
                resolve('ok');
            }).catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.tis_not_updated', {tisLabelShort: companyType.tisLabelShort}));
                reject(error);
            });
        });
    }
}
