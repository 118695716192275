<ng-container [ngSwitch]="question.type">
    <div *ngSwitchCase="QuestionType.TEXT">
        <vwui-textarea
            autosize
            class="vwui-textarea--table-column"
            [ngModel]="valueModel"
            [cols]="1"
            (change)="handleChange($event)"
            (vwuiChange)="onTextChange($event)"
            [disabled]="readonly"
            [maxlength]="question.textAnswerLength ?? defaultTextAnswerLength"
            [class.question-is-mandatory]="questionIsMandatory">
        </vwui-textarea>
        <span *ngIf="!readonly" class="characters-remaining">
            {{'COMPONENT.QUESTION_INPUT.charactersRemaining' | translate: {charactersRemaining: charactersRemaining} }}
        </span>
    </div>

    <vwui-datepicker
        *ngSwitchCase="QuestionType.DATE"
        [class.disabled]="readonly"
        [class.question-is-mandatory]="questionIsMandatory"
        [disabled]="readonly"
        [flatpickrOptions]="{dateFormat: 'Y-m-dTH:i', altFormat: 'd-m-Y', altInput: true, convertModelValue: true, allowInput: true, locale: 'nl'}"
        [ngModel]="valueModel"
        (change)="handleChange($event)">
    </vwui-datepicker>

    <ng-select
        *ngSwitchCase="QuestionType.DEPARTMENT" class="select-answer"
        [placeholder]="'COMPONENT.QUESTION_INPUT.selectDepartment' | translate"
        [notFoundText]="'COMPONENT.QUESTION_INPUT.noDepartmentsFound' | translate"
        [ngModel]="valueModel"
        (change)="handleChange($event)"
        [readonly]="readonly"
        [searchFn]="filterItems.bind(this)"
        [class.question-is-mandatory]="questionIsMandatory">
        <ng-option *ngFor="let department of departments" [value]="department.id">{{department.name}}</ng-option>
    </ng-select>

    <ng-select
        *ngSwitchDefault class="select-answer"
        [placeholder]="'COMPONENT.QUESTION_INPUT.selectAnswer' | translate"
        [notFoundText]="'COMPONENT.QUESTION_INPUT.noAnswersFound' | translate"
        [ngModel]="valueModel"
        (change)="handleChange($event)"
        [readonly]="readonly"
        [searchFn]="filterItems.bind(this)"
        [class.question-is-mandatory]="questionIsMandatory">
        <ng-option *ngFor="let answer of possibleAnswers" [value]="answer.id">{{answer.answer}}</ng-option>
    </ng-select>

    <vwui-input-mask
        *ngSwitchCase="QuestionType.CALCULATION"
        type="number"
        mask="integer"
        [readonly]="true"
        [ngModel]="valueModel">
    </vwui-input-mask>

    <vwui-input-mask
        *ngSwitchCase="QuestionType.INTEGER"
        type="number"
        [mask]="{alias: 'integer', groupSeparator: '.'}"
        [disabled]="readonly"
        [ngModel]="valueModel"
        (ngModelChange)="handleChange($event)"
        [class.question-is-mandatory]="questionIsMandatory">
    </vwui-input-mask>

    <vwui-radio-group
        *ngSwitchCase="QuestionType.BOOL"
        (vwuiGroupChecked)="handleChange($event.detail)"
        [class.question-is-mandatory]="questionIsMandatory">
        <vwui-radio [id]="question.id + 'true'" [name]="'question' + question.id" [checked]="valueModel === true" [value]="true"
                    [disabled]="readonly">
            {{ 'COMPONENT.QUESTION_INPUT.boolean.true' | translate }}
        </vwui-radio>
        <vwui-radio [id]="question.id + 'false'" [name]="'question' + question.id" [checked]="valueModel === false" [value]="false"
                    [disabled]="readonly">
            {{ 'COMPONENT.QUESTION_INPUT.boolean.false' | translate }}
        </vwui-radio>
    </vwui-radio-group>

    <vwui-input-mask
        *ngSwitchCase="QuestionType.PERCENTAGE"
        type="number"
        [mask]="{alias: 'percentage', digits: '2', step: 0.01, min: 0, max: 100}"
        [disabled]
        [ngModel]="valueModel"
        (ngModelChange)="handleChange($event)"
        [class.question-is-mandatory]="questionIsMandatory">
    </vwui-input-mask>
</ng-container>
