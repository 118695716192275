import {Injectable} from '@angular/core';
import {SearchUtil} from '../../utils';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor() {
    }

    /*
     * Searches the given object
     * @param {<objectType>[]} searchObject - The object we loop through
     * @param {string[]} searchFields - the fields we want to find the value(s) in
     * @param {string} searchValue - the value can be a comma separated string, to be used in a search
     * @param {boolean} caseSensitive - Case sensitivity, defaults to false
     * @returns {<objectType>[]} - Returns the filtered object array
     */
    public searchTable<objectType>(searchObject: objectType[], searchFields: string[], searchValue: string): objectType[] {
        return searchValue ? searchObject.filter((object) => searchFields.find((searchField) => {
                if (object.hasOwnProperty(searchField)) {
                    const searchArray = searchValue.split(',');
                    // if we find any value that is correct within the array we return true, else false.
                    return !!searchArray.find((searchKey: string) => {
                        searchKey = searchKey.trim(); // we trim it so we dont search for the spaces before and after
                        if (searchKey.length > 0) {
                            const objectField = object[searchField].toString();

                            return SearchUtil.hasMatch(searchKey, objectField);
                        }
                    });
                }
            })) : searchObject;
    }
}
