import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Library, LibraryCategory, LibraryEntry, ProjectLibraryItem} from '../../models';
import {FilterService} from '../../services';

@Component({
    selector: 'app-add-project-library-items-modal',
    templateUrl: './add-project-library-items-modal.component.html',
    styleUrls: ['./add-project-library-items-modal.component.scss']
})
export class AddProjectLibraryItemsModalComponent {

    public form = new FormGroup({});
    public loading = false;

    public title: string;
    public library: Library;
    public projectItems?: ProjectLibraryItem[];
    public selectedItems?: LibraryEntry[];
    public selectableItems: LibraryEntry[];
    public filteredSelectableItems: LibraryEntry[];
    public selectedCategory: LibraryCategory;
    private searchFields = ['subject', 'riskDescription', 'causeDescription', 'effectDescription'];
    public searchValue = '';
    public searchError = false;
    public noItems = false;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; library: Library; projectItems: ProjectLibraryItem[] }>,
        private tableSearchService: FilterService
    ) {
        this.title = modalConfig.data.title;
        this.library = modalConfig.data.library;
        this.projectItems = modalConfig.data.projectItems;
        this.selectedItems = [];
        this.selectCategory(this.library.categories.length > 0 ? this.library.categories[0] : null);
    }

    public submit() {
        this.modalRef.close(this.selectedItems);
    }

    public selectCategory(category: LibraryCategory): void {
        this.selectedCategory = category;
        if (this.selectedCategory === null) {
            this.selectableItems = [];
        } else {
            this.selectableItems = this.selectedCategory.entries.filter((entry) => this.projectItems.find((item) => item.libraryItemId === entry.id) === undefined);
        }

        this.search(this.searchValue);
    }

    public isCategorySelected(category: LibraryCategory): boolean {
        return category === this.selectedCategory;
    }

    public isItemSelected(selectableItem: LibraryEntry): boolean {
        return this.selectedItems.find((item) => item.id === selectableItem.id) !== undefined;
    }

    public toggleItem(selectableItem: LibraryEntry): void {
        const index = this.selectedItems.indexOf(selectableItem, 0);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(selectableItem);
        }
    }

    public deselectAllItems() {
        this.selectedItems = [];
    }

    public search(value: string = '') {
        this.searchValue = value.trim();
        if (this.searchValue === '') {
            this.filteredSelectableItems = this.selectableItems;
            this.searchError = false;
            this.noItems = this.filteredSelectableItems.length === 0;
        } else {
            this.filteredSelectableItems = this.tableSearchService.searchTable<LibraryEntry>(this.selectableItems, this.searchFields, this.searchValue);
            this.noItems = false;
            this.searchError = this.filteredSelectableItems.length === 0;
        }
    }
}
