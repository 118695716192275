export interface Timer {
    start: () => void;
    tick: () => void;
    stop: () => void;
}

export class TimerUtil {

    public static runCallbackWhenTimerIsIdle(callback: () => void, idleTime = 1000): Timer {
        let timeoutId;
        let started = false;

        return {
            start: () => {
                started = true;
            },
            tick: () => {
                clearTimeout(timeoutId);

                if (started) {
                    timeoutId = setTimeout(callback, idleTime);
                }
            },
            stop: () => {
                started = false;
            }
        };
    }

}
