<ng-container *ngIf="appVersionLog.length > 0; else noVersionLog">
    <vwui-tooltip class="version-tooltip" position="top-end">
        {{ 'COMPONENT.version' | translate:{version: appVersion} }}
        <div slot="label" class="version-tooltip__content">
            <div class="version-tooltip__entry" *ngFor="let entry of appVersionLog">
                <small class="version-tooltip__date">{{ entry.date | date: ('DATE.short' | translate) }}</small>
                <div class="version-tooltip__subject">{{ entry.subject }}</div>
            </div>
        </div>
    </vwui-tooltip>
</ng-container>
<ng-template #noVersionLog>
    {{ 'COMPONENT.version' | translate:{version: appVersion} }}
</ng-template>
