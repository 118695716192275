import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-pdf-page',
    templateUrl: './pdf-page.component.html',
    styleUrls: ['./pdf-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageComponent {

    constructor() {
    }

}
