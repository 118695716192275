import {Component, Input} from '@angular/core';
import {IProgressBarStep} from '../../interfaces';
import {Router} from '@angular/router';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

    @Input() public progress: number;
    @Input() public steps: IProgressBarStep[] = [];

    constructor(private router: Router) {
    }

    public setStep(step) {
        this.router.navigate(step);
    }

}
