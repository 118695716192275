import {Component, Input} from '@angular/core';
import {IProject, ProjectDetails} from '../../models';

@Component({
    selector: 'app-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent {

    @Input() public project: IProject | ProjectDetails;

}
