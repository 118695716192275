<form *ngIf="formGroup" [formGroup]="formGroup">
    <vwui-form-group inputId="active">
        <vwui-checkbox inputId="active" formControlName="active">{{ 'COMPONENT.library_mgmt.item.active' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="subject"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.subject' | translate }}">
        <vwui-input inputId="subject" type="text" formControlName="subject"></vwui-input>
    </vwui-form-group>
    <vwui-form-group inputId="riskDescription"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.riskDescription' | translate }}"
                     required>
        <vwui-textarea inputId="riskDescription" formControlName="riskDescription"></vwui-textarea>
    </vwui-form-group>
    <vwui-form-group inputId="causeDescription"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.causeDescription' | translate }}"
                     required>
        <vwui-textarea inputId="causeDescription" formControlName="causeDescription"></vwui-textarea>
    </vwui-form-group>
    <vwui-form-group inputId="effectDescription"
                     label="{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectDescription' | translate }}"
                     required>
        <vwui-textarea inputId="effectDescription" formControlName="effectDescription"></vwui-textarea>
    </vwui-form-group>
</form>
