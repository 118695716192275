import {Component, Input} from '@angular/core';
import {FocusUtil} from '../../utils/focus.util';

@Component({
    selector: 'a11y-skip-link',
    templateUrl: './skip-link.component.html',
    styleUrls: ['./skip-link.component.scss']
})
export class SkipLinkComponent {

    @Input() public skipToElement = 'vwui-main';

    public focusSkipLink(): void {
        const element = document.querySelector(this.skipToElement);

        if (element) {
            const focusableElements = element.querySelectorAll('a, input, button, textarea, select, [tabindex]:not([tabindex="-1"])');

            if (focusableElements) {
                // Make sure to remove elements that have the tabindex set to -1 for regular interactive elements
                // Like a tags that we do not want to be focusable
                const firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable(focusableElement as HTMLElement));

                if (firstFocusableElement) {
                    (firstFocusableElement as HTMLElement).focus();
                }
            }
        }
    }

}
