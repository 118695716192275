import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {AccessibilityService, HotkeyMark, HotkeyType} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yHotkeyConfirm]'
})
export class HotkeyConfirmDirective implements AfterViewInit {

    constructor(private accessibilityService: AccessibilityService, private elementRef: ElementRef) {
    }

    // Confirm hotkey - Defaults to CTRL-ENTER
    @Output() hotkeyConfirm = new EventEmitter<string>();

    ngAfterViewInit() {
        setTimeout(() => {
            FocusUtil.applyHotkeyMarker(this.elementRef.nativeElement, HotkeyMark.hotkeyConfirm);
        }, 500); // TODO AC-109
    }

    @HostListener('keydown', ['$event']) onConfirm($event){
        if (this.accessibilityService.checkHotkey($event, HotkeyType.CONFIRM)) {
            $event.preventDefault();
            this.hotkeyConfirm.emit();
        }
    }
}
