import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastService, ProjectService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails} from '../../models';

@Component({
    selector: 'app-delete-project-modal',
    templateUrl: './delete-project-modal.component.html'
})
export class DeleteProjectModalComponent {

    public loading = false;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ projectDetails: ProjectDetails }>,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {
    }

    async submit() {

        this.loading = true;
        this.projectService.toArchived(this.modalConfig.data.projectDetails)
            .then((newStatus) => {
                const message = this.translateService.instant('DELETE_PROJECT.modal.success');
                this.toastService.openSuccess(message);
                this.modalRef.close(true);
            })
            .catch((error) => {
                console.error(error);
                this.toastService.openError(error, this.translateService.instant('ERRORS.project_to_archived_nok'));
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
