import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CompanyTypeService, ImageService, LoadingSpinnerService, ProjectService, ToastService, TranslationService, UserService} from '../../services';
import {TermsAndConditions} from '../../enums/terms-and-conditions/terms-and-conditions';
import {TranslateService} from '@ngx-translate/core';
import {ContractType} from '../../enums/contract-type/contract-type';
import {Collaboration} from '../../enums/collaboration/collaboration';
import {ProjectDetails} from '../../models';
import moment from 'moment';
import {FormatEuroCentsDirective} from '../../directives/format-euro-cents.directive';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ICompanyType} from '../../models/user/user';

@Component({
    selector: 'app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
    @Input()
    set projectDetails(val: ProjectDetails) {
        this.projectDetailsValue = val;
        this.updateDerivedFields();
        if (val) {
            this.setProjectDetails(val);
            this.checkCanEditOwner();
        }

        this.loadingSpinnerService.hide();
    }
    @Output() isFormValid = new EventEmitter<boolean>();
    public isProcessingThumbnail = false;

    public projectDetailsForm: FormGroup;
    private subscriptions: Subscription[] = [];

    public projectDetailsValue: ProjectDetails = null;

    public companyType: ICompanyType = null;
    public ownerChangeable = false;
    public riskManagerChangeable = false;
    public countries: { id: string; name: string }[];
    public companyNames?: string[];
    public isApiProject = false;
    public contractTypes: { key: string; value: string }[];
    public termsAndConditions: { key: string; value: string }[];
    public collaborations: { key: string; value: string }[] = [];

    public imageBase64: { image?: string; previewSrc?: string; file?: File};
    public contractType: string;
    public termsAndCondition: string;
    public collaboration: string;
    public constructionCosts: string;
    public profitContribution: string;
    private maxDescriptionLength = 1024;
    private maxContractTypeLength = 255;
    private maxTermsAndConditionsLength = 255;
    private maxClientLength = 255;
    private maxCollaborationCompaniesLength = 255;

    public costsForm: FormGroup;

    get durationInMonths(): string {
        return this.projectDetailsValue.projectInfo.durationInMonths ? '' + this.projectDetailsValue.projectInfo.durationInMonths : '';
    }
    set startDate(startDate: string) {
        this.projectDetailsValue.projectInfo.workStartsAt = moment(startDate).format('YYYY-MM-DD');
        this.projectDetailsValue.projectInfo.durationInMonths = this.getDiffInMonths(this.projectDetailsValue.projectInfo.workStartsAt, this.projectDetailsValue.projectInfo.workEndsAt);
    }
    get startDate(): string {
        return this.projectDetailsValue.projectInfo.workStartsAt;
    }
    set endDate(endDate: string) {
        this.projectDetailsValue.projectInfo.workEndsAt = moment(endDate).format('YYYY-MM-DD');
        this.projectDetailsValue.projectInfo.durationInMonths = this.getDiffInMonths(this.projectDetailsValue.projectInfo.workStartsAt, this.projectDetailsValue.projectInfo.workEndsAt);
    }
    get endDate(): string {
        return this.projectDetailsValue.projectInfo.workEndsAt;
    }

    constructor(
        private projectService: ProjectService,
        private companyTypeService: CompanyTypeService,
        private userService: UserService,
        private imageService: ImageService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private translationService: TranslationService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
        this.countries = translationService.getCountriesAsKeyValue();
    }

    ngOnInit(): void {
        if (!this.projectDetailsValue) {
            this.loadingSpinnerService.show();
        }

        this.checkCanEditOwner();

        const termsAndConditions = [];
        for (const key in TermsAndConditions) {
            if (TermsAndConditions.hasOwnProperty(key)) {
                const value: string = TermsAndConditions[key];
                termsAndConditions.push({
                    key: value,
                    value: this.translateService.instant(`PAGE_PROJECT_DETAILS.termsAndConditions.${value}`)
                });
            }
        }
        this.termsAndConditions = termsAndConditions;

        this.setCostsForm();
    }

    ngOnDestroy(): void {
        this.clearCostsForm();
    }

    private setCostsForm(): void {
        this.costsForm = new FormGroup({
            constructionCosts: new FormControl(this.constructionCosts),
            profitContribution: new FormControl(this.profitContribution)
        });

        this.subscriptions.push(this.costsForm.get('constructionCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            this.constructionCosts = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.costsForm.get('constructionCosts').setValue(this.constructionCosts, {emitEvent: false});

            this.updateMoneyValues();
        }));
        this.subscriptions.push(this.costsForm.get('profitContribution').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            this.profitContribution = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.costsForm.get('profitContribution').setValue(this.profitContribution, {emitEvent: false});

            this.updateMoneyValues();
        }));
    }

    private clearCostsForm() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
        this.costsForm.removeControl('constructionCosts');
        this.costsForm.removeControl('profitContribution');
        this.costsForm = undefined;
    }

    public isProjectOwner() {
        return this.projectService.isOwner(this.projectDetailsValue);
    }

    public canEditDetails() {
        return this.projectDetailsValue
            && !this.projectDetailsValue.isOnHold()
            && !this.projectDetailsValue.isInMandateProcess()
            && !this.projectDetailsValue.isClosed()
            && this.projectService.canEditProjectDetails(this.projectDetailsValue);
    }

    public checkCanEditOwner() {
        if (this.projectDetailsForm) {
            this.ownerChangeable = this.projectService.canManageCompany(this.projectDetailsValue) && !this.projectDetailsValue.isClosed();
            this.riskManagerChangeable = (this.ownerChangeable || this.projectService.isOwner(this.projectDetailsValue)) && !this.projectDetailsValue.isClosed();;
            if (this.ownerChangeable) {
                this.projectDetailsForm.get('newOwner').enable();
            } else {
                this.projectDetailsForm.get('newOwner').disable();
            }
            if (this.riskManagerChangeable) {
                this.projectDetailsForm.get('newRiskManager').enable();
            } else {
                this.projectDetailsForm.get('newRiskManager').disable();
            }
        }
    }

    public async changeImageBase64() {
        // RISC-428: disable the submission of the form when processing of the image
        this.isFormValid.emit(false);
        this.isProcessingThumbnail = true;

        if (this.imageBase64) {
            if (this.imageBase64.file) {
                const images = await this.imageService.processImage(this.imageBase64.file, 80, 80);
                this.projectDetailsValue.projectInfo.imageBase64 = images.image;
                this.projectDetailsValue.thumbnailBase64 = images.thumbnail;

                // If the file is >10MB in bytes (10.000.000) warn that upload will fail
                if (this.imageBase64.file.size >= 10000000) {
                    this.toastService.open({
                        type: 'error',
                        duration: 4500,
                        icon: 'exclamation',
                        code: '0',
                        message: this.translateService.instant('ERRORS.filesize_too_high'),
                        dismiss: true
                    });
                }
            } else if (this.imageBase64.previewSrc) {
                this.projectDetailsValue.projectInfo.imageBase64 = this.imageBase64.previewSrc;
            }
        } else {
            this.projectDetailsValue.projectInfo.imageBase64 = null;
            this.projectDetailsValue.thumbnailBase64 = null;
        }

        this.isProcessingThumbnail = false;
        this.isFormValid.emit(this.projectDetailsForm.valid);
    }

    public isContractTypeOther(): boolean {
        return this.getContractType() === ContractType.OTHER;
    }

    public getContractType() {
        if (this.projectDetailsValue) {
            if (this.projectDetailsValue.projectInfo.contractType === null) {
                return null;
            }
            const foundType = this.contractTypes.find((ct) => ct.key === this.projectDetailsValue.projectInfo.contractType);

            return foundType !== undefined ? foundType.key : ContractType.OTHER;
        }
        return undefined;
    }

    public getTermsAndConditions() {
        if (this.projectDetailsValue) {
            return this.getType(this.projectDetailsValue.projectInfo.termsAndConditions, TermsAndConditions, TermsAndConditions.OTHER);
        }
        return undefined;
    }

    public isTermsAndConditionsOther(): boolean {
        return this.getTermsAndConditions() === TermsAndConditions.OTHER;
    }

    public updateTermsAndConditions() {
        this.projectDetailsValue.projectInfo.termsAndConditions = this.termsAndCondition;

        if (this.getTermsAndConditions() === TermsAndConditions.OTHER && this.termsAndCondition === TermsAndConditions.OTHER) {
            this.projectDetailsValue.projectInfo.termsAndConditions = '';
        }
    }

    public getCollaboration() {
        if (this.projectDetailsValue) {
            if (this.projectDetailsValue.projectInfo.collaboration === null) {
                return null;
            }
            const foundType = this.collaborations.find((ct) => ct.key === this.projectDetailsValue.projectInfo.collaboration);

            return foundType !== undefined ? foundType.key : Collaboration.NOT_APPLICABLE;
        }
        return undefined;
    }

    public isCollaborationSpecified(): boolean {
        return this.getCollaboration() !== null && this.getCollaboration() !== Collaboration.NOT_APPLICABLE;
    }

    public updateCollaboration() {
        this.projectDetailsValue.projectInfo.collaboration = this.collaboration;
    }

    public updateContractType() {
        this.projectDetailsValue.projectInfo.contractType = this.contractType;

        if (this.getContractType() === ContractType.OTHER && this.contractType === ContractType.OTHER) {
            this.projectDetailsValue.projectInfo.contractType = '';
        }
    }

    public stringLimitReached(property: string) {
        const projectInfo = this.projectDetailsValue.projectInfo;

        const descriptionLimitReached = this.maxDescriptionLength - this.getStringLength(this.projectDetailsForm.controls.description.value) < 0;
        const termsAndConditionsLimitReached = this.maxTermsAndConditionsLength - this.getStringLength(this.projectDetailsValue.projectInfo.termsAndConditions) < 0;
        const contractTypeLimitReached = this.maxContractTypeLength - this.getStringLength(this.projectDetailsValue.projectInfo.contractType) < 0;
        const collaborationCompaniesLimitReached = this.maxCollaborationCompaniesLength - this.getStringLength(projectInfo.collaborationCompanies) < 0;
        const clientLimitReached = this.maxClientLength - this.getStringLength(projectInfo.client) < 0;

        this.isFormValid.emit(
            !(descriptionLimitReached || termsAndConditionsLimitReached || contractTypeLimitReached || collaborationCompaniesLimitReached || clientLimitReached)
                       && this.projectDetailsForm.valid);
        switch (property) {
            case 'description':
                return descriptionLimitReached;
            case 'termsAndConditionsOther':
                return termsAndConditionsLimitReached;
            case 'client':
                return clientLimitReached;
            case 'collaborationCompanies':
                return collaborationCompaniesLimitReached;
            case 'contractTypeOther':
                return contractTypeLimitReached;
        }
    }

    public stringLimitText(property: string) {
        let limit;
        let count;
        switch (property) {
            case 'description':
                limit = this.maxDescriptionLength;
                count = limit - this.getStringLength(this.projectDetailsForm.controls.description.value);
                break;
            case 'termsAndConditionsOther':
                limit = this.maxTermsAndConditionsLength;
                count = limit - this.getStringLength(this.projectDetailsValue.projectInfo.termsAndConditions);
                break;
            case 'client':
                limit = this.maxClientLength;
                count = limit - this.getStringLength(this.projectDetailsValue.projectInfo.client);
                break;
            case 'collaborationCompanies':
                limit = this.maxCollaborationCompaniesLength;
                count = limit - this.getStringLength(this.projectDetailsValue.projectInfo.collaborationCompanies);
                break;
            case 'contractTypeOther':
                limit = this.maxContractTypeLength;
                count = limit - this.getStringLength(this.projectDetailsValue.projectInfo.contractType);
                break;
        }

        return this.stringLimitReached(property) ?
            this.translateService.instant(`PAGE_PROJECT_DETAILS.form.description.charactersOverLimit`, {count: Math.abs(count), limit})
            : this.translateService.instant(`PAGE_PROJECT_DETAILS.form.description.charactersLeft`, {count, limit});
    }

    private getStringLength(value): number {
        return value ? value.length : 0;
    }

    private setProjectDetails(projectDetails: ProjectDetails) {
        const disabled = !this.canEditDetails();
        this.projectDetailsForm = new FormGroup({
            description: new FormControl({value: projectDetails.description ? projectDetails.description : '', disabled}, Validators.maxLength(this.maxDescriptionLength)),
            name: new FormControl({value: projectDetails.name || '', disabled}, [Validators.maxLength(255), Validators.required]),
            address: new FormControl({value: projectDetails.address || '', disabled}, [Validators.maxLength(255), Validators.required]),
            city: new FormControl({value: projectDetails.city || '', disabled}, [Validators.maxLength(255), Validators.required]),
            country: new FormControl({value: projectDetails.country || '', disabled}, [Validators.maxLength(255), Validators.required]),
            newOwner: new FormControl({value: projectDetails.newOwner || null, disabled: disabled || !this.ownerChangeable}, Validators.required),
            newRiskManager: new FormControl( {value: projectDetails.newRiskManager || null, disabled: disabled || !this.ownerChangeable}),
            company: new FormControl({value: projectDetails.company || '', disabled: disabled || !this.isProjectOwner() || this.isApiProject})
        });

        this.companyTypeService.getCompanyType(this.projectDetailsValue.companyTypeId).then((companyType) => {
            this.companyType = companyType;
        });

        this.subscriptions.push(
            this.projectDetailsForm.statusChanges.subscribe((value) => {
                if (!this.isProcessingThumbnail) {
                    this.isFormValid.emit(value === 'VALID');
                }
            }),
            this.projectDetailsForm.valueChanges
                .subscribe((values) => {
                    this.isFormValid.emit(this.projectDetailsForm.valid);
                    Object.keys(values).forEach((key) => {
                        if (this.projectDetailsForm.get(key).valid) { // Save everything that is valid
                            this.projectDetailsValue[key] = values[key];
                        }
                    });
                })
        );
    }

    private addProjectCompany(): void {
        if (this.projectDetailsValue) {
            if (!this.companyNames.includes(this.projectDetailsValue.company)) {
                this.companyNames.push(this.projectDetailsValue.company);
                this.isApiProject = true;
                this.projectDetailsForm.get('company').disable();
            }
        }
    }

    private getType(value: string, type, defaultType) {
        if (value === null) {
            return null;
        }
        for (const aType in type) {
            if (type[aType] === value) {
                return value;
            }
        }

        return defaultType;
    }

    private updateDerivedFields(): void {
        if (this.projectDetailsValue) {
            this.initializeContractTypes();
            this.initializeCollaborations();

            this.termsAndCondition = this.getTermsAndConditions();
            this.contractType = this.getContractType();
            this.collaboration = this.getCollaboration();

            this.profitContribution = FormatEuroCentsDirective.convertNumberToEuros(this.projectDetailsValue.projectInfo.profitContribution, 'nl');
            this.constructionCosts = FormatEuroCentsDirective.convertNumberToEuros(this.projectDetailsValue.projectInfo.constructionCosts, 'nl');
            this.setCostsForm();

            this.imageBase64 = this.projectDetailsValue.projectInfo.imageBase64 ?
                {previewSrc: this.projectDetailsValue.projectInfo.imageBase64, file: null} : null;

            this.projectDetailsValue.projectInfo.workStartsAt = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null;
            this.projectDetailsValue.projectInfo.workEndsAt = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null;
            this.projectDetailsValue.projectInfo.durationInMonths = this.getDiffInMonths(this.projectDetailsValue.projectInfo.workStartsAt, this.projectDetailsValue.projectInfo.workEndsAt);

            this.userService.getUserInfo().then((userInfo) => {
                this.companyNames = userInfo.companiesForManuallyAddingProjects
                    .filter((entry) => entry.companyType.id === this.projectDetailsValue.companyTypeId)
                    .map((entry) => entry.name);

                this.addProjectCompany();
            });
        }
    }

    private updateMoneyValues(): void {
        if (FormatEuroCentsDirective.convertToNumber(this.profitContribution) > 999000000000000 || FormatEuroCentsDirective.convertToNumber(this.constructionCosts) > 999000000000000) {
            this.toastService.open({
                type: 'error',
                duration: 1500,
                icon: 'exclamation',
                code: '0',
                message: this.translateService.instant('ERRORS.too_many_euros'),
                dismiss: true
            });
        } else {
            this.projectDetailsValue.projectInfo.profitContribution = this.profitContribution ? FormatEuroCentsDirective.convertToNumber(this.profitContribution) : null;
            this.projectDetailsValue.projectInfo.constructionCosts = this.constructionCosts ? FormatEuroCentsDirective.convertToNumber(this.constructionCosts) : null;
        }
    }

    private getDiffInMonths(startDate, endDate) {
        if (!startDate || !endDate) {
            return null;
        }

        startDate = moment(startDate);
        endDate = moment(endDate);

        // Minimum duration in Months will always be 1, this is only when start and end are filled in
        // Case: 16-3 start 19-3 end, didn't show any duration, made this to be 1
        return Math.abs(Math.round(endDate.diff(startDate, 'M', true))) || 1;
    }

    private getTranslationWithFallback(keyPrefix: string, value: string): string {
        const translationKey = `${keyPrefix}.${value}`.trim();
        const translation = this.translateService.instant(translationKey);

        return translation === translationKey ? value : translation;
    }

    private initializeContractTypes(): void {
        const contractTypes = this.projectDetailsValue.projectInfo.contractTypeLabels
            .map((label) => ({
                key: label,
                value: this.getTranslationWithFallback(`PAGE_PROJECT_DETAILS.contractType`, label)
            }));

        contractTypes.push({
            key: ContractType.OTHER,
            value: this.translateService.instant(`PAGE_PROJECT_DETAILS.contractType.other`)
        });

        this.contractTypes = contractTypes;
    }

    private initializeCollaborations(): void {
        const collaborations = [];
        for (const key in Collaboration) {
            if (Collaboration.hasOwnProperty(key)) {
                const value: string = Collaboration[key];
                collaborations.push({
                    key: value,
                    value: this.translateService.instant(`PAGE_PROJECT_DETAILS.collaboration.${value}`)
                });
            }
        }
        for (const additionalCollaboration of this.projectDetailsValue.projectInfo.additionalCollaborations) {
            collaborations.push({
                key: additionalCollaboration,
                value: additionalCollaboration
            });
        }

        if (this.getCollaboration() === Collaboration.NOT_APPLICABLE && this.projectDetailsValue.projectInfo.collaboration !== Collaboration.NOT_APPLICABLE) {
            // Collaboration in use for this project is not anymore in the configured collaborations. Add it manually
            collaborations.push({
                key: this.projectDetailsValue.projectInfo.collaboration,
                value: this.projectDetailsValue.projectInfo.collaboration
            });

        }
        this.collaborations = collaborations;
    }

}
