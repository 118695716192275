<app-library-item-quantification-grid
    *ngIf="emptyQuantificationItem && forPdf"
    [overruleCanEdit]="true"
    [entry]="emptyQuantificationItem"
    [projectDetails]="projectDetails"
    class="pdf-page__risks-opportunities-quantification-grid disable-interaction"
></app-library-item-quantification-grid>

<div class="grid-spacer grid-spacer--large"></div>

<table class="pdf-page__risks-opportunities-table">
    <colgroup>
        <col *ngIf="!forPdf" class="pdf-page__risks-opportunities-table-col-checkbox">
        <col class="pdf-page__risks-opportunities-table-col-number">
        <col class="pdf-page__risks-opportunities-table-col-category">
        <col class="pdf-page__risks-opportunities-table-col-description">
        <col class="pdf-page__risks-opportunities-table-col-number">
        <col class="pdf-page__risks-opportunities-table-col-number">
        <col *ngFor="let quantification of quantificationCategoriesRisks" class="pdf-page__risks-opportunities-table-col-number">
        <col class="pdf-page__risks-opportunities-table-col-status">
        <col class="pdf-page__risks-opportunities-table-col-remarks">
        <col class="pdf-page__risks-opportunities-table-col-remarks">
    </colgroup>
    <thead>
    <tr>
        <th *ngIf="!forPdf" class="pdf-page__risks-opportunities-table-col-highlight col-checkbox" rowspan="2">
            <vwui-checkbox [disabled]="allItemsDisabled" [checked]="allItemsSelected" (vwuiChecked)="selectAllItems($event)"></vwui-checkbox>
        </th>
        <th class="pdf-page__risks-opportunities-table-col-highlight vertical-label" rowspan="2">
            <span
                class="pdf-page__risks-opportunities-table-vertical-label">{{ 'PAGE_RISK_RAINBOW.tables.columns.number' | translate }}
            </span>
        </th>
        <th class="pdf-page__risks-opportunities-table-col-highlight"
            colspan="2">{{ 'PAGE_RISK_RAINBOW.tables.columns.description' | translate }}</th>
        <th class="pdf-page__risks-opportunities-table-col-highlight"
            [attr.colspan]="quantificationCategoriesRisks?.length + 2">{{ 'PAGE_RISK_RAINBOW.tables.columns.quantification' | translate }}</th>
        <th class="pdf-page__risks-opportunities-table-col-highlight"
            colspan="2">{{ 'PAGE_RISK_RAINBOW.tables.columns.counter_measures' | translate }}</th>
        <th class="pdf-page__risks-opportunities-table-col-highlight"
            rowspan="2">{{ 'PAGE_RISK_RAINBOW.tables.columns.remarks' | translate }}</th>
    </tr>
    <tr>
        <th>
            <div>{{ 'PAGE_RISK_RAINBOW.tables.columns.category' | translate }}</div>
            <div
                class="pdf-page__risks-opportunities-table-text">{{ 'PAGE_RISK_RAINBOW.tables.columns.subject' | translate }}</div>
        </th>
        <th>
            <div class="table-legends">
                <div class="table-legends__item">
                    <vwui-icon class="table-legends__icon" iconName="owner"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.owner' | translate }}
                </div>
            </div>
        </th>

        <th class="pdf-page__risks-opportunities-table-col-highlight vertical-label">
                <span
                    class="pdf-page__risks-opportunities-table-vertical-label"> {{ 'PAGE_RISK_RAINBOW.tables.columns.probability' | translate }} </span>
        </th>

        <th *ngFor="let quantification of quantificationCategoriesRisks"
            class="pdf-page__risks-opportunities-table-col-highlight vertical-label">
            <span class="pdf-page__risks-opportunities-table-vertical-label">{{ quantification.label }} </span>
        </th>

        <th class="pdf-page__risks-opportunities-table-col-highlight vertical-label sortable-label" a11yKeyboardClickable (click)="toggleSort('score')" [ngClass]="{'sortable-label--active': sortFields['score'] !== 0}">
            <vwui-icon class="pdf-icon-size sortable-label__icon" [iconName]="sortFields['score'] === 1 ? 'sort-numeric-down' : 'sort-numeric-up'"></vwui-icon>
            <span
                class="pdf-page__risks-opportunities-table-vertical-label"> {{ 'PAGE_RISK_RAINBOW.tables.columns.score' | translate }} </span>
        </th>

        <th class="sortable-label" a11yKeyboardClickable (click)="toggleSort('bestGuess')" [ngClass]="{'sortable-label--active': sortFields['bestGuess'] !== 0}">
            <div class="sortable-label__icon">
                <span class="sortable-label__text">{{ 'PAGE_RISK_RAINBOW.tables.columns.best_guess' | translate }}</span>
                <vwui-icon class="pdf-icon-size" [iconName]="sortFields['bestGuess'] === 1 ? 'sort-numeric-down' : 'sort-numeric-up'"></vwui-icon>
            </div>
            <div class="table-legends">
                <div
                    class="table-legends__item table-legends__item--no-margin">{{ 'PAGE_RISK_RAINBOW.tables.columns.status' | translate }}</div>
                <div class="table-legends__item">
                    <vwui-icon class="table-legends__icon pdf-icon-size" iconName="min-costs"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.min_costs' | translate }}
                </div>
                <div class="table-legends__item">
                    <vwui-icon class="table-legends__icon pdf-icon-size" iconName="best-guess"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.best_guess' | translate }}
                </div>
                <div class="table-legends__item">
                    <vwui-icon class="table-legends__icon pdf-icon-size" iconName="max-costs"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.max_costs' | translate }}
                </div>
                <div *ngIf="!clientOnly" class="table-legends__item">
                    <vwui-icon class="table-legends__icon pdf-icon-size" iconName="in-per"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.in_per' | translate }}
                </div>
            </div>
        </th>
        <th>
            <div class="table-legends">
                <div class="table-legends__item">
                    <i class="table-legends__icon far fa-clock"></i>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.deadline' | translate }}
                </div>
                <div class="table-legends__item">
                    <i class="table-legends__icon fas fa-euro-sign"></i>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.counter_measures_costs' | translate }}
                </div>
                <div class="table-legends__item">
                    <vwui-icon class="table-legends__icon" iconName="action-holder"></vwui-icon>
                    = {{ 'PAGE_RISK_RAINBOW.tables.columns.action_holder' | translate }}
                </div>
            </div>
        </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let projectLibraryCategory of projectLibraryCategories">
        <tr>
            <td [attr.colspan]="columnAmount"
                class="pdf-page__risks-opportunities-table-title">{{ projectLibraryCategory.title }}</td>
        </tr>
        <tr *ngFor="let projectLibraryCategoryItem of projectLibraryCategory.items">
            <td class="col-checkbox" *ngIf="!forPdf">
                <ng-container
                    *ngIf="filterControls.controls.selectedItems.get('item-' + projectLibraryCategoryItem.id) as selectedItemFormGroup; else selectedItemSkeleton"
                    [formGroup]="selectedItemFormGroup"
                >
                    <vwui-checkbox formControlName="selected"></vwui-checkbox>
                </ng-container>
                <ng-template #selectedItemSkeleton>
                    <vwui-checkbox [disabled]="true"></vwui-checkbox>
                </ng-template>
            </td>
            <td class="center-number">{{ projectLibraryCategoryItem.getEntryNumber(projectLibraryCategory.items) }}</td>

            <td>
                <div
                    class="pdf-page__risks-opportunities-table-title">{{ projectLibraryCategoryItem.libraryCategoryName }}</div>
                <div
                    class="pdf-page__risks-opportunities-table-text">{{ projectLibraryCategoryItem.getSubject() }}</div>
            </td>
            <td (click)="openModal(projectLibraryCategoryItem, 'description')"
                a11yKeyboardClickable
                tabindex="0" class="clickable_cell">
                <div [innerHTML]="projectLibraryCategoryItem.riskDescription | escapeHTML | nl2br"></div>
                <div class="pdf-page__risks-opportunities-table-text"
                     *ngIf="projectLibraryCategoryItem.causeDescription | trim">
                    <div class="pdf-page__risks-opportunities-table-label"
                         [title]="'PAGE_RISK_RAINBOW.tables.columns.cause' | translate"
                         [innerHTML]="projectLibraryCategoryItem.causeDescription | escapeHTML | nl2br"></div>
                </div>
                <div class="pdf-page__risks-opportunities-table-text"
                     *ngIf="projectLibraryCategoryItem.effectDescription | trim">
                    <div class="pdf-page__risks-opportunities-table-label"
                         [title]="'PAGE_RISK_RAINBOW.tables.columns.effect' | translate"
                         [innerHTML]="projectLibraryCategoryItem.effectDescription | escapeHTML | nl2br"></div>
                </div>
                <div class="table-legends-vertical">
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.itemOwner">
                        <vwui-icon class="table-legends-vertical__icon" iconName="owner"></vwui-icon>
                        {{ projectLibraryCategoryItem.itemOwner }}
                    </div>
                </div>
            </td>

            <td class="center-number clickable_cell" tabindex="0" (click)="openModal(projectLibraryCategoryItem, 'quantification')"
                a11yKeyboardClickable
                [ngClass]="{'pdf-page__risks-opportunities-table-col-highlight': projectLibraryCategoryItem.quantification?.probability}">{{ projectLibraryCategoryItem.quantification ? projectLibraryCategoryItem.quantification.probability : ''| translate }}</td>
            <ng-container *ngFor="let quantification of quantificationCategoriesRisks">
                <td class="center-number clickable_cell"
                    (click)="openModal(projectLibraryCategoryItem, 'quantification')"
                    [a11yKeyboardClickable]="projectLibraryCategoryItem[quantification.effectCategory]"
                    [ngClass]="{'pdf-page__risks-opportunities-table-col-highlight': projectLibraryCategoryItem[quantification.effectCategory]}">{{ projectLibraryCategoryItem.quantification ? projectLibraryCategoryItem.quantification[quantification.fieldName] : '' | translate}}</td>
            </ng-container>

            <td class="center-number clickable_cell"
                (click)="openModal(projectLibraryCategoryItem, 'quantification')"
                a11yKeyboardClickable
                >{{ projectLibraryCategoryItem.getScore() || '?' }}</td>

            <td class="clickable_cell"
                (click)="openModal(projectLibraryCategoryItem, 'riskMitigation')"
                a11yKeyboardClickable>
                <div class="table-legends-vertical">
                    <div class="table-legends-vertical__item">
                        {{ ('PAGE_PROJECT_LIBRARY_ITEMS.library_type.' + projectLibraryCategoryItem.libraryType + '.status.' + projectLibraryCategoryItem.getStatus()) | translate }}
                    </div>
                    <br/>
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.minCosts">
                        <vwui-icon class="pdf-icon-size" iconName="min-costs"></vwui-icon>
                        {{ projectLibraryCategoryItem.minCosts | priceFormat }}
                    </div>
                    <br/>
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.bestGuessCosts">
                        <vwui-icon class="pdf-icon-size" iconName="best-guess"></vwui-icon>
                        {{ projectLibraryCategoryItem.bestGuessCosts | priceFormat }}
                    </div>
                    <br/>
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.maxCosts">
                        <vwui-icon class="pdf-icon-size" iconName="max-costs"></vwui-icon>
                        {{ projectLibraryCategoryItem.maxCosts | priceFormat }}
                    </div>
                    <br/>
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.perCosts && !clientOnly">
                        <vwui-icon class="pdf-icon-size" iconName="in-per"></vwui-icon>
                        {{ projectLibraryCategoryItem.perCosts | priceFormat }}
                    </div>
                </div>
            </td>
            <td class="clickable_cell"
                tabindex="0" (click)="openModal(projectLibraryCategoryItem, 'riskMitigation')"
                a11yKeyboardClickable>
                >
                <div>{{ projectLibraryCategoryItem.counterMeasures }}</div>
                <div class="table-legends-vertical">
                    <div class="table-legends-vertical__item"
                         *ngIf="projectLibraryCategoryItem.counterMeasuresDeadline"><i
                        class="table-legends-vertical__icon far fa-clock"></i> {{ projectLibraryCategoryItem.counterMeasuresDeadline | date: ('DATE.short' | translate) }}
                    </div>
                    <div class="table-legends-vertical__item"
                         *ngIf="projectLibraryCategoryItem.counterMeasuresCosts">{{ projectLibraryCategoryItem.counterMeasuresCosts | priceFormat }}</div>
                    <div class="table-legends-vertical__item" *ngIf="projectLibraryCategoryItem.counterMeasuresActionHolder">
                        <vwui-icon class="table-legends-vertical__icon" iconName="action-holder"></vwui-icon>
                        {{ projectLibraryCategoryItem.counterMeasuresActionHolder }}
                    </div>
                </div>
            </td>

            <td class="clickable_cell"
                tabindex="0" (click)="openModal(projectLibraryCategoryItem, 'remarks')" a11yKeyboardClickable>

                <div class="pdf-page__risks-opportunities-table-text"
                     *ngFor="let remark of projectLibraryCategoryItem.getRemarks(clientOnly); let i = index">
                    <div *ngIf="i < maxRemarksVisible"
                         class="pdf-page__risks-opportunities-table-label pdf-page__risks-opportunities-table-label--wide"
                         [title]="remark.createdAt| date: ('DATE.short' | translate)"
                         [innerHTML]="remark.remark | escapeHTML | nl2br"></div>
                </div>
                <div *ngIf="projectLibraryCategoryItem?.remarks.length > maxRemarksVisible"
                     class="pdf-page__risks-opportunities-table-text">
                    <vwui-icon iconName="info"></vwui-icon>
                    <span [innerHTML]="'PAGE_RISK_RAINBOW.tables.note.more_remarks' | translate:{max: maxRemarksVisible, count: projectLibraryCategoryItem.remarks.length} | safeHTML"></span>
                </div>
            </td>
        </tr>
        <tr>
            <td [attr.colspan]="columnAmount" *ngIf="projectLibraryCategory.items.length === 0 && filtersValue">{{ 'ERRORS.no_items_found_for_type'|translate }}</td>
            <td [attr.colspan]="columnAmount" *ngIf="projectLibraryCategory.items.length === 0 && !filtersValue">{{ 'ERRORS.no_items_added_for_type'|translate }}</td>
        </tr>
    </ng-container>
    </tbody>
    <tfoot>
    <tr>
        <td [attr.colspan]="columnAmount">&nbsp;</td>
    </tr>
    </tfoot>
</table>

<ng-container *ngIf="emptyQuantificationItem && !forPdf">
    <div class="grid-spacer grid-spacer--large"></div>

    <app-library-item-quantification-grid
        [overruleCanEdit]="true"
        [entry]="emptyQuantificationItem"
        [projectDetails]="projectDetails"
        class="pdf-page__risks-opportunities-quantification-grid disable-interaction"
    ></app-library-item-quantification-grid>

</ng-container>
