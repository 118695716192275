import {Component, OnDestroy, OnInit} from '@angular/core';
import {Category, ICategoryData, IQuestionData, IQuestionType, LibraryEntry, QuickscanDefinition} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CompanyTypeService,
    LibraryService,
    LoadingSpinnerService,
    QuickscanDefinitionService,
    ToastService
} from '../../services';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {
    DeleteItemModalComponent,
    QuickscanCreateUpdateCategoryModalComponent,
    QuickscanCreateUpdateQuestionModalComponent
} from '../../components';
import {Subscription} from 'rxjs';
import {Question} from '../../models/definition/question';
import {TranslateService} from '@ngx-translate/core';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-page-quickscan',
    templateUrl: './page-quickscan.component.html',
    styleUrls: ['./page-quickscan.component.scss']
})
export class PageQuickscanComponent implements OnInit, OnDestroy {

    public quickscanId: number;
    public quickscanDefinition: QuickscanDefinition;
    public quickscanCategories: Category[];
    public isUpdatingQuickscan = false;
    public subscriptions: Subscription[] = [];
    private companyTypeId: number;
    private allRiskEntries: LibraryEntry[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService,
        private companyTypeService: CompanyTypeService,
        private libraryService: LibraryService
    ) {
    }

    ngOnInit() {
        this.setData();
        this.getQuickscan();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    public updateQuickscan(): void {
        const quickscanDefinition = this.quickscanDefinition.cloneData();
        this.isUpdatingQuickscan = true;
        this.quickscanDefinitionService.updateQuickscanDefinition(this.companyTypeId, quickscanDefinition)
            .then(() => {
                this.toastService.openSuccess(this.translateService.instant('PAGE_QUICKSCAN_EDIT.updated'));
            })
            .finally(() => {
                this.isUpdatingQuickscan = false;
            });
    }

    public updateCategoryTables(): void {
        this.quickscanCategories = this.quickscanDefinition.getCategories();
    }

    public openCreateCategoryModal(): void {
        const modal = this.modalService.open(
            QuickscanCreateUpdateCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.quickscan.modal.create_category.title',
                    data: {
                        name: ''
                    },
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed.subscribe((data: ICategoryData) => {
            if (data) {
                this.quickscanDefinition.addCategory(data);
            }
        });
    }

    public openUpdateCategoryModal(category: Category): void {
        const modal = this.modalService.open(
            QuickscanCreateUpdateCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.quickscan.modal.update_category.title',
                    data: category.cloneData(),
                    saveText: 'BUTTON.update'
                }
            }
        );

        modal.afterClosed.subscribe((data: ICategoryData) => {
            if (data) {
                category.setData(data);
            }
        });
    }

    public openCreateQuestionModal(theme: Category): void {
        const subCategories = this.quickscanDefinition.getSubCategoryNames();
        const modal = this.modalService.open(
            QuickscanCreateUpdateQuestionModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    title: 'COMPONENT.quickscan.modal.create_question.title',
                    allRiskEntries: this.allRiskEntries,
                    companyTypeId: this.companyTypeId,
                    subCategories,
                    data: {
                        name: '',
                        possibleAnswers: [],
                        type: IQuestionType.MULTIPLECHOICE
                    },
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed.subscribe((data: IQuestionData) => {
            if (data) {
                theme.addSubCategoryQuestion(data);
            }
        });
    }

    public openUpdateQuestionModal(question: Question): void {
        const subCategories = this.quickscanDefinition.getSubCategoryNames();
        const modal = this.modalService.open(
            QuickscanCreateUpdateQuestionModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    title: 'COMPONENT.quickscan.modal.update_question.title',
                    allRiskEntries: this.allRiskEntries,
                    subCategories,
                    data: {...question.cloneData(), categoryName: question.getCategoryName()},
                    saveText: 'BUTTON.update'
                }
            }
        );

        modal.afterClosed.subscribe((data: IQuestionData) => {
            if (data) {
                question.setData(data);
            }
        });
    }

    public navigateToQuickscanList(): void {
        this.router.navigate(['/beheer', 'quickscan']);
    }

    public openDeleteCategoryModal(category: Category): void {
        const modal = this.modalService.open(
            DeleteItemModalComponent,
            {
                data: {
                    title: 'COMPONENT.quickscan.modal.delete_category.title',
                    description: 'COMPONENT.quickscan.modal.delete_category.description'
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                category.delete();
            }
        });
    }

    public openDeleteQuestionModal(question: Question): void {
        const modal = this.modalService.open(
            DeleteItemModalComponent,
            {
                data: {
                    title: 'COMPONENT.quickscan.modal.delete_question.title',
                    description: 'COMPONENT.quickscan.modal.delete_question.description'
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                question.delete();
            }
        });
    }

    public moveCategoryUp(category: Category): void {
        category.moveUp();
    }

    public moveCategoryDown(category: Category): void {
        category.moveDown();
    }

    public moveQuestionUp(question: Question, base: Category): void {
        base.moveQuestionUp(question);
    }

    public moveQuestionDown(question: Question, base: Category): void {
        base.moveQuestionDown(question);
    }

    public redflagChanged(value: number): void {
        if (this.validatePercentage(value, 'PAGE_QUICKSCAN_EDIT.error.field.redflagTreshold')) {
            this.quickscanDefinition.redFlagTresholdCategory = value;
        }
    }

    public getMaxScoreForQuestion(question: Question) {
        return question.possibleAnswers
            .map(possibleAnswer => possibleAnswer.score)
            .reduce((a, b) => Math.max(a, b), 0);
    }

    public trackQuickscanCategory(index: number, category: Category): string {
        return `${category.id}`;
    }

    public trackQuickscanSubcategoryQuestions(index: number, question: Question): string {
        return `${question.getParent().id}_${question.id}`;
    }

    private setData(): void {
        this.companyTypeId = parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10);
        this.quickscanId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        this.companyTypeService.setActiveCompanyTypeId(this.companyTypeId);
        this.libraryService.getLibrary(this.companyTypeId, LibraryType.RISKS)
            .then((riskLibrary) => {
                this.allRiskEntries = riskLibrary.getLibraryEntries();
            });
    }

    private getQuickscan(): void {
        this.loadingSpinnerService.show();
        this.quickscanDefinitionService.getQuickscanDefinition(this.companyTypeId, this.quickscanId, true)
            .then((quickscanDefinition) => {
                this.quickscanDefinition = quickscanDefinition;
                this.subscriptions.push(this.quickscanDefinition.onChange.subscribe(() => {
                    this.updateCategoryTables();
                }));
                this.updateCategoryTables();
            }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }

    private validatePercentage(percentage: number, errorString: string): boolean {
        if (percentage) {
            if (percentage < 0 || percentage > 100) {
                this.toastService.openError('400', this.translateService.instant(errorString));
                return false;
            }
        }
        return true;
    }
}
