import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IChartSignalMatrixConfig, IChartSignalMatrixItem, IChartSignalMatrixLabel} from './chart-signal-matrix.data';
import {ObjectUtil} from '../../utils';

@Component({
    selector: 'app-chart-signal-matrix',
    templateUrl: './chart-signal-matrix.component.html',
    styleUrls: ['./chart-signal-matrix.component.scss']
})
export class ChartSignalMatrixComponent implements OnInit {

    @Input()
    set config(val: IChartSignalMatrixConfig) {
        this.configuration = ObjectUtil.cloneObject(val);
        this.setData();
    }

    @Input()
    public large = false;

    @Input()
    public signalReversed = false;

    @Output() itemClicked = new EventEmitter<IChartSignalMatrixItem>();

    public configuration: IChartSignalMatrixConfig;
    public labelsHorizontal: IChartSignalMatrixLabel[];
    public labelsVertical: IChartSignalMatrixLabel[];
    public targetPositionHorizontal: number;
    public targetPositionVertical: number;

    constructor() {
    }

    ngOnInit() {
        this.setData();
    }

    private setData() {
        this.labelsHorizontal = this.setLabels(this.configuration.horizontal.labels, false);
        this.labelsVertical = this.setLabels(this.configuration.vertical.labels, true);
        this.targetPositionHorizontal = this.configuration.targetValue ? this.configuration.targetValue.horizontal : null;
        this.targetPositionVertical = this.configuration.targetValue ? this.configuration.targetValue.vertical : null;
        this.clusterValues();
    }

    private clusterValues() {
        if (this.configuration.values) {
            this.configuration.values.forEach((currentValue) => {
                const valuesToCluster = this.configuration.values.filter((value) => currentValue.horizontal === value.horizontal && currentValue.vertical === value.vertical);

                if (valuesToCluster.length > 1) {
                    const degreesBasedOnNrItems = 360 / valuesToCluster.length;
                    const circumference = valuesToCluster.length * 18;
                    const radius = circumference / (Math.PI * 2);
                    const radiants = (degreesBasedOnNrItems * (valuesToCluster.indexOf(currentValue) + 1)) * (Math.PI / 180);

                    if (!currentValue.offset) {
                        currentValue.offset = {
                            x: radius * Math.round(Math.cos(radiants) * 100) / 100,
                            y: radius * Math.round(Math.sin(radiants) * 100) / 100
                        };
                    }
                }
            });
        }
    }

    public getHorizontalPositionValue(value: number): number {
        return this.getPositionValue(value, this.configuration.horizontal.labels, false);
    }

    public getVerticalPositionValue(value: number): number {
        return this.getPositionValue(value, this.configuration.vertical.labels, true);
    }

    private getPositionValue(value: number, labels: number[], flip: boolean): number {
        const minValue = Math.min(...labels);
        const maxValue = Math.max(...labels);
        const diff = Math.abs(maxValue - minValue);
        const position = (((value - minValue) / diff) * 100);

        return flip ? 100 - position : position;
    }

    private setLabels(labels: number[], flip: boolean = false): IChartSignalMatrixLabel[] {
        const matrixLabels = [];

        labels.forEach((label) => {
            matrixLabels.push({
                label,
                position: this.getPositionValue(label, labels, flip)
            });
        });

        return matrixLabels;
    }

    public valueClicked(item: IChartSignalMatrixItem) {
        this.itemClicked.emit(item);
    }

}
