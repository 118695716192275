<vwui-spinner class="vwui-spinner--with-margin" *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div class="main-wrapper">
    <app-explanation [link]="getUriForStep(1)" *ngIf="!mandateDetails?.preconditions?.constructionCostsNotSpecified.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.construction_costs_not_specified' | translate : { constructionCostsLabel: projectDetails.projectInfo.constructionCostsLabel } }}</app-explanation>
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotFilledIn.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.quickscan_not_filled' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotSubmitted.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.project_not_classified' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(7)" *ngIf="!mandateDetails?.preconditions?.tisQuickscanNotSubmitted.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.tis_not_classified' | translate : { tisLabelShort : projectDetails.projectInfo.tisLabelShort } }}</app-explanation>
    <app-explanation [link]="getUriForStep(7)" *ngIf="!mandateDetails?.preconditions?.tisQuickscanOutdated.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.tis_quickscan_outdated' | translate : { tisLabelShort : projectDetails.projectInfo.tisLabelShort } }}</app-explanation>
    <app-explanation [link]="getUriForStep(3)" *ngIf="!mandateDetails?.preconditions?.nrOfRisksNotCompliant.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.nr_risks_not_sufficient' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(4)" *ngIf="!mandateDetails?.preconditions?.nrOfOpportunitiesNotCompliant.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.nr_opportunities_not_sufficient' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(8)" *ngIf="!mandateDetails?.preconditions?.tisNotFilledIn.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.tis_not_filled' | translate }}</app-explanation>

    <div class="start-mandating" *ngIf="showStartMandatingButton()">
        <vwui-button
            class="is-primary btn-start"
            [appDisabledReason]="startMandateDisabledReason"
            [loading]="isSaving"
            (click)="mandateProject()"
        >{{ 'COMPONENT.mandating.start_mandating' | translate }}</vwui-button>
    </div>

    <div *ngIf="showStartMandatingButton()">
        <app-mandating-flow-info-card><div class="mce-content-body" [innerHTML]="companyMandatingText"></div></app-mandating-flow-info-card>
    </div>

    <div class="restart-mandating" *ngIf="showRestartMandatingButton()">
        <app-explanation [link]="getUriForStep(1)">
            {{ 'COMPONENT.mandating.failed' | translate : { tisLabelShort: projectDetails.projectInfo.tisLabelShort } }}
            <ng-container actions>
                <vwui-button
                    class="is-primary btn-start"
                    [appDisabledReason]="restartMandateDisabledReason()"
                    [loading]="isSaving"
                    (click)="restartMandateProject()"
                >
                    {{ 'COMPONENT.mandating.restart_mandating' | translate }}
                </vwui-button>
            </ng-container>
        </app-explanation>
    </div>

    <div class="to-execution" *ngIf="showInExecutionButton()">
        <vwui-button class="is-primary btn-start" [appDisabledReason]="inExecutionDisabledReason()" [loading]="isSaving" (click)="setProjectInExecution()">
            {{ 'COMPONENT.mandating.to_execution' | translate }}
        </vwui-button>
        <vwui-button class="has-warning btn-start" [appDisabledReason]="disabledForTendermanagers()" [loading]="isSavingForArchived" (click)="setProjectToArchived()">
            {{ 'COMPONENT.mandating.to_archived' | translate }}
        </vwui-button>
    </div>

    <div class="mandate-status" *ngIf="showMandatingStatus()">
        <vwui-button class="is-outline" [ngClass]="{'has-warning': projectDetails.status === 'mandating_failed'}">
            {{ getStartDateMandate() | date: ('DATE.short' | translate) }} {{ 'COMPONENT.mandating.mandate_start' | translate }}
        </vwui-button>
        <vwui-tooltip *ngIf="companyMandatingText" class="mandating-info-icon" position="bottom">
            <div class="mandating-info-icon--list-padding mce-content-body mandate-info" slot="label" [innerHTML]="companyMandatingText"></div>
            <vwui-btn-icon icon="info"></vwui-btn-icon>
        </vwui-tooltip>
        <div class="mandate-steps">
            <ng-container *ngFor="let mandateStep of mandateDetails?.mandateSteps">
                <div class="mandate-step">
                    <div class="mandate-step__content">
                        <app-item-status [status]="mandateStep.stepResult">
                            <div class="item-user"><vwui-tooltip [label]="mandateStep.actionholderName"><vwui-icon iconName="vwui-user"></vwui-icon></vwui-tooltip></div>
                            <div class="item-title">{{ mandateStep.stepTitle }}</div>
                            <div class="item-date">{{ mandateStep.stepResultSubmittedAt | date: ('DATE.long' | translate) }}</div>
                            <ng-container *ngIf="mandateStep.remark">
                                <div class="item-remark">{{ mandateStep.remark }}</div>
                            </ng-container>
                        </app-item-status>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
