import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {
    PageCompaniesComponent,
    PageCompanyTypesComponent,
    PageErrorComponent,
    PageLogoutComponent,
    PageNoAccessComponent,
    PageOpportunitiesLibraryComponent,
    PageTasksLibraryComponent,
    PagePdfLandscapeComponent,
    PagePdfPortraitComponent,
    PageProjectsComponent,
    PageProjectStepsComponent,
    PageQuickscanComponent,
    PageQuickscansComponent,
    PageRisksLibraryComponent,
    PageTenderManagersComponent,
    PageTisComponent,
    PageTisversionComponent,
    PageNotificationsComponent,
    PageUserManagementComponent,
    PageAccessibleStepManagementComponent
} from '../pages';

import {
    ProjectStepGuard,
    UserGuard,
    UserRoleGuard,
    UserRedirectGuard
} from '../guards';
import {UserRoles} from '../enums';
import {MsAuthGuard} from '../module/ms-auth';

const routes: Routes = [{
    path: '',
    component: PageNoAccessComponent,
    pathMatch: 'full',
    canActivate: [MsAuthGuard, UserGuard, UserRedirectGuard],
    data: {
        userRedirects: [{
            roles: [
                UserRoles.TENDER_MANAGER,
                UserRoles.COMPANY_PROJECT_EDITOR,
                UserRoles.COMPANY_PROJECT_VIEWER,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PROJECT_VIEWER,
                UserRoles.PROJECT_EDITOR,
                UserRoles.RISK_MANAGER
            ],
            url: '/projecten'
        }, {
            roles: [],
            url: '/no-access'
        }]
    }
}, {
    path: 'no-access',
    component: PageNoAccessComponent,
    data: {
        menuVisible: true
    }
}, {
    path: 'error',
    canActivate: [UserRedirectGuard],
    data: {
        userRedirects: [{
            roles: [
                UserRoles.TENDER_MANAGER,
                UserRoles.COMPANY_PROJECT_EDITOR,
                UserRoles.COMPANY_PROJECT_VIEWER,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PROJECT_VIEWER,
                UserRoles.PROJECT_EDITOR,
                UserRoles.RISK_MANAGER
            ],
            url: '/'
        }],
        menuVisible: true
    },
    component: PageErrorComponent
}, {
    path: 'projecten',
    component: PageProjectsComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [
            UserRoles.TENDER_MANAGER,
            UserRoles.COMPANY_PROJECT_VIEWER,
            UserRoles.COMPANY_PROJECT_EDITOR,
            UserRoles.COMPANY_ADMIN,
            UserRoles.PROJECT_VIEWER,
            UserRoles.PROJECT_EDITOR,
            UserRoles.RISK_MANAGER
        ]
    }
}, {
    path: 'beheer/quickscan/:companyTypeId',
    component: PageQuickscansComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/quickscan/:companyTypeId/:id',
    component: PageQuickscanComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/tis/:companyTypeId',
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    component: PageTisComponent,
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/tis/:companyTypeId/:id',
    component: PageTisversionComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/risicos/:companyTypeId',
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    component: PageRisksLibraryComponent,
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/kansen/:companyTypeId',
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    component: PageOpportunitiesLibraryComponent,
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/taken/:companyTypeId',
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    component: PageTasksLibraryComponent,
    data: {
        menuVisible: true,
        roles: [UserRoles.RISK_MANAGER]
    }
}, {
    path: 'beheer/tendermanagers',
    component: PageTenderManagersComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [
            UserRoles.ADMIN,
            UserRoles.RISK_MANAGER,
            UserRoles.COMPANY_ADMIN
        ]
    }
}, {
    path: 'beheer/gebruikersbeheer',
    component: PageUserManagementComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [
            UserRoles.ADMIN,
            UserRoles.RISK_MANAGER
        ]
    }
}, {
    path: 'beheer/stappen',
    component: PageAccessibleStepManagementComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [
            UserRoles.ADMIN
        ]
    }
}, {
    path: 'beheer/werkmaatschappijen',
    component: PageCompaniesComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [
            UserRoles.ADMIN,
            UserRoles.RISK_MANAGER,
            UserRoles.COMPANY_ADMIN
        ]
    }
}, {
    path: 'beheer/bedrijfstypes',
    component: PageCompanyTypesComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [UserRoles.ADMIN]
    }
}, {
    path: 'beheer/meldingen',
    component: PageNotificationsComponent,
    canActivate: [MsAuthGuard, UserGuard, UserRoleGuard],
    data: {
        menuVisible: true,
        roles: [UserRoles.ADMIN]
    }
}, {
    path: 'logout',
    component: PageLogoutComponent,
    data: {
        menuVisible: true
    }
}, {
    path: 'project/:projectId/step/:stepId',
    canActivate: [MsAuthGuard, ProjectStepGuard, UserGuard, UserRoleGuard],
    component: PageProjectStepsComponent,
    data: {
        menuVisible: false,
        roles: [
            UserRoles.TENDER_MANAGER,
            UserRoles.COMPANY_PROJECT_VIEWER,
            UserRoles.COMPANY_PROJECT_EDITOR,
            UserRoles.COMPANY_ADMIN,
            UserRoles.PROJECT_VIEWER,
            UserRoles.PROJECT_EDITOR,
            UserRoles.RISK_MANAGER
        ]
    }
}, {
    path: 'pdf/:uuid/landscape/:includedPages',
    component: PagePdfLandscapeComponent,
    data: {
        menuVisible: false
    }
}, {
    path: 'pdf/:uuid/portrait/:includedPages',
    component: PagePdfPortraitComponent,
    data: {
        menuVisible: false
    }
}];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
