import {Component, OnInit} from '@angular/core';
import {CompanyTypeService, LibraryService, LoadingSpinnerService} from '../../services';
import {Library} from '../../models';
import {ActivatedRoute} from '@angular/router';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-page-risk-library',
    templateUrl: './page-risks-library.component.html',
    styleUrls: ['./page-risks-library.component.scss']
})
export class PageRisksLibraryComponent implements OnInit {

    public riskLibrary: Library;
    private companyTypeId: number;

    constructor(
        private libraryService: LibraryService,
        private companyTypeService: CompanyTypeService,
        private route: ActivatedRoute,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.setData();
        this.loadLibrary();
    }

    private setData() {
        this.companyTypeId = parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10);
        this.companyTypeService.setActiveCompanyTypeId(this.companyTypeId);
    }

    private loadLibrary() {
        this.loadingSpinnerService.show();
        this.libraryService.getLibrary(this.companyTypeId, LibraryType.RISKS).then((library) => {

            this.riskLibrary = library;
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }
}
