<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_COMPANIES.title' | translate}}</h2>
</vwui-header>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <vwui-table [compactable]="false">
        <div slot="table-title">{{ 'PAGE_COMPANIES.table.header' | translate }}</div>
        <vwui-table-row>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--name">{{ 'PAGE_COMPANIES.table.column.name' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--company-type">{{ 'PAGE_COMPANIES.table.column.company_type' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--editors">{{ 'PAGE_COMPANIES.table.column.editors' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--viewers">{{ 'PAGE_COMPANIES.table.column.viewers' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--admin">{{ 'PAGE_COMPANIES.table.column.admin' | translate }}
            </vwui-table-header>
            <vwui-table-header
                class="tender-manager-col tender-manager-col--actions">
            </vwui-table-header>
        </vwui-table-row>
        <vwui-table-row
            *ngFor="let company of companies">
            <vwui-table-column class="tender-manager-col tender-manager-col--name">
                {{ company.name }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--company-type">
                {{ company.companyType.name }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--editors">
                {{ company.editors | namesFormat }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--viewers">
                {{ company.viewers | namesFormat }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--admin">
                {{ company.admin | namesFormat }}
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--actions">
                <vwui-btn-icon icon="edit" (click)="openUpdateCompanyModal(company)"></vwui-btn-icon>
                <vwui-btn-icon icon="vwui-trash" (click)="openDeleteCompanyModal(company)" [appDisabledReason]="deleteDisabledReason()"></vwui-btn-icon>
            </vwui-table-column>
        </vwui-table-row>
        <vwui-table-row>
            <vwui-table-column class="tender-manager-col tender-manager-col--name">
                <vwui-button class="is-secondary" (click)="openCreateCompanyModal()">
                    {{ 'PAGE_COMPANIES.create_company' | translate }}
                </vwui-button>
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--companies">
            </vwui-table-column>
            <vwui-table-column class="tender-manager-col tender-manager-col--actions">
            </vwui-table-column>
        </vwui-table-row>
    </vwui-table>
</div>
