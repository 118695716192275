<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ "PAGE_TIS_VERSION.title" | translate : { tisLabelShort: companyType?.tisLabelShort, tisLabelLong: companyType?.tisLabelLong } }}</h2>

    <div class="button-group" slot="action">
        <vwui-button (click)="navigateToTisList()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="isUpdatingTis" (click)="updateTis()">
            {{ 'BUTTON.save' | translate }}
        </vwui-button>
    </div>
</vwui-header>

<vwui-spinner *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<ng-container *ngIf="tisCategories">
    <div class="tis-category" *ngFor="let tisCategory of tisCategories; let categoryIndex = index; trackBy: trackTisCategory">
        <vwui-table [compactable]="false">
            <div slot="table-title">{{ categoryIndex + 1 }}. {{ tisCategory.name }}</div>
            <div slot="table-actions">
                <vwui-btn-icon icon="vwui-chevron-up" (click)="moveCategoryUp(tisCategory)" [disabled]="categoryIndex === 0"></vwui-btn-icon>
                <vwui-btn-icon icon="chevron-down" (click)="moveCategoryDown(tisCategory)" [disabled]="categoryIndex >= tisCategories.length - 1"></vwui-btn-icon>
                <vwui-btn-icon icon="edit" (click)="openUpdateCategoryModal(tisCategory)"></vwui-btn-icon>
                <vwui-btn-icon icon="vwui-trash" (click)="openDeleteCategoryModal(tisCategory)"></vwui-btn-icon>
            </div>
            <vwui-table-row>
                <vwui-table-header class="tis-col tis-col--nr">{{ 'COMPONENT.tis.table.column.nr' | translate }}</vwui-table-header>
                <vwui-table-header class="tis-col tis-col--question">{{ 'COMPONENT.tis.table.column.question' | translate }}</vwui-table-header>
                <vwui-table-header class="tis-col tis-col--options"></vwui-table-header>
            </vwui-table-row>
            <vwui-table-row *ngFor="let question of tisCategory | categoryGetAllSubCategoryQuestions | async; let questionIndex = index; trackBy: trackTisSubCategoryQuestions">
                <vwui-table-column class="tis-col tis-col--nr">{{ categoryIndex + 1 }}.{{ questionIndex + 1 }} </vwui-table-column>
                <vwui-table-column class="tis-col tis-col--question">
                    {{ question.name }}
                </vwui-table-column>
                <vwui-table-column class="tis-col tis-col--options">
                    <vwui-btn-icon icon="vwui-chevron-up" [disabled]="questionIndex === 0" (click)="moveQuestionUp(question, tisCategory)"></vwui-btn-icon>
                    <vwui-btn-icon icon="chevron-down" [disabled]="questionIndex >= tisCategory.getSubcategoryQuestionCount() - 1" (click)="moveQuestionDown(question, tisCategory)"></vwui-btn-icon>
                    <vwui-btn-icon icon="edit" (click)="openUpdateQuestionModal(question)"></vwui-btn-icon>
                    <vwui-btn-icon icon="vwui-trash" (click)="openDeleteQuestionModal(question)"></vwui-btn-icon>
                </vwui-table-column>
            </vwui-table-row>
            <vwui-table-row>
                <vwui-table-column></vwui-table-column>
                <vwui-table-column>
                    <vwui-button class="is-secondary" (click)="openCreateQuestionModal(tisCategory)">
                        {{ 'COMPONENT.tis.create_question' | translate }}
                    </vwui-button>
                </vwui-table-column>
                <vwui-table-column></vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </div>
</ng-container>
<vwui-button *ngIf="tisCategories" class="is-secondary" (click)="openCreateCategoryModal()">
    {{ 'COMPONENT.tis.create_category' | translate }}
</vwui-button>
