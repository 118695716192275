<div class="content-viewport">
    <div class="content-viewport__title">
        <app-library-item-filters-with-toggle
            class="filters-toggle"
            [filters]="libraryItemFilters"
            [badge]="filterBadge"
        ></app-library-item-filters-with-toggle>
        <vwui-header>
            {{ 'PAGE_RISK_DOSSIER.title' | translate }}
        </vwui-header>
    </div>
    <div class="content-viewport__scrollable">
        <div class="filters-selected" [ngClass]="{'filters-selected--hidden': !filterSelectionCount}">
            <app-library-item-filters-selected
                [filters]="libraryItemFilters"
                (selectionCountChange)="filterSelectionCountChanged($event)"
            ></app-library-item-filters-selected>
        </div>
        <div class="content-viewport__table">
            <app-pdf-page-risks-and-opportunities-content
                [projectDetails]="projectDetailsValue"
                [filters]="libraryItemFilters"
            ></app-pdf-page-risks-and-opportunities-content>
        </div>
    </div>
</div>
