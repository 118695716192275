import {Pipe, PipeTransform} from '@angular/core';
import {FormatEuroCentsDirective} from '../directives/format-euro-cents.directive';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    transform(value: string | number = '') {
        const numberValue = FormatEuroCentsDirective.convertToNumber(value);
        const formattedEuro = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

        return formattedEuro;
    }

}
