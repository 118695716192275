import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {TrackingService} from '../../../services';

@Component({
    selector: 'app-delete-item-modal',
    templateUrl: './delete-item-modal.component.html',
    styleUrls: ['./delete-item-modal.component.scss']
})
export class DeleteItemModalComponent {

    form = new FormGroup({});
    public title: string;
    public description: string;
    public extraDescription: string | null = null;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; description: string; extraDescription: string | null }>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.description = modalConfig.data.description;
        this.extraDescription = modalConfig.data.extraDescription;
    }

    async submit() {
        try {
            this.modalRef.close(true);
        } catch (e) {
            console.error('Deleting item failed: ', e);
            this.trackingService.exception(e);
        }
    }
}
