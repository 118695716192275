import {Component, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {PublishTisModalComponent} from '../../components';
import {QuickscanPublish} from '../../enums';
import {QuickscanDefinition, TisDefinition} from '../../models';
import {CompanyTypeService, LoadingSpinnerService, TisDefinitionService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyType} from '../../models/user/user';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-page-tis',
    templateUrl: './page-tis.component.html',
    styleUrls: ['./page-tis.component.scss']
})
export class PageTisComponent implements OnInit, OnDestroy {

    public allTisDefinitions: TisDefinition[];
    public tableConfig: { title: string; columns: object[] };
    public companyType: CompanyType;
    private subscriptions: Subscription[] = [];

    constructor(
        private tisDefinitionService: TisDefinitionService,
        private companyTypeService: CompanyTypeService,
        private modalService: VwuiModalService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tableConfig = {
            title: this.translateService.instant('PAGE_TIS_VERSIONS.table.title', { tisLabelShort: this.companyType ? this.companyType.tisLabelShort : '' }),
            columns: [
                {header: this.translateService.instant('PAGE_TIS_VERSIONS.table.versionId')},
                {header: this.translateService.instant('PAGE_TIS_VERSIONS.table.status')},
                {header: this.translateService.instant('PAGE_TIS_VERSIONS.table.publishedAt')},
                {header: ''}
            ]
        };

        await this.setData();

        this.subscriptions.push(this.companyTypeService.getActiveCompanyTypeSubject().subscribe((companyType) => {
            if (!!companyType) {
                this.companyType = companyType;
                this.loadTisDefinitions();
                this.tableConfig.title = this.translateService.instant('PAGE_TIS_VERSIONS.table.title', { tisLabelShort: this.companyType ? this.companyType.tisLabelShort : '' });
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public trackTisDefinition(index: number, tisDefinition: TisDefinition): string {
        return `${tisDefinition.id}`;
    }

    private async setData(): Promise<void> {
        await this.companyTypeService.setActiveCompanyTypeId(
            parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10)
        );
    }

    onMenuSelect(option: {id: string; data: QuickscanDefinition}) {
        if (option.id === QuickscanPublish.PUBLISH) {
            this.openEditVersionModal(option.data);
        }
    }

    openEditVersionModal(selectedElement: QuickscanDefinition) {
        const modal = this.modalService.open(PublishTisModalComponent, {data: {companyType: this.companyType}});
        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.tisDefinitionService.publishTisDefinition(this.companyType, selectedElement.versionId).then(() => {
                    this.loadTisDefinitions();
                });
            }
        });
    }

    public editTis(tis: TisDefinition): void {
        this.router.navigate(['/beheer', 'tis', this.companyType.id, tis.versionId]);
    }

    private loadTisDefinitions() {
        this.loadingSpinnerService.show();
        this.tisDefinitionService.getTisDefinitions(this.companyType).then((tisDefinitions) => {
            tisDefinitions.sort((a, b) => b.versionId - a.versionId);

            this.allTisDefinitions = tisDefinitions;
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }
}
