<form [formGroup]="form" (submit)="submit()" class="create-update-modal" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <div class="grid">
            <div class="grid__row">
                <div class="grid__col grid__col--6">
                    <vwui-form-group label="{{ 'COMPONENT.company_type.name' | translate }}" inputId="name">
                        <vwui-input inputId="name" formControlName="name" placeholder="{{ 'COMPONENT.company_type.placeholder.name' | translate}}"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.contract_types.label' | translate }}">
                        <ng-select
                            [selectOnTab]="true"
                            addTag="true"
                            multiple="true"
                            formControlName="contractTypes"
                            labelForId="contractTypes"
                            [notFoundText]="'COMPONENT.company_type.contract_types.not_found' | translate"
                            [placeholder]="'COMPONENT.company_type.contract_types.placeholder' | translate"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.tis_label_short' | translate }}" inputId="tisLabelShort">
                        <vwui-input inputId="tisLabelShort" formControlName="tisLabelShort" placeholder="{{ 'COMPONENT.company_type.placeholder.tis_label_short' | translate}}"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.tis_label_long' | translate }}" inputId="tisLabelLong">
                        <vwui-input inputId="tisLabelLong" formControlName="tisLabelLong" placeholder="{{ 'COMPONENT.company_type.placeholder.tis_label_long' | translate}}"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.construction_costs_label' | translate }}" inputId="constructionCostsLabel">
                        <vwui-input inputId="constructionCostsLabel" formControlName="constructionCostsLabel" placeholder="{{ 'COMPONENT.company_type.placeholder.construction_costs_label' | translate}}"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.ad_registration.label' | translate }}" inputId="azureAdRegistrationLabel">
                        <ng-select
                            [selectOnTab]="true"
                            addTag="true"
                            [multiple]="false"
                            bindLabel="name"
                            [items]="azureAdRegistrations"
                            formControlName="azureAdRegistration"
                            [notFoundText]="'COMPONENT.company_type.ad_registration.not_found' | translate"
                            [placeholder]="'COMPONENT.company_type.ad_registration.placeholder' | translate"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.risk_managers.label' | translate }}">
                        <app-user-identity-input
                            multiple="true"
                            [control]="form.controls.riskManagers"
                            [companyType]="data ? data : null"
                        ></app-user-identity-input>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.additional_collaborations.label' | translate }}">
                        <ng-select
                            [selectOnTab]="true"
                            addTag="true"
                            multiple="true"
                            formControlName="additionaLCollaborations"
                            labelForId="additionaLCollaborations"
                            [notFoundText]="'COMPONENT.company_type.additional_collaborations.not_found' | translate"
                            [placeholder]="'COMPONENT.company_type.additional_collaborations.placeholder' | translate"
                        >
                        </ng-select>
                    </vwui-form-group>
                </div>

                <div class="grid__col grid__col--6">
                    <vwui-form-group label="{{ 'COMPONENT.company_type.quantification_categories_risks.label' | translate }}">
                        <ng-select [items]="quantificationCategoriesRisks"
                                   [selectOnTab]="true"
                                   bindValue="key"
                                   labelForId="label"
                                   multiple="true"
                                   [searchable]="false"
                                   [clearable]="false"
                                   placeholder="{{ 'COMPONENT.company_type.quantification_categories_risks.placeholder' | translate }}"
                                   formControlName="quantificationCategoriesRisks"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="{{ 'COMPONENT.company_type.quantification_categories_opportunities.label' | translate }}">
                        <ng-select [items]="quantificationCategoriesOpportunities"
                                   [selectOnTab]="true"
                                   bindValue="key"
                                   labelForId="label"
                                   multiple="true"
                                   [searchable]="false"
                                   [clearable]="false"
                                   placeholder="{{ 'COMPONENT.company_type.quantification_categories_opportunities.placeholder' | translate }}"
                                   formControlName="quantificationCategoriesOpportunities"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group *ngIf="showTasks" label="{{ 'COMPONENT.company_type.quantification_categories_tasks.label' | translate }}">
                        <ng-select [items]="quantificationCategoriesTasks"
                                   [selectOnTab]="true"
                                   bindValue="key"
                                   labelForId="label"
                                   multiple="true"
                                   [searchable]="false"
                                   [clearable]="false"
                                   placeholder="{{ 'COMPONENT.company_type.quantification_categories_tasks.placeholder' | translate }}"
                                   formControlName="quantificationCategoriesTasks"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group *ngIf="showBaseCompanyTypeInfo" label="{{ 'COMPONENT.company_type.base_company_type.label' | translate }}">
                        <ng-select
                            [items]="availableBaseCompanyTypeNames"
                            [selectOnTab]="true"
                            multiple="false"
                            formControlName="selectedBaseCompanyType"
                            bindLabel="name"
                            bindValue="id"
                            labelForId="companyType"
                            [notFoundText]="'COMPONENT.company_type.base_company_type.not_found' | translate"
                            [placeholder]="'COMPONENT.company_type.base_company_type.placeholder' | translate"
                        >
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group [label]="'COMPONENT.company_type.mandating_description.label' | translate">
                        <app-rich-text-editor
                            [controller]="form.controls.companyMandatingInfo"
                        ></app-rich-text-editor>
                    </vwui-form-group>
                </div>
            </div>
        </div>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
