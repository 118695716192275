<form [formGroup]="form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2" class="header-title">
            {{ 'COMPONENT.share_project.title' | translate }}
        </h2>
    </vwui-modal-header>

    <vwui-modal-body class="body" a11yAutoFocus>
        <div class="owner">{{ 'COMPONENT.share_project.owner' | translate}}: {{ projectDetails.owner | namesFormat }}</div>

        <vwui-table [compactable]="false" class="member">
            <div slot="table-title">{{ 'COMPONENT.share_project.table.title' | translate }}</div>

            <ng-container *ngIf="getMembers().length > 0">
                <vwui-table-row>
                    <vwui-table-header class="member__col">{{ 'COMPONENT.share_project.table.column.email' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--quickscan">{{ 'COMPONENT.share_project.table.column.quickscan' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--project-library-items">{{ 'COMPONENT.share_project.table.column.project_library_items' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--tis">{{ 'COMPONENT.share_project.table.column.tis' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--options"></vwui-table-header>
                </vwui-table-row>

                <vwui-table-row *ngFor="let member of getMembers()">
                    <vwui-table-column class="member__col">{{ member.user | namesFormat }}</vwui-table-column>
                    <vwui-table-column class="member__col member__col--quickscan"><vwui-checkbox [checked]="member.quickscanEditor" (click)="toggleQuickscan($event, member)"></vwui-checkbox></vwui-table-column>
                    <vwui-table-column class="member__col member__col--project-library-items"><vwui-checkbox [checked]="member.projectLibraryItemEditor" (click)="toggleProjectLibraryItems($event, member)"></vwui-checkbox></vwui-table-column>
                    <vwui-table-column class="member__col member__col--tis"><vwui-checkbox [checked]="member.tisEditor" (click)="toggleTis($event, member)"></vwui-checkbox></vwui-table-column>
                    <vwui-table-column class="member__col member__col--options"><vwui-btn-icon icon="vwui-trash" (click)="removeMember(member)"></vwui-btn-icon></vwui-table-column>
                </vwui-table-row>
            </ng-container>

            <vwui-table-row *ngIf="getMembers().length === 0">
                <vwui-table-column>{{ 'COMPONENT.share_project.table.column.email_empty' | translate }}</vwui-table-column>
            </vwui-table-row>
        </vwui-table>

        <div class="add-row">
            <div class="add-row__input"><app-user-identity-input [companyType]="companyType" [control]="form.controls.user" [identitiesToExclude]="identitiesToExclude$"></app-user-identity-input></div>
            <div class="add-row__input add-row__input--submit"><vwui-button class="is-secondary" (click)="addMember()" [disabled]="isAddMemberButtonDisabled()">{{ 'BUTTON.create' | translate }}</vwui-button></div>
        </div>
    </vwui-modal-body>

    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close(false)">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="saving" (click)="submit()">
            {{ 'BUTTON.save' | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
