<form [formGroup]="form" (submit)="submit()" a11yFocusTrap>
    <vwui-modal-header>
        {{ 'COMPONENT.EDIT_REMARK.title' | translate }}
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <vwui-form-group label="{{ 'COMPONENT.EDIT_REMARK.remark' | translate }}" inputId="remark">
            <vwui-textarea inputId="remark" formControlName="remark"></vwui-textarea>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [disabled]="modalConfig.data.readonly" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
