import {Pipe, PipeTransform} from '@angular/core';
import {FormUtil} from '../utils';

@Pipe({
    name: 'isValidEmailAddress'
})
export class IsValidEmailAddressPipe implements PipeTransform {

    transform(emailAddress: string): boolean {
        return FormUtil.isValidEmailAddress(emailAddress);
    }

}
