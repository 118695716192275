import {Injectable} from '@angular/core';
import {ApiService, ToastService} from '../';
import {IProject, IProjectDetailsData, IProjectMandateDetails, ProjectDetails, ProjectMandateDetails} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class ProjectMandateService {

    constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
    }

    public getMandateDetails(project: ProjectDetails): Promise<ProjectMandateDetails> {
        return this.apiService.get(`/project/${project.id}/mandate`).then((projectResponse: HttpResponse<IProjectMandateDetails>) => new ProjectMandateDetails(projectResponse.body)).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.project_mandate_details_nok'));
            return Promise.reject(error);
        });
    }

    public startMandate(project: ProjectDetails): Promise<ProjectMandateDetails> {
        return this.apiService.put(`/project/${project.id}/mandate`, {}).then((projectResponse: HttpResponse<IProjectMandateDetails>) => new ProjectMandateDetails(projectResponse.body)).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.project_mandate_details_nok'));
            return Promise.reject(error);
        });
    }

}
