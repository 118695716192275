import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastService, ProjectService, CompanyTypeService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails} from '../../models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtil} from '../../utils';
import {ICompanyType} from '../../models/user/user';

@Component({
    selector: 'app-execute-project-modal',
    templateUrl: './execute-project-modal.component.html'
})
export class ExecuteProjectModalComponent {

    public loading = false;
    public form: FormGroup;
    public companyType: ICompanyType = null;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ projectDetails: ProjectDetails }>,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private companyTypeService: CompanyTypeService,
        private toastService: ToastService
    ) {
        const projectLead = this.modalConfig.data.projectDetails.owner;
        this.form = new FormGroup({
            projectLead: new FormControl(projectLead, [Validators.required])
        });

        this.companyTypeService.getCompanyType(this.modalConfig.data.projectDetails.companyTypeId).then((companyType) => {
            this.companyType = companyType;
        });
    }

    async submit() {
        FormUtil.validate(this.form);

        if (this.form.valid) {

            this.loading = true;

            const updatedValues = {...this.modalConfig.data.projectDetails};
            updatedValues.owner = this.form.value.projectLead;
            await this.projectService.toExecution(updatedValues)
                .then((details) => {
                    const message = this.translateService.instant('COMPONENT.mandating.in_execution');
                    this.toastService.openSuccess(message);
                    this.modalRef.close(true);
                })
                .catch((error) => {
                    console.error(error);
                    this.toastService.openError(error, this.translateService.instant('ERRORS.project_to_execution_nok'));
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
