import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';

export class FormUtil {

    public static numberValidator(control: AbstractControl): ValidationErrors | null {
        const value = (control.value || '').replace(/\.|,/g, '');
        const valid = /^([1-9]|[1-9][0-9]+)$/.test(value);
        return value === '' || valid ? null : {invalidNumber: true};
    }

    public static numberFormatterInput(control: AbstractControl, locale: string): void {
        const value = (control.value || '').replace(/\.|,/g, '');
        const numberValue = Number(value);
        const formattedValue = numberValue.toLocaleString(locale);
        if (value !== '' && !isNaN(numberValue)) {
            control.setValue(formattedValue);
        }
    }

    public static numberFormatter(value: number, locale: string, fixedDecimal?: number): string {
        const newValue = this.roundNumber(value, 10).toString();
        const numberValue = Number(newValue);
        const options = fixedDecimal ? {minimumFractionDigits: fixedDecimal, maximumFractionDigits: fixedDecimal} : {};
        const formattedValue = numberValue.toLocaleString(locale, options);
        return newValue !== '' && !isNaN(numberValue) ? formattedValue : value.toString();
    }

    public static emailValidator(control: AbstractControl): ValidationErrors | null {
        const value = (control.value || '');
        const valid = FormUtil.isValidEmailAddress(value);
        return value === '' || valid ? null : {invalidEmail: true};
    }

    public static isValidEmailAddress(emailAddress: string): boolean {
        // Email Regex from: https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
        const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

        return EMAIL_REGEXP.test(emailAddress);
    }

    public static roundNumber(value: number, decimal: number = 0) {
        const multiplier = Math.pow(10, decimal);
        return Math.round(value * multiplier) / multiplier;
    }

    public static validate(control: AbstractControl) {
        if (control instanceof FormGroup) {
            this.validateFormGroup(control);
        } else if (control instanceof FormControl) {
            this.validateFormControl(control);
        } else if (control instanceof FormArray) {
            this.validateFormArray(control);
        }
    }

    private static validateFormGroup(form: FormGroup) {
        for (const controlKey of Object.keys(form.controls)) {
            const control = form.get(controlKey);
            this.validate(control);
        }
    }

    private static validateFormControl(control: FormControl) {
        control.markAsTouched({onlySelf: true});
    }

    private static validateFormArray(array: FormArray) {
        for (const control of array.controls) {
            this.validate(control);
        }
    }
}
