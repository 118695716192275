import {Injectable} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {

    @SessionStorage() private loadingSpinnerVisible: boolean;

    constructor() {
    }

    public show(): void {
        this.loadingSpinnerVisible = true;
    }

    public hide(): void {
        this.loadingSpinnerVisible = false;
    }

    public isSpinnerVisible(): boolean {
       return this.loadingSpinnerVisible;
    }
}
