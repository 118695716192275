import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LibraryEntry} from '../../models';

@Component({
    selector: 'app-item-risk-mitigation-tab',
    templateUrl: './item-risk-mitigation-tab.component.html'
})
export class ItemRiskMitigationTabComponent {
    @Input() entry: LibraryEntry;
    @Input() libraryType: string;
    @Input() formGroup: FormGroup;

    constructor() {
    }
}
