import {Component} from '@angular/core';

@Component({
    selector: 'app-page-no-access',
    templateUrl: './page-no-access.component.html',
    styleUrls: ['./page-no-access.component.scss']
})
export class PageNoAccessComponent {

    constructor() {
    }

}
