import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree, CanActivate, ActivatedRoute} from '@angular/router';
import {StepService} from '../../services';

@Injectable({
    providedIn: 'root'
})
export class ProjectStepGuard implements CanActivate {

    constructor(
        private router: Router,
        private stepService: StepService
    ) {}

    // Route guard when entering
    canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
        const stepId = parseInt(route.params.stepId, 10);
        const projectId = route.params.projectId;

        if (!this.stepService.isValidStep(stepId)) {
            return this.router.parseUrl(`/project/${projectId}/step/1`);
        }
        return true;
    }

}
