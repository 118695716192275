<form [formGroup]="form" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_category.field.name' | translate" inputId="name">
            <vwui-input inputId="name" formControlName="name"></vwui-input>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_category.field.weightedScore' | translate" inputId="weightedScore">
            <vwui-input type="number" inputId="weightedScore" formControlName="weightedScore"></vwui-input>
        </vwui-form-group>
        <vwui-form-group [label]="'COMPONENT.quickscan.modal.create_category.field.type' | translate" inputId="type">
            <vwui-radio [checked]="categoryType === categoryTypes.RISK" (click)="setType(categoryTypes.RISK)">{{ 'COMPONENT.quickscan.modal.create_category.field.type_value_risk' | translate }}</vwui-radio>
            <vwui-radio [checked]="categoryType === categoryTypes.VALUE" (click)="setType(categoryTypes.VALUE)">{{ 'COMPONENT.quickscan.modal.create_category.field.type_value_value' | translate }}</vwui-radio>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
