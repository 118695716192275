import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {IChartSignalMatrixConfig, IChartSignalMatrixItem} from '../chart-signal-matrix/chart-signal-matrix.data';
import {TranslateService} from '@ngx-translate/core';
import {LibraryItemFilters, ProjectLibraryItem} from '../../models';
import {IAllRisksWithScores} from './pdf-page-risk-rainbow.data';
import {ScoreThresholdsOpportunities, ScoreThresholdsRisks} from '../score-signal-badge/score-signal-badge.data';
import {ProjectLibraryItemStatus} from '../../models/project/project-libary-item';
import {RiskRainbowUtil} from '../../utils';
import {FormatEuroCentsDirective} from '../../directives/format-euro-cents.directive';
import {LibraryItemSort} from '../../models/library/sort';
import {ItemSorting, SortableTypes, SortingColumns} from '../../models/library/sort-data'   ;
import {PdfService} from '../../services';

@Component({
    selector: 'app-pdf-page-risk-rainbow',
    templateUrl: './pdf-page-risk-rainbow.component.html',
    styleUrls: ['./pdf-page-risk-rainbow.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageRiskRainbowComponent implements OnInit {

    @Input()
    public pdfDetails: PdfDetails;
    @Input()
    public clientOnly = false;
    @Input()
    public options: string[] = [];

    public libraryItemFilters: LibraryItemFilters;
    public libraryItemSort: LibraryItemSort;

    public readonly SortableTypes = SortableTypes;
    public readonly SortableColumns = SortingColumns;
    public readonly ItemSorting = ItemSorting;

    public scoreThresholdsRisks = ScoreThresholdsRisks;
    public scoreThresholdsOpportunities = ScoreThresholdsOpportunities;
    public filteredRisks: ProjectLibraryItem[] = [];
    public filteredOpportunities: ProjectLibraryItem[] = [];
    public filteredTasks: ProjectLibraryItem[] = [];
    public allRisksWithScores: IAllRisksWithScores[] = [];
    public allOpportunitiesWithScores: IAllRisksWithScores[] = [];
    public risksVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.impact'), labels: [0, 1, 2, 3, 4, 5]},
        vertical: {title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.probability'), labels: [0, 1, 2, 3, 4, 5]}
    };
    public opportunitiesVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.impact'), labels: [0, 1, 2, 3, 4, 5]},
        vertical: {title: this.translateService.instant('PAGE_RISK_RAINBOW.matrix.probability'), labels: [0, 1, 2, 3, 4, 5]}
    };

    constructor(
        private translateService: TranslateService,
        private pdfService: PdfService
    ) {
    }

    private static calculateScores(items: ProjectLibraryItem[]): IAllRisksWithScores[] {
        return RiskRainbowUtil.calculateRainbowScores(items);
    }

    ngOnInit() {
        const filters = this.pdfDetails.projectDetails.getDefaultLibraryItemProjectFilters(this.translateService);

        this.filteredRisks = this.pdfDetails.projectDetails.getRisksWithinFilters(filters, this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item));
        this.filteredOpportunities = this.pdfDetails.projectDetails.getOpportunitiesWithinFilters(filters, this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item));
        this.filteredTasks = this.pdfDetails.projectDetails.getTasksWithinFilters(filters, this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item));
        this.allRisksWithScores = PdfPageRiskRainbowComponent.calculateScores(this.pdfDetails.projectDetails.getRisks(this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item))
        );
        this.allOpportunitiesWithScores = PdfPageRiskRainbowComponent.calculateScores(this.pdfDetails.projectDetails.getOpportunities(this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item))
        );
        this.risksVsValueMatrix.values = this.generateMatrixValues('risks');
        this.opportunitiesVsValueMatrix.values = this.generateMatrixValues('opportunities');
        this.libraryItemFilters = new LibraryItemFilters(this.pdfDetails.projectDetails.getDefaultLibraryItemProjectFilters(this.translateService));
        this.libraryItemSort = new LibraryItemSort(this.pdfDetails.projectDetails.libraryItemSort);

        this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.RISK, this.libraryItemSort.getRiskColumn(), false, this.filteredRisks);
        this.libraryItemSort.sortRiskAndOpportunityLibraryItems(SortableTypes.OPPORTUNITY, this.libraryItemSort.getOpportunityColumn(), false, this.filteredOpportunities);
    }

    public getCorrectSortOrderIcon(type: SortableTypes): string {
        if (type === SortableTypes.RISK) {
            return this.libraryItemSort.getControl().value.riskOption === ItemSorting.DESCENDING ? 'sort-numeric-down' : 'sort-numeric-up';
        } else {
            return this.libraryItemSort.getControl().value.opportunityOption === ItemSorting.DESCENDING ? 'sort-numeric-down' : 'sort-numeric-up';
        }
    }

    public getNrIncidents(): number {
        return this.pdfDetails.projectDetails.getRisks(this.clientOnly).filter(risk => risk.status === ProjectLibraryItemStatus.INCIDENT).length;
    }

    public getBestGuess(item: ProjectLibraryItem): string {
        if (item.bestGuessCosts) {
            return FormatEuroCentsDirective.convertNumberToEuros(item.bestGuessCosts, this.translateService.getDefaultLang());
        } else {
            return '-';
        }
    }

    public getIncludedInPer(item: ProjectLibraryItem): string {
        if (item.includedInPer && item.perCosts) {
            return FormatEuroCentsDirective.convertNumberToEuros(item.perCosts, this.translateService.getDefaultLang());
        } else {
            return '-';
        }
    }

    public getTotalsIncludedInPer(items: ProjectLibraryItem[]): number {
        return items
            .filter((item) => this.shouldShowItemBasedOnOptions(item))
            .filter(item => item.includedInPer)
            .reduce(((previous, current) => previous + (current.perCosts || 0)), 0);
    }

    public getTotalsBestGuess(items: ProjectLibraryItem[]): number {
        return items.reduce(((previous, current) => previous + (current.bestGuessCosts || 0)), 0);
    }

    public getTotalCostsRisks(): number {
        return this.pdfDetails.projectDetails.getRisks(this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item))
            .map((item) => item.bestGuessCosts || 0)
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);
    }

    public getTotalValueOpportunities(): number {
        return this.pdfDetails.projectDetails.getOpportunities(this.clientOnly)
            .filter((item) => this.shouldShowItemBasedOnOptions(item))
            .map((item) => item.bestGuessCosts || 0)
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);
    }

    public getTotalCostsRiskMitigations(): number {
        return [
            ...this.pdfDetails.projectDetails.getRisks(this.clientOnly),
            ...this.pdfDetails.projectDetails.getOpportunities(this.clientOnly)
        ].filter((item) => this.shouldShowItemBasedOnOptions(item))
            .map((item) => item.counterMeasuresCosts)
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);
    }

    private generateMatrixValues(libraryType: string): IChartSignalMatrixItem[] {
        let allItems: IAllRisksWithScores[] = [];
        switch (libraryType) {
            case 'risks':
                allItems = this.allRisksWithScores;
                break;
            case 'opportunities':
                allItems = this.allOpportunitiesWithScores;
                break;
        }
        return allItems.map((item) => ({
            horizontal: item.impactScore,
            vertical: item.opportunityScore,
            tooltip: item.item.getTitle(this.pdfDetails.projectDetails.getRisks()),
            itemId: item.item.id,
            inFilter: true
        }));
    }

    private shouldShowItemBasedOnOptions(item: ProjectLibraryItem): boolean {
        return (!!this.options && this.options.includes(PdfService.INCLUDE_PROPOSALS))
            || (item.status !== ProjectLibraryItemStatus.PROPOSED);
    }

}
