import {ObjectUtil} from '../../utils';
import {IQuickscanResultData, IScorePotentialData} from './quickscanresult-data';
import {QuickscanResultDefault} from './quickscanresult-default';

export class QuickscanResult implements IQuickscanResultData {

    classification: string;
    quickscanClassifiedAt: Date;
    projectId: number;
    redFlags: IScorePotentialData;
    risks: IScorePotentialData;
    values: IScorePotentialData;

    constructor(
        config: IQuickscanResultData|null = null
    ) {
        const settings = ObjectUtil.mergeObjects<IQuickscanResultData>([QuickscanResultDefault, config]);

        this.projectId = settings.projectId;
        this.classification = settings.classification;
        this.quickscanClassifiedAt = settings.quickscanClassifiedAt;
        this.risks = settings.risks;
        this.values = settings.values;
        this.redFlags = settings.redFlags;
    }

    public determineRiskProgress(): number {
        return this.determineProgress(this.risks);
    }

    public determineValueProgress(): number {
        return this.determineProgress(this.values);
    }

    public determineRedflagProgress(): number {
        return this.determineProgress(this.redFlags);
    }

    private determineProgress(scorePotential: IScorePotentialData): number {
        if (scorePotential.score + scorePotential.potential === 0) {
            return 0;
        } else {
            return Math.round(( scorePotential.score / scorePotential.potential ) * 100 );
        }
    }

    public determineRiskColor(): string {
        const riskProgress = this.determineRiskProgress();
        if (riskProgress <= 20) {
            return 'GREEN';
        } else if (riskProgress > 20 && riskProgress < 40) {
            return 'ORANGE';
        } else {
            return 'RED';
        }
    }

}
