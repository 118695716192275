<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_QUICKSCAN_VERSIONS.title' | translate }}</h2>
</vwui-header>

<vwui-table [compactable]="false">
    <div slot="table-title">{{ tableConfig.title }}</div>
    <vwui-table-row>
        <vwui-table-header *ngFor="let column of tableConfig.columns">
            {{ column.header}}
        </vwui-table-header>
    </vwui-table-row>

    <vwui-table-row *ngIf="loadingSpinnerService.isSpinnerVisible()">
        <vwui-spinner></vwui-spinner>
    </vwui-table-row>

    <ng-container *ngIf="!loadingSpinnerService.isSpinnerVisible()">
        <vwui-table-row *ngFor="let item of allQuickscanDefinitions; trackBy: trackQuickscanDefinition">
            <vwui-table-column>
                {{ item.versionId }}
            </vwui-table-column>
            <vwui-table-column>
                {{ (item.isPublished ? 'PAGE_QUICKSCAN_VERSIONS.published' : 'PAGE_QUICKSCAN_VERSIONS.concept') | translate }}
            </vwui-table-column>
            <vwui-table-column *ngIf="item.isPublished">
                {{ item.publishedAt | date: ('DATE.long' | translate) }}
            </vwui-table-column>
            <vwui-table-column *ngIf="!item.isPublished">
                <slot></slot>
            </vwui-table-column>
            <vwui-table-column>
                <div class="button-group" *ngIf="!item.isPublished">
                    <vwui-button (click)="openPublishModal(item)">
                        {{ 'PAGE_QUICKSCAN_VERSIONS.publish' | translate }}
                    </vwui-button>
                    <vwui-button (click)="editQuickscan(item)">
                        {{ 'PAGE_QUICKSCAN_VERSIONS.edit' | translate }}
                    </vwui-button>
                </div>
            </vwui-table-column>
        </vwui-table-row>
    </ng-container>
</vwui-table>
