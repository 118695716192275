export enum IQuestionType {
    MULTIPLECHOICE = 'multiplechoice',
    DATE = 'date',
    TEXT = 'text',
    INTEGER = 'integer',
    BOOL = 'bool',
    PERCENTAGE = 'percentage',
    DEPARTMENT = 'department',
    CALCULATION = 'calculation'
}
