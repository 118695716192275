<div a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{"CLOSE_PROJECT.modal.title" | translate}}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        {{"CLOSE_PROJECT.modal.question" | translate}}
    </vwui-modal-body>
    <vwui-modal-footer>
        <div class="button-group" a11yAutoFocus>
            <vwui-button class="is-secondary" (click)="modalRef.close(false)">
                {{"BUTTON.cancel" | translate}}
            </vwui-button>
            <vwui-button class="has-warning " [loading]="loading" (click)="submit()">
                {{"CLOSE_PROJECT.modal.close_button" | translate}}
            </vwui-button>
        </div>
    </vwui-modal-footer>
</div>
