import {ProjectLibraryItem} from '../models';

export class RiskRainbowUtil {

    static calculateRainbowScores(items: ProjectLibraryItem[]): { item: ProjectLibraryItem; opportunityScore: number; impactScore: number }[] {
        return items.filter((item) => item.quantification).map((item) => {
            let impactScore = 0;
            Object.keys(item.quantification).forEach((key) => {
                const quantification = item.quantification[key];

                if (key === 'probability') {
                    return;
                } else {
                    impactScore = quantification > impactScore ? quantification : impactScore;
                }
            });

            return {
                opportunityScore: item.quantification ? item.quantification.probability : 0,
                item,
                impactScore
            };
        });
    }
}
