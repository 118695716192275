<div a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{"PDF.modal.title" | translate}}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <form *ngIf="form" [formGroup]="form" a11yAutoFocus>
            <vwui-form-group label="{{ 'PDF.modal.pdf_types' | translate }}" inputId="pdfType">
                <ng-select [items]="pdfTypes"
                           [selectOnTab]="true"
                           bindLabel="name"
                           bindValue="id"
                           labelForId="pdfType"
                           required="true"
                           [clearable]="false"
                           [searchable]="false"
                           formControlName="pdfType"
                           (change)="updateFormOptions($event)"
                >
                </ng-select>
            </vwui-form-group>

            <div class="grid">
                <div class="grid__row">
                    <div class="grid__col grid__col--6" *ngIf="pdfType === PdfType.DEFAULT">
                        <vwui-form-group inputId="{{PDFPageKeys.PROJECT_DETAILS}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.PROJECT_DETAILS}}" formControlName="{{PDFPageKeys.PROJECT_DETAILS}}">{{"PDF.modal.project_details" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group inputId="{{PDFPageKeys.QUICKSCAN}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.QUICKSCAN}}" formControlName="{{PDFPageKeys.QUICKSCAN}}">{{"PDF.modal.quickscan_questions" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group inputId="{{PDFPageKeys.TIS_QUICKSCAN}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.TIS_QUICKSCAN}}" formControlName="{{PDFPageKeys.TIS_QUICKSCAN}}">{{"PDF.modal.tis_quickscan_questions" | translate : { tisLabelShort : projectDetails.projectInfo.tisLabelShort } }}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group inputId="{{PDFPageKeys.TIS}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.TIS}}" formControlName="{{PDFPageKeys.TIS}}">{{"PDF.modal.tis" | translate : { tisLabelShort : projectDetails.projectInfo.tisLabelShort } }}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT" inputId="{{PDFPageKeys.MANDATING_FLOW}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.MANDATING_FLOW}}" formControlName="{{PDFPageKeys.MANDATING_FLOW}}">{{"PDF.modal.mandating_flow" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                    </div>
                    <div class="grid__col grid__col--6">
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT" inputId="{{PDFPageKeys.RISKS_OPPORTUNITIES}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.RISKS_OPPORTUNITIES}}" formControlName="{{PDFPageKeys.RISKS_OPPORTUNITIES}}">{{"PDF.modal.risks_opportunities" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <app-radio-group *ngIf="(pdfType === PdfType.DEFAULT ) && showRiskDossierFilters" [form]="form" [name]="PDF_PAGE_RISK_OPPORTUNITIES_FILTER" [radioItems]="riskOpportunityFilterItems"></app-radio-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT || pdfType === PdfType.CLIENT_LIST" inputId="{{PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT}}" formControlName="{{PDFPageKeys.RISKS_OPPORTUNITIES_CLIENT}}">{{"PDF.modal.risks_opportunities_client" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <app-radio-group *ngIf="(pdfType === PdfType.DEFAULT || pdfType === PdfType.CLIENT_LIST ) && showRiskDossierClientFilters" [form]="form" [name]="PDF_PAGE_RISK_OPPORTUNITIES_CLIENT_FILTER" [radioItems]="riskOpportunityClientFilterItems"></app-radio-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT" inputId="{{PDFPageKeys.RISK_RAINBOW}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.RISK_RAINBOW}}" formControlName="{{PDFPageKeys.RISK_RAINBOW}}">{{"PDF.modal.risk_rainbow" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT || pdfType === PdfType.CLIENT_LIST" inputId="{{PDFPageKeys.RISK_RAINBOW_CLIENT}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.RISK_RAINBOW_CLIENT}}" formControlName="{{PDFPageKeys.RISK_RAINBOW_CLIENT}}">{{"PDF.modal.risk_rainbow_client" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT || pdfType === PdfType.FORECAST_END_RESULT_LIST" inputId="{{PDFPageKeys.FORECAST_END_RESULT_LIST}}">
                            <vwui-checkbox inputId="{{PDFPageKeys.FORECAST_END_RESULT_LIST}}" formControlName="{{PDFPageKeys.FORECAST_END_RESULT_LIST}}">{{"PDF.modal.forecast_end_result_list" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                        <vwui-form-group *ngIf="pdfType === PdfType.DEFAULT">
                            <vwui-checkbox inputId="{{INCLUDE_PROPOSALS}}" formControlName="{{INCLUDE_PROPOSALS}}">{{"PDF.modal.proposals" | translate}}</vwui-checkbox>
                        </vwui-form-group>
                    </div>
                </div>
            </div>
        </form>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="close()" >
            {{"BUTTON.cancel" | translate}}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="isDownloading" (click)="submit()" [disabled]="hasNoCheckedBoxes()" >
            {{"PDF.modal.download" | translate}}
        </vwui-button>
    </vwui-modal-footer>
</div>
