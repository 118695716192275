<ng-container *ngIf="type === 'admin' && companyTypes?.length > 0">
    <div class="company-type-management-header">
        <vwui-nav-header>
            {{ 'MENU.labels.management' | translate }}
        </vwui-nav-header>
    </div>
    <div *ngIf="companyTypes?.length > 1" class="company-type-selection">
        <ng-select [items]="companyTypes"
            [selectOnTab]="true"
            bindLabel="name"
            bindValue="id"
            required="true"
            [placeholder]="'PAGE_COMPANY_TYPES.placeholder.active_company_type' | translate"
            [(ngModel)]="activeCompanyTypeId"
        >
        </ng-select>
    </div>
</ng-container>

<ng-container *ngFor="let navItem of navItems">
    <ng-container *ngIf="navItem.type === 'item'">
        <vwui-nav-item
            [href]="navItem.href"
            [iconName]="navItem.iconName"
            [routerLink]="['/' + navItem.href]"
            routerLinkActive="is-active"
            tabindex="-1"
        >
            {{navItem.label | translate}}
        </vwui-nav-item>
    </ng-container>
    <ng-container *ngIf="navItem.type === 'header'">
        <div class="u-margin-b--md">
            <vwui-nav-header>
                {{navItem.label | translate}}
            </vwui-nav-header>
            <ng-container *ngFor="let headerNavItem of navItem.items">
                <vwui-nav-item
                    [href]="headerNavItem.href"
                    [iconName]="headerNavItem.iconName"
                    [routerLink]="['/' + headerNavItem.href]"
                    routerLinkActive="is-active"
                    tabindex="-1"
                >
                    {{headerNavItem.label | translate}}
                </vwui-nav-item>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
