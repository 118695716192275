export class ObjectUtil {

    public static mergeObjects<T extends object>(objects: T[]): T {
        const mergedObject = {};
        const clonedObjects: object[] = ObjectUtil.cloneObjects(objects);

        clonedObjects.forEach((object) => {
            for (const property in object) {
                if (object.hasOwnProperty(property)) {
                    if (ObjectUtil.isObject(object[property])) {
                        mergedObject[property] = ObjectUtil.mergeObjects([mergedObject[property] || {}, object[property]]);
                    } else {
                        mergedObject[property] = object[property];
                    }
                }
            }
        });

        return (mergedObject as T);
    }

    public static isObject<T>(item: T): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    public static cloneObjects<T extends object>(objects: T[]): T[] {
        const uniqueReferences = [];

        return objects.map((object) => JSON.parse(JSON.stringify(object || {}, (key, value) => {
            if (ObjectUtil.isObject<T>(value) && value !== null) {
                if (uniqueReferences.indexOf(value) !== -1) { return; }
                uniqueReferences.push(value);
            }

            return value;
        })));
    }

    public static cloneObject<T extends object>(object: T): T {
        return ObjectUtil.cloneObjects([object])[0];
    }

    public static findIndexForEqualObject<ObjectType>(object: ObjectType, objectArray: ObjectType[], primaryKey: string = 'id') {
        return objectArray.findIndex(it => it === object || it[primaryKey] === object[primaryKey]);
    }
}
