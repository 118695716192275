import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {LibraryCategory} from '../../../models';
import {FormUtil} from '../../../utils';
import {TrackingService} from '../../../services';

@Component({
    selector: 'app-create-project-modal',
    templateUrl: './create-update-library-category-modal.component.html'
})
export class CreateUpdateLibraryCategoryModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: LibraryCategory;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{title: string; data?: LibraryCategory; saveText: string}>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;

        const nameValue = this.data ? this.data.name : '';
        const numberPrefixValue = this.data ? this.data.numberPrefix : '';
        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required),
            numberPrefix: new FormControl(numberPrefixValue, Validators.required)
        });
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues = {...this.data, ...this.form.value};
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Creating category failed: ', e);
            this.trackingService.exception(e);
        }
    }
}
