<div class="scroll-navigation__container">
    <ng-container *ngFor="let navItem of navItemValues">
        <app-nav-item
            [class.is-active]="navItem.active"
            [class.is-groupelement]="navItem.groupHeader"
            (click)="navigateTo(navItem)"
            a11yKeyboardClickable
        >{{ navItem.label | translate }}</app-nav-item>
    </ng-container>
</div>
