import {Component, Input} from '@angular/core';
import {LibraryItemFilters} from '../../models';

@Component({
  selector: 'app-library-item-filters-with-toggle',
  templateUrl: './library-item-filters-with-toggle.component.html',
  styleUrls: ['./library-item-filters-with-toggle.component.scss']
})
export class LibraryItemFiltersWithToggleComponent {

    @Input() filters: LibraryItemFilters;
    @Input() badge: string;

    public filtersVisible = false;

    public toggleFilterVisibility(): void {
        this.filtersVisible = !this.filtersVisible;
    }

    public setFilterVisibility(filtersVisible: boolean): void {
        this.filtersVisible = filtersVisible;
    }

}
