import {Component, OnInit} from '@angular/core';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
    selector: 'app-publish-quick-scan-version-modal',
    templateUrl: './publish-quick-scan-version-modal.component.html'
})
export class PublishQuickScanVersionModalComponent {

    public loading = false;

    constructor(
        public modalRef: VwuiModalRef
    ) {
    }

    async submit() {
        this.modalRef.close(true);
    }

}
