import {Component, Input} from '@angular/core';
import {SignalColors} from './score-signal-badge.data';

@Component({
    selector: 'app-score-signal-badge',
    templateUrl: './score-signal-badge.component.html',
    styleUrls: ['./score-signal-badge.component.scss']
})
export class ScoreSignalBadgeComponent {

    public scoreString = '';
    public scoreNumber: number;
    public scoreThresholds: SignalColors[] = [];
    public scoreThreshold: SignalColors;

    constructor() {
    }

    @Input() set score(score: string | number) {
        this.scoreString = `${score}`.trim();
        this.setData();
    }

    @Input() set thresholds(thresholds: SignalColors[]) {
        this.scoreThresholds = thresholds;
        this.setData();
    }

    private setData(): void {
        this.scoreNumber = Number(this.scoreString);

        if (!isNaN(this.scoreNumber) && this.scoreThresholds.length > 0) {
            const scoreThresholdIndexCalculated = Math.floor(this.scoreNumber / (100 / this.scoreThresholds.length));
            const scoreThresholdIndex = Math.max(Math.min(scoreThresholdIndexCalculated, this.scoreThresholds.length - 1), 0);

            this.scoreThreshold = this.scoreThresholds[scoreThresholdIndex];
        } else {
            this.scoreThreshold = null;
        }
    }

}
