import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IUser, User} from '../../models';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class TenderManagerService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getTenderManagers(): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/tendermanager/all').then(async (response: HttpResponse<IUser[]>) => {
                resolve(response.body.map((data) => new User(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public deleteItem(tenderManager: User): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/tendermanager/${tenderManager.id}`).then(async (response: HttpResponse<IUser[]>) => {
                resolve(response.body.map((data) => new User(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public updateTenderManager(updatedItem: User): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/tendermanager/${updatedItem.id}`, updatedItem).then(async (response: HttpResponse<IUser[]>) => {
                resolve(response.body.map((data) => new User(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public createTenderManager(updatedItem: User): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/tendermanager`, updatedItem).then(async (response: HttpResponse<IUser[]>) => {
                resolve(response.body.map((data) => new User(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
