<table class="pdf-page">
    <thead class="pdf-page__header" [ngClass]="{'pdf-page__header--hidden': pdfPageHeader.children.length === 0}">
        <tr>
            <th class="pdf-page__header-body" #pdfPageHeader><ng-content select="app-pdf-page-header"></ng-content></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="pdf-page__content-body"><ng-content></ng-content></td>
        </tr>
    </tbody>
    <tfoot class="pdf-page__footer" [ngClass]="{'pdf-page__footer--hidden': pdfPageFooter.children.length === 0}">
        <tr>
            <td class="pdf-page__footer-body" #pdfPageFooter><ng-content select="app-pdf-page-footer"></ng-content></td>
        </tr>
    </tfoot>
</table>
