import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectDetails} from '../../models';
import {LibraryType} from '../../enums/library-type/library-type';

@Component({
    selector: 'app-project-tasks',
    templateUrl: './project-tasks.component.html',
    styleUrls: ['./project-tasks.component.scss']
})
export class ProjectTasksComponent {

    readonly libraryType = LibraryType.TASKS;

    @Input()
    public projectDetails: ProjectDetails;

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    constructor() {
    }

    public taskDetailsChanged($event: ProjectDetails) {
        this.projectDetailsChanged.emit($event);
    }
}
