import Pica from 'pica';
import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

    /**
     * Process the selected image file
     *
     * @param imageFile File object from FileList
     * @param thumbWidth number
     * @param thumbHeight number
     */
    public async processImage(imageFile: File, thumbWidth: number, thumbHeight: number): Promise<{ thumbnail: string; image: string }> {
        const imageSrc = window.URL.createObjectURL(imageFile);

        let thumbnailBase64 = null;
        const imageBase64 = imageSrc;

        await this.imageLoader(imageSrc).then((image) => {
            const aspectRatios = [thumbWidth / image.width, thumbHeight / image.height];
            const newWidth = Math.round(Math.max(...aspectRatios) * image.width);
            const newHeight = Math.round(Math.max(...aspectRatios) * image.height);
            const cropOffset = {
                x: Math.round((thumbWidth - newWidth) / 2),
                y: Math.round((thumbHeight - newHeight) / 2)
            };

            const resizeCanvas = this.createCanvas(newWidth, newHeight);
            const cropCanvas = this.createCanvas(thumbWidth, thumbHeight);

            const thumbResizer = new Pica();
            const thumbGenerator = thumbResizer.resize(image, resizeCanvas) as Promise<void>;
            return thumbGenerator.then(() => {
                const thumbSrc = resizeCanvas.toDataURL('image/png');

                return this.imageLoader(thumbSrc).then((thumb) => {
                    cropCanvas.getContext('2d').drawImage(
                        thumb,
                        0,
                        0,
                        thumb.width,
                        thumb.height,
                        cropOffset.x,
                        cropOffset.y,
                        thumb.width,
                        thumb.height
                    );
                    thumbnailBase64 = cropCanvas.toDataURL('image/jpeg', 0.75);
                });
            });
        });

        return {
            thumbnail: thumbnailBase64,
            image: await this.fileToBase64(imageFile)
        };
    }

    private async fileToBase64(file): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result instanceof ArrayBuffer ? 'ERROR' : reader.result);
            reader.onerror = error => reject(error);
        });
    }


    /**
     * Create and load an image source
     *
     * @param imageSrc Source to load
     * @returns Promise<HTMLImageElement>
     */
    private imageLoader(imageSrc: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const loader = new Image();
            loader.onload = () => resolve(loader);
            loader.onerror = () => reject(loader);
            loader.src = imageSrc;
        });
    }

    /**
     * Create canvas for processing images
     *
     * @param width Width of the created canvas
     * @param height Height of the created canvas
     * @returns HTMLCanvasElement
     */
    private createCanvas(width, height): HTMLCanvasElement {
        const newCanvas = document.createElement('canvas') as HTMLCanvasElement;
        newCanvas.width  = width;
        newCanvas.height = height;
        return newCanvas;
    }

}
