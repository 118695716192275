import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {AccessibilityService} from '../services/accessibility.service';

export interface FormFieldMessageDirectiveConfig {
    valid?: boolean;
    errorClass?: string;
}

@Directive({
    selector: '[a11yFormFieldMessage]'
})
export class FormFieldMessageDirective implements OnChanges, AfterViewInit, OnDestroy {
    // Used to meet WCAG 2.1 SC 3.3.1
    // This directive is meant to offer the correct and non-spammy implementation of help and validation messages
    // In form fields meant for screen reader narration
    // NOTE - Assumes that the message is in the same shared parent as a singular input field - Up to three levels up

    private hiddenElement: HTMLElement = null;
    private hiddenElementObserver: MutationObserver = null;
    private inputIdentifier = '';
    private describedByElementId = '';

    @Input() a11yFormFieldMessage: FormFieldMessageDirectiveConfig = null;
    private errorClass = 'error-message';
    private valid = true;

    constructor(
        private elementRef: ElementRef,
        private accessibilityService: AccessibilityService
    ) {
    }

    // Change the validation announcement for the input field
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.a11yFormFieldMessage.currentValue.errorClass) {
            this.errorClass = changes.a11yFormFieldMessage.currentValue.errorClass;
        }

        if (changes.a11yFormFieldMessage.firstChange ||
            changes.a11yFormFieldMessage.currentValue.valid !== changes.a11yFormFieldMessage.previousValue.valid) {
            this.valid = !changes.a11yFormFieldMessage.currentValue || changes.a11yFormFieldMessage.currentValue.valid;
            this.updateValidationStatus(this.valid);
        }
    }

    // Clear all the state from the accessibility service
    ngOnDestroy(): void {
        this.updateValidationStatus(true);

        if (this.hiddenElementObserver) {
            this.hiddenElementObserver.disconnect();
            this.accessibilityService.removeUniqueIdentifier(this.hiddenElement.id);
        } else {
            this.accessibilityService.removeUniqueIdentifier(this.elementRef.nativeElement.id);
        }

        // Remove the aria describedby identifier from the input field but leave the others intact
        const inputElement = document.getElementById(this.inputIdentifier);
        if (inputElement) {
            const ariaDescribedByAttr = inputElement.attributes.getNamedItem('aria-describedby');

            if (ariaDescribedByAttr) {
                ariaDescribedByAttr.value = ariaDescribedByAttr.value.split(' ').filter((describedByIdentifier) => {
                    return describedByIdentifier !== this.describedByElementId;
                }).join(' ');

                inputElement.attributes.setNamedItem(ariaDescribedByAttr);
            }
        }
    }

    ngAfterViewInit(): void {
        // Find the input field in the shared parent
        setTimeout(() => {
            // Find form fields recursively up to three levels up
            let formFields = this.elementRef.nativeElement.parentElement.querySelectorAll('input, select, textarea');
            if (formFields.length === 0) {
                formFields = this.elementRef.nativeElement.parentElement.parentElement.querySelectorAll('input, select, textarea');
            }
            if (formFields.length === 0) {
                formFields = this.elementRef.nativeElement.parentElement.parentElement
                    .parentElement.querySelectorAll('input, select, textarea, button');
            }

            if (formFields.length > 0) {
                this.inputIdentifier = formFields[0].id;

                // If no identifier is available on the input field, generate one for ourselves
                if (!this.inputIdentifier) {
                    this.inputIdentifier = this.accessibilityService.generateUniqueIdentifier();
                    formFields[0].id = this.inputIdentifier;
                }

                // If the shared parent is a label - Make sure we generate a hidden element outside of it to reference to
                // So the full label doesn't get announced with every change on the message ( In case of a counter for example )
                if (this.elementRef.nativeElement.parentElement.nodeName.toLowerCase() === 'label') {
                    this.hiddenElement = document.createElement('span');
                    this.hiddenElement.style.opacity = '0%';
                    this.hiddenElement.style.height = '0px';
                    this.hiddenElement.style.width = '0px';
                    this.hiddenElement.style.overflow = 'hidden';
                    this.hiddenElement.style.position = 'absolute';

                    const labelElement = this.elementRef.nativeElement.parentElement;
                    labelElement.parentNode.insertBefore(this.hiddenElement, labelElement.nextSibling);

                    const hideForScreenReaders = document.createAttribute('aria-hidden');
                    hideForScreenReaders.value = 'true';
                    this.elementRef.nativeElement.attributes.setNamedItem(hideForScreenReaders);
                    this.synchronizeDescriptiveText();

                    // Update the hidden field whenever the elements content is changed
                    this.hiddenElementObserver = new MutationObserver((_) => {
                        this.synchronizeDescriptiveText();
                    });
                    this.hiddenElementObserver.observe(this.elementRef.nativeElement, {
                        attributes:    false,
                        childList:     true,
                        subtree:       true,
                        characterData: true
                    });
                }

                // Set the describing identifier on the correct element
                this.describedByElementId = this.accessibilityService.generateUniqueIdentifier(this.inputIdentifier);
                if (this.hiddenElement) {
                    this.hiddenElement.id = this.describedByElementId;
                } else {
                    this.elementRef.nativeElement.id = this.describedByElementId;
                }

                // Fill or append the aria-describedby attribute properly
                let ariaDescribedByAttr = formFields[0].attributes.getNamedItem('aria-describedby');
                if (!ariaDescribedByAttr) {
                    ariaDescribedByAttr = document.createAttribute('aria-describedby');
                }
                ariaDescribedByAttr.value = ariaDescribedByAttr.value ?
                    ariaDescribedByAttr.value + ' ' + this.describedByElementId : this.describedByElementId;
                formFields[0].attributes.setNamedItem(ariaDescribedByAttr);

                this.updateValidationStatus(this.valid);
            }
        }, 500);
    }

    /**
     * Synchronize the descriptive text on the hidden element so it is properly announced to screen readers
     *
     * @private
     */
    private synchronizeDescriptiveText() {
        if (this.hiddenElement) {
            if (this.elementRef.nativeElement.textContent !== this.hiddenElement.textContent) {
                this.hiddenElement.textContent = this.elementRef.nativeElement.textContent;
            }
        }
    }

    private updateValidationStatus(isValid: boolean) {
        if (isValid) {
            this.accessibilityService.addInputError(this.inputIdentifier, this.describedByElementId);
        } else {
            this.accessibilityService.removeInputError(this.inputIdentifier, this.describedByElementId);
        }

        const ariaInvalidAttr = document.createAttribute('aria-invalid');
        if (this.accessibilityService.validateInputIdentifier(this.inputIdentifier)) {
            ariaInvalidAttr.value = 'true';
            const element = document.getElementById(this.inputIdentifier);
            if (element) {
                element.attributes.setNamedItem(ariaInvalidAttr);

                if (!this.elementRef.nativeElement.classList.contains(this.errorClass)) {
                    this.elementRef.nativeElement.classList.add(this.errorClass);
                }
            }
        } else {
            ariaInvalidAttr.value = 'false';
            const element = document.getElementById(this.inputIdentifier);
            if (element) {
                element.attributes.setNamedItem(ariaInvalidAttr);

                if (this.elementRef.nativeElement.classList.contains(this.errorClass)) {
                    this.elementRef.nativeElement.classList.remove(this.errorClass);
                }
            }
        }
    }
}
