import {Component} from '@angular/core';

@Component({
    selector: 'app-page-start',
    templateUrl: './page-start.component.html',
    styleUrls: ['./page-start.component.scss']
})
export class PageStartComponent {

    constructor() {
    }
}
