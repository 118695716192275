export class FocusUtil {

    /**
     * Do a quick DOM query to find all possible focusable elements inside the given element
     *
     * @param element
     */
    public static findCoarseFocusableElements(element: HTMLElement): NodeList {
        return element.querySelectorAll('a, input, button, textarea, select, ng-select, ' +
            '[contenteditable], [focusable], [tabindex]:not([tabindex="-1"])');
    }

    /**
     * Do a fine grained, more expensive check whether or not the html element is keyboard focusable
     *
     * @param element
     */
    public static isKeyboardFocusable(element: HTMLElement): boolean {
        const tabbable = ( !element.attributes.getNamedItem('tabIndex') ||
                element.attributes.getNamedItem('tabIndex').nodeValue !== '-1' ) &&
            element.attributes.getNamedItem('disabled') === null;

        if (tabbable) {
            const style = window.getComputedStyle(element);
            const visibilityProperty = style.getPropertyValue('visibility');
            const displayProperty = style.getPropertyValue('display');

            // Visibility check - Invisible elements cannot be keyboard focussed
            const isVisible = displayProperty !== 'none' && visibilityProperty !== 'hidden' && visibilityProperty !== 'collapse'
                && (element.offsetWidth * element.offsetHeight > 0);

            // Extra check for NG-SELECT input fields, that have invisible input fields that can be focused
            // The easiest check is to see if the direct parent is a combobox
            if (!isVisible) {
                const roleAtrribute = element.parentElement.attributes.getNamedItem('role');
                return !roleAtrribute || roleAtrribute.nodeValue === 'combobox';
            } else {
                return isVisible;
            }
        }

        return false;
    }

    /**
     * Apply a hotkey marker to an HTML element
     *
     * @param element
     * @param marker
     */
    public static applyHotkeyMarker(element: HTMLElement, marker) {
        const focusableElements = FocusUtil.findCoarseFocusableElements(element);
        if (focusableElements) {

            // Make sure to remove elements that have the tabindex set to -1 for regular interactive elements
            // Like a tags that we do not want to be focusable
            const firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

            if (firstFocusableElement) {
                const dataCreateAttr = document.createAttribute(marker);
                (firstFocusableElement as HTMLElement).attributes.setNamedItem(dataCreateAttr);
            }
        }
    }

}
