export enum ProjectStatus {
    INITIAL = 'initial',
    QUICKSCAN_SAVED = 'quickscan_saved',
    MANDATING = 'mandating',
    MANDATING_FAILED = 'mandating_failed',
    MANDATING_SUCCEEDED = 'mandating_succeeded',
    IN_EXECUTION = 'in_execution',
    CLOSED = 'closed',
    ON_HOLD = 'on_hold',
}
