<div a11yFocusTrap>
    <vwui-modal-header>
        {{"PAGE_TIS_VERSIONS.modal.title" | translate : { tisLabelShort: companyType?.tisLabelShort, tisLabelLong: companyType?.tisLabelLong } }}
    </vwui-modal-header>
    <vwui-modal-body>
        {{"PAGE_TIS_VERSIONS.modal.question" | translate : { tisLabelShort: companyType?.tisLabelShort, tisLabelLong: companyType?.tisLabelLong } }}
    </vwui-modal-body>
    <vwui-modal-footer a11yAutoFocus>
        <vwui-button class="is-secondary" [loading]="loading" (click)="submit()">
            {{"PAGE_TIS_VERSIONS.modal.publish" | translate}}
        </vwui-button>
    </vwui-modal-footer>
</div>
