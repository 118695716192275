import {Injectable} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {FocusUtil} from '../utils/focus.util';
import {v4 as uuidv4} from 'uuid';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

export enum HotkeyType {
    CONFIRM = 'hotkeyConfirm',
    FOCUS_SEARCH = 'hotkeyFocusSearch',
    CREATE = 'hotkeyCreate',
    DELETE = 'hotkeyDelete',
    FOCUS_FIRST = 'hotkeyFocusFirst',
    FOCUS_LAST = 'hotkeyFocusLast'
}

const HotkeyMark = {
    hotkeyConfirm: 'data-hotkey-confirm',
    hotkeyFocusSearch: 'data-hotkey-search',
    hotkeyCreate: 'data-hotkey-create',
    hotkeyDelete: 'data-hotkey-delete'
};

export enum ContrastSettings { // WCAG compliance for contrast ( https://webaim.org/articles/contrast/#ratio )
    REGULAR, // No WCAG compliance required
    INCREASED, // WCAG AA compliant - Requires contrast ratio of 4.5:1
    HIGHEST// WCAG AAA compliant - Requires contrast ratio's of 7:1
}

interface A11yStyleSettings {
    animations: boolean;
    contrast: ContrastSettings;
    fontSize: number;
}

const defaultA11yStyleSettings: A11yStyleSettings = {
    animations: true,
    contrast: ContrastSettings.REGULAR,
    fontSize: 1
};

const defaultHotkeys = {
    hotkeyConfirm: {
        keyCode: 13,
        key: 'enter',
        ctrlKey: true,
        shiftKey: false,
        altKey: false,
        allowed: true
    },
    hotkeyFocusSearch: {
        keyCode: 191,
        key: '/',
        ctrlKey: true,
        shiftKey: false,
        altKey: false,
        allowed: true
    },
    hotkeyCreate: {
        keyCode: 78,
        key: 'n',
        ctrlKey: false,
        shiftKey: false,
        altKey: true,
        allowed: true
    },
    hotkeyDelete: {
        keyCode: 46,
        key: 'delete',
        ctrlKey: true,
        shiftKey: false,
        altKey: false,
        allowed: true
    },
    hotkeyFocusFirst: {
        keyCode: 36,
        key: 'home',
        ctrlKey: false,
        shiftKey: false,
        altKey: false,
        allowed: true
    },
    hotkeyFocusLast: {
        keyCode: 35,
        key: 'end',
        ctrlKey: false,
        shiftKey: false,
        altKey: false,
        allowed: true
    },
    hotkeysEnabled: true
};

export {HotkeyMark, A11yStyleSettings};

@Injectable({
    providedIn: 'root'
})
export class AccessibilityService {

    // Global representation of all available hotkeys to make it configurable for the user
    @LocalStorage('hotkeys', defaultHotkeys) hotkeys;
    @LocalStorage('styleSettings', defaultA11yStyleSettings) styleSettings;

    // For some reason this class instantiated twice when it is imported in another module
    // Could not figure out why, using static for now instead
    @LocalStorage('browserScaledFontSize', 1) browserScaledFontSize;

    private currentPageTitle: string;
    private previousUrlWithoutQueryParams = '';

    // HTML identifiers for the announcement regions
    private ariaTitleIdentifier = 'a11y-title';
    private focusResetIdentifier = 'a11y-focus-reset';

    private uniqueIdentifiers = [];
    private inputIdentifiers = {};

    // Keep multiple page titles available to make sure we can revert to a previous level in case one level is overwritten
    // For example - in the case of a modal having a level 2 title element as well as the page having a level 2 title element
    private pageTitleElements = {
        level0: [],
        level1: [],
        level2: [],
        level3: []
    };

    private routeChangedSubscription: Subscription;

    public constructor(
        private router: Router
    ) {
    }

    /**
     * Initialize the page title headers
     */
    public init(
        whitelistedRoutes: string[] = []
    ) {
        this.currentPageTitle = document.title;
        this.pageTitleElements.level0.push(document.title);

        // Detect font scaling from browser settings
        // Based on a default of 16px
        const computedStyle = window.getComputedStyle(document.documentElement);
        if (computedStyle && computedStyle.fontSize.includes('px')) {
            const scaledFontSize = parseInt(computedStyle.fontSize.replace('px', ''), 10);
            this.browserScaledFontSize = Math.max(1, scaledFontSize / 16);
        } else {
            this.browserScaledFontSize = 1;
        }

        // Synchronize font size with browser font size
        if (this.browserScaledFontSize > this.styleSettings.fontSize) {
            this.styleSettings.fontSize = this.browserScaledFontSize;
        }

        // Add the focus, create, confirm and delete hotkey to the body element
        document.body.addEventListener('keydown', ($event) => {
            this.globalHotkeyCallback($event);
        });

        // Add an invisible live region to announce title updates to
        this.createLiveRegion(this.ariaTitleIdentifier, 'polite');

        // Add the focus reset element to the top of the page
        const resetElement = document.createElement('div');
        resetElement.id = this.focusResetIdentifier;
        const tabIndexAttr = document.createAttribute('tabindex');
        tabIndexAttr.value = '-1';
        resetElement.attributes.setNamedItem(tabIndexAttr);
        document.body.insertBefore(resetElement, document.body.firstChild);

        // Listen for route changes to do a focus reset on to make sure the page behaves like a regular web page
        // The resetting of focus is done to make sure screen reader users aren't disoriented when a page transition happens focus wise
        // Do not reset focus when the URL changes due to query parameters - Those should be reserved for search and filters anyway
        this.routeChangedSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlWithoutQueryParams = event.url.split('?')[0];

                let shouldResetFocus = urlWithoutQueryParams !== this.previousUrlWithoutQueryParams;
                for (const whitelistedRoute in whitelistedRoutes) {
                    if (event.url.startsWith(whitelistedRoute)) {
                        shouldResetFocus = false;
                        break;
                    }
                }

                if (shouldResetFocus) {
                    this.resetFocus();
                }

                this.previousUrlWithoutQueryParams = urlWithoutQueryParams;
            }
        });

        this.updateStyles();
    }

    /**
     * Global hotkey callback for stuff like focussing the create button or the search fields
     * This will only be called when no other element has caught the event
     *
     * @param $event
     * @private
     */
    private globalHotkeyCallback($event) {
        let focusableElement;
        const hotkeyTypes = [HotkeyType.FOCUS_SEARCH, HotkeyType.CREATE, HotkeyType.DELETE, HotkeyType.CONFIRM];
        for (const hotkeyType of hotkeyTypes) {
            if (this.checkHotkey($event, hotkeyType)) {
                const selector = `[${HotkeyMark[hotkeyType]}]`;
                const elements = document.querySelectorAll(selector);

                if (elements) {
                    if (FocusUtil.isKeyboardFocusable(elements[0] as HTMLElement)) {
                        focusableElement = elements[0];
                    } else {
                        const focusableElements = FocusUtil.findCoarseFocusableElements(elements[0] as HTMLElement);
                        focusableElement = Array.from(focusableElements).find((element) => {
                            return FocusUtil.isKeyboardFocusable((element as HTMLElement));
                        });
                    }
                }
            }

            if (focusableElement) {
                (focusableElement as HTMLElement).focus();
                (focusableElement as HTMLElement).click();
                $event.preventDefault();
                break;
            }
        }
    }

    /**
     * Used to set a descriptive page title to adhere to WCAG 2.0 criteria 2.4.2\
     *
     * @param title
     * @param level
     */
    public setPageTitle(title: string, level: number) {
        if (level > -1 && level <= 3) {
            this.pageTitleElements['level' + level].push(title.trim());
            this.updatePageTitle();
        }
    }

    /**
     * Clear a part of the descriptive page title
     *
     * @param title
     * @param level
     */
    public clearPageTitle(title: string, level: number) {
        if (level > -1 && this.pageTitleElements['level' + level].includes(title.trim())) {
            this.pageTitleElements['level' + level] = this.pageTitleElements['level' + level].filter((item) => item !== title.trim());
            this.updatePageTitle();
        }
    }

    private updatePageTitle() {
        const titleElements = [];

        for (let i = 3; i > -1; i--) {
            if (this.pageTitleElements['level' + i].length > 0) {
                titleElements.push( this.pageTitleElements['level' + i][this.pageTitleElements['level' + i].length - 1]);
            }
        }

        const newTitle = titleElements.filter((element) => element).join(' | ');
        if (this.currentPageTitle !== newTitle) {
            this.currentPageTitle = newTitle;
            document.title = this.currentPageTitle;

            // TODO - This should probably be debounced to prevent a storm of announcements to screen readers?
            const element = document.getElementById(this.ariaTitleIdentifier);
            if (element) {
                element.innerHTML = this.currentPageTitle;
            }
        }
    }

    /**
     * Check if a specific hotkey has been used
     *
     * @param $event
     * @param hotkey_type
     */
    public checkHotkey($event: KeyboardEvent, hotkey_type: HotkeyType): boolean {
        if (this.hotkeys.hotkeysEnabled === false) {
            return false;
        }

        const hotkeyData = this.hotkeys[hotkey_type];
        return hotkeyData && $event.keyCode === hotkeyData.keyCode && $event.ctrlKey === hotkeyData.ctrlKey &&
            $event.shiftKey === hotkeyData.shiftKey && $event.altKey === hotkeyData.altKey;
    }

    /**
     * Generate a unique HTML identifier for use in aria connections
     *
     * @param postfix
     */
    public generateUniqueIdentifier(postfix = ''): string {
        let uniqueIdentifier = uuidv4() + postfix;
        while (this.uniqueIdentifiers.includes(uniqueIdentifier)) {
            uniqueIdentifier = uuidv4() + postfix;
        }

        this.uniqueIdentifiers.push(uniqueIdentifier);
        return uniqueIdentifier;
    }

    /**
     * Remove the identifier from the list of identifiers
     *
     * @param identifier
     */
    public removeUniqueIdentifier(identifier) {
        if (!identifier) {
            this.uniqueIdentifiers = this.uniqueIdentifiers.filter((uniqueIdentifier) => uniqueIdentifier !== identifier);
        }
    }

    /**
     * Add a known input error to the input field - Used in the form field message directive to manage aria-invalid
     *
     * @param inputIdentifier
     * @param errorIdentifier
     */
    public addInputError(inputIdentifier: string, errorIdentifier: string) {
        if (!this.inputIdentifiers.hasOwnProperty(inputIdentifier)) {
            this.inputIdentifiers[inputIdentifier] = [];
        }

        if (!this.inputIdentifiers[inputIdentifier].includes(errorIdentifier)) {
            this.inputIdentifiers[inputIdentifier].push(errorIdentifier);
        }
    }

    /**
     * Remove an input error - Used in the form field message directive to manage aria-invalid
     *
     * @param inputIdentifier
     * @param errorIdentifier
     */
    public removeInputError(inputIdentifier: string, errorIdentifier: string) {
        if (this.inputIdentifiers.hasOwnProperty(inputIdentifier)) {
            this.inputIdentifiers[inputIdentifier] = this.inputIdentifiers[inputIdentifier].filter((errors) => errors !== errorIdentifier);
        }
    }

    /**
     * Check if the input field is valid according to the amount of input errors available
     *
     * @param inputIdentifier
     */
    public validateInputIdentifier(inputIdentifier: string) {
        return !this.inputIdentifiers.hasOwnProperty(inputIdentifier) || this.inputIdentifiers[inputIdentifier].length === 0;
    }

    /**
     * Reset the focus to the resete element on top of the page to make sure the route navigation behaves like a regular webpage
     * Instead of every navigation having the focus on an arbitrary place
     */
    public resetFocus() {
        const element = document.getElementById(this.focusResetIdentifier);
        if (element) {
            (element as HTMLElement).focus();
        }
    }

    /**
     * Update the styling related settings according to the user configured settings
     */
    public updateStyles() {
        const a11yClasses = [];

        const prefix = 'theme-a11y--';
        const possibleClasses = [`${prefix}fontsize-125`, `${prefix}fontsize-150`, `${prefix}fontsize-175`, `${prefix}fontsize-200`,
            `${prefix}no-animations`, `${prefix}contrast`, `${prefix}contrast-highest`];

        // How much we should change the paddings based on the actual font size
        const fontSize = Math.max(this.styleSettings.fontSize, this.browserScaledFontSize);
        if (fontSize > 1) {
            if (fontSize <= 1.25) {
                a11yClasses.push(`${prefix}fontsize-125`);
            } else if (fontSize <= 1.5) {
                a11yClasses.push(`${prefix}fontsize-150`);
            } else if (fontSize <= 1.75) {
                a11yClasses.push(`${prefix}fontsize-175`);
            } else if (fontSize > 1.75) {
                a11yClasses.push(`${prefix}fontsize-200`);
            }
        }

        // How much we should scale the font based on the difference between the default browser font scale and the filled in set scale
        // We use a style here instead of a class as the difference can be quite dynamic
        const expectedFontScale = 1 + this.styleSettings.fontSize - this.browserScaledFontSize;
        if (expectedFontScale > 1) {
            document.documentElement.style.fontSize = (expectedFontScale * 100) + '%';
        } else {
            document.documentElement.style.fontSize = null;
        }

        // Allow reduced animations
        if (!this.styleSettings.animations) {
            a11yClasses.push(`${prefix}no-animations`);
        }

        // Allow increased contrast
        if (this.styleSettings.contrast === ContrastSettings.INCREASED) {
            a11yClasses.push(`${prefix}contrast`);
        } else if (this.styleSettings.contrast === ContrastSettings.HIGHEST) {
            a11yClasses.push(`${prefix}contrast-highest`);
        }

        const htmlClasses = document.documentElement.classList;
        htmlClasses.remove(...possibleClasses);
        htmlClasses.add(...a11yClasses);
    }

    /**
     * Retrieve the style settings defined by the user
     */
    public getStyleSettings() {
        return this.styleSettings;
    }

    /**
     * Get the user configured hotkeys to be altered by the application
     */
    public getHotkeys() {
        return this.hotkeys;
    }

    /**
     * Override the style settings defined by the user
     */
    public setStyleSettings(styleSettings) {
        this.styleSettings = styleSettings;
        this.updateStyles();
    }

    /**
     * Override the user configured hotkeys to be altered by the application
     */
    public setHotkeys(hotkeys) {
        this.hotkeys = hotkeys;
    }

    /**
     * Adds invisible live regions that get announced to screen readers - But are invisible to regular users
     *
     * @param identifier
     * @param ariaLiveType
     * @private
     */
    private createLiveRegion(identifier: string, ariaLiveType: string) {
        if (!document.getElementById(identifier)) {
            const ariaRegionAttribute = document.createAttribute('aria-live');
            ariaRegionAttribute.value = ariaLiveType;
            const liveRegion = document.createElement('div');
            liveRegion.style.opacity = '0';
            liveRegion.style.position = 'absolute';
            liveRegion.style.left = '-1000px';
            liveRegion.id = identifier;
            liveRegion.attributes.setNamedItem(ariaRegionAttribute);
            document.body.appendChild(liveRegion);
        }
    }

}
