import {
    Library,
    ProjectDetails,
    QuickscanDefinition,
    QuickscanResult,
    TisDefinition,
    ProjectMandateDetails
} from '..';
import {IPdfDetails} from './pdf-details-data';

export class PdfDetails {

    public projectDetails: ProjectDetails;
    public quickscanResult: QuickscanResult;
    public tisQuickscanResult: QuickscanResult;
    public tisDefinition: TisDefinition;
    public quickscanDefinition: QuickscanDefinition;
    public risksLibrary: Library;
    public opportunitiesLibrary: Library;
    public mandateDetails: ProjectMandateDetails;

    constructor(data: IPdfDetails) {
        this.projectDetails = new ProjectDetails(data.projectDetails);
        this.quickscanResult = new QuickscanResult(data.quickscanResult);
        this.tisQuickscanResult = new QuickscanResult(data.tisQuickscanResult);
        this.tisDefinition = new TisDefinition(data.tisDefinition);
        this.quickscanDefinition = new QuickscanDefinition(data.quickscanDefinition);
        this.risksLibrary = new Library(data.risksLibrary);
        this.opportunitiesLibrary = new Library(data.opportunitiesLibrary);
        this.mandateDetails = new ProjectMandateDetails(data.mandateDetails);
    }
}
