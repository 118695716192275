<form *ngIf="formGroup" [formGroup]="formGroup">
    <div>{{ 'COMPONENT.library_mgmt.item.quantification_description' | translate }}</div>
    <div>&nbsp;</div>
    <vwui-form-group inputId="effectCategoryMoney">
        <vwui-checkbox inputId="effectCategoryMoney" formControlName="effectCategoryMoney">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategoryMoney' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategoryTime">
        <vwui-checkbox inputId="effectCategoryTime" formControlName="effectCategoryTime">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategoryTime' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategoryQuality">
        <vwui-checkbox inputId="effectCategoryQuality" formControlName="effectCategoryQuality">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategoryQuality' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategorySafety">
        <vwui-checkbox inputId="effectCategorySafety" formControlName="effectCategorySafety">{{ 'COMPONENT.library_mgmt.library_type.' +libraryType + '.item.effectCategorySafety' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategorySurroundings">
        <vwui-checkbox inputId="effectCategorySurroundings" formControlName="effectCategorySurroundings">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategorySurroundings' | translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategoryImage">
        <vwui-checkbox inputId="effectCategoryImage" formControlName="effectCategoryImage">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategoryImage'| translate }}</vwui-checkbox>
    </vwui-form-group>
    <vwui-form-group inputId="effectCategoryReputation">
        <vwui-checkbox inputId="effectCategoryReputation" formControlName="effectCategoryReputation">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.item.effectCategoryReputation'| translate }}</vwui-checkbox>
    </vwui-form-group>
</form>
