<div class="block-stats">
    <div class="block-stats__summary">
        <div class="block-stats__value">{{ config.value }}</div>
        <div class="block-stats__value-of">/{{ config.of }}</div>
        <div class="block-stats__label">{{ config.label }}</div>
    </div>
    <div class="block-stats__progress">
        <div class="block-stats__progress-bar" [ngStyle]="{'width.%': config.progress, 'backgroundColor': config.barColor}"></div>
    </div>
    <div class="block-stats__slot"><ng-content></ng-content></div>
</div>
