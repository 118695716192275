import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IPdfDetails} from '../../models/pdf/pdf-details-data';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {IAttachment} from '../../models/project/project-data';
import {Question} from '../../models/definition/question';
import {Category} from '../../models';

@Component({
    selector: 'app-pdf-page-project-tis',
    templateUrl: './pdf-page-project-tis.component.html',
    styleUrls: ['./pdf-page-project-tis.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageProjectTisComponent {

    @Input() pdfDetails: IPdfDetails;

    constructor(
        private datePipe: DatePipe,
        private translateService: TranslateService
    ) {
    }

    public getTisAnswer(questionId: number): string {
        const answer = this.pdfDetails.projectDetails.tisAnswers.find((tisAnswer) => questionId === tisAnswer.questionId);

        if (answer && answer.value.text) {
            return answer.value.text.trim();
        } else if (answer && answer.value.date) {
            return this.datePipe.transform(answer.value.date, this.translateService.instant('DATE.short'));
        }

        return '';
    }

    public hasAttachmentsForTisAnswer(questionId: number): boolean {
        const answer = this.pdfDetails.projectDetails.tisAnswers.find((tisAnswer) => questionId === tisAnswer.questionId);
        return answer && answer.value && answer.value.attachments.length > 0;
    }

    public getAttachmentsForTisAnswer(questionId: number): IAttachment[] {
        const answer = this.pdfDetails.projectDetails.tisAnswers.find((tisAnswer) => questionId === tisAnswer.questionId);
        return answer && answer.value && answer.value.attachments.length > 0 ? answer.value.attachments : [];
    }

    public trackTisSubCategory(index: number, category: Category): string {
        return `${category.id}`;
    }

    public trackTisSubCategoryQuestion(index: number, question: Question): string {
        return `${question.getParent().id}_${question.id}`;
    }

}
