import {ProjectClassification, ProjectStatus} from '../../enums';
import {IUserIdentity} from '../user/user';

export interface IProject {
    id: number;
    name: string;
    owner: IUserIdentity;
    riskManager: IUserIdentity;
    status: ProjectStatus;
    statusBeforeOnHold: ProjectStatus;
    modifiedAt: Date;
    classification: ProjectClassification;
    tisClassification: ProjectClassification;
    tisClassificationOutdated: boolean;
    address: string;
    city: string;
    country: string;
    company: string;
    companyTypeId: number;
    thumbnailBase64?: string;
    members: IProjectMember[];
    companyMembers: IMember[];
    tisLabelShort: string;
}

export interface IPageableProjects {
    content: IProject[];
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    number: number;
    size: number;
    last: boolean;
    first: boolean;
}

export interface IMember {
    user: IUserIdentity;
    hasViewRights: boolean;
    hasEditRights: boolean;
}

export interface IProjectMember {
    user: IUserIdentity;
    quickscanEditor: boolean;
    tisEditor: boolean;
    projectLibraryItemEditor: boolean;
    hasViewRights: boolean;
}

export class Project implements IProject {
    id: number;
    name: string;
    owner: IUserIdentity;
    riskManager: IUserIdentity;
    status: ProjectStatus;
    statusBeforeOnHold: ProjectStatus;
    modifiedAt: Date;
    classification: ProjectClassification;
    tisClassification: ProjectClassification;
    tisClassificationOutdated: boolean;
    address: string;
    city: string;
    country: string;
    company: string;
    companyTypeId: number;
    thumbnailBase64?: string;
    members: ProjectMember[];
    companyMembers: Member[];
    tisLabelShort: string;

    constructor(data: IProject) {
        this.id = data.id;
        this.name = data.name;
        this.owner = data.owner;
        this.riskManager = data.riskManager;
        this.status = data.status;
        this.statusBeforeOnHold = data.statusBeforeOnHold;
        this.modifiedAt = new Date(data.modifiedAt);
        this.classification = data.classification;
        this.tisClassification = data.tisClassification;
        this.tisClassificationOutdated = data.tisClassificationOutdated;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.company = data.company;
        this.companyTypeId = data.companyTypeId;
        this.thumbnailBase64 = data.thumbnailBase64;
        this.members = data.members ? data.members.map((member) => new ProjectMember(member)) : [];
        this.companyMembers = data.companyMembers ? data.companyMembers.map((member) => new Member(member)) : [];
        this.tisLabelShort = data.tisLabelShort ? data.tisLabelShort : 'TIS';
    }
}

export class Member implements IMember {
    user: IUserIdentity;
    hasViewRights: boolean;
    hasEditRights: boolean;

    constructor(data: IMember) {
        this.user = data.user;
        this.hasViewRights = data.hasViewRights;
        this.hasEditRights = data.hasEditRights;
    }
}

export class ProjectMember implements IProjectMember {
    user: IUserIdentity;
    quickscanEditor: boolean;
    tisEditor: boolean;
    projectLibraryItemEditor: boolean;
    hasViewRights: boolean;

    constructor(data: IProjectMember) {
        this.user = data.user;
        this.quickscanEditor = data.quickscanEditor;
        this.tisEditor = data.tisEditor;
        this.projectLibraryItemEditor = data.projectLibraryItemEditor;
        this.hasViewRights = data.hasViewRights;
    }

    isProjectEditor(): boolean {
        // These fields are the only two that require saving of project details
        return this.quickscanEditor || this.tisEditor;
    }
}
