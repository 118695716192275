<form [formGroup]="form" class="form__wrapper" (submit)="submit()" a11yFocusTrap>
    <div class="form__fixed-part">
        <div class="form__fixed-part-header">
            <div class="library-item__top" *ngIf="!(item.isProjectSpecific && !item.id)">
                <h2 a11yPageTitleLevel="2" class="library-item__title">
                    {{ item.getTitle(getAllItems()) }}
                    <ng-container *ngIf="item.isProjectSpecific">
                        &nbsp;|&nbsp;
                        <small>{{'COMPONENT.quantify_supplement_project_library.form.category.project_specific.' + item.libraryType | translate }}</small>
                    </ng-container>
                </h2>
                <div class="button-group">
                    <vwui-button class="is-light" [appDisabledReason]="getCloneDisabledReason() | translate" (click)="clone()" *ngIf="item.id">
                        <vwui-icon iconName="clone-regular"></vwui-icon>
                    </vwui-button>
                    <vwui-button class="is-light" (click)="remove()" *ngIf="item.id"
                                 [appDisabledReason]="getDeleteDisabledReason() | translate">
                        <vwui-icon iconName="vwui-trash"></vwui-icon>
                    </vwui-button>
                    <vwui-button class="is-light" (click)="modalRef.close()">
                        <vwui-icon iconName="close"></vwui-icon>
                    </vwui-button>
                </div>
            </div>
            <div class="library-item__top" *ngIf="item.isProjectSpecific && !item.id">
                <div class="library-item__title">{{ 'COMPONENT.library_mgmt.library_type.' + libraryType + '.project_specific.create_item.title' | translate }}</div>
                <div class="button-group">
                    <vwui-button class="is-light" (click)="modalRef.close()">
                        <vwui-icon iconName="close"></vwui-icon>
                    </vwui-button>
                </div>
            </div>
        </div>
    </div>

    <div class="form__content">
        <vwui-modal-body a11yAutoFocus>
            <vwui-tab-bar [selected]="activeTab" (vwuiTabChange)="onTabChange($event.detail)" class="form__fixed" [a11yTablistControls]="activeTab">
                <vwui-tab
                    tabId="description"> {{ 'COMPONENT.quantify_supplement_project_library.tab.description' | translate }} </vwui-tab>
                <vwui-tab
                    tabId="quantification"> {{ 'COMPONENT.quantify_supplement_project_library.tab.quantification' | translate }} </vwui-tab>
                <vwui-tab
                    tabId="riskMitigation"> {{ 'COMPONENT.quantify_supplement_project_library.tab.riskMitigation' | translate }} </vwui-tab>
                <vwui-tab
                    tabId="remarks"
                    *ngIf="item.id"> {{ 'COMPONENT.quantify_supplement_project_library.tab.remarks' | translate }} </vwui-tab>
            </vwui-tab-bar>

            <div ngSwitch="{{ activeTab }}" class="modal-tab">
                <ng-container *ngSwitchCase="'description'">
                    <vwui-form-group inputId="subject" *ngIf="item.isProjectSpecific"
                                     label="{{ 'COMPONENT.library_mgmt.library_type.risks.item.subject' | translate }}"
                                     required>
                        <vwui-input inputId="subject" formControlName="subject"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group inputId="category" *ngIf="item.isProjectSpecific"
                                     label="{{ 'COMPONENT.library_mgmt.library_type.risks.item.category' | translate }}"
                                     required>
                        <ng-select
                            [items]="categories | async "
                            [selectOnTab]="true"
                            [clearable]="false"
                            [multiple]="false"
                            [required]="true"
                            bindLabel="name"
                            bindValue="id"
                            formControlName="libraryCategoryId"
                        ></ng-select>
                    </vwui-form-group>
                    <vwui-form-group inputId="riskClient" *ngIf="item.libraryType === 'risks'">
                        <vwui-checkbox formControlName="riskClient">
                            {{"COMPONENT.quantify_supplement_project_library.form.riskClient" | translate}}
                        </vwui-checkbox>
                    </vwui-form-group>
                    <vwui-form-group inputId="riskDescription"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.riskDescription' | translate }}"
                                     required>
                        <vwui-textarea class="vwui-textarea--small" inputId="riskDescription"
                                       formControlName="riskDescription"></vwui-textarea>
                    </vwui-form-group>
                    <vwui-form-group inputId="causeDescription"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.causeDescription' | translate }}"
                                     required>
                        <vwui-textarea class="vwui-textarea--small" inputId="causeDescription"
                                       formControlName="causeDescription"></vwui-textarea>
                    </vwui-form-group>
                    <vwui-form-group inputId="effectDescription"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.effectDescription' | translate }}"
                                     required>
                        <vwui-textarea class="vwui-textarea--small" inputId="effectDescription"
                                       formControlName="effectDescription"></vwui-textarea>
                    </vwui-form-group>
                    <vwui-form-group inputId="itemOwner"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.itemOwner' | translate }}"
                                     required>
                        <vwui-input inputId="itemOwner" formControlName="itemOwner"></vwui-input>
                    </vwui-form-group>
                </ng-container>
                <ng-container *ngSwitchCase="'quantification'">
                    <app-library-item-quantification-grid
                        [entry]="item"
                        [projectDetails]="projectDetails"
                        formControlName="quantification"
                    >
                    </app-library-item-quantification-grid>
                </ng-container>
                <ng-container *ngSwitchCase="'riskMitigation'">
                    <vwui-form-group inputId="status"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.status' | translate }}"
                                     required>
                        <ng-select
                            [items]="statuses"
                            [selectOnTab]="true"
                            [clearable]="false"
                            [multiple]="false"
                            [required]="true"
                            [readonly]="cannotChangeStatus$ | async"
                            bindLabel="label"
                            bindValue="id"
                            formControlName="status"
                        ></ng-select>
                        <ng-container *ngIf="item.status === 'proposed' && !canChangeStatus()">
                            <app-input-hint>{{ 'COMPONENT.quantify_supplement_project_library.form.status_proposed' | translate }}</app-input-hint>
                        </ng-container>
                    </vwui-form-group>
                    <vwui-form-group inputId="includedInPer"
                                     required>
                        <vwui-checkbox inputId="includedInPer"
                                       formControlName="includedInPer">{{ 'COMPONENT.quantify_supplement_project_library.form.included_in_per' | translate }}</vwui-checkbox>
                    </vwui-form-group>
                    <label>
                        <span
                            class="vwui-form-group__label">{{ 'COMPONENT.quantify_supplement_project_library.form.financialImpact' | translate }}</span>
                    </label>
                    <div class="financial-impact-table">
                        <div class="financial-impact-table financial-impact-table--cell">
                            <vwui-form-group inputId="minCosts"
                                             label="{{ 'COMPONENT.quantify_supplement_project_library.form.minCosts_' + libraryType | translate }}"
                                             required>
                                <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input"
                                     [ngClass]="{'disabled': !canEditProject() }">
                                    <input class="sc-vwui-input" type="text" formControlName="minCosts"/>
                                </div>
                            </vwui-form-group>
                        </div>
                        <div class="financial-impact-table financial-impact-table--cell">
                            <vwui-form-group inputId="bestGuessCosts"
                                             label="{{ 'COMPONENT.quantify_supplement_project_library.form.bestGuessCosts' | translate }}"
                                             required>
                                <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input"
                                     [ngClass]="{'disabled': !canEditProject() }">
                                    <input class="sc-vwui-input" type="text" formControlName="bestGuessCosts"/>
                                </div>
                            </vwui-form-group>
                        </div>
                        <div class="financial-impact-table financial-impact-table--cell">
                            <vwui-form-group inputId="maxCosts"
                                             label="{{ 'COMPONENT.quantify_supplement_project_library.form.maxCosts_' + libraryType | translate }}"
                                             required>
                                <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input"
                                     [ngClass]="{'disabled': !canEditProject() }">
                                    <input class="sc-vwui-input" type="text" formControlName="maxCosts"/>
                                </div>
                            </vwui-form-group>
                        </div>
                        <div *ngIf="this.form.get('includedInPer').value" class="financial-impact-table financial-impact-table--cell">
                            <vwui-form-group inputId="perCosts"
                                             label="{{ 'COMPONENT.quantify_supplement_project_library.form.perCosts' | translate }}"
                                             required>
                                <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input"
                                     [ngClass]="{'disabled': !canEditProject() }">
                                    <input class="sc-vwui-input" type="text" formControlName="perCosts"/>
                                </div>
                            </vwui-form-group>
                        </div>
                    </div>

                    <vwui-form-group inputId="counterMeasuresActionHolder"
                                 label="{{ 'COMPONENT.quantify_supplement_project_library.form.counterMeasuresActionHolder' | translate }}"
                                 required>
                    <vwui-input inputId="counterMeasuresActionHolder"
                                formControlName="counterMeasuresActionHolder"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group inputId="counterMeasures"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.counterMeasures' | translate }}"
                                     required>
                        <vwui-textarea class="vwui-textarea--small" inputId="counterMeasures"
                                       formControlName="counterMeasures"></vwui-textarea>
                    </vwui-form-group>
                    <vwui-form-group inputId="counterMeasuresCosts"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.counterMeasuresCosts' | translate }}"
                                     required>
                        <div class="sc-vwui-input-h sc-vwui-input-s vwui-container sc-vwui-input"
                             [ngClass]="{'disabled': !canEditProject() }">
                            <input class="sc-vwui-input" type="text" formControlName="counterMeasuresCosts"/>
                        </div>
                    </vwui-form-group>

                    <ng-container *ngIf="!this.form.enabled">
                        <vwui-form-group
                            [label]="'COMPONENT.quantify_supplement_project_library.form.counterMeasuresDeadline' | translate">
                            <vwui-input type="text" [readonly]="true" [disabled]="true"
                                        [value]="readonlyCounterMeasureDeadline"></vwui-input>
                        </vwui-form-group>
                    </ng-container>
                    <ng-container *ngIf="this.form.enabled">
                        <vwui-form-group
                            inputId="counterMeasuresDeadline"
                            label="{{ 'COMPONENT.quantify_supplement_project_library.form.counterMeasuresDeadline' | translate }}"
                        >
                            <vwui-datepicker
                                [flatpickrOptions]="{dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, convertModelValue: true, allowInput: true, locale: 'nl'}"
                                formControlName="counterMeasuresDeadline"
                            ></vwui-datepicker>
                        </vwui-form-group>
                    </ng-container>

                </ng-container>
                <ng-container *ngSwitchCase="'remarks'">
                    <vwui-form-group inputId="remarks"
                                     label="{{ 'COMPONENT.quantify_supplement_project_library.form.remarks' | translate }}">
                        <vwui-textarea class="vwui-textarea--small" inputId="newRemark"
                                       formControlName="newRemark"></vwui-textarea>
                    </vwui-form-group>
                    <vwui-button class="is-secondary add-button" [loading]="loading" (click)="addRemark()"
                                 [appDisabledReason]="getAddRemarkDisabledReason()">
                        {{ 'COMPONENT.quantify_supplement_project_library.form.add_new_remark' | translate }}
                    </vwui-button>
                    <vwui-table *ngIf="item.remarks.length > 0" [compactable]="false">
                        <div
                            slot="table-title">{{ 'COMPONENT.quantify_supplement_project_library.remarks_logbook.title' | translate }}</div>
                        <vwui-table-row *ngFor="let remark of item.remarks">
                            <vwui-table-column class="remarks-logbook remarks-logbook--multiline">
                                {{ remark.remark }}
                                <div
                                    class="remarks-logbook__created">{{ remark.createdAt | date: ('DATE.long' | translate) }}
                                    &nbsp;-&nbsp; {{ remark.user | namesFormat }}</div>
                            </vwui-table-column>
                        </vwui-table-row>
                    </vwui-table>
                </ng-container>
            </div>
        </vwui-modal-body>
    </div>
    <div class="form__fixed-part form__fixed-part--fade">
        <vwui-modal-footer>
            <vwui-button class="is-light" (click)="modalRef.close()">
                {{ 'BUTTON.close' | translate }}
            </vwui-button>
            <vwui-button class="is-primary" [loading]="loading" (click)="submit()"
                         [appDisabledReason]="getSaveDisabledReason()">
                {{ 'BUTTON.save' | translate }}
            </vwui-button>
        </vwui-modal-footer>
    </div>
</form>
