import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Company, ICompany} from '../../models';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class CompanyService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getCompanies(): Promise<Company[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/company/all').then(async (response: HttpResponse<ICompany[]>) => {
                resolve(response.body.map((data) => new Company(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public deleteItem(company: Company): Promise<Company[]> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/company/${company.id}`).then(async (response: HttpResponse<ICompany[]>) => {
                resolve(response.body.map((data) => new Company(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public updateCompany(updatedItem: Company): Promise<Company[]>  {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/company/${updatedItem.id}`, updatedItem).then(async (response: HttpResponse<ICompany[]>) => {
                resolve(response.body.map((data) => new Company(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public createCompany(updatedItem: Company): Promise<Company[]>  {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/company`, updatedItem).then(async (response: HttpResponse<ICompany[]>) => {
                resolve(response.body.map((data) => new Company(data)));
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
