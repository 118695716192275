import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-pdf-page-footer',
    templateUrl: './pdf-page-footer.component.html',
    styleUrls: ['./pdf-page-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageFooterComponent {

    constructor() {
    }

}
