<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails.projectDetails.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ getPageName() + '.result.title' | translate : { tisLabelShort: pdfDetails.projectDetails.projectInfo.tisLabelShort } }}</h1>
    <div class="grid-spacer grid-spacer--large"></div>
    <div class="grid">
        <div class="grid__row grid__row--spacing-large">
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <div class="grid__row">
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.risc' | translate),
                                value: quickscanResult.risks.score,
                                of: quickscanResult.risks.potential,
                                progress: quickscanResult.determineRiskProgress(),
                                barColor: quickscanResult.determineRiskColor()
                            }"
                        ></app-block-stats>
                    </div>
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.value' | translate),
                                value: quickscanResult.values.score,
                                of: quickscanResult.values.potential,
                                progress: quickscanResult.determineValueProgress(),
                                barColor: colors.GREEN
                            }"
                        ></app-block-stats>
                    </div>
                </div>
                <div class="grid__row">
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.redFlags' | translate),
                                value: quickscanResult.redFlags.score,
                                of: quickscanResult.redFlags.potential,
                                progress: quickscanResult.determineRedflagProgress(),
                                barColor: colors.RED
                            }"
                        >
                            <ng-container *ngFor="let redFlag of quickscanResult.redFlags.values">
                                <vwui-icon class="red-flag" iconName="flag"></vwui-icon>
                            </ng-container>
                        </app-block-stats>
                    </div>
                    <div class="grid__col grid__col--6">
                        <app-block-signal
                            class="block-signal--{{ quickscanResult.classification }}"
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.classification' | translate),
                                value: ('CLASSIFICATION.' + quickscanResult.classification | translate),
                                backgroundColor: this.determineClassificationColor(quickscanResult.classification),
                                color: this.determineClassificationTextColor(quickscanResult.classification)
                            }"
                        ></app-block-signal>
                    </div>
                </div>
            </div>
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <app-chart-signal-matrix
                    class="block-chart-signal"
                    [config]="risksVsValueMatrix"
                ></app-chart-signal-matrix>
            </div>
        </div>
    </div>

    <ng-container *ngIf="redFlags.length > 0">
        <vwui-card>
            <vwui-card-header>{{ 'PAGE_QUICKSCAN_RESULT.result.redFlags' | translate }}</vwui-card-header>
            <vwui-card-body>
                <div class="red-flag-title" *ngFor="let redFlag of redFlags"><vwui-icon class="red-flag" iconName="flag"></vwui-icon> {{ redFlag }}</div>
            </vwui-card-body>
        </vwui-card>
        <div class="grid-spacer grid-spacer--large"></div>
    </ng-container>

    <div class="grid" *ngIf="tis || !pdfDetails.tisQuickscanResult">
        <div class="grid__row grid__row--spacing-large">
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <vwui-card>
                    <vwui-card-header>{{ 'PAGE_QUICKSCAN_RESULT.evaluation.title' | translate }}</vwui-card-header>
                    <vwui-card-body>
                        {{ pdfDetails.projectDetails.evaluation || '&nbsp;' }}
                    </vwui-card-body>
                </vwui-card>
            </div>
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <vwui-card>
                    <vwui-card-header>{{ 'PAGE_QUICKSCAN_RESULT.statement.title' | translate }}</vwui-card-header>
                    <vwui-card-body>
                        {{ pdfDetails.projectDetails.statementFromUser || '&nbsp;' }}
                    </vwui-card-body>
                </vwui-card>
            </div>
        </div>
    </div>

    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
