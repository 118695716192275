<div *ngIf="attachments?.length > 0">
    <ng-container *ngFor="let attachment of attachments">
        <div>
            <vwui-icon *ngIf="!readonly" iconName="vwui-trash" (click)="removeAttachment(attachment)"></vwui-icon>
            {{ attachment.originalFileName }}
        </div>
    </ng-container>
</div>
<div *ngIf="dropping" class="image-file-upload__dropping-container">
    <app-loading-spinner></app-loading-spinner>
</div>
<div  *ngIf="attachments?.length < MAX_ATTACHMENTS && !dropping && !readonly" class="image-file-upload image-file-upload__image-container">
    <ngx-file-drop
        class="image-file-upload__file-drop"
        [showBrowseBtn]="true"
        [accept]="getSupportedExtensions()"
        [disabled]="readonly"
        (onFileDrop)="dropped($event)">
    </ngx-file-drop>
</div>
