<div a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ 'PROJECT_ON_HOLD.modal.title' | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <ng-container *ngIf="!modalConfig.data.projectDetails.isOnHold(); else viewProjectResume">
            {{ 'PROJECT_ON_HOLD.modal.question' | translate }}
        </ng-container>
        <ng-template #viewProjectResume>
            {{ 'PROJECT_ON_HOLD.modal.question_resume' | translate:{beforeStatus: (('PROJECT_STATUS.' + modalConfig.data.projectDetails.statusBeforeOnHold) | translate: { tisLabelShort: modalConfig.data.projectDetails.tisLabelShort }) } }}
        </ng-template>
    </vwui-modal-body>
    <vwui-modal-footer>
        <div class="button-group" a11yAutoFocus>
            <vwui-button class="is-secondary" (click)="modalRef.close(false)">
                {{ 'BUTTON.cancel' | translate }}
            </vwui-button>
            <vwui-button class="has-warning" [loading]="loading" (click)="submit()">
                {{ 'BUTTON.change_status' | translate }}
            </vwui-button>
        </div>
    </vwui-modal-footer>
</div>
