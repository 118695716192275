import {LocalStorageService} from 'ngx-webstorage';
import {AppModule} from '../../app/app.module';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {NotificationModalComponent} from '../../components';
import {take} from 'rxjs/operators';
import {NotificationService} from '../../services';

export interface INotificationData {
    id: number;
    title: string;
    message: string;
    publicationStart: string;
    dismissed: boolean;
}

export interface INotificationModalData extends INotificationData {
    isPreview: boolean;
    canDismiss: boolean;
}

export class Notification {

    public readonly id: number;
    public readonly title: string;
    public readonly message: string;
    public readonly publicationStartDate?: Date;
    public readonly dismissed: boolean;

    private localStorageKey: string;
    private localStorageService: LocalStorageService;
    private modalService: VwuiModalService;

    constructor(partialData: Partial<INotificationData> = {}) {
        this.id = partialData.id || null;
        this.title = partialData.title || '';
        this.message = partialData.message || '';
        this.publicationStartDate = partialData.publicationStart ? new Date(partialData.publicationStart) : null;
        this.dismissed = partialData.dismissed || false;

        this.localStorageKey = `NOTIFICATION_${this.id}_dismissed`;
        this.localStorageService = AppModule.injector.get(LocalStorageService);
        this.modalService = AppModule.injector.get(VwuiModalService);
    }

    public isPublished(): boolean {
        const publicationStartTime = this.publicationStartDate?.getTime();

        return publicationStartTime && !isNaN(publicationStartTime) && Date.now() > publicationStartTime;
    }

    public isDismissed(): boolean {
        return this.dismissed;
    }

    /** for migration of local data. See RISC-673 */
    public isDismissedLocally(): boolean {
        const publicationStartTime = this.publicationStartDate.getTime();
        if (!!this.localStorageService.retrieve(this.localStorageKey)) {
            const dismissDate = new Date(this.localStorageService.retrieve(this.localStorageKey) || '');
            const dismissTime = dismissDate.getTime();

            return !isNaN(publicationStartTime) && !isNaN(dismissTime) && dismissTime > publicationStartTime;
        }
        return false;
    }

    public showPreview(notificationService: NotificationService): void {
        this.show(true, notificationService);
    }

    public show(isPreview: boolean = false, notificationService: NotificationService): void {
        const canDismiss = !this.isDismissed();
        const modal = this.modalService.open(NotificationModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'medium-modal',
                data: {
                    isPreview,
                    canDismiss,
                    ...this.getData()
                } as INotificationModalData
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe(async () => {
                if (!isPreview && canDismiss) {
                    await notificationService.dismissNotification(this);
                }
            });
    }

    public getData(): Readonly<INotificationData> {
        return Object.freeze({
            id: this.id,
            title: this.title,
            message: this.message,
            publicationStart: this.publicationStartDate != null ? this.publicationStartDate.toISOString() : '',
            dismissed: this.dismissed
        });
    }

}
