import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {ICompany, IUser, ProjectDetails, User} from '../../models';
import {UserRoles} from '../../enums';
import {SessionStorage} from 'ngx-webstorage';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {MsAuthService} from '../../module/ms-auth';
import {PdfService} from '../pdf/pdf.service';
import {IPageableUsers, IUserRights} from '../../models/user/user';

export type ManageableUsersParams = {
    userSearch?: string;
    company?: ICompany;
    page?: number;
    size?: number;
};

@Injectable()
export class UserService {

    private userInfo: User = null;
    private userInfoSubject = new Subject<User>();

    private accessTokenSubject = new Subject<string>();

    @SessionStorage() private userHasLogin = false;
    @SessionStorage() private userIsAdmin = false;
    @SessionStorage() private userIsRiskManager = false;
    @SessionStorage() private userIsTenderManager = false;
    @SessionStorage() private userIsCompanyAdmin = false;

    constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private authService: MsAuthService,
        private pdfService: PdfService
    ) {
    }

    public onUserInfoChange(): Observable<IUser> {
        return this.userInfoSubject.asObservable();
    }

    public getUserInfo(): Promise<User> {
        if (this.userInfo || this.pdfService.isGeneratingPdf()) {
            return Promise.resolve(this.userInfo);
        }
        return new Promise(async (resolve, reject) => {
            await this.authService.isReady();
            if (!this.authService.isAuthenticated()) {
                this.authService.login();

            } else {
                this.apiService.get('/userinfo').then(async (userInfoResponse: HttpResponse<IUser>) => {
                    this.userInfo = new User(userInfoResponse.body);
                    this.userInfoSubject.next(this.userInfo);
                    this.userHasLogin = true;
                    await this.setUserRoleFlags();
                    resolve(this.userInfo);
                }).catch((error) => {
                    this.userHasLogin = this.authService.isAuthenticated();
                    this.userIsAdmin = false;
                    this.userIsRiskManager = false;
                    this.userIsTenderManager = false;
                    this.userIsCompanyAdmin = false;
                    reject(error);
                });
            }
        });
    }

    public async userHasAnyRole(roles: string[]): Promise<boolean> {
        if (!this.userInfo) {
            await this.getUserInfo();
        }

        let hasRole = false;
        if (this.userHasLogin && this.userInfo) {
            for (const role of roles) {
                if (!hasRole) {
                    const roleIndex = this.userInfo.roleNames.indexOf(role);
                    hasRole = roleIndex >= 0;
                }
            }
        }

        return hasRole;
    }

    public async userHasNoRoles(): Promise<boolean> {
        if (!this.userInfo) {
            await this.getUserInfo();
        }

        let noRoles = false;
        if (this.userHasLogin && this.userInfo) {
            noRoles = this.userInfo.roleNames.length === 0;
        }

        return noRoles;
    }

    public userHasProperOwnerRoles(userInfo: IUser): boolean {
        const ownerRoles = ['ROLE_TENDER_MANAGER', 'ROLE_COMPANY_PROJECT_EDITOR', 'ROLE_PROJECT_EDITOR', 'ROLE_COMPANY_ADMIN'];

        return ownerRoles.filter(value => userInfo.roleNames.includes(value)).length > 0;
    }

    public userHasProperAdminRoles(userInfo: IUser, project: ProjectDetails): boolean {
        const adminRoles = ['ROLE_ADMIN'];

        if (adminRoles.filter(value => userInfo.roleNames.includes(value)).length > 0) {
            return true;
        }
        const projectAdminRoles = ['ROLE_COMPANY_ADMIN'];
        if (projectAdminRoles.filter(value => userInfo.roleNames.includes(value)).length > 0) {
            const matchingCompany = this.userInfo.manageableCompanies.find((company) => company.name === project.company);

            if (!!matchingCompany && !!matchingCompany.admin) {
                return matchingCompany.admin.emailAddress === userInfo.uniqueName;
            }
        }
        return false;
    }

    private isAuthenticationError(statusCode: number): boolean {
        return statusCode === 401 || statusCode === 403;
    }

    private async setUserRoleFlags() {
        this.userIsRiskManager = await this.userHasAnyRole([UserRoles.RISK_MANAGER]);
        this.userIsTenderManager = await this.userHasAnyRole([UserRoles.TENDER_MANAGER]);
        this.userIsAdmin = await this.userHasAnyRole([UserRoles.ADMIN]);
        this.userIsCompanyAdmin = await this.userHasAnyRole([UserRoles.COMPANY_ADMIN]);
    }

    public isTenderManager() {
        return this.userIsTenderManager;
    }

    public isRiskManager() {
        return this.userIsRiskManager;
    }

    public isAdmin() {
        return this.userIsAdmin;
    }

    public isCompanyAdmin() {
        return this.userIsCompanyAdmin;
    }

    public resetUserInfo(): Promise<User> {
        this.userInfo = null;
        return this.getUserInfo();
    }

    public manageableUsers(userParameters: ManageableUsersParams): Promise<IPageableUsers> {
        const searchParams = new URLSearchParams();
        if (userParameters.userSearch) {
            searchParams.set('userSearch', userParameters.userSearch);
        }
        if (userParameters.company) {
            searchParams.set('company', userParameters.company.id.toString());
        }
        if (userParameters.page) {
            searchParams.set('page', userParameters.page.toString());
        }
        if (userParameters.size) {
            searchParams.set('size', userParameters.size.toString());
        }
        return this.apiService.get(`/user/all?${searchParams.toString()}`).then(
            (response: HttpResponse<IPageableUsers>) => {
                return response.body;
            }
        );
    }

    saveUserRights(userForSubmission: IUserRights): Promise<IUserRights> {
        if (userForSubmission.id) {
            return this.apiService.put(`/user/${userForSubmission.id}`, userForSubmission).then(
                (response: HttpResponse<IUserRights>) => response.body
            );
        } else {
            return this.apiService.post(`/user`, userForSubmission).then(
                (response: HttpResponse<IUserRights>) => response.body
            );
        }
    }
}
