import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ToastService, ProjectService, QuickscanDefinitionService, ProjectMandateService} from '../../services';
import {Colors, ProjectStatus} from '../../enums';
import {
    IQuickscanResultData,
    ProjectDetails,
    ProjectMandateDetails,
    QuickscanDefinition,
    QuickscanResult
} from '../../models';
import {IChartSignalMatrixConfig} from '../chart-signal-matrix/chart-signal-matrix.data';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ObjectUtil} from '../../utils';

@Component({
    selector: 'app-project-quickscan-result',
    templateUrl: './project-quickscan-result.component.html',
    styleUrls: ['./project-quickscan-result.component.scss']
})
export class ProjectQuickscanResultComponent implements OnInit, OnDestroy {

    public colors = Colors;

    public risksVsValueMatrix: IChartSignalMatrixConfig = {
        horizontal: {labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]},
        vertical: {labels: [0, 2, 4, 6, 8, 10]},
        targetValue: {
            horizontal: 0,
            vertical: 0
        }
    };

    public redFlags: string[];
    public persistDisabledReason: string = null;
    public isPersistCalculationSaving = false;

    public form = new FormGroup({
        evaluation: new FormControl(null, Validators.maxLength(1024)),
        statementFromUser: new FormControl(null, Validators.maxLength(1024))
    });

    public result: QuickscanResult;
    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();
    private formChangeSubscription: Subscription;
    private quickscanDefinition: QuickscanDefinition;

    public projectDetailsValue: ProjectDetails;

    constructor(
        private projectService: ProjectService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private quickscanDefinitionService: QuickscanDefinitionService
    ) {
        this.result = new QuickscanResult();
    }

    private isDisabled = false;
    @Input()
    set disabled(val: boolean) {
        if (this.isDisabled !== val) {
            this.isDisabled = val;
            this.handleFormControls();
        }
    }

    @Input()
    set projectDetails(val: ProjectDetails) {
        this.projectDetailsValue = val;
        this.quickscanDefinitionService.getQuickscanDefinition(
            this.projectDetailsValue.companyTypeId,
            this.projectDetailsValue.quickscanDefinitionVersionId
        ).then(definition => {
            this.quickscanDefinition = definition;
            this.updateForm();
            this.updateCalculation();
        });
        this.projectDetailsValue.onChanged().subscribe(() => {
            this.updateForm();
            this.updateCalculation();
        });
    }

    @Input()
    public tis = false;

    ngOnInit(): void {
        this.updateForm();
        this.handleFormValueChanges();
        this.handleFormControls();
    }

    ngOnDestroy(): void {
        this.formChangeSubscription.unsubscribe();
    }

    public async persistCalculation() {
        if (this.projectDetailsValue && !this.persistDisabledReason) {
            this.isPersistCalculationSaving = true;

            return this.projectService.saveProjectDetails(this.projectDetailsValue)
                .then((projectDetails) => {
                    this.projectDetailsChanged.emit(projectDetails);
                    this.projectDetailsValue = projectDetails;
                }).then(() => this.projectService.getProjectDetails(this.projectDetailsValue.id)).then((details) => {
                    this.projectDetailsChanged.emit(details);
                    this.projectDetailsValue = details;

                    return this.updateCalculation(true);

                // Reload the status after the quickscan has been made definitive
                }).then(() => this.projectService.getProjectDetails(this.projectDetailsValue.id)).then((details) => {
                    this.projectDetailsChanged.emit(details);
                    this.projectDetailsValue = details;

                    const message = this.translateService.instant(this.getPageName() + '.message_updated',  { tisLabelShort : this.projectDetailsValue.projectInfo.tisLabelShort } );
                    this.toastService.openSuccess(message);
                })
                .catch((error) => {
                    console.error(error);
                    this.toastService.openError(error, this.translateService.instant('ERRORS.quickscan_not_calculated',  { tisLabelShort : this.projectDetailsValue.projectInfo.tisLabelShort } ));
                })
                .finally(() => {
                    this.isPersistCalculationSaving = false;
                });
        }
    }

    public async updateCalculation(persist: boolean = false): Promise<QuickscanResult> {
        if (this.projectDetailsValue) {
            await this.projectService.getQuickscanResult(this.projectDetailsValue, this.tis, persist).then((result) => {
                this.result = result;
            });
            this.determineRedFlags();
            this.determineRisksVsValueMatrix(this.result);
            this.updateDisabledReason();
        }
        return Promise.resolve(this.result);
    }

    public determineClassificationColor(classification): string {
        if (classification === 'green') {
            return Colors.GREEN;
        } else if (classification === 'orange') {
            return Colors.ORANGE;
        } else if (classification === 'red') {
            return Colors.RED;
        } else {
            return Colors.WHITE;
        }
    }

    public determineClassificationTextColor(classification): string {
        if (classification === 'initial') {
            return Colors.DARK;
        } else {
            return Colors.WHITE;
        }
    }

    public determineRisksVsValueMatrix(result: IQuickscanResultData): void {
        const horizontalNotches = 10;
        const horizontalLabels = [0];
        const horizontalInterval = Math.round(result.risks.potential / horizontalNotches);
        for (let i = 0; i < horizontalNotches; i++) {
            horizontalLabels.push((i + 1) * horizontalInterval);
        }

        const verticalLabels = [0];
        const verticalNotches = 5;
        const verticalInterval = Math.round(result.values.potential / verticalNotches);
        for (let i = 0; i < verticalNotches; i++) {
            verticalLabels.push((i + 1) * verticalInterval);
        }

        this.risksVsValueMatrix = {
            horizontal: {labels: horizontalLabels},
            vertical: {labels: verticalLabels},
            targetValue: {
                horizontal: result.risks.score,
                vertical: result.values.score
            }
        };
    }

    public determineRedFlags(): void {
        if (!this.quickscanDefinition) {
            return;
        }

        this.redFlags = this.quickscanDefinition.getRedFlagStrings(this.result.redFlags.values);
    }

    public isMandated(): boolean {
        return this.projectDetailsValue && this.projectDetailsValue.isMandated();
    }

    public canUpdateQuickscanResult(): boolean {
        return !this.projectDetailsValue.isMandated() &&
            (
                (this.tis && this.projectService.canEditTis(this.projectDetailsValue))
            || (!this.tis && this.projectService.canEditQuickscan(this.projectDetailsValue))
        );
    }

    public getPageName(): string {
        return this.tis ? 'PAGE_TIS_QUICKSCAN_RESULT' : 'PAGE_QUICKSCAN_RESULT';
    }

    public doShowQuickscanSubmittedAt() {
        return !this.tis
            && this.result.classification !== 'initial'
            && this.result.quickscanClassifiedAt;
    }

    private updateForm(): void {
        if (this.projectDetailsValue) {
            const value = ObjectUtil.cloneObject(this.projectDetailsValue);
            this.form.patchValue({
                evaluation: value.evaluation,
                statementFromUser: value.statementFromUser
            });
        }
    }

    private handleFormControls(): void {
        this.form.enable();

        if (this.isDisabled) {
            this.form.disable();
        }
    }

    private handleFormValueChanges() {
        if (!!this.formChangeSubscription) {
            this.formChangeSubscription.unsubscribe();
        }
        this.formChangeSubscription = this.form.valueChanges.subscribe(async (value) => {
            if (!this.projectDetailsValue) {
                return;
            }
            this.projectDetailsValue.evaluation = value.evaluation;
            this.projectDetailsValue.statementFromUser = value.statementFromUser;
        });
    }

    private updateDisabledReason(): void {
        this.persistDisabledReason = this.projectDetailsValue.isOnHold() ? 'DISABLED.project_on_hold'
            : this.result.classification === 'initial' ? `${this.getPageName()}.disabled.not_filled_in`
                : !this.canUpdateQuickscanResult() ? 'DISABLED.no_edit_rights'
                    : this.tis ? (this.isMandated() ?  'DISABLED.quickscan_finalized' : null)
                        : this.projectDetailsValue.status !== ProjectStatus.INITIAL ? 'DISABLED.quickscan_finalized' : null;
    }

    public hasTisQuickscanBeenChanged() {
        return (this.tis ? this.projectDetailsValue.hasTisQuickscanBeenChanged() : false);
    }

}
