import {HttpErrorResponse} from '@angular/common/http';

export class ErrorCode {
    httpCode: number;
    errorCode: string;

    constructor(error: HttpErrorResponse) {
        this.httpCode = error.status;
        this.errorCode = error.error ? error.error.errorCode ? error.error.errorCode : 'generic' : 'generic';
    }
}
