import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {MsAuthService} from './service';

@Injectable({
    providedIn: 'root'
})
export class MsAuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private msAuthService: MsAuthService
    ) {
    }

    async canActivate(): Promise<boolean> {
        await this.msAuthService.isReady();

        if (this.msAuthService.isAuthenticated()) {
            return true;
        } else {
            await this.msAuthService.redirectWhenNoAuthentication();

            return false;
        }
    }

    async canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

}
