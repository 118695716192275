import {Pipe, PipeTransform} from '@angular/core';
import {IUserIdentity} from '../models/user/user';

@Pipe({
    name: 'namesFormat'
})
export class NamesFormatPipe implements PipeTransform {

    /**
     * Takes a user and returns a field of it
     */
    transform(value?: {name: string}[] | {name: string } | string) {
        if (!value) {
            return '';
        }

        if (typeof value === 'string') {
            return value;
        }

        if (Array.isArray(value)) {
            return value
                .map((v) => v.name.trim())
                .join(', ')
                .replace(/, ((?:.(?!, ))+)$/gm, ' & $1');
        } else {
            return value.name;
        }
    }
}
