import {Component, OnDestroy, OnInit} from '@angular/core';
import {IPageableProjects, IProject, Notification} from '../../models';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {
    CompanyTypeService,
    FilterService,
    LoadingSpinnerService,
    NotificationService,
    ProjectService,
    UserService
} from '../../services';
import {CreateProjectModalComponent} from '../../components';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConstructionCosts, ProjectClassification, ProjectStatus, ProjectSteps} from '../../enums';
import {from, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';
import {CompanyType} from '../../models/user/user';
import {LocalStorage} from 'ngx-webstorage';

@Component({
    selector: 'app-page-projects',
    templateUrl: './page-projects.component.html',
    styleUrls: ['./page-projects.component.scss']
})
export class PageProjectsComponent implements OnInit, OnDestroy {

    public currentPageData: IPageableProjects;
    public projects: IProject[];
    @LocalStorage()
    public searchValue: string;
    public currentPage = 0;
    public statuses = [];
    public generatingCsv = false;
    public classifications = [
        this.classificationStatus(ProjectClassification.INITIAL),
        this.classificationStatus(ProjectClassification.RED),
        this.classificationStatus(ProjectClassification.ORANGE),
        this.classificationStatus(ProjectClassification.GREEN)
    ];

    public constructionCostOptions = [
        this.constructionCosts(ConstructionCosts.LESS_THAN_10M),
        this.constructionCosts(ConstructionCosts.BETWEEN_10M_AND_25M),
        this.constructionCosts(ConstructionCosts.BETWEEN_25M_AND_50M),
        this.constructionCosts(ConstructionCosts.GREATER_THAN_50M)
    ];

    public tableConfig = {
        title: this.translateService.instant(`PAGE_PROJECTEN.title`),
        columns: [
            {header: this.translateService.instant(`PAGE_PROJECTEN.table.column.name`)},
            {header: this.translateService.instant(`PAGE_PROJECTEN.table.column.company`) + ' & ' + this.translateService.instant(`PAGE_PROJECTEN.table.column.owner`)},
            {header: this.translateService.instant(`PAGE_PROJECTEN.table.column.modifiedAt`)},
            {header: this.translateService.instant(`PAGE_PROJECTEN.table.column.status`)},
            {
                header: this.translateService.instant(`PAGE_PROJECTEN.table.column.classification`),
                tooltip: 'PAGE_PROJECTEN.table.column.classification_tooltip'
            }
        ]
    };
    private pageSize = 15;
    @LocalStorage()
    public companyNames?: string[];
    private searchValueSubject: Subject<string>;
    @LocalStorage()
    public searchClassifications: string[];
    @LocalStorage()
    public searchCompanyName?: string;
    @LocalStorage()
    public searchStatuses?: string[];
    @LocalStorage()
    public searchConstructionCosts?: string;

    public hasCompaniesWithRegularFlow = false;
    public publishedNotifications$: Observable<Notification[]>;

    private subscriptions: Subscription[] = [];

    constructor(
        private projectService: ProjectService,
        private userService: UserService,
        private modalService: VwuiModalService,
        private router: Router,
        private translateService: TranslateService,
        private tableSearchService: FilterService,
        private companyTypeService: CompanyTypeService,
        public loadingSpinnerService: LoadingSpinnerService,
        public notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.loadProjects();

        this.subscriptions.push(
            this.companyTypeService.getActiveCompanyTypeSubject()
                .subscribe(companyType => this.initializeStatuses(companyType))
        );

        this.userService.getUserInfo().then((userInfo) => {
            this.companyNames = userInfo.getAllViewableCompanies();
            this.hasCompaniesWithRegularFlow = userInfo.getCompanyNamesForManuallyAddingProjects().length > 0;
        });

        this.searchValueSubject = new Subject<string>();
        this.subscriptions.push(
            this.searchValueSubject
                .pipe(
                    debounceTime(400)
                ).subscribe(value => {
                this.searchValue = value;
                this.loadProjects();
            })
        );

        this.publishedNotifications$ = from(this.notificationService.getRemoteNotifications())
            .pipe(
                map((notifications) => notifications.filter((notification) => notification.isPublished())),
                tap({
                    next: (notifications) => {
                        const notDismissedRemote = notifications.filter((notification) => !notification.isDismissed());
                        notDismissedRemote.forEach(async (notYetDismissed) => {
                            if (notYetDismissed.isDismissedLocally()) {
                                // RISC-673: preserve local state, update to server
                                await this.notificationService.dismissNotification(notYetDismissed);
                            }
                        });
                        notDismissedRemote.find((notification) => !notification.isDismissedLocally() && !notification.isDismissed())
                            ?.show(false, this.notificationService);
                    }
                })
            );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public searchCompanyChanged(company: string) {
        this.searchCompanyName = company === undefined ? null : company;
        this.loadProjects();
    }

    public searchStatusChanged(statuses: { id: string; name: string }[]) {
        this.searchStatuses = statuses.map(status => status.id);
        this.loadProjects();
    }

    public searchCategoryChanged(classifications: { id: string; name: string }[]) {
        this.searchClassifications = classifications.map(status => status.id);
        this.loadProjects();
    }

    public searchConstructionCostsChanged(constructionCosts: { id: string; name: string }) {
        if (!!constructionCosts) {
            this.searchConstructionCosts = constructionCosts.id;
        } else {
            this.searchConstructionCosts = null;
        }

        this.loadProjects();
    }

    public openCreateProjectModal() {
        const modal = this.modalService.open(CreateProjectModalComponent,
            {
                closeOnBackdropClick: false
            }
        );

        modal.afterClosed.subscribe(it => {
            this.loadProjects();
        });
    }

    public search(value: string) {
        this.searchValueSubject.next(value);
    }

    public onProjectClick(project: IProject) {
        let stepId = 1;
        switch (project.status) {
            case ProjectStatus.INITIAL:
                stepId = ProjectSteps.DETAILS;
                break;
            case ProjectStatus.IN_EXECUTION:
                stepId = ProjectSteps.RAINBOW;
                break;
            case ProjectStatus.QUICKSCAN_SAVED:
                stepId = ProjectSteps.TIS_QUICKSCAN;
                break;
            case ProjectStatus.MANDATING:
            case ProjectStatus.MANDATING_FAILED:
            case ProjectStatus.MANDATING_SUCCEEDED:
                stepId = ProjectSteps.MANDATING;
                break;
        }
        this.router.navigate(['/project', project.id, 'step', stepId]);
    }

    public isTenderManager() {
        return this.userService.isTenderManager();
    }

    public setPage(pageNumber: number): void {
        this.currentPage = pageNumber - 1;
        this.loadProjects();
    }

    public firstItem(): number {
        if (this.currentPageData) {
            return (this.currentPageData.number * this.pageSize) + 1;
        } else {
            return 0;
        }
    }

    public lastItem(): number {
        if (this.currentPageData) {
            return Math.min((this.currentPageData.number + 1) * this.pageSize, this.currentPageData.totalElements);
        } else {
            return 0;
        }
    }

    public hasSearchFilter() {
        return (this.searchClassifications && this.searchClassifications.length > 0)
            || (this.searchCompanyName && this.searchCompanyName.length > 0)
            || (this.searchStatuses && this.searchStatuses.length > 0)
            || (this.searchValue && this.searchValue.length > 0);
    }

    private loadProjects() {
        this.loadingSpinnerService.show();
        this.projectService.getProjects(
            this.currentPage,
            this.pageSize,
            this.searchValue,
            this.searchCompanyName,
            this.searchStatuses,
            this.searchClassifications,
            this.searchConstructionCosts
        ).then((pageableProjects) => {
            this.projects = pageableProjects.content;
            this.currentPageData = pageableProjects;

            this.loadingSpinnerService.hide();
        });
    }

    private classificationStatus(classification: ProjectClassification) {
        return {id: classification, name: this.translateService.instant(`CLASSIFICATION.${classification}`)};
    }

    private constructionCosts(constructionCosts: ConstructionCosts) {
        return {
            id: constructionCosts,
            name: this.translateService.instant(`FILTER_CONSTRUCTION_COSTS.${constructionCosts}`)
        };
    }

    private initializeStatuses(companyType: CompanyType) {
        this.statuses = [
            this.projectStatus(ProjectStatus.INITIAL, companyType),
            this.projectStatus(ProjectStatus.QUICKSCAN_SAVED, companyType),
            this.projectStatus(ProjectStatus.MANDATING, companyType),
            this.projectStatus(ProjectStatus.MANDATING_FAILED, companyType),
            this.projectStatus(ProjectStatus.MANDATING_SUCCEEDED, companyType),
            this.projectStatus(ProjectStatus.IN_EXECUTION, companyType),
            this.projectStatus(ProjectStatus.ON_HOLD, companyType),
            this.projectStatus(ProjectStatus.CLOSED, companyType)
        ];
    }

    private projectStatus(status: ProjectStatus, companyType: CompanyType) {
        const tisLabelShort = companyType ? companyType.tisLabelShort : 'TIS';
        return {id: status, name: this.translateService.instant(`PROJECT_STATUS.${status}`, {tisLabelShort})};
    }

    public async downloadCsvExport() {
        this.generatingCsv = true;
        await this.projectService.generateCsvExport();
        this.generatingCsv = false;
    }
}
