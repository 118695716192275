import {Component, Input, ViewEncapsulation} from '@angular/core';
import {LogoSizes} from './pdf-page-header.data';
import moment from 'moment';
import momentTZ from 'moment-timezone';

@Component({
    selector: 'app-pdf-page-header',
    templateUrl: './pdf-page-header.component.html',
    styleUrls: ['./pdf-page-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageHeaderComponent {

    @Input() logoSize: LogoSizes = LogoSizes.DEFAULT;
    @Input() repeat = true;
    @Input() spacer = true;

    public logoSizes = LogoSizes;
    public dateNow = moment(momentTZ().tz('Europe/Amsterdam')).toDate();

    constructor() {
    }

}
