<vwui-spinner class="vwui-spinner--with-margin" *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <app-scroll-navigation [navItems]="navItems"></app-scroll-navigation>
    <div class="quickscan">

        <app-quickscan-list
            [projectDetails]="projectDetails"
            [quickscanDefinition]="quickscanDefinition"
            [quickscanAnswers]="projectDetails.quickscanAnswers"
            [categoryInfo]="projectDetails.categoryInfo"
            [disabled]="!canEditQuickscan()"
            (projectDetailsChanged)="projectDetailsChanged.emit($event)">
        ></app-quickscan-list>

        <app-project-quickscan-result
            class="align-left"
            [projectDetails]="projectDetails"
            [disabled]="!canEditQuickscan()"
            (projectDetailsChanged)="projectDetailsChanged.emit($event)">
        </app-project-quickscan-result>

    </div>
</div>
