import {ApiProjectCreationType} from '../../enums';

export interface IAzureAdRegistration {
    id: number;
    name: string;
}

export interface IUserIdentity {
    emailAddress: string;
    name: string;
}

export interface IUser {
    // Optional, to create users
    id?: number;
    uniqueName: string;
    fullName: string;
    roleNames: string[];
    manageableCompanies: ICompany[];
    allViewableCompanies: ICompany[];
    companyTypes: ICompanyType[];
}

export interface ICompany {
    id: number;
    name: string;
    editors: IUserIdentity[];
    viewers: IUserIdentity[];
    tenderManagers: IUserIdentity[];
    admin?: IUserIdentity;
    companyType: ICompanyType;
    companyCode: string;
    apiProjectCreation: ApiProjectCreationType;
}

export interface ICompanyType {
    id: number;
    name: string;
    contractTypes: string[];
    constructionCostsLabel: string;
    tisLabelShort: string;
    tisLabelLong: string;
    azureAdRegistration: IAzureAdRegistration | null;
    riskManagers: IUserIdentity[];
    additionalCollaborations: string[];
    quantificationCategoriesRisks: string[];
    quantificationCategoriesOpportunities: string[];
    quantificationCategoriesTasks: string[];
    companyMandatingInfo: string;
}

export class User implements IUser {
    id: number;
    uniqueName: string;
    fullName: string;
    roleNames: string[];
    companiesForManuallyAddingProjects: Company[];
    manageableCompanies: Company[];
    allViewableCompanies: Company[];
    companyTypes: CompanyType[];

    constructor(data: IUser) {
        this.id = data.id;
        this.uniqueName = data.uniqueName;
        this.fullName = data.fullName;
        this.roleNames = data.roleNames;
        this.companiesForManuallyAddingProjects = data.manageableCompanies.map((item) => new Company(item)).filter((company) => {
            return company.apiProjectCreation !== ApiProjectCreationType.API_CREATION_ONLY && company.tenderManagers
                .some((tenderManager) => tenderManager.emailAddress === this.uniqueName);
        });
        this.manageableCompanies = data.manageableCompanies.map((item) => new Company(item));
        this.allViewableCompanies = data.allViewableCompanies.map((item) => new Company(item));
        this.companyTypes = data.companyTypes.map((item) => new CompanyType(item));
    }

    public getCompanyNamesForManuallyAddingProjects(): string[] {
        return this.companiesForManuallyAddingProjects.map((company) => company.name);
    }

    public getManageableCompanies(): string[] {
        return this.manageableCompanies.map((company) => company.name);
    }

    public getAllViewableCompanies(): string[] {
        return this.allViewableCompanies.map((company) => company.name);
    }
}

export class Company implements ICompany {
    id: number;
    name: string;
    editors: IUserIdentity[];
    viewers: IUserIdentity[];
    tenderManagers: IUserIdentity[];
    admin?: IUserIdentity;
    companyType: CompanyType;
    companyCode: string;
    apiProjectCreation: ApiProjectCreationType;

    constructor(data: ICompany) {
        this.id = data.id;
        this.name = data.name;
        this.editors = data.editors;
        this.viewers = data.viewers;
        this.tenderManagers = data.tenderManagers;
        this.admin = data.admin;
        this.companyType = new CompanyType(data.companyType);
        this.companyCode = data.companyCode;
        this.apiProjectCreation = data.apiProjectCreation;
    }
}

export class CompanyType implements ICompanyType {
    id: number;
    name: string;
    contractTypes: string[];
    constructionCostsLabel: string;
    tisLabelShort: string;
    tisLabelLong: string;
    riskManagers: IUserIdentity[];
    additionalCollaborations: string[];
    quantificationCategoriesRisks: string[];
    quantificationCategoriesOpportunities: string[];
    quantificationCategoriesTasks: string[];
    azureAdRegistration: IAzureAdRegistration|null;
    companyMandatingInfo: string;

    constructor(data: ICompanyType) {
        this.id = data.id;
        this.name = data.name;
        this.contractTypes = data.contractTypes;
        this.constructionCostsLabel = data.constructionCostsLabel;
        this.tisLabelShort = data.tisLabelShort;
        this.tisLabelLong = data.tisLabelLong;
        this.riskManagers = data.riskManagers;
        this.additionalCollaborations = data.additionalCollaborations;
        this.quantificationCategoriesRisks = data.quantificationCategoriesRisks;
        this.quantificationCategoriesOpportunities = data.quantificationCategoriesOpportunities;
        this.quantificationCategoriesTasks = data.quantificationCategoriesTasks;
        this.azureAdRegistration = data.azureAdRegistration;
        this.companyMandatingInfo = data.companyMandatingInfo;
    }
}

export const predefinedContractTypes: { key: string }[] = [
    {key: 'traditional'},
    {key: 'integrated'}
];

export interface IPageable<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    number: number;
    size: number;
    last: boolean;
    first: boolean;
}

export type IPageableUsers = IPageable<IUserRights>;

export interface ICompanyTypeRights {
    id: number;
    name: string;
    riskManager: boolean;
}

export interface ICompanyRights {
    id: number;
    name: string;
    // Optional when submitting
    companyTypeId?: number;
    companyTypeName?: string;
    admin: boolean;
    tenderManager: boolean;
    editor: boolean;
    viewer: boolean;
}

export interface IUserRights {
    id?: number;
    uniqueName: string;
    fullName: string;
    companyTypeRights: ICompanyTypeRights[];
    companyRights: ICompanyRights[];
}
