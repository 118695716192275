import {Injectable} from '@angular/core';
import {ApiService} from '../';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {IPdfDetails} from '../../models/pdf/pdf-details-data';
import {HttpResponse} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import slugify from 'slugify';
import {PDFPageKeys} from '../../enums';
import {ProjectDetails} from '../../models';

@Injectable()
export class PdfService {

    private pdfGenerationTrigger: HTMLElement;
    private pdfGenerationTriggerAppended = false;
    private pdfGenerationTimeoutId: number;
    readonly pdfBasePath = '/pdf/generation';
    private pdfGeneration = false;

    public static INCLUDE_PROPOSALS = 'proposals';
    public static FILTERED_ONLY = 'filtered';
    public static RISK_DOSSIER_FILTERED_ONLY = 'risksAndOpportunities-filtered';
    public static RISK_DOSSIER_CLIENT_FILTERED_ONLY = 'risksAndOpportunitiesClient-filtered';
    private pageParameters = [PdfService.INCLUDE_PROPOSALS,
        PdfService.FILTERED_ONLY,
        PdfService.RISK_DOSSIER_FILTERED_ONLY,
        PdfService.RISK_DOSSIER_CLIENT_FILTERED_ONLY];

    constructor(
        private apiService: ApiService
    ) {
        this.pdfGenerationTrigger = document.createElement('div');
        this.pdfGenerationTrigger.id = 'pageReadyForPdfGeneration';
    }

    /**
     * Download the PDF for the given project ID
     */
    public downloadPdf(projectDetails: ProjectDetails, projectName: string, includedPages: string[]) {
        const dateFormat = moment(momentTZ().tz('Europe/Amsterdam')).format('DD-MM-YYYY-HH.mm');
        const slugifyOptions = {replacement: '-', remove: /[\[\]_{}<>?`=*+~.,()'"!:;@/\\]/g, lower: true};
        const pdfFilesBaseName = `PRM-${slugify(projectName, slugifyOptions)}-${dateFormat}`;

        return this.apiService.getAttachment(`/pdf/${projectDetails.id}?includedPages=${includedPages.join('+')}`).then((response: Blob) => {
            FileSaver.saveAs(response, `${pdfFilesBaseName}.pdf`);
        })
            .then(() => {
                if (includedPages.some(page => PDFPageKeys.TIS === page)
                    && projectDetails.hasAttachmentsForTis()
                ) {
                    return this.apiService.getAttachment(`/pdf/attachments/${projectDetails.id}`, 'application/zip').then((response: Blob) => {
                        FileSaver.saveAs(response, `${pdfFilesBaseName}-bijlagen.zip`);
                    });
                }
                return Promise.resolve();
            });
    }

    /**
     * Get the PDF data given the routes UUID
     */
    public getPdfData(uuid: string): Promise<PdfDetails> {
        this.pdfGeneration = true;
        // Add the full url including uuid to the whitelist
        // To make sure it properly matches the endsWith

        return new Promise((resolve, reject) => {
            this.apiService.getForPdf(`${this.pdfBasePath}/${uuid}/details`).then((response: HttpResponse<IPdfDetails>) => {
                const pdfDetails: PdfDetails = new PdfDetails(response.body);
                resolve(pdfDetails);
            }).catch((error) => {
                console.error(error.message);
                reject(error);
            });
        });
    }

    /**
     * Append a specific div to trigger the waitFor in Puppeteer
     */
    public pageReadyForPdfGeneration(): void {
        if (!this.pdfGenerationTriggerAppended) {
            if (this.pdfGenerationTimeoutId) {
                window.clearTimeout(this.pdfGenerationTimeoutId);
            }
            this.pdfGenerationTimeoutId = window.setTimeout(() => {
                this.pdfGenerationTriggerAppended = true;
                document.body.appendChild(this.pdfGenerationTrigger);
            }, 50);
        }
    }

    public pageCleanupAfterPdfGeneration(): void {
        if (this.pdfGenerationTriggerAppended) {
            document.body.parentNode.removeChild(this.pdfGenerationTrigger);
            this.pdfGenerationTriggerAppended = false;
        }
    }

    public isGeneratingPdf(): boolean {
        return this.pdfGeneration;
    }

    getPdfPageWithOptions(pageNameWithOptionsString): {pageName: string; options: string[]} {
        const pageName = this.getPageName(pageNameWithOptionsString);
        const options = pageNameWithOptionsString.split('-');
        options.shift();
        return {
            pageName,
            options
        };
    }

    private getPageName(page: string): string {
        if (this.pageParameters.find((pageParameter) => page.includes(pageParameter))) {
            return page.slice(0, page.indexOf('-'));
        } else {
            return page;
        }
    }
}
