import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyService, LoadingSpinnerService, TenderManagerService, ToastService, UserService} from '../../services';
import {Company, IPageable, IUserRights} from '../../models/user/user';
import {ManageableUsersParams} from '../../services/user/user.service';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ChangeUserModalComponent} from '../../components';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Component({
    selector: 'app-page-user-management',
    templateUrl: './page-user-management.component.html',
    styleUrls: ['./page-user-management.component.scss']
})
export class PageUserManagementComponent implements OnInit, OnDestroy {

    public usersRights: IUserRights[];
    public pageable: IPageable<unknown>;
    public companies$: Promise<Company[]>;
    public filterForm = new FormGroup({
        search: new FormControl(),
        company: new FormControl()
    });
    private userParameters: ManageableUsersParams = {};
    private subscriptions: Subscription[] = [];

    constructor(
        public loadingSpinnerService: LoadingSpinnerService,
        private tenderManagerService: TenderManagerService,
        private userService: UserService,
        private companyService: CompanyService,
        private toastService: ToastService,
        private modalService: VwuiModalService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(this.filterForm.valueChanges.subscribe(filterValues => {
            this.userParameters.userSearch = filterValues.search;
            this.userParameters.company = filterValues.company;
            this.loadUserPage();
        }));
        this.loadUsers();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }

    loadUsers(): void {
        this.loadingSpinnerService.show();
        this.companies$ = this.companyService.getCompanies();
        this.loadUserPage();
    }

    private loadUserPage() {
        this.userService.manageableUsers(this.userParameters)
            .then((userPage) => {
                this.pageable = userPage;
                this.usersRights = userPage.content.map((ui) => ui);

                // Reset the user info to make sure the roles are kept in sync
                this.userService.resetUserInfo();
            })
            .catch((error) => {
                this.toastService.openError(error.status, 'Oops!');
            })
            .finally(() => {
                this.loadingSpinnerService.hide();
            });
    }

    changePage($event: CustomEvent<number>): void {
        if ($event.detail && $event.detail <= this.pageable.totalPages) {
            this.userParameters.page = $event.detail - 1;
            this.loadUserPage();
        }
    }

    editUserRights(userRights: IUserRights) {
        this.modalService.open(
            ChangeUserModalComponent,
            {
                data: {
                    userRights
                }
            }
        ).afterClosed.pipe(
            take(1),
            map(() => {
                this.loadUserPage();
            })).subscribe();
    }

    newUserRights() {
        this.modalService.open(
            ChangeUserModalComponent,
            {
                data: {
                    userRights: undefined
                }
            }
        ).afterClosed.pipe(
            take(1),
            map(() => {
                this.loadUserPage();
            })).subscribe();
    }

    userRightsTrackByFn(index: number, userRights: IUserRights) {
        return userRights.id;
    }
}
