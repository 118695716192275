import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {IPossibleAnswerData, IQuestionData, LibraryEntry, PossibleAnswerDefault} from '../../models';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';

@Component({
    selector: 'app-quickscan-create-update-question-modal',
    templateUrl: './quickscan-create-update-question-modal.component.html',
    styleUrls: ['./quickscan-create-update-question-modal.component.scss']
})
export class QuickscanCreateUpdateQuestionModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public subCategories: string[];
    public data?: IQuestionData;
    public minAnswers = 2;
    public countAnswers = 0;
    public allRiskEntries: { id: number; title: string }[] = [];

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; allRiskEntries: LibraryEntry[]; subCategories: string[]; data?: IQuestionData; saveText: string }>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.subCategories = modalConfig.data.subCategories;
        this.data = modalConfig.data.data;
        this.allRiskEntries = modalConfig.data.allRiskEntries
            .map((item) => ({id: item.id, title: item.getTitle()}));

        const categoryNameValue = this.data ? this.data.categoryName : undefined;
        const nameValue = this.data ? this.data.name : '';
        const infoValue = this.data ? this.data.info : '';

        this.form = new FormGroup({
            categoryName: new FormControl(categoryNameValue, Validators.required),
            name: new FormControl(nameValue, Validators.required),
            info: new FormControl(infoValue),
            possibleAnswers: new FormArray([], Validators.min(this.minAnswers))
        });

        this.form.get('possibleAnswers').valueChanges.subscribe(possibleAnswers => {
            const riskEntryIdMap = this.allRiskEntries.reduce((acc, value) => {
                acc[value.id] = value.title;
                return acc;
            }, {});

            possibleAnswers = possibleAnswers.map((possibleAnswer) => {
                const isLibraryItemNumbers = possibleAnswer.libraryEntryIds.length > 0 && typeof possibleAnswer.libraryEntryIds[0] === 'number';
                possibleAnswer.libraryEntryIds.sort((libraryEntry1, libraryEntry2) => {
                    // Differend sorting because of mixed types
                    if (typeof libraryEntry1 === 'number') {
                        if (riskEntryIdMap[libraryEntry1] > riskEntryIdMap[libraryEntry2]) {
                            return 1;
                        } else if (riskEntryIdMap[libraryEntry1] < riskEntryIdMap[libraryEntry2]) {
                            return -1;
                        }
                    } else {
                        if (riskEntryIdMap[libraryEntry1.id] > riskEntryIdMap[libraryEntry2.id]) {
                            return 1;
                        } else if (riskEntryIdMap[libraryEntry1.id] < riskEntryIdMap[libraryEntry2.id]) {
                            return -1;
                        }
                    }

                    return 0;
                });

                if (isLibraryItemNumbers) {
                    possibleAnswer.libraryEntryIds = possibleAnswer.libraryEntryIds.map((libraryEntryId) => ({id: libraryEntryId, title: riskEntryIdMap[libraryEntryId]}));
                }

                return possibleAnswer;
            });

            this.form.controls.possibleAnswers.setValue(possibleAnswers, {onlySelf: true, emitEvent: false});
        });

        this.data.possibleAnswers.forEach((possibleAnswer) => {
            this.addAnswer(possibleAnswer);
        });

        this.minAnswersArray();
    }

    public getAnswersArray(): FormArray {
        return this.form.controls.possibleAnswers as FormArray;
    }

    public addAnswer(possibleAnswer: IPossibleAnswerData = PossibleAnswerDefault): void {
        this.getAnswersArray().push(new FormGroup({
            id: new FormControl(possibleAnswer.id),
            answer: new FormControl(possibleAnswer.answer, Validators.required),
            score: new FormControl(possibleAnswer.score, Validators.required),
            triggersRedFlag: new FormControl(possibleAnswer.triggersRedFlag, Validators.required),
            libraryEntryIds: new FormControl(possibleAnswer.libraryEntryIds
                .map((id) => this.allRiskEntries.find((item) => item.id === id)
                ))
        }));
        this.updateAnswersCount();
    }

    public removeAnswer(answerIndex: number): void {
        this.getAnswersArray().removeAt(answerIndex);
        this.updateAnswersCount();
        this.minAnswersArray();
    }

    private updateAnswersCount() {
        this.countAnswers = this.getAnswersArray().length;
    }

    private minAnswersArray() {
        while (this.countAnswers < this.minAnswers) {
            this.addAnswer();
        }
    }

    public submit(): void {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                let formValue = Object.assign([], this.form.get('possibleAnswers').value);
                formValue = formValue.map((possibleAnswer) => {
                    possibleAnswer.libraryEntryIds = possibleAnswer.libraryEntryIds.map((libraryEntryId) => libraryEntryId.id);
                    return possibleAnswer;
                });
                this.form.controls.possibleAnswers.setValue(formValue, {onlySelf: false, emitEvent: false});

                this.modalRef.close({...this.data, ...this.form.value});
            }
        } catch (e) {
            console.error('Creating question failed: ', e);
            this.trackingService.exception(e);
        }
    }

    public compareEntry(a: { id: number }, b: { id: number }): boolean {
        // RISC-684: be able to handle undefined issues
        return !!a && !!b && a.id === b.id;
    }

}
