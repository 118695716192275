import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {CompanyType} from '../../models/user/user';

@Component({
    selector: 'app-publish-tis-version-modal',
    templateUrl: './publish-tis-version-modal.component.html'
})
export class PublishTisModalComponent {

    public loading = false;
    public companyType: CompanyType;

    constructor(
        public modalConfig: VwuiModalConfig<{ companyType: CompanyType }>,
        public modalRef: VwuiModalRef
    ) {
        this.companyType = modalConfig.data.companyType;
    }

    async submit() {
        this.modalRef.close(true);
    }

}
