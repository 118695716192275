import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastService, ProjectService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails} from '../../models';

@Component({
    selector: 'app-project-on-hold-modal',
    templateUrl: './project-on-hold-modal.component.html'
})
export class ProjectOnHoldModalComponent {

    public loading = false;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ projectDetails: ProjectDetails }>,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {
    }

    public async submit() {
        this.loading = true;
        this.projectService.toggleOnHold(this.modalConfig.data.projectDetails)
            .then(() => {
                const messageTranslation = this.modalConfig.data.projectDetails.isOnHold() ?
                    'PROJECT_ON_HOLD.modal.success_resume' : 'PROJECT_ON_HOLD.modal.success';
                this.toastService.openSuccess(this.translateService.instant(messageTranslation));
                this.modalRef.close(true);
            })
            .catch((error) => {
                console.error(error);
                const errorTranslation = this.modalConfig.data.projectDetails.isOnHold() ?
                    'PROJECT_ON_HOLD.modal.error_resume' : 'PROJECT_ON_HOLD.modal.error';
                this.toastService.openError(error, this.translateService.instant(errorTranslation));
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
