import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {LoadingSpinnerService, ProjectMandateService, ProjectService, QuickscanDefinitionService} from '../../services';
import {ProjectDetails, ProjectMandateDetails, QuickscanDefinition} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {IScrollNavigationItem} from '../scroll-navigation/scroll-navigation.data';
import {ProjectStatus, ProjectSteps} from '../../enums';

@Component({
    selector: 'app-project-tis-quickscan',
    templateUrl: './project-tis-quickscan.component.html',
    styleUrls: ['./project-tis-quickscan.component.scss']
})
export class ProjectTisQuickscanComponent implements OnInit {

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    public navItems: IScrollNavigationItem[] = [];

    @Input()
    public projectDetails: ProjectDetails;
    public quickscanDefinition?: QuickscanDefinition;
    public mandateDetails: ProjectMandateDetails;

    constructor(
        private projectService: ProjectService,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private projectMandateService: ProjectMandateService,
        private modalService: VwuiModalService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit() {
        this.loadingSpinnerService.show();
        this.quickscanDefinition = await this.quickscanDefinitionService.getQuickscanDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.quickscanDefinitionVersionId
        );

        this.navItems.push(...this.navItemsForQuickscan());
        this.navItems.push(this.navItemForQuickscanResult());

        await this.projectMandateService.getMandateDetails(this.projectDetails)
            .then((mandateDetails) => {
                this.mandateDetails = mandateDetails;
            });
    }

    public canEditTisQuickscan(): boolean {
        return this.projectDetails.status !== ProjectStatus.INITIAL
            && !this.projectDetails.isOnHold()
            && !this.projectDetails.isClosed()
            && !this.projectDetails.isInMandateProcess()
            && this.projectService.canEditTis(this.projectDetails);
    }

    private navItemsForQuickscan(): IScrollNavigationItem[] {
        return [
            {
                label: this.translateService.instant('PAGE_FILL_TIS_QUICKSCAN.title', { tisLabelShort: this.projectDetails.projectInfo.tisLabelShort }),
                elementId: 'ThemeTitle',
                groupHeader: true
            },
            ...this.quickscanDefinition.categories.map((category, index) => {
                this.loadingSpinnerService.hide();
                return {
                    label: '' + (index + 1) + '. ' + category.name,
                    elementId: 'ThemeTitle' + category.id
                };
            })
        ];
    }

    public getUriForStep(stepId: ProjectSteps): string {
        return `/project/${this.projectDetails.id}/step/${stepId}`;
    }

    public quickscanSubmitted(): boolean {
        return !(this.mandateDetails && !this.mandateDetails.preconditions.quickscanNotSubmitted.satisfiedOrOptional);
    }

    private navItemForQuickscanResult(): IScrollNavigationItem {
        return {
            label: this.translateService.instant('PAGE_TIS_QUICKSCAN_RESULT.result.title', { tisLabelShort : this.projectDetails.projectInfo.tisLabelShort}),
            elementId: 'ThemeTitleResult', groupHeader: true, active: this.projectDetails.status !== ProjectStatus.INITIAL
        };
    }
}
