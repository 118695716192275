import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingSpinnerService, PdfService, UserService} from '../services';
import {SessionStorage} from 'ngx-webstorage';
import {RouteUtil} from '../utils';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MsAuthService} from '../module/ms-auth';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked, OnInit, OnDestroy {

    public menuVisible = false;
    private routeChangedSubscription: Subscription;

    @SessionStorage() public readonly loadingSpinnerVisible: boolean;
    @SessionStorage() public readonly userHasLogin: boolean;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private changeDetector: ChangeDetectorRef,
        private authService: MsAuthService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private pdfService: PdfService
    ) {
        this.setMenuVisibility();
    }

    async ngOnInit() {
        this.setMenuVisibility();

        this.routeChangedSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setMenuVisibility();
                this.pdfService.pageCleanupAfterPdfGeneration();
            }
        });
    }

    ngOnDestroy() {
        this.routeChangedSubscription.unsubscribe();
    }

    ngAfterViewChecked() {
        // Prevents ExpressionChangedAfterItHasBeenCheckedError
        this.changeDetector.detectChanges();
    }

    private setMenuVisibility(): void {
        this.menuVisible = RouteUtil.getRouteData<boolean>(this.getActivatedRouteSnapshot(), 'menuVisible', false);
    }

    private getActivatedRouteSnapshot(): ActivatedRouteSnapshot {
        const currentRoute = this.route.root.firstChild;
        return currentRoute && currentRoute.snapshot ? currentRoute.snapshot : undefined;
    }

    public logout() {
        this.authService.logout();
        this.userService.resetUserInfo();
    }

}
