import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {MsAuthConfig} from './interfaces';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@Injectable()
export class MsAuthService {

    private config?: MsAuthConfig;
    private isReady$ = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router,
        private oAuthService: OAuthService
    ) {
    }

    public init(config: MsAuthConfig): void {
        this.config = config;

        const {issuer, clientId, redirectUri, scopes, tokenEndpoint, redirectToExternalEndSessionEndpointAfterLogout} = this.config;

        this.oAuthService.configure({
            issuer,
            clientId,
            redirectUri,
            tokenEndpoint,
            scope: scopes.join(' '),
            strictDiscoveryDocumentValidation: false,
            skipIssuerCheck: true,
            responseType: 'code',
            openUri: (uri) => {
                if (!redirectToExternalEndSessionEndpointAfterLogout && uri.startsWith(this.oAuthService.logoutUrl)) {
                    this.redirectWhenNoAuthentication();
                } else {
                    window.location.href = uri;
                }
            }
        });

        if (this.config.sessionStorageDriver) {
            this.oAuthService.setStorage(this.config.sessionStorageDriver);
        }

        this.oAuthService.loadDiscoveryDocument()
            .then(async () => {
                if (!!tokenEndpoint) {
                    this.oAuthService.tokenEndpoint = tokenEndpoint;
                }

                await this.oAuthService.tryLogin();
                this.isReady$.next(true);
            });

        fromEvent(document, 'visibilitychange').subscribe(this.refreshSessionWithWait.bind(this));
        setInterval(this.checkIfSessionNeedsRefresh.bind(this), 30000);
    }

    public isReady(): Promise<void> {
        return new Promise((resolve) => {
            this.isReady$
                .pipe(
                    filter(isReady => !!isReady),
                    take(1)
                )
                .subscribe(() => resolve());
        });
    }

    public login(): void {
        this.oAuthService.initCodeFlow(undefined, {prompt: 'select_account'});
    }

    public getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    public isAuthenticated(): boolean {
        return !!this.getAccessToken() && this.oAuthService.hasValidAccessToken();
    }

    public async redirectAfterAuthentication(): Promise<void> {
        await this.isReady();
        await this.router.navigate(this.config.redirectRoute.afterAuthentication);
    }

    public async redirectAfterFailedAuthentication(): Promise<void> {
        await this.isReady();
        await this.router.navigate(this.config.redirectRoute.failedAuthentication);
    }

    public async redirectWhenNoAuthentication(): Promise<void> {
        await this.isReady();
        await this.router.navigate(this.config.redirectRoute.noAuthentication);
    }

    public logout(): void {
        this.oAuthService.logOut();
    }

    public async checkIfSessionNeedsRefresh(): Promise<void> {
        await this.isReady();

        const nearExpirationInMinutes = 15;
        const isNearExpiration = 0 >
            (Math.round((this.oAuthService.getAccessTokenExpiration() - Date.now()) / 1000)
                - (60 * nearExpirationInMinutes));

        if (!!this.getAccessToken() && (!this.oAuthService.hasValidAccessToken() || isNearExpiration)) {
            await this.refreshSession();
        }
    }

    private async refreshSessionWithWait() {
        return await this.refreshSession(true);
    }

    private async refreshSession(waitFirst = false): Promise<void> {
        if (waitFirst) {
            await new Promise((resolve) => setTimeout(resolve, 1500));
        }

        await this.isReady();
        await this.oAuthService.refreshToken();
    }

}
