import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-item-card',
    templateUrl: './item-card.component.html',
    styleUrls: ['./item-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ItemCardComponent {

    public isSelected = false;

    constructor() {
    }

    @Input()
    set selected(isSelected: boolean) {
        this.isSelected = isSelected;
    }

}
