import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {INotificationModalData} from '../../models';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<INotificationModalData>
    ) {
    }

}
