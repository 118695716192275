export enum UserRoles {
    ADMIN = 'ROLE_ADMIN',
    RISK_MANAGER = 'ROLE_RISK_MANAGER',
    TENDER_MANAGER = 'ROLE_TENDER_MANAGER',
    PROJECT_EDITOR = 'ROLE_PROJECT_EDITOR',
    PROJECT_VIEWER = 'ROLE_PROJECT_VIEWER',
    COMPANY_PROJECT_EDITOR = 'ROLE_COMPANY_PROJECT_EDITOR',
    COMPANY_PROJECT_VIEWER = 'ROLE_COMPANY_PROJECT_VIEWER',
    COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN',
}
