import {IQuickscanResultData} from './quickscanresult-data';

export const QuickscanResultDefault: IQuickscanResultData = {
    projectId: 0,
    classification: 'initial',
    quickscanClassifiedAt: null,
    risks: {
        score: 0,
        potential: 100,
        values: []
    },
    values: {
        score: 0,
        potential: 10,
        values: []
    },
    redFlags: {
        score: 0,
        potential: 0,
        values: []
    }
};
