import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavElement} from '@recognizebv/vwui-angular';
import {MenuBuilder, CompanyTypeService, UserService} from '../../services';
import {CompanyType} from '../../models/user/user';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MsAuthService} from '../../module/ms-auth';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

    public navItems: NavElement[];
    public companyTypes: CompanyType[];
    private userInfoSubscription: Subscription;
    private myType: string;
    private myActiveCompanyType: CompanyType;

    constructor(
        private menuBuilder: MenuBuilder,
        private companyTypeService: CompanyTypeService,
        private authService: MsAuthService,
        private userService: UserService,
        private router: Router
    ) {
    }

    get type(): string {
        return this.myType;
    }

    @Input()
    set type(type: string) {
        this.myType = type;
        this.updateMenu();
    }

    public get activeCompanyTypeId(): number {
        if (!this.myActiveCompanyType) {
            this.initializeCompanyType();
        }

        return this.myActiveCompanyType.id;
    }

    public set activeCompanyTypeId(id: number) {
        if (this.myActiveCompanyType && this.myActiveCompanyType.id === id) {
            return;
        }
        const selectedCompanyTypes = this.companyTypes
            .filter((companyType) => companyType.id === id);

        if (selectedCompanyTypes.length === 1) {
            this.myActiveCompanyType = selectedCompanyTypes[0];

            this.updateMenu();

            this.router.navigate(['/beheer', 'quickscan', this.myActiveCompanyType.id]);
        }
    }

    async ngOnInit() {
        await this.userService.getUserInfo().then((userInfo) => {
            this.companyTypes = userInfo.companyTypes.sort((a, b) => a.name.localeCompare(b.name));
            this.initializeCompanyType();
        });

        this.companyTypeService.getActiveCompanyTypeSubject().subscribe((activeCompanyType) => {
            this.myActiveCompanyType = activeCompanyType;
            this.updateMenu();
        });
        this.userInfoSubscription = this.userService.onUserInfoChange().subscribe((userInfo) => {
            this.companyTypes = userInfo.companyTypes;
            this.initializeCompanyType();
            this.updateMenu();
        });
    }

    ngOnDestroy() {
        this.userInfoSubscription.unsubscribe();
    }

    private initializeCompanyType(): void {
        this.myActiveCompanyType = undefined;
        if (this.companyTypes.length > 0) {
            this.myActiveCompanyType = this.companyTypes[0];
            this.updateMenu();
        }
    }

    private updateMenu() {
        switch (this.myType) {
            case 'admin':
                this.menuBuilder.buildAdminMenu(this.myActiveCompanyType).then((navItems) => this.navItems = navItems);
                break;
            default:
                this.menuBuilder.buildProjectsMenu().then((navItems) => this.navItems = navItems);
                break;
        }
    }
}
