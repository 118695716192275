<div *ngIf="withinPdf" class="within-pdf">{{ 'LIBRARY_ITEM_FILTERS_SELECTED.filtered_on' | translate }}</div>
<app-library-item-filters-selected-button
    [control]="filterControl.controls.riskScore"
    [defaultValue]="0"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.risk_score' | translate:{score: filterControl.value.riskScore} }}</app-library-item-filters-selected-button>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.opportunityScore"
    [defaultValue]="0"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.opportunity_score' | translate:{score: filterControl.value.opportunityScore} }}</app-library-item-filters-selected-button>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.sharedWithPrincipal"
    [defaultValue]="false"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.shared_with_principal' | translate }}</app-library-item-filters-selected-button>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.selectedOnly"
    [defaultValue]="false"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.selected_only' | translate }}</app-library-item-filters-selected-button>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.includeProposals"
    [defaultValue]="false"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.include_proposals' | translate }}</app-library-item-filters-selected-button>

<ng-container *ngFor="let control of filterControl.value.actionHolders | keyvalue">
    <app-library-item-filters-selected-button
        *ngIf="filterControl.controls.actionHolders.get(control.key)"
        [control]="filterControl.controls.actionHolders.get(control.key).controls.selected"
        [defaultValue]="false"
        [withinPdf]="withinPdf"
    >{{ control.value.name }}</app-library-item-filters-selected-button>
</ng-container>

<ng-container *ngFor="let control of filterControl.value.themes | keyvalue">
    <app-library-item-filters-selected-button
        *ngIf="filterControl.controls.themes.get(control.key)"
        [control]="filterControl.controls.themes.get(control.key).controls.selected"
        [defaultValue]="false"
        [withinPdf]="withinPdf"
    >{{ control.value.name }}</app-library-item-filters-selected-button>
</ng-container>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.bestGuessMin"
    [defaultValue]="undefined"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.best_guess_min' | translate:{price: (filterControl.value.bestGuessMin | priceFormat)} }}</app-library-item-filters-selected-button>

<app-library-item-filters-selected-button
    [control]="filterControl.controls.bestGuessMax"
    [defaultValue]="undefined"
    [withinPdf]="withinPdf"
>{{ 'COMPONENT.library_item_filters_selected.button.best_guess_max' | translate:{price: (filterControl.value.bestGuessMax | priceFormat)} }}</app-library-item-filters-selected-button>

<ng-container *ngFor="let control of filterControl.value.statuses | keyvalue">
    <app-library-item-filters-selected-button
        *ngIf="filterControl.controls.statuses.get(control.key)"
        [control]="filterControl.controls.statuses.get(control.key).controls.selected"
        [defaultValue]="false"
        [withinPdf]="withinPdf"
    >{{ control.value.name }}</app-library-item-filters-selected-button>
</ng-container>
