import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {AccessibilityService} from '../services/accessibility.service';

@Directive({
    selector: '[a11yPageTitleLevel]'
})
export class PageTitleLevelDirective implements AfterViewInit, OnDestroy, OnChanges {
    @Input() pageTitleLevel = 1;

    // Used to pass WCAG2.1 2.4.2 (A)
    constructor(
        private accessibilityService: AccessibilityService,
        private elementRef: ElementRef
    ) {
    }

    ngAfterViewInit() {
        this.accessibilityService.setPageTitle(this.elementRef.nativeElement.textContent, this.pageTitleLevel);
    }

    ngOnDestroy() {
        this.accessibilityService.clearPageTitle(this.elementRef.nativeElement.textContent, this.pageTitleLevel);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.pageTitleLevel.firstChange && changes.pageTitleLevel.currentValue > -1) {
            this.accessibilityService.setPageTitle(this.elementRef.nativeElement.textContent, changes.pageTitleLevel.currentValue);
        }
    }
}
