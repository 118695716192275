<ng-container *ngIf="pdfDetails">
    <ng-container *ngIf="pageIncluded(PDFPageKeys.PROJECT_DETAILS)">
        <app-pdf-page-project-info [projectDetails]="pdfDetails.projectDetails"></app-pdf-page-project-info>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.QUICKSCAN)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.QUICKSCAN)"></app-pdf-pagebreak>
        <app-pdf-page-quickscan [pdfDetails]="pdfDetails"></app-pdf-page-quickscan>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.TIS_QUICKSCAN)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.TIS_QUICKSCAN)"></app-pdf-pagebreak>
        <app-pdf-page-tis-quickscan [pdfDetails]="pdfDetails"></app-pdf-page-tis-quickscan>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.TIS)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.TIS)"></app-pdf-pagebreak>
        <app-pdf-page-project-tis [pdfDetails]="pdfDetails"></app-pdf-page-project-tis>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.RISK_RAINBOW)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.RISK_RAINBOW)"></app-pdf-pagebreak>
        <app-pdf-page-risk-rainbow [pdfDetails]="pdfDetails" [options]="pageOptions(PDFPageKeys.RISK_RAINBOW)"></app-pdf-page-risk-rainbow>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.RISK_RAINBOW_CLIENT)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.RISK_RAINBOW_CLIENT)"></app-pdf-pagebreak>
        <app-pdf-page-risk-rainbow [pdfDetails]="pdfDetails" [clientOnly]="true"></app-pdf-page-risk-rainbow>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.FORECAST_END_RESULT_LIST)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.FORECAST_END_RESULT_LIST)"></app-pdf-pagebreak>
        <app-pdf-page-forecast-end-result-list [pdfDetails]="pdfDetails"></app-pdf-page-forecast-end-result-list>
    </ng-container>
    <ng-container *ngIf="pageIncluded(PDFPageKeys.MANDATING_FLOW)">
        <app-pdf-pagebreak *ngIf="needPageBreak(PDFPageKeys.MANDATING_FLOW)"></app-pdf-pagebreak>
        <app-pdf-page-mandating-flow [pdfDetails]="pdfDetails"></app-pdf-page-mandating-flow>
    </ng-container>
</ng-container>
