import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Company, User} from '../../models';
import {FormUtil} from '../../utils';
import {CompanyService, TrackingService} from '../../services';

@Component({
    selector: 'app-create-project-modal',
    templateUrl: './create-update-tender-manager-modal.component.html',
    styleUrls: ['./create-update-tender-manager-modal.component.scss']
})
export class CreateUpdateTenderManagerModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public user?: User = null;
    public companies: Company[];
    public selectedCompanyNames: string[];
    public availableCompanyNames: string[];

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; data?: User; saveText: string }>,
        private companyService: CompanyService,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.user = modalConfig.data.data;

        const userValue = this.user ? {name: this.user.fullName, emailAddress: this.user.uniqueName } : null;
        this.selectedCompanyNames = this.user ? this.user.manageableCompanies.map((item) => item.name) : [];
        this.form = new FormGroup({
            user: new FormControl({value: userValue, disabled: userValue !== null},[Validators.required]),
            selectedCompanyNames: new FormControl(this.selectedCompanyNames)
        });
        this.companyService.getCompanies().then((companies) => {
            this.companies = companies;
            this.availableCompanyNames = companies.map((item) => item.name);
        });
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues = {...this.user};

                if (this.form.value.user) {
                    updatedValues.uniqueName = this.form.value.user.emailAddress;
                    if (updatedValues.uniqueName !== this.form.value.user.name) {
                        updatedValues.fullName = this.form.value.user.name;
                    }
                }

                const selectedItems: string[] = this.form.value.selectedCompanyNames;
                updatedValues.manageableCompanies = this.companies.filter((item) => selectedItems.includes(item.name));
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Updating item failed: ', e);
            this.trackingService.exception(e);
        }
    }
}
