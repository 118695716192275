import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {OAuthModule} from 'angular-oauth2-oidc';
import {MsAuthComponent} from './component';
import {MsAuthService} from './service';
import {MsAuthConfig} from './interfaces';
import {MsAuthRoutes} from './routes';
import {MsAuthInterceptor} from './interceptor';
import {MsAuthGuard} from './guard';

@NgModule({
    declarations: [
        MsAuthComponent
    ],
    providers: [
        MsAuthService
    ],
    imports: [
        OAuthModule.forRoot(),
        RouterModule.forRoot(MsAuthRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class MsAuthModule {

    public static Config: MsAuthConfig;

    constructor(
        private msAuthService: MsAuthService
    ) {
        this.msAuthService.init(MsAuthModule.Config);
    }

    static forRoot(config: MsAuthConfig): ModuleWithProviders<MsAuthModule> {
        MsAuthModule.Config = config;

        return {
            ngModule: MsAuthModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsAuthInterceptor,
                    multi: true
                },
                MsAuthGuard,
                MsAuthService
            ]
        };
    }

}
