<vwui-spinner *ngIf="!result" class="vwui-spinner--with-margin"></vwui-spinner>

<div class="main-wrapper" *ngIf="result">
    <vwui-header [id]="'ThemeTitleResult'">
        {{ getPageName() + '.result.title' | translate : { tisLabelShort : projectDetailsValue.projectInfo.tisLabelShort } }}
        <br/>
    </vwui-header>
    <div class="grid">
        <app-explanation *ngIf="canUpdateQuickscanResult() && hasTisQuickscanBeenChanged()">{{ getPageName() + '.preliminaryExplanation' | translate  : { tisLabelShort : projectDetailsValue.projectInfo.tisLabelShort } }}</app-explanation>
        <div class="grid__row grid__row--spacing-large">
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <div class="grid__row">
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.risc' | translate),
                                value: result.risks.score,
                                of: result.risks.potential,
                                progress: result.determineRiskProgress(),
                                barColor: result.determineRiskColor()
                            }"
                        ></app-block-stats>
                    </div>
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.value' | translate),
                                value: result.values.score,
                                of: result.values.potential,
                                progress: result.determineValueProgress(),
                                barColor: colors.GREEN
                            }"
                        ></app-block-stats>
                    </div>
                </div>
                <div class="grid__row">
                    <div class="grid__col grid__col--6">
                        <app-block-stats
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.redFlags' | translate),
                                value: result.redFlags.score,
                                of: result.redFlags.potential,
                                progress: result.determineRedflagProgress(),
                                barColor: colors.RED
                            }"
                        >
                            <ng-container *ngFor="let redFlag of redFlags">
                                <vwui-tooltip label="{{ redFlag }}">
                                    <vwui-icon class="red-flag" iconName="flag"></vwui-icon>
                                </vwui-tooltip>
                            </ng-container>
                        </app-block-stats>
                    </div>
                    <div class="grid__col grid__col--6">
                        <app-block-signal
                            class="block-signal--{{ result.classification }}"
                            [config]="{
                                label: ('PAGE_QUICKSCAN_RESULT.result.classification' | translate),
                                value: ('CLASSIFICATION.' + result.classification | translate),
                                backgroundColor: this.determineClassificationColor(result.classification),
                                color: this.determineClassificationTextColor(result.classification)
                            }"
                        ></app-block-signal>
                    </div>
                </div>
            </div>
            <div class="grid__col grid__col--spacing-large grid__col--6">
                <app-chart-signal-matrix
                    class="block-chart-signal"
                    [config]="risksVsValueMatrix"
                ></app-chart-signal-matrix>
            </div>
        </div>
    </div>
    <div class="grid">
        <form [formGroup]="form">
            <div class="grid__row grid__row--spacing-large">
                <div class="grid__col grid__col--spacing-large grid__col--6">
                    <vwui-card>
                        <vwui-card-header>{{ 'PAGE_QUICKSCAN_RESULT.evaluation.title' | translate }}</vwui-card-header>
                        <vwui-card-body>
                            <vwui-form-group inputId="evaluation">
                                <vwui-textarea inputId="evaluation" formControlName="evaluation"></vwui-textarea>
                            </vwui-form-group>
                        </vwui-card-body>
                    </vwui-card>
                </div>
                <div class="grid__col grid__col--spacing-large grid__col--6">
                    <vwui-card>
                        <vwui-card-header>{{ 'PAGE_QUICKSCAN_RESULT.statement.title' | translate }}</vwui-card-header>
                        <vwui-card-body>
                            <vwui-form-group inputId="statementFromUser">
                                <vwui-textarea inputId="statementFromUser" formControlName="statementFromUser"></vwui-textarea>
                            </vwui-form-group>
                        </vwui-card-body>
                    </vwui-card>
                </div>
            </div>
        </form>
    </div>

    <div class="clear flex-wrapper">
        <app-explanation *ngIf="this.result.classification === 'initial'">{{ getPageName() + '.cannotBePersistedExplanation' | translate  : { tisLabelShort : projectDetailsValue.projectInfo.tisLabelShort }  }}</app-explanation>
        <vwui-button class="is-primary flex-end" *ngIf="!isMandated()" [appDisabledReason]="this.persistDisabledReason" [loading]="isPersistCalculationSaving" (click)="persistCalculation()">{{ getPageName() + '.button.save' | translate : { tisLabelShort : projectDetailsValue.projectInfo.tisLabelShort } }}</vwui-button>
        <small class="flex-end" *ngIf="doShowQuickscanSubmittedAt()">{{ 'PAGE_QUICKSCAN_RESULT.classified_at' | translate : {date: (this.result.quickscanClassifiedAt | date: ('DATE.long' | translate))} }}</small>
    </div>

</div>
