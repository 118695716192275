import {Directive, ElementRef, AfterViewInit, HostListener, EventEmitter, Output} from '@angular/core';
import {AccessibilityService, HotkeyMark, HotkeyType} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yHotkeyCreate]'
})
export class HotkeyCreateDirective implements AfterViewInit {
    // Focus create button and click it - Defaults to ALT-N

    constructor(
        private elementRef: ElementRef,
        private accessibilityService: AccessibilityService
    ) {
    }

    @Output() hotkeyCreate = new EventEmitter<string>();

    ngAfterViewInit() {
        setTimeout(() => {
            FocusUtil.applyHotkeyMarker(this.elementRef.nativeElement, HotkeyMark.hotkeyCreate);
        }, 500); // TODO AC-109
    }

    @HostListener('keydown', ['$event']) onCreate($event){
        if (this.accessibilityService.checkHotkey($event, HotkeyType.CREATE)) {
            $event.preventDefault();
            this.hotkeyCreate.emit();
        }
    }
}
