import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalRef} from '@recognizebv/vwui-angular';
import {ImageService, LoadingSpinnerService, ProjectService, TranslationService, UserService} from '../../services';
import {FormUtil} from '../../utils';
import {TranslateService} from '@ngx-translate/core';
import {ICompany} from '../../models';

@Component({
    selector: 'app-create-project-modal',
    templateUrl: './create-project-modal.component.html'
})
export class CreateProjectModalComponent implements OnInit {

    public form = new FormGroup({
        name: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        country: new FormControl('NL', Validators.required),
        companyName: new FormControl('', Validators.required)
    });
    public loading = false;
    public companyNames: string[];
    public countries: {id: string; name: string}[];

    constructor(
        private projectService: ProjectService,
        private userService: UserService,
        public modalRef: VwuiModalRef,
        private translationService: TranslationService
    ) {
        this.countries = translationService.getCountriesAsKeyValue();
    }

    ngOnInit(): void {
        this.userService.getUserInfo().then((userInfo) => {
            this.companyNames = userInfo.getCompanyNamesForManuallyAddingProjects();
        });
    }

    async submit() {
        this.loading = true;

        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                await this.projectService.createProject(this.form.value);
                this.modalRef.close(true);
            }
        } finally {
            this.loading = false;
        }
    }
}
