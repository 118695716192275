export enum PDFPageKeys {
    PROJECT_DETAILS = 'projectDetails',
    QUICKSCAN = 'quickscanQuestions',
    TIS_QUICKSCAN = 'tisQuickscanQuestions',
    TIS = 'tis',
    RISK_RAINBOW = 'riskRainbow',
    RISK_RAINBOW_CLIENT = 'riskRainbowClient',
    RISKS_OPPORTUNITIES = 'risksAndOpportunities',
    RISKS_OPPORTUNITIES_CLIENT = 'risksAndOpportunitiesClient',
    FORECAST_END_RESULT_LIST = 'forecastEndResultList',
    MANDATING_FLOW = 'mandatingFlow',
}
