export interface ILibraryItemFiltersCollapsedData {
    score: boolean;
    actionHolder: boolean;
    theme: boolean;
    bestGuess: boolean;
    status: boolean;
}

export const LibraryItemFiltersCollapsedDefaultData: ILibraryItemFiltersCollapsedData = {
    score: true,
    actionHolder: true,
    theme: true,
    bestGuess: true,
    status: true
};
