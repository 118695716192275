<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails.projectDetails.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ 'PAGE_FILL_TIS.title' | translate : { tisLabelShort: pdfDetails.projectDetails.projectInfo.tisLabelShort } }}</h1>
    <small class="header-subtitle">{{ 'PAGE_FILL_TIS.version' | translate : { tisLabelShort: pdfDetails.projectDetails.projectInfo.tisLabelShort } }}
        : {{ pdfDetails.tisDefinition?.versionId }}</small>

    <div class="grid-spacer grid-spacer--large"></div>
    <ng-container *ngFor="let tisCategory of pdfDetails.tisDefinition.categories; let tisCategoryIndex = index; trackBy: trackTisSubCategory">
        <div class="prevent-pagebreak-inside">
            <vwui-table [compactable]="false">
                <div slot="table-title">{{ tisCategoryIndex + 1 }}. {{ tisCategory.name }}</div>
                <vwui-table-row>
                    <vwui-table-header class="table-col table-col--nr">{{ 'PAGE_FILL_TIS.table.column.number' | translate }}</vwui-table-header>
                    <vwui-table-header class="table-col table-col--question">{{ 'PAGE_FILL_TIS.table.column.question' | translate }}</vwui-table-header>
                    <vwui-table-header class="table-col table-col--answer">{{ 'PAGE_FILL_TIS.table.column.answer' | translate }}</vwui-table-header>
                </vwui-table-row>

                <ng-container *ngFor="let tisCategoryQuestion of tisCategory | categoryGetAllSubCategoryQuestions | async; let tisCategoryQuestionIndex = index; trackBy: trackTisSubCategoryQuestion">
                    <vwui-table-row [ngClass]="{'table-row table-row--with-attachments': hasAttachmentsForTisAnswer(tisCategoryQuestion.id)}">
                        <vwui-table-column class="table-col table-col--nr">{{ tisCategoryIndex + 1 }}.{{ tisCategoryQuestionIndex + 1 }}</vwui-table-column>
                        <vwui-table-column class="table-col table-col--question">{{ tisCategoryQuestion.name }}</vwui-table-column>
                        <vwui-table-column class="table-col table-col--answer">{{ getTisAnswer(tisCategoryQuestion.id) }}</vwui-table-column>
                    </vwui-table-row>
                    <vwui-table-row class="table-row table-row--attachments" *ngIf="hasAttachmentsForTisAnswer(tisCategoryQuestion.id)">
                        <vwui-table-column></vwui-table-column>
                        <vwui-table-column class="table-col table-col--question">
                            {{ 'PAGE_FILL_TIS.attachments_pdf' | translate }}
                        </vwui-table-column>
                        <vwui-table-column class="table-col table-col--answer">
                            <ng-container *ngFor="let attachment of getAttachmentsForTisAnswer(tisCategoryQuestion.id)">
                                <div>{{ attachment.originalFileName }}</div>
                            </ng-container>
                        </vwui-table-column>
                    </vwui-table-row>
                </ng-container>
            </vwui-table>
            <div class="grid-spacer grid-spacer--large"></div>
        </div>
    </ng-container>
    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
