import {ICategoryInfoData} from './project-data';
import {Category} from '..';

export class CategoryInfos {
    private categoryInfo: CategoryInfo[];

    constructor(data: ICategoryInfoData[]) {
        this.categoryInfo = data.map(item => new CategoryInfo(item));
    }

    public getRemarkForCategory(category: Category) {
        const categoryInfo = this.getCategoryInfoForCategory(category);

        return categoryInfo.remarks;
    }

    public setRemarkForCategory(category: Category, remark: string) {
        const categoryInfo = this.getCategoryInfoForCategory(category);
        categoryInfo.setRemarks(remark);
    }

    public getCategoryInfoForCategory(category: Category): CategoryInfo {
        let categoryInfo = this.categoryInfo.find((info) => info.categoryId === category.id);
        if (categoryInfo === undefined) {
            categoryInfo = new CategoryInfo({categoryId: category.id});
            this.categoryInfo.push(categoryInfo);
        }

        return categoryInfo;
    }

    getCategoryInfo() {
        return this.categoryInfo;
    }
}

export class CategoryInfo {
    categoryId: number;
    remarks?: string;

    constructor(data: ICategoryInfoData) {
        this.categoryId = data.categoryId;
        this.remarks = data.remarks;
    }

    public setRemarks(remarks: string) {
        this.remarks = remarks;
    }
}
