<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ "PAGE_TASK_LIBRARY.title" | translate}}</h2>
</vwui-header>

<vwui-spinner *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div class="mt-lg" *ngIf="!loadingSpinnerService.isSpinnerVisible()">
    <app-library-mgmt [library]="tasksLibrary" [companyTypeId]="companyTypeId">
    </app-library-mgmt>
</div>
