<ng-container *ngIf="control.value !== defaultValue">
    <ng-container *ngIf="withinPdf">
        <span class="filter-button__pdf-content"><ng-container *ngTemplateOutlet="content"></ng-container></span>
    </ng-container>
    <ng-container *ngIf="!withinPdf">
        <vwui-tooltip [label]="'BUTTON.delete' | translate">
            <vwui-button class="filter-button" (click)="resetControlValue()">
                <span class="filter-button__content"><ng-container *ngTemplateOutlet="content"></ng-container></span>
                <vwui-icon class="filter-button__icon" iconName="vwui-close"></vwui-icon>
            </vwui-button>
        </vwui-tooltip>
    </ng-container>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
