import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-sticky-header',
    templateUrl: './sticky-header.component.html',
    styleUrls: ['./sticky-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StickyHeaderComponent implements OnInit, OnDestroy {

    constructor() {
    }

    ngOnInit() {
        document.querySelector('body').classList.add('has-sticky-header');
    }

    ngOnDestroy() {
        document.querySelector('body').classList.remove('has-sticky-header');
    }

}
