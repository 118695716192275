export class AriaUtil {

    /**
     * Do a quick DOM query to find all possible tabs that aren't disabled
     *
     * @param element
     */
    public static findAvailableTabs(element: HTMLElement): NodeList {
        return element.querySelectorAll('[role=tab]:not([disabled], [aria-disabled]), vwui-tab:not([disabled], [aria-disabled=true])');
    }

}
