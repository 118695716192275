<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ "PAGE_QUICKSCAN_EDIT.title" | translate }}</h2>
    <div class="button-group" slot="action">
        <vwui-button (click)="navigateToQuickscanList()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="isUpdatingQuickscan" (click)="updateQuickscan()">
            {{ 'BUTTON.save' | translate }}
        </vwui-button>
    </div>
</vwui-header>

<vwui-spinner *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<ng-container *ngIf="quickscanCategories">
    <div class="quickscan-property">
        <div class="property-header">
            {{ 'PAGE_QUICKSCAN_EDIT.property.redflagTreshold' | translate }}
        </div>
        <div class="property-value">
            <vwui-input (change)="redflagChanged($event.target.value)" [value]="quickscanDefinition.redFlagTresholdCategory" type="number" [min]="0" [max]="100" ></vwui-input>
        </div>
    </div>
    <div class="quickscan-theme" *ngFor="let quickscanCategory of quickscanCategories; let categoryIndex = index; trackBy: trackQuickscanCategory">
        <vwui-table [compactable]="false">
            <div slot="table-title">{{ categoryIndex + 1 }}. {{ quickscanCategory.name }} <div class="weighted-score">{{ 'COMPONENT.quickscan.table.header.weighted_score' | translate }}: {{ quickscanCategory.weightedScore }}</div></div>
            <div slot="table-actions">
                <vwui-btn-icon icon="vwui-chevron-up" (click)="moveCategoryUp(quickscanCategory)" [disabled]="categoryIndex === 0"></vwui-btn-icon>
                <vwui-btn-icon icon="chevron-down" (click)="moveCategoryDown(quickscanCategory)" [disabled]="categoryIndex >= quickscanCategories.length - 1"></vwui-btn-icon>
                <vwui-btn-icon icon="edit" (click)="openUpdateCategoryModal(quickscanCategory)"></vwui-btn-icon>
                <vwui-btn-icon icon="vwui-trash" (click)="openDeleteCategoryModal(quickscanCategory)"></vwui-btn-icon>
            </div>
            <vwui-table-row>
                <vwui-table-header class="quickscan-col quickscan-col--nr">{{ 'COMPONENT.quickscan.table.column.nr' | translate }}</vwui-table-header>
                <vwui-table-header class="quickscan-col quickscan-col--question">{{ 'COMPONENT.quickscan.table.column.question' | translate }}</vwui-table-header>
                <vwui-table-header class="quickscan-col quickscan-col--maxscore">{{ 'COMPONENT.quickscan.table.column.maxscore' | translate }}</vwui-table-header>
                <vwui-table-header class="quickscan-col quickscan-col--options"></vwui-table-header>
            </vwui-table-row>

            <vwui-table-row *ngFor="let question of quickscanCategory | categoryGetAllSubCategoryQuestions | async; let questionIndex = index; trackBy: trackQuickscanSubcategoryQuestions">
                <vwui-table-column class="quickscan-col quickscan-col--nr">{{ categoryIndex + 1 }}.{{ questionIndex + 1 }} </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--question">
                    <div class="quickscan-subject">{{ question.getCategoryName() }}</div>
                    {{ question.name }}
                    <ng-container *ngIf="question.info">
                        <vwui-tooltip [label]="question.info"><vwui-icon class="info" iconName="info"></vwui-icon></vwui-tooltip>
                    </ng-container>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--maxscore"> {{ getMaxScoreForQuestion(question) }} </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--options">
                    <vwui-btn-icon icon="vwui-chevron-up" [disabled]="questionIndex === 0" (click)="moveQuestionUp(question, quickscanCategory)"></vwui-btn-icon>
                    <vwui-btn-icon icon="chevron-down" [disabled]="questionIndex >= quickscanCategory.getSubcategoryQuestionCount() - 1" (click)="moveQuestionDown(question, quickscanCategory)"></vwui-btn-icon>
                    <vwui-btn-icon icon="edit" (click)="openUpdateQuestionModal(question)"></vwui-btn-icon>
                    <vwui-btn-icon icon="vwui-trash" (click)="openDeleteQuestionModal(question)"></vwui-btn-icon>
                </vwui-table-column>
            </vwui-table-row>

            <vwui-table-row>
                <vwui-table-column></vwui-table-column>
                <vwui-table-column>
                    <vwui-button class="is-secondary" (click)="openCreateQuestionModal(quickscanCategory)">
                        {{ 'COMPONENT.quickscan.create_question' | translate }}
                    </vwui-button>
                </vwui-table-column>
                <vwui-table-column class="quickscan-col quickscan-col--maxscore quickscan-col--maxscore-total">{{ quickscanCategory.getMaxScore() }}</vwui-table-column>
                <vwui-table-column></vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </div>

    <vwui-button class="is-secondary" (click)="openCreateCategoryModal()">
        {{ 'COMPONENT.quickscan.create_category' | translate }}
    </vwui-button>
</ng-container>
