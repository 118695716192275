<form [formGroup]="form" (submit)="modalRef.close()" class="create-update-modal" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <vwui-form-group label="{{ 'COMPONENT.company.name' | translate }}" inputId="name" [required]="true">
            <vwui-input inputId="name" formControlName="name" placeholder="{{ 'COMPONENT.company.placeholder' | translate}}"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'COMPONENT.company.type' | translate }}" inputId="companyType" [required]="true">
            <ng-select [selectOnTab]="true"
                       [items]="companyTypes"
                       bindLabel="name"
                       formControlName="companyType"
                       labelForId="id"
                       required="true"
                       placeholder="{{ 'COMPONENT.company.type' | translate }}"
                       [readonly]="this.data"
            >
            </ng-select>
        </vwui-form-group>
        <app-radio-group *ngIf="isApiProjectCreationEnabled" label="{{ 'COMPONENT.company.apiProjectCreation.label' | translate}}" class="radio-group" [form]="form" name="apiProjectCreation" [radioItems]="apiProjectCreationOptions" [required]="true"></app-radio-group>
        <vwui-form-group label="{{ 'COMPONENT.company.companyCode' | translate }}" [required]="isCompanyCodeRequired">
            <vwui-input inputId="company_code" formControlName="companyCode"></vwui-input>
        </vwui-form-group>
        <vwui-table [compactable]="false" class="member">
            <div slot="table-title">{{ 'COMPONENT.company.table.permissions' | translate }}</div>

            <ng-container *ngIf="getMembers().length > 0">
                <vwui-table-row>
                    <vwui-table-header class="member__col member__col--name">{{ 'COMPONENT.company.table.column.name' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--edit-rights">{{ 'COMPONENT.company.table.column.edit_rights' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--admin-rights">{{ 'COMPONENT.company.table.column.admin_rights' | translate }}</vwui-table-header>
                    <vwui-table-header class="member__col member__col--options"></vwui-table-header>
                </vwui-table-row>

                <vwui-table-row *ngFor="let member of getMembers()">
                    <vwui-table-column class="member__col member__col--name">{{ member.user | namesFormat }}</vwui-table-column>
                    <vwui-table-column class="member__col member__col--edit-rights"><vwui-checkbox [checked]="member.editRights" (click)="toggleAccess($event, member)"></vwui-checkbox></vwui-table-column>
                    <vwui-table-column class="member__col member__col--admin-rights"><vwui-checkbox [checked]="member.adminRights" (click)="toggleAdminAccess($event, member)"></vwui-checkbox></vwui-table-column>
                    <vwui-table-column class="member__col member__col--options"><vwui-btn-icon icon="vwui-trash" (click)="removeMember(member)"></vwui-btn-icon></vwui-table-column>
                </vwui-table-row>
            </ng-container>

            <vwui-table-row *ngIf="getMembers().length === 0">
                <vwui-table-column>{{ 'COMPONENT.share_project.table.column.email_empty' | translate }}</vwui-table-column>
            </vwui-table-row>
        </vwui-table>

        <div class="add-row">
            <div class="add-row__input"><app-user-identity-input [control]="form.controls.user" [identitiesToExclude]="identitiesToExclude$" [companyType]="data ? data.companyType : null"></app-user-identity-input></div>
            <div class="add-row__input add-row__input--submit"><vwui-button class="is-secondary" (click)="addMember()" [disabled]="isAddMemberButtonDisabled()">{{ 'BUTTON.create' | translate }}</vwui-button></div>
        </div>

    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
