import {Component, Input, OnInit} from '@angular/core';
import {
    LoadingSpinnerService,
    ProjectMandateService,
    ProjectService,
    QuickscanDefinitionService,
    TisDefinitionService
} from '../../services';
import {
    IQuestionData,
    IQuestionType,
    ProjectDetails,
    ProjectMandateDetails,
    QuickscanDefinition,
    TisDefinition
} from '../../models';
import {IScrollNavigationItem} from '../scroll-navigation/scroll-navigation.data';
import {IAttachment, ITisAnswerValue} from '../../models/project/project-data';
import {Question} from '../../models/definition/question';
import {RouterService} from '../../services/router/router.service';
import {ProjectSteps} from '../../enums';
import {TisPossibleAnswer} from '../../models/definition/tis-possible-answer';
import {DepartmentService} from '../../services/department/department.service';
import {IDepartment} from '../../models/department/department';
import {TisCalculationOperator} from '../../models/definition/tis-calculation-operator';

@Component({
    selector: 'app-project-tis',
    templateUrl: './project-tis.component.html',
    styleUrls: ['./project-tis.component.scss']
})
export class ProjectTisComponent implements OnInit {

    public navItems: IScrollNavigationItem[] = [];
    public quickscanDefinition?: QuickscanDefinition;
    public tisDefinition?: TisDefinition;
    @Input() public projectDetails: ProjectDetails;
    public mandateDetails: ProjectMandateDetails;
    public isPreviousUrlMandating = false;
    public departments: IDepartment[] = [];

    constructor(
        private projectService: ProjectService,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private tisDefinitionService: TisDefinitionService,
        private projectMandateService: ProjectMandateService,
        private routerService: RouterService,
        private departmentService: DepartmentService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit() {
        this.loadingSpinnerService.show();

        this.departments = await this.departmentService.getDepartments(this.projectDetails.id);

        this.tisDefinition = await this.tisDefinitionService.getTisDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.tisDefinitionVersionId,
            this.projectDetails.tisLabelShort
        );

        this.quickscanDefinition = await this.quickscanDefinitionService.getQuickscanDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.quickscanDefinitionVersionId
        );

        this.navItems.push(
            ...this.tisDefinition.categories.map((category, index) => ({
                label: '' + (index + 1) + '. ' + category.name,
                elementId: 'ThemeTitleTIS' + category.id
            }))
        );

        await this.projectMandateService.getMandateDetails(this.projectDetails)
            .then((mandateDetails) => {
                this.mandateDetails = mandateDetails;
            });

        this.isPreviousUrlMandating = this.routerService.checkIfFromMandating();

        if (this.navItems && this.tisDefinition) {
            this.loadingSpinnerService.hide();
        }
    }

    protected isDependencyMet(question: Question): boolean {
        if (question.dependentQuestion == null) {
            return true;
        }

        const dependentQuestion = this.tisDefinition.questions
            .find(q => q.id === question.dependentQuestion.id);
        const dependentAnswer = this.projectDetails.tisAnswers
            .find(answer => answer.questionId === dependentQuestion.id);
        if (dependentAnswer == null) {
            return false;
        }

        if (!this.isDependencyMet(dependentQuestion)) {
            return false;
        }

        if (dependentQuestion.type === IQuestionType.BOOL) {
            return dependentAnswer.value.bool === question.dependentQuestion.boolValue;
        } else if (dependentQuestion.type === IQuestionType.MULTIPLECHOICE) {
            return dependentAnswer.value.answerId === question.dependentQuestion.answerId;
        } else {
            throw new Error(`Unexpected type ${dependentQuestion.type}`);
        }
    }

    public answerChanged(answer: string | Date, question: IQuestionData) {
        const tisAnswerValue = this.convertFormAnswerToRestTisValue(question, answer);

        this.projectDetails.updateTisAnswer(question, tisAnswerValue);

        const dependentQuestions = this.tisDefinition.questions.filter(q => q.calculation?.leftQuestionTempId === question.tempId || q.calculation?.rightQuestionTempId === question.tempId);
        for (const dependentQuestion of dependentQuestions) {
            this.recalculate(dependentQuestion);
        }
    }

    private recalculate(calculationQuestion: Question) {
        const leftQuestion = this.tisDefinition.questions.find(q => q.tempId === calculationQuestion.calculation.leftQuestionTempId);
        const rightQuestion = this.tisDefinition.questions.find(q => q.tempId === calculationQuestion.calculation.rightQuestionTempId);

        const {value: {integer: leftValue}} = this.projectDetails.tisAnswers.find(a => a.questionId === leftQuestion.id);
        const {value: {integer: rightValue}} = this.projectDetails.tisAnswers.find(a => a.questionId === rightQuestion.id);
        const computedAnswer = this.projectDetails.tisAnswers.find(a => a.questionId === calculationQuestion.id);

        if (typeof leftValue !== 'number' || typeof rightValue !== 'number') {
            computedAnswer.setValue({integer: null, attachments: []});
        } else {
            const computedValue = this.applyOperator(calculationQuestion.calculation.operator, leftValue, rightValue);
            computedAnswer.setValue({integer: computedValue, attachments: []});
        }
    }

    private applyOperator(operator: TisCalculationOperator, left: number, right: number) {
        switch (operator) {
            case TisCalculationOperator.SUBTRACT:
                return left - right;
            default:
                throw new Error(`Unexpected operator ${operator}`);
        }
    }

    public attachmentsChanged(attachments: IAttachment[], question: IQuestionData) {
        const tisAnswerValue = this.projectDetails.getTisAnswerForQuestion(question).value;
        tisAnswerValue.attachments = attachments;

        this.projectDetails.updateTisAnswer(question, tisAnswerValue);
    }

    public getInitialValue(question: Question) {
        const answer = this.projectDetails.getTisAnswerForQuestion(question);

        return this.convertRestTisValueToFormAnswer(question, answer.value);
    }

    public getUriForStep(stepId: ProjectSteps): string {
        return `/project/${this.projectDetails.id}/step/${stepId}`;
    }

    public quickscanSubmitted(): boolean {
        return !(this.mandateDetails && !this.mandateDetails.preconditions.quickscanNotSubmitted);
    }

    public canEditTis(): boolean {
        return !this.projectDetails.isMandated()
            && !this.projectDetails.isOnHold()
            && !this.projectDetails.isClosed()
            && !this.projectDetails.isInMandateProcess()
            && this.projectService.canEditTis(this.projectDetails);
    }

    public getQuestionAttachments(question: Question): IAttachment[] {
        const tisAnswerValue = this.projectDetails.getTisAnswerForQuestion(question).value;
        return tisAnswerValue.attachments;
    }

    public hasEmptyRequiredQuestion(): boolean {
        return this.tisDefinition.categories
            .flatMap(category => category.questions)
            .filter(question => !this.isQuestionFilledOrOptional(question))
            .some(question => this.isDependencyMet(question));
    }

    private isQuestionFilledOrOptional(question: Question): boolean {
        return !question.isRequired || (
            this.getInitialValue(question) != null &&
            this.getInitialValue(question) !== '');
    }

    private convertFormAnswerToRestTisValue(question: IQuestionData, answer: string | Date | number | boolean | TisPossibleAnswer): ITisAnswerValue {
        if (question.type === IQuestionType.DATE) {
            let transformedValue = answer as Date;

            if (typeof transformedValue === 'string' && transformedValue === 'Invalid date') {
                transformedValue = null;
            }

            return {date: transformedValue, attachments: []};
        } else if (question.type === IQuestionType.TEXT) {
            return {text: answer as string, attachments: []};
        } else if (question.type === IQuestionType.INTEGER || question.type === IQuestionType.CALCULATION) {
            return {integer: answer as number, attachments: []};
        } else if (question.type === IQuestionType.BOOL) {
            return {bool: answer as boolean, attachments: []};
        } else if (question.type === IQuestionType.PERCENTAGE) {
            return {percentage: answer as number, attachments: []};
        } else if (question.type === IQuestionType.MULTIPLECHOICE) {
            return {answerId: answer as number, attachments: []};
        } else if (question.type === IQuestionType.DEPARTMENT) {
            return {departmentId: answer as number, attachments: []};
        } else {
            throw new Error(`Unrecognized question type ${question.type}`);
        }
    }

    private convertRestTisValueToFormAnswer(question: IQuestionData, restAnswer: ITisAnswerValue): string | Date | number | boolean {
        if (restAnswer === null) {
            return null;
        } else if (question.type === IQuestionType.DATE) {
            return restAnswer.date;
        } else if (question.type === IQuestionType.TEXT) {
            return restAnswer.text;
        } else if (question.type === IQuestionType.INTEGER || question.type === IQuestionType.CALCULATION) {
            return restAnswer.integer;
        } else if (question.type === IQuestionType.BOOL) {
            return restAnswer.bool;
        } else if (question.type === IQuestionType.PERCENTAGE) {
            return restAnswer.percentage;
        } else if (question.type === IQuestionType.MULTIPLECHOICE) {
            return restAnswer.answerId;
        } else if (question.type === IQuestionType.DEPARTMENT) {
            return restAnswer.departmentId;
        } else {
            throw new Error(`Unrecognized question type ${question.type}`);
        }
    }
}
