import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-inline-notification',
    templateUrl: './inline-notification.component.html',
    styleUrls: ['./inline-notification.component.scss']
})
export class InlineNotificationComponent {

    @Input() public icon: string;

    constructor() {
    }

}
