import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    private previousRoute = '';

    private currentRoute = '';

    constructor(
        private router: Router
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (this.currentRoute !== event.url) {
                    this.previousRoute = this.currentRoute;
                }
                this.currentRoute = event.url;
            });
    }

    public getPreviousUrl(): string {
        return this.previousRoute;
    }

    public checkIfFromMandating(): boolean {
        return this.getPreviousUrl().split('/step/')[1] === '9';
    }
}
