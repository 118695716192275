import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CategoryType, ICategoryData, CategoryDefault} from '../../models';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';

@Component({
    selector: 'app-quickscan-create-update-category-modal',
    templateUrl: './quickscan-create-update-category-modal.component.html',
    styleUrls: ['./quickscan-create-update-category-modal.component.scss']
})
export class QuickscanCreateUpdateCategoryModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: ICategoryData;
    public categoryTypes = CategoryType;
    public categoryType: string = CategoryType.RISK;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{title: string; data?: ICategoryData; saveText: string}>,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;

        const nameValue = this.data && this.data.name ? this.data.name : CategoryDefault.name;
        const typeValue = this.data && this.data.type ? this.data.type : CategoryDefault.type;
        const weightedScoreValue = this.data && this.data.weightedScore ? this.data.weightedScore : CategoryDefault.weightedScore;

        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required),
            type: new FormControl(typeValue, Validators.required),
            weightedScore: new FormControl(weightedScoreValue, Validators.required)
        });

        this.setType(typeValue);
    }

    public setType(type: CategoryType) {
        this.categoryType = type;

        this.form.setValue({...this.form.value, ...{
            type
        }});
    }

    public submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues: ICategoryData = {...this.data, ...this.form.value};
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Creating category failed: ', e);
            this.trackingService.exception(e);
        }
    }

}
