import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-input-counter',
    templateUrl: './input-counter.component.html',
    styleUrls: ['./input-counter.component.scss']
})
export class InputCounterComponent {

    private static readonly testValue = new Array(65535).fill('-').join('');
    private formControl: AbstractControl;

    public maxLength: number;

    @Input()
    set control(formControl: AbstractControl) {
        const errors = formControl.validator({value: InputCounterComponent.testValue} as AbstractControl);

        this.formControl = formControl;
        this.maxLength = errors?.maxlength?.requiredLength || undefined;
    }

    get control(): AbstractControl {
        return this.formControl;
    }

}
