<div
    *ngIf="control"
    class="input-counter"
    [ngClass]="{'input-counter--has-error': control.errors?.maxlength}"
>
    <ng-content select="[slot='start']"></ng-content>
    <ng-container *ngIf="control.errors?.maxlength?.requiredLength; else showHint">
        {{ 'COMPONENT.input_counter.error' | translate:{count: control.value.length, max: maxLength, diff: control.value.length - maxLength} }}
    </ng-container>
    <ng-template #showHint>
        {{ 'COMPONENT.input_counter.hint' | translate:{count: (control.value || '').length, max: maxLength} }}
    </ng-template>
</div>
