<form [formGroup]="form" (submit)="submit()" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ 'PAGE_PROJECTEN.new_project' | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body a11yAutoFocus>
        <vwui-form-group label="{{ 'PAGE_PROJECTEN.form.name' | translate }}" inputId="name">
            <vwui-input inputId="name" formControlName="name" placeholder="{{ 'PAGE_PROJECTEN.placeholder.name' | translate }}"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'PAGE_PROJECTEN.form.address' | translate }}" inputId="location">
            <vwui-input inputId="address" formControlName="address" placeholder="{{ 'PAGE_PROJECTEN.placeholder.address' | translate }}"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'PAGE_PROJECTEN.form.city' | translate }}" inputId="city">
            <vwui-input inputId="city" formControlName="city" placeholder="{{ 'PAGE_PROJECTEN.placeholder.city' | translate }}"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'PAGE_PROJECTEN.form.country' | translate }}" inputId="country">
            <ng-select [items]="countries"
                       [selectOnTab]="true"
                       bindLabel="name"
                       bindValue="id"
                       labelForId="country"
                       required="true"
                       placeholder="{{ 'PAGE_PROJECTEN.placeholder.country' | translate }}"
                       formControlName="country"
            >
            </ng-select>
        </vwui-form-group>
        <vwui-form-group label="{{ 'PAGE_PROJECTEN.form.companyName' | translate }}" inputId="companyName">
            <ng-select [items]="companyNames"
                       [selectOnTab]="true"
                       bindValue="companyName"
                       labelForId="age"
                       required="true"
                       placeholder="{{ 'PAGE_PROJECTEN.placeholder.companyName' | translate }}"
                       formControlName="companyName">
            </ng-select>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ 'BUTTON.create' | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
