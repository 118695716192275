<vwui-header>
    <h2 a11yPageTitleLevel="1">{{ 'PAGE_ACCESSIBLE_STEPS.title' | translate }}</h2>
    <vwui-button *ngIf="showSetToDefaultButton" slot="action" (click)="setToDefault()">
        {{ 'PAGE_ACCESSIBLE_STEPS.set_to_default_btn' | translate }}
    </vwui-button>
    <vwui-button
        slot="action"
        class="is-primary"
        [disabled]="isDisabled"
        [loading]="isLoading"
        (click)="save()">
        {{ 'BUTTON.save' | translate }}
    </vwui-button>
</vwui-header>

<div class="card-container">
    <vwui-card class="step-list step-list--accessible-steps">
        <vwui-card-header>{{ 'PAGE_ACCESSIBLE_STEPS.accessible_steps_title' | translate }}</vwui-card-header>
        <vwui-card-body
            class="step-list__body"
            #accessibleStepList="cdkDropList"
            cdkDropList
            [cdkDropListData]="accessibleSteps"
            [cdkDropListConnectedTo]="[remainingStepList]"
            (cdkDropListDropped)="onDrop($event)"
            [cdkDropListSortPredicate]="sortPredicate.bind(this)">
            <div *ngFor="let step of accessibleSteps"
                 class="step-item item"
                 [class.item--draggable]="!isStepDisabled(step)"
                 [class.item--disabled]="isStepDisabled(step)"
                 cdkDrag
                 [cdkDragDisabled]="isStepDisabled(step)">{{getTitleForStepId(step)}}</div>
        </vwui-card-body>
    </vwui-card>

    <vwui-card class="step-list step-list--remaining-steps">
        <vwui-card-header>{{ 'PAGE_ACCESSIBLE_STEPS.remaining_steps_title' | translate }}</vwui-card-header>
        <vwui-card-body
            class="step-list__body"
            #remainingStepList="cdkDropList"
            cdkDropList
            [cdkDropListData]="remainingSteps"
            [cdkDropListConnectedTo]="[accessibleStepList]"
            (cdkDropListDropped)="onDrop($event)">
            <div *ngFor="let step of remainingSteps" class="step-item item item--draggable"
                 cdkDrag>{{getTitleForStepId(step)}}</div>
            <div class="item empty-state">{{ 'PAGE_ACCESSIBLE_STEPS.empty_state_message' | translate }}</div>
        </vwui-card-body>
    </vwui-card>
</div>
