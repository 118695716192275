<form [formGroup]="form" class="create-update-modal" a11yFocusTrap>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ 'COMPONENT.update_notification_modal.title' | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <div class="scrollable-body">
            <vwui-form-group [label]="'COMPONENT.update_notification_modal.form.title' | translate" a11yAutoFocus>
                <vwui-input formControlName="title"></vwui-input>
                <app-input-counter [control]="form.controls.title"></app-input-counter>
            </vwui-form-group>
            <vwui-form-group [label]="'COMPONENT.update_notification_modal.form.message' | translate">
                <app-rich-text-editor [controller]="form.controls.message"></app-rich-text-editor>
                <app-input-counter [control]="form.controls.message">
                    <ng-container slot="start">{{ 'COMPONENT.update_notification_modal.form.message_hint' | translate }}</ng-container>
                </app-input-counter>
            </vwui-form-group>
            <vwui-form-group [label]="'COMPONENT.update_notification_modal.form.publication_start' | translate">
                <vwui-datepicker
                    formControlName="publicationStart"
                    class="datepicker"
                    [ngClass]="{'disabled': form.disabled}"
                    [disabled]="form.disabled"
                    [placeholder]="'COMPONENT.update_notification_modal.form.publication_start_placeholder' | translate"
                    [flatpickrOptions]="{
                    enableTime: true,
                    dateFormat: 'Z',
                    altFormat: ('COMPONENT.update_notification_modal.form.publication_start_format' | translate),
                    altInput: true,
                    convertModelValue: true,
                    allowInput: false,
                    locale: 'nl'
                }"
                ></vwui-datepicker>
                <vwui-btn-icon *ngIf="form.controls.publicationStart.value" class="datepicker-clear-button" icon="vwui-close" (click)="clearPublicationStart($event)"></vwui-btn-icon>
            </vwui-form-group>
        </div>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="isUpdating" (click)="submit()">
            {{ 'BUTTON.save' | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
