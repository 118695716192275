<app-pdf-page>
    <app-pdf-page-header>{{ projectDetails?.name }}</app-pdf-page-header>
    <h1 class="pdf-page__project-title">{{ 'PAGE_FORECAST_END_RESULT.title' | translate }}</h1>

    <div class="grid-spacer grid-spacer--large"></div>

    <app-pdf-page-forecast-end-result-list-table [projectDetails]="projectDetails" [projectLibraryItems]="risksInPer" [tableType]="'risks'">
    </app-pdf-page-forecast-end-result-list-table>
    <app-pdf-page-forecast-end-result-list-table [projectDetails]="projectDetails" [projectLibraryItems]="opportunitiesInPer" [tableType]="'opportunities'" >
    </app-pdf-page-forecast-end-result-list-table>

    <app-pdf-page-footer><app-classification></app-classification></app-pdf-page-footer>
</app-pdf-page>
