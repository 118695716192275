import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {IAzureAdRegistration} from '../../models';

@Injectable()
export class AzureAdRegistrationService {
    private allAzureAdRegistrations: IAzureAdRegistration[] = undefined;

    constructor(
        private apiService: ApiService
    ) {
        this.getAzureAdRegistrations();
    }

    public async getAzureAdRegistrations(): Promise<IAzureAdRegistration[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/azure/registrations')
                .then((response: HttpResponse<IAzureAdRegistration[]>) => {
                    this.allAzureAdRegistrations = response.body;
                    resolve(this.allAzureAdRegistrations);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

}
