import {Component, OnInit} from '@angular/core';
import {CompanyTypeService, LoadingSpinnerService, QuickscanDefinitionService} from '../../services';
import {NgVwuiTableConfig, VwuiModalService} from '@recognizebv/vwui-angular';
import {PublishQuickScanVersionModalComponent} from '../../components';
import {QuickscanDefinition} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-page-quick-scans',
    templateUrl: './page-quickscans.component.html',
    styleUrls: ['./page-quickscans.component.scss']
})
export class PageQuickscansComponent implements OnInit {

    public allQuickscanDefinitions: QuickscanDefinition[];
    public tableConfig: Partial<NgVwuiTableConfig>;
    private companyTypeId: number;

    constructor(
        private quickscanDefinitionService: QuickscanDefinitionService,
        private companyTypeService: CompanyTypeService,
        private modalService: VwuiModalService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.companyTypeId = parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10);
        this.route.params.subscribe(params => {
            this.companyTypeId = parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10);
            this.setData();
            this.loadQuickscanDefinitions();
        });
    }

    private setData(): void {
        this.companyTypeService.setActiveCompanyTypeId(this.companyTypeId);
        this.loadQuickscanDefinitions();
        this.tableConfig = {
            title: this.translateService.instant('PAGE_QUICKSCAN_VERSIONS.table.title'),
            columns: [
                {itemKey: '', header: this.translateService.instant('PAGE_QUICKSCAN_VERSIONS.table.versionId')},
                {itemKey: '', header: this.translateService.instant('PAGE_QUICKSCAN_VERSIONS.table.status')},
                {itemKey: '', header: this.translateService.instant('PAGE_QUICKSCAN_VERSIONS.table.publishedAt')},
                {itemKey: '', header: ''}
            ]
        };
    }

    public openPublishModal(selectedElement: QuickscanDefinition): void {
        const modal = this.modalService.open(PublishQuickScanVersionModalComponent, {data: selectedElement});
        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.quickscanDefinitionService.publishQuickscanDefinition(this.companyTypeId, selectedElement.versionId).then(() => {
                    this.loadQuickscanDefinitions();
                });
            }
        });
    }

    public editQuickscan(quickscan: QuickscanDefinition): void {
        this.router.navigate(['/beheer', 'quickscan', this.companyTypeId, quickscan.versionId]);
    }

    public trackQuickscanDefinition(index: number, quickscanDefinition: QuickscanDefinition): string {
        return `${quickscanDefinition.id}`;
    }

    private loadQuickscanDefinitions() {
        this.loadingSpinnerService.show();
        this.quickscanDefinitionService.getQuickscanDefinitions(this.companyTypeId).then((quickscanDefinitions) => {

            quickscanDefinitions.sort((a, b) => b.versionId - a.versionId);
            this.allQuickscanDefinitions = quickscanDefinitions;
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }
}
