export enum SignalColors {
    GREEN = 'green',
    ORANGE = 'orange',
    RED = 'red',
}

export const ScoreThresholdsRisks: SignalColors[] = [
    SignalColors.GREEN,
    SignalColors.ORANGE,
    SignalColors.RED
];

export const ScoreThresholdsOpportunities: SignalColors[] = [
    SignalColors.RED,
    SignalColors.ORANGE,
    SignalColors.GREEN
];
