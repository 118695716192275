import {Component, OnInit} from '@angular/core';
import {LoadingSpinnerService, ToastService, TenderManagerService, UserService} from '../../services';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../models';
import {CreateUpdateTenderManagerModalComponent, DeleteItemModalComponent} from '../../components';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-page-tendermanagers',
    templateUrl: './page-tender-managers.component.html',
    styleUrls: ['./page-tender-managers.component.scss']
})
export class PageTenderManagersComponent implements OnInit {
    public tenderManagers: User[];

    constructor(
        private tenderManagerService: TenderManagerService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private userService: UserService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.loadTenderManagers();
    }

    public openUpdateTenderManagerModal(tenderManager: User): void {
        this.openCreateOrUpdateModal(tenderManager);
    }

    public openCreateTenderManagerModal(): void {
        this.openCreateOrUpdateModal();
    }

    public openDeleteTenderManagerModal(tenderManager: User): void {
        const modal = this.modalService.open(DeleteItemModalComponent,
            {
                data: {
                    title: `PAGE_TENDER_MANAGERS.delete_item.title`,
                    description: `PAGE_TENDER_MANAGERS.delete_item.description`
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.tenderManagerService.deleteItem(tenderManager).then(() => {
                    this.toastService.openSuccess(this.translateService.instant('PAGE_TENDER_MANAGERS.tender_manager_removed'));
                    this.loadTenderManagers();
                }).catch((error) => {
                    this.toastService.handleError(error, 'ERRORS.tender_manager_not_deleted');
                });
            }
        });
    }

    private loadTenderManagers() {
        this.loadingSpinnerService.show();
        this.tenderManagerService.getTenderManagers()
            .then((tenderManagers) => {
                this.tenderManagers = tenderManagers;

                // Reset the user info to make sure the roles are kept in sync
                this.userService.resetUserInfo();
            }).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.tender_managers_not_retrieved'));
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }

    private openCreateOrUpdateModal(tenderManager?: User): void {
        const modal = this.modalService.open(CreateUpdateTenderManagerModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'medium-modal',
                data: {
                    title: tenderManager ? 'PAGE_TENDER_MANAGERS.update_item.title' : `PAGE_TENDER_MANAGERS.create_item.title`,
                    saveText: tenderManager ? 'PAGE_TENDER_MANAGERS.update_item.save' : `PAGE_TENDER_MANAGERS.create_item.save`,
                    data: tenderManager
                }
            }
        );

        modal.afterClosed.subscribe((updatedItem: User) => {
            if (updatedItem) {
                if (tenderManager !== undefined) {
                    this.tenderManagerService.updateTenderManager(updatedItem).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_TENDER_MANAGERS.tender_manager_modified'));
                        this.loadTenderManagers();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.tender_manager_not_modified'));
                    });
                } else if (this.isTenderManagerUnique(updatedItem.uniqueName)) {
                    this.tenderManagerService.createTenderManager(updatedItem).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_TENDER_MANAGERS.tender_manager_added'));
                        this.loadTenderManagers();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.tender_manager_not_added'));
                    });
                } else {
                    this.toastService.openError(403, this.translateService.instant('ERRORS.tender_manager_already_exists'));
                }
            }
        });
    }

    private isTenderManagerUnique(name: string): boolean {
        const uniqueName = name.toLowerCase().trim();

        return this.tenderManagers && this.tenderManagers.findIndex((tenderManager) => tenderManager.uniqueName.toLowerCase().trim() === uniqueName) === -1;
    }

}
