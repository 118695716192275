<app-pdf-page>
    <app-pdf-page-header>{{ pdfDetails.projectDetails.name }}</app-pdf-page-header>

    <div class="spacing-mb spacing-mb--large">
        <h1 class="pdf-page__project-title">{{ 'PAGE_RISK_RAINBOW.title' | translate }}</h1>
        <div *ngIf="!clientOnly">
            {{ 'PAGE_RISK_RAINBOW.all_risks.explanation' | translate }}
        </div>
        <app-library-item-filters-selected
            [filters]="libraryItemFilters"
            [withinPdf]="true"
        ></app-library-item-filters-selected>
    </div>

    <div class="spacing-mb spacing-mb--large">
        <div class="label">{{ 'PAGE_RISK_RAINBOW.risks.header' | translate }}</div>
        <app-chart-signal-matrix [large]="true" [config]="risksVsValueMatrix"></app-chart-signal-matrix>
    </div>

    <div class="spacing-mb spacing-mb--large">
        <vwui-table [compactable]="false">
            <div slot="table-title">
                {{ 'PAGE_RISK_RAINBOW.risks.title' | translate }}
            </div>

            <ng-container *ngIf="filteredRisks.length > 0">
                <vwui-table-row>
                    <vwui-table-header
                        class="table-col">{{ 'PAGE_RISK_RAINBOW.risks.column.title' | translate }}</vwui-table-header>
                    <vwui-table-header *ngIf="!clientOnly"
                                       class="table-col table-col--risk-in-per">
                        {{ 'PAGE_RISK_RAINBOW.risks.column.in_per' | translate }}
                        <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.IN_PER, SortableTypes.RISK)"
                                   class="pdf-sortable-label"
                                   [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon>
                    </vwui-table-header>
                    <vwui-table-header
                        class="table-col table-col--risk-financial-impact">
                        {{ 'PAGE_RISK_RAINBOW.risks.column.best_guess' | translate }}
                        <vwui-icon
                            *ngIf="libraryItemSort.isColumnActive(SortableColumns.BEST_GUESS, SortableTypes.RISK)"
                            class="pdf-sortable-label"
                            [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon>
                    </vwui-table-header>
                    <vwui-table-header
                        class="table-col table-col--risk-score">
                        {{ 'PAGE_RISK_RAINBOW.risks.column.score' | translate }}
                        <vwui-icon *ngIf="libraryItemSort.isColumnActive(SortableColumns.SCORE, SortableTypes.RISK)"
                                   class="pdf-sortable-label"
                                   [iconName]="getCorrectSortOrderIcon(SortableTypes.RISK)"></vwui-icon>
                    </vwui-table-header>
                </vwui-table-row>

                <vwui-table-row *ngFor="let filteredRisk of filteredRisks">
                    <vwui-table-column
                        class="table-col">{{ filteredRisk.getSubject() + filteredRisk.getPostfixForItem(filteredRisks) }}</vwui-table-column>
                    <vwui-table-column *ngIf="!clientOnly"
                                       class="table-col table-col--risk-in-per">{{ getIncludedInPer(filteredRisk) }}</vwui-table-column>
                    <vwui-table-column
                        class="table-col table-col--risk-financial-impact">{{ getBestGuess(filteredRisk) }}</vwui-table-column>
                    <vwui-table-column class="table-col table-col--risk-score">
                        <app-score-signal-badge [thresholds]="scoreThresholdsRisks"
                                                [score]="filteredRisk.getScore() || '?'"></app-score-signal-badge>
                    </vwui-table-column>
                </vwui-table-row>
                <vwui-table-row class="vwui-table-row">
                    <vwui-table-column
                        class="table-col table-col--bold">{{ 'PAGE_RISK_RAINBOW.risks.totals' | translate }}</vwui-table-column>
                    <vwui-table-column *ngIf="!clientOnly"
                                       class="table-col table-col--risk-in-per">{{ getTotalsIncludedInPer(filteredRisks) | priceFormat }} </vwui-table-column>
                    <vwui-table-column
                        class="table-col table-col--risk-financial-impact">{{ getTotalsBestGuess(filteredRisks) | priceFormat }}</vwui-table-column>
                    <vwui-table-column class="table-col table-col--risk-score"></vwui-table-column>
                </vwui-table-row>

            </ng-container>

            <vwui-table-row *ngIf="filteredRisks.length === 0">
                <vwui-table-column>{{ 'PAGE_RISK_RAINBOW.no_risks.message_title' | translate }}</vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </div>

    <div class="prevent-pagebreak-inside" *ngIf="!clientOnly">
        <div class="spacing-mb spacing-mb--large">
            <div class="label">{{ 'PAGE_RISK_RAINBOW.opportunities.header' | translate }}</div>
            <app-chart-signal-matrix [large]="true" [signalReversed]="true"
                                     [config]="opportunitiesVsValueMatrix"></app-chart-signal-matrix>
        </div>

        <div class="spacing-mb spacing-mb--large">
            <vwui-table [compactable]="false">
                <div slot="table-title">
                    {{ 'PAGE_RISK_RAINBOW.opportunities.title' | translate }}
                </div>

                <ng-container *ngIf="filteredOpportunities.length > 0">
                    <vwui-table-row>
                        <vwui-table-header
                            class="table-col">{{ 'PAGE_RISK_RAINBOW.opportunities.column.title' | translate }}</vwui-table-header>
                        <vwui-table-header
                            class="table-col table-col--risk-in-per">{{ 'PAGE_RISK_RAINBOW.opportunities.column.in_per' | translate }}
                            <vwui-icon
                                *ngIf="libraryItemSort.isColumnActive(SortableColumns.IN_PER, SortableTypes.OPPORTUNITY)"
                                class="pdf-sortable-label"
                                [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                        </vwui-table-header>
                        <vwui-table-header
                            class="table-col table-col--risk-financial-impact">
                            {{ 'PAGE_RISK_RAINBOW.opportunities.column.best_guess' | translate }}
                            <vwui-icon
                                *ngIf="libraryItemSort.isColumnActive(SortableColumns.BEST_GUESS, SortableTypes.OPPORTUNITY)"
                                class="pdf-sortable-label"
                                [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                        </vwui-table-header>
                        <vwui-table-header
                            class="table-col table-col--risk-score">
                            {{ 'PAGE_RISK_RAINBOW.opportunities.column.score' | translate }}
                            <vwui-icon
                                *ngIf="libraryItemSort.isColumnActive(SortableColumns.SCORE, SortableTypes.OPPORTUNITY)"
                                class="pdf-sortable-label"
                                [iconName]="getCorrectSortOrderIcon(SortableTypes.OPPORTUNITY)"></vwui-icon>
                        </vwui-table-header>
                    </vwui-table-row>

                    <vwui-table-row *ngFor="let filteredOpportunity of filteredOpportunities">
                        <vwui-table-column
                            class="table-col">{{ filteredOpportunity.getSubject() + filteredOpportunity.getPostfixForItem(filteredOpportunities) }}</vwui-table-column>
                        <vwui-table-column
                            class="table-col table-col--risk-in-per">{{ getIncludedInPer(filteredOpportunity) }}</vwui-table-column>
                        <vwui-table-column
                            class="table-col table-col--risk-financial-impact">{{ getBestGuess(filteredOpportunity) }}</vwui-table-column>
                        <vwui-table-column class="table-col table-col--risk-score">
                            <app-score-signal-badge [thresholds]="scoreThresholdsOpportunities"
                                                    [score]="filteredOpportunity.getScore() || '?'"></app-score-signal-badge>
                        </vwui-table-column>
                    </vwui-table-row>
                    <vwui-table-row class="vwui-table-row">
                        <vwui-table-column
                            class="table-col table-col--bold">{{ 'PAGE_RISK_RAINBOW.opportunities.totals' | translate }}</vwui-table-column>
                        <vwui-table-column
                            class="table-col table-col--risk-in-per">{{ getTotalsIncludedInPer(filteredOpportunities)  | priceFormat}}</vwui-table-column>
                        <vwui-table-column
                            class="table-col table-col--risk-financial-impact">{{ getTotalsBestGuess(filteredOpportunities) | priceFormat }}</vwui-table-column>
                        <vwui-table-column class="table-col table-col--risk-score"></vwui-table-column>
                    </vwui-table-row>
                </ng-container>

                <vwui-table-row *ngIf="filteredOpportunities.length === 0">
                    <vwui-table-column>{{ 'PAGE_RISK_RAINBOW.no_opportunities.message_title' | translate }}</vwui-table-column>
                </vwui-table-row>
            </vwui-table>
        </div>
    </div>

    <div class="spacing-mb spacing-mb--large prevent-pagebreak-inside">
        <vwui-card>
            <vwui-card-header>{{ 'PAGE_RISK_RAINBOW.financial_overview.title' | translate }}</vwui-card-header>
            <vwui-card-body>
                <table class="table-info">
                    <tbody>
                    <tr>
                        <th>{{ 'PAGE_RISK_RAINBOW.financial_overview.total.costs_risks' | translate }}</th>
                        <td>
                            <vwui-badge class="badge badge--red">
                                {{ getTotalCostsRisks() ? (getTotalCostsRisks() | priceFormat) : '-' }}
                            </vwui-badge>
                        </td>
                    </tr>
                    <tr *ngIf="!clientOnly">
                        <th>{{ 'PAGE_RISK_RAINBOW.financial_overview.total.value_opportunities' | translate }}</th>
                        <td>
                            <vwui-badge class="badge badge--green">
                                {{ getTotalValueOpportunities() ? (getTotalValueOpportunities() | priceFormat) : '-' }}
                            </vwui-badge>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'PAGE_RISK_RAINBOW.financial_overview.total.costs_risk_mitigations' | translate }}</th>
                        <td>
                            <vwui-badge class="badge">
                                {{ getTotalCostsRiskMitigations() ? (getTotalCostsRiskMitigations() | priceFormat) : '-' }}
                            </vwui-badge>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'PAGE_RISK_RAINBOW.financial_overview.total.incidents' | translate }}</th>
                        <td>
                            <vwui-badge class="badge">
                                {{ getNrIncidents() }}
                            </vwui-badge>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </vwui-card-body>
        </vwui-card>
    </div>

    <div class="prevent-pagebreak-inside">
        <vwui-card>
            <vwui-card-header>{{ 'PAGE_RISK_RAINBOW.form.riskAssessmentDescription' | translate }}</vwui-card-header>
            <vwui-card-body>
                {{ pdfDetails.projectDetails.riskAssessmentDescription || '&nbsp;' }}
            </vwui-card-body>
        </vwui-card>
    </div>

    <app-pdf-page-footer>
        <app-classification></app-classification>
    </app-pdf-page-footer>
</app-pdf-page>
