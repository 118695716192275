<form [formGroup]="form" (submit)="submit()" class="create-update-modal" a11yFocusTrap a11yAutoFocus>
    <vwui-modal-header>
        <h2 a11yPageTitleLevel="2">{{ title | translate }}</h2>
    </vwui-modal-header>
    <vwui-modal-body>
        <vwui-form-group label="{{ 'COMPONENT.tendermanager.unique_name' | translate }}" inputId="uniqueName">
            <app-user-identity-input inputId="uniqueName" [control]="form.controls.user"></app-user-identity-input>
        </vwui-form-group>
        <vwui-form-group label="{{ 'COMPONENT.tendermanager.company_names.label' | translate }}">
            <ng-select [items]="availableCompanyNames"
                       [selectOnTab]="true"
                       multiple="true"
                       formControlName="selectedCompanyNames"
                       bindLabel="name"
                       bindValue="id"
                       labelForId="company"
                       required="true"
                       placeholder="{{ 'COMPONENT.tendermanager.company_names.placeholder' | translate }}"
            >
            </ng-select>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">
            {{ 'BUTTON.cancel' | translate }}
        </vwui-button>
        <vwui-button class="is-primary" [loading]="loading" (click)="submit()">
            {{ saveText | translate }}
        </vwui-button>
    </vwui-modal-footer>
</form>
