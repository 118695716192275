import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {UserService} from '../../services';
import {RouteUtil} from '../../utils';
import {MsAuthService} from '../../module/ms-auth';

@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: MsAuthService
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let canActivate = this.hasValidAccessToken();
        if (canActivate) {
            const navigationOptions = {
                skipLocationChange: true // Stay on the same url
            };

            await this.userService.getUserInfo().then(async () => {
                const routeRoles = RouteUtil.getRouteData<string[]>(route, 'userSpecificRoles', []);
                if (routeRoles.length > 0) {
                    const hasRole = await this.userService.userHasAnyRole(routeRoles);
                    if (!hasRole) {
                        // No permission to access the page
                        this.router.navigate(['no-permission'], navigationOptions);
                        canActivate = false;
                    }
                }
            }).catch((error) => {
                const errorCode = RouteUtil.getErrorCode(error);
                if (errorCode >= 400 && errorCode <= 499) {
                    // Not authorized to access the application
                    this.router.navigate(['no-access'], navigationOptions);
                } else {
                    // Backend error
                    this.router.navigate(['error'], navigationOptions);
                }
                canActivate = false;
            });
        }
        return canActivate;
    }

    private hasValidAccessToken() {
        return this.authService.getAccessToken() !== null;
    }

}
