import {IQuickscanDefinitionData} from './quickscandefinition-data';

export const QuickscanDefinitionDefault: IQuickscanDefinitionData = {
    id: undefined,
    versionId: undefined,
    createdAt: undefined,
    isPublished: false,
    publishedAt: undefined,
    redFlagTresholdCategory: 0,
    categories: []
};
