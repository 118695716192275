import {Component, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormUtil} from '../../utils';
import {AzureAdRegistrationService, CompanyTypeService, TrackingService} from '../../services';
import {CompanyType, ICompanyType, predefinedContractTypes} from '../../models/user/user';
import {quantificationCategories} from 'src/enums/effect-category/effect-category';
import {TranslateService} from '@ngx-translate/core';
import {IAzureAdRegistration} from '../../models';
import {AccessibleStepsService} from '../../services/accessible-steps/accessible-steps.service';
import {ProjectSteps} from '../../enums';

@Component({
    selector: 'app-create-company-modal',
    templateUrl: './create-update-company-type-modal.component.html',
    styleUrls: ['./create-update-company-type-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateUpdateCompanyTypeModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: ICompanyType;
    public availableBaseCompanyTypeNames: string[];
    public selectedBaseCompanyTypeName: string;
    public quantificationCategoriesRisks: {key: string; label: string}[] = [];
    public quantificationCategoriesOpportunities: {key: string; label: string}[] = [];
    public quantificationCategoriesTasks: {key: string; label: string}[] = [];
    public predefinedContractTypes: {key: string; label: string}[] = [];

    private companyTypes: CompanyType[];
    public azureAdRegistrations: IAzureAdRegistration[];
    public showBaseCompanyTypeInfo = false;

    get showTasks(): boolean {
        return this.accessibleStepsService.isStepAccessible(ProjectSteps.TASKS);
    }

    constructor(
        public modalRef: VwuiModalRef,
        public companyTypeService: CompanyTypeService,
        public modalConfig: VwuiModalConfig<{ title: string; data?: ICompanyType; saveText: string }>,
        private trackingService: TrackingService,
        private translateService: TranslateService,
        private azureAdRegistrationService: AzureAdRegistrationService,
        private accessibleStepsService: AccessibleStepsService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;
        this.quantificationCategoriesRisks = quantificationCategories.map((item) => ({key: item.key, label: this.translateService.instant(`COMPONENT.library_item_quantification.${item.key}.label`)}));
        this.quantificationCategoriesOpportunities = quantificationCategories.map((item) => ({key: item.key, label: this.translateService.instant(`COMPONENT.library_item_quantification.${item.key}.label`)}));
        this.quantificationCategoriesTasks = quantificationCategories.map((item) => ({key: item.key, label: this.translateService.instant(`COMPONENT.library_item_quantification.${item.key}.label`)}));
        this.predefinedContractTypes = predefinedContractTypes.map((item) => ({
                key: item.key,
                label: this.translateService.instant(`COMPONENT.company_type.predefined_contracttype.${item.key}`)
            }));

        const nameValue = this.data ? this.data.name : '';
        if (nameValue === '') {
            this.showBaseCompanyTypeInfo = true;
        }
        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required),
            contractTypes: new FormControl(this.data ? this.transformPredefinedContractTypesToLabels(this.data.contractTypes) : []),
            constructionCostsLabel: new FormControl(this.data ? this.data.constructionCostsLabel : ''),
            tisLabelShort: new FormControl(this.data ? this.data.tisLabelShort : '', Validators.required),
            tisLabelLong: new FormControl(this.data ? this.data.tisLabelLong : '', Validators.required),
            azureAdRegistration: new FormControl(this.data ? this.data.azureAdRegistration : null),
            riskManagers: new FormControl(this.data ? this.data.riskManagers : [], [ Validators.required ]),
            selectedBaseCompanyType: new FormControl(this.selectedBaseCompanyTypeName),
            additionaLCollaborations: new FormControl(this.data ? this.data.additionalCollaborations : [], Validators.required),
            quantificationCategoriesRisks: new FormControl(this.data ? this.data.quantificationCategoriesRisks : [], Validators.required),
            quantificationCategoriesOpportunities: new FormControl(this.data ? this.data.quantificationCategoriesOpportunities : [], Validators.required),
            quantificationCategoriesTasks: new FormControl(this.data ? this.data.quantificationCategoriesTasks : []),
            companyMandatingInfo: new FormControl(this.data ? this.data.companyMandatingInfo : '')
        });
        this.companyTypeService.getCompanyTypes().then((companyTypes) => {
            this.companyTypes = companyTypes;
            this.availableBaseCompanyTypeNames = this.companyTypes.map((item) => item.name);
        });
        this.azureAdRegistrationService.getAzureAdRegistrations().then((data) => {
            this.azureAdRegistrations = data;

        });
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues = {...this.data};
                updatedValues.name = this.form.value.name;
                updatedValues.contractTypes = this.transformPredefinedContractTypesToKeys(this.getNgSelectPlainList(this.form.value.contractTypes));
                updatedValues.constructionCostsLabel = this.form.value.constructionCostsLabel;
                updatedValues.tisLabelShort = this.form.value.tisLabelShort;
                updatedValues.tisLabelLong = this.form.value.tisLabelLong;
                updatedValues.constructionCostsLabel = this.form.value.constructionCostsLabel;
                updatedValues.azureAdRegistration = this.form.value.azureAdRegistration;
                updatedValues.riskManagers = this.form.value.riskManagers;
                updatedValues.additionalCollaborations = this.getNgSelectPlainList(this.form.value.additionaLCollaborations);
                updatedValues.quantificationCategoriesRisks = this.getNgSelectPlainList(this.form.value.quantificationCategoriesRisks);
                updatedValues.quantificationCategoriesOpportunities = this.getNgSelectPlainList(this.form.value.quantificationCategoriesOpportunities);
                updatedValues.quantificationCategoriesTasks = this.getNgSelectPlainList(this.form.value.quantificationCategoriesTasks);
                updatedValues.companyMandatingInfo = this.form.value.companyMandatingInfo;

                const companyTypeId = this.form.value.selectedBaseCompanyType ?
                    this.companyTypes.find(companyType => companyType.name.localeCompare(this.form.value.selectedBaseCompanyType) === 0).id
                    : undefined;

                this.modalRef.close({companyType: updatedValues, selectedBaseCompanyTypeId: companyTypeId});
            }
        } catch (e) {
            console.error('Updating item failed: ', e);
            this.trackingService.exception(e);
        }
    }

    private getNgSelectPlainList(value: (string | { label: string })[]) {
        return value.map((item) => {
            // TODO: get rid of string to label conversions
            if (typeof item === 'string') {
                return item;
            } else {
                return item.label;
            }
        });
    }

    private transformPredefinedContractTypesToLabels(contractTypes: string[]) {
        return this.transformPredefinedContractType(contractTypes, 'key', 'label');
    }

    private transformPredefinedContractTypesToKeys(contractTypes: string[]) {
        return this.transformPredefinedContractType(contractTypes, 'label', 'key');
    }

    private transformPredefinedContractType(contractTypes: string[], from: string, to: string) {
        return contractTypes.map((item) => {
            const predefinedType = this.predefinedContractTypes.find((predefinedItem) => predefinedItem[from] === item);
            if (predefinedType) {
                return predefinedType[to];
            } else {
                return item;
            }
        });
    }

    private emailArrayValidator(control: FormControl): {[s: string]: boolean} {
        const emailsAllValid = control.value.every((value: { label: string }) => this.validateEmail(value));
        if (emailsAllValid) {
            return null;
        }
        return {emailIsInvalid: true};
    }

    validateEmail(emailInput: string|{label: string}): boolean {
        let email: string;
        if (typeof emailInput === 'string') {
            email = emailInput as string;
        } else {
            email = (emailInput as {label: string}).label;
        }

        return FormUtil.isValidEmailAddress(String(email).toLowerCase());
    }
}
