<vwui-spinner class="vwui-spinner--with-margin" *ngIf="loadingSpinnerService.isSpinnerVisible()"></vwui-spinner>

<div class="main-wrapper" *ngIf="!quickscanSubmitted()">
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotFilledIn.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.quickscan_not_filled' | translate }}</app-explanation>
    <app-explanation [link]="getUriForStep(2)" *ngIf="!mandateDetails?.preconditions?.quickscanNotSubmitted.satisfiedOrOptional">{{ 'COMPONENT.mandating.cannot_start.project_not_classified' | translate }}</app-explanation>
</div>

<div *ngIf="!loadingSpinnerService.isSpinnerVisible() && quickscanSubmitted()">
    <app-scroll-navigation [navItems]="navItems"></app-scroll-navigation>
    <div class="tis-quickscan">

        <app-quickscan-list
            [projectDetails]="projectDetails"
            [quickscanDefinition]="quickscanDefinition"
            [quickscanAnswers]="projectDetails.tisQuickscanAnswers"
            [categoryInfo]="projectDetails.categoryInfo"
            [disabled]="!canEditTisQuickscan()"
            (projectDetailsChanged)="projectDetailsChanged.emit($event)"
            [tis]="true"
        ></app-quickscan-list>

        <app-project-quickscan-result
            class="align-left"
            [projectDetails]="projectDetails"
            (projectDetailsChanged)="projectDetailsChanged.emit($event)"
            [disabled]="!canEditTisQuickscan()"
            [tis]="true"
        ></app-project-quickscan-result>

    </div>
</div>
