<ng-container *ngFor="let quickscanCategory of quickscanDefinition.categories; let quickscanCategoryIndex = index; trackBy: trackQuickscanCategory">
    <div class="prevent-pagebreak-inside">
        <vwui-table [compactable]="false">
            <div slot="table-title">{{ quickscanCategoryIndex + 1 }}. {{ quickscanCategory.name }}</div>

            <vwui-table-row>
                <vwui-table-header class="table-col table-col--nr">{{ 'PAGE_FILL_QUICKSCAN.table.column.nr' | translate }}</vwui-table-header>
                <vwui-table-header class="table-col table-col--question">{{ 'PAGE_FILL_QUICKSCAN.table.column.question' | translate }}</vwui-table-header>
                <vwui-table-header class="table-col table-col--answer-and-remark">{{ 'PAGE_FILL_QUICKSCAN.table.column.answer' | translate }}</vwui-table-header>
                <vwui-table-header class="table-col table-col--score">{{ 'PAGE_FILL_QUICKSCAN.table.column.score' | translate }}</vwui-table-header>
            </vwui-table-row>

            <vwui-table-row *ngFor="let quickscanQuestion of quickscanCategory | categoryGetAllSubCategoryQuestions | async; let quickscanQuestionIndex = index; trackBy: trackQuickscanCategoryQuestion">
                <vwui-table-column class="table-col table-col--nr">{{ quickscanCategoryIndex + 1 }}.{{ quickscanQuestionIndex + 1 }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--question">
                    <div class="pdf-page__project-subtitle">
                        {{ quickscanQuestion.getCategoryName() }}
                        <vwui-icon *ngIf="hasRedFlagForQuestion(quickscanQuestion)" class="red-flag" iconName="flag"></vwui-icon>
                        <vwui-icon *ngIf="canHaveRedFlagForQuestion(quickscanQuestion) && !hasRedFlagForQuestion(quickscanQuestion)" class="flag-outline" iconName="flag-outline"></vwui-icon>
                    </div>
                    <div>{{ quickscanQuestion.name }}</div>
                    <small *ngIf="quickscanQuestion.info" class="pdf-page__project-note"><vwui-icon iconName="info"></vwui-icon>{{ quickscanQuestion.info }}</small>
                    <small *ngIf="hasQuickscanQuestionRemark(quickscanQuestion)" class="pdf-page__project-note"><vwui-icon iconName="chat"></vwui-icon>{{ getQuickscanQuestionRemark(quickscanQuestion) }}</small>
                </vwui-table-column>
                <vwui-table-column class="table-col table-col--answer-and-remark">{{ getQuickscanQuestionAnswer(quickscanQuestion) }}</vwui-table-column>
                <vwui-table-column class="table-col table-col--score">{{ getWeightedScoreForQuestion(quickscanQuestion, quickscanCategory) }}</vwui-table-column>
            </vwui-table-row>

            <vwui-table-row>
                <vwui-table-column class="table-col table-col--nr"></vwui-table-column>
                <vwui-table-column class="table-col table-col--question">
                    <small *ngIf="hasQuickscanCategoryRemark(quickscanCategory)" class="pdf-page__project-note pdf-page__project-note--bottom"><vwui-icon iconName="chat"></vwui-icon>{{ getQuickscanCategoryRemark(quickscanCategory) }}</small>
                </vwui-table-column>
                <vwui-table-column class="table-col table-col--answer-and-remark">
                    <ng-container *ngIf="quickscanCategory.type === 'risk'">
                        <vwui-badge class="badge" [ngClass]="{'badge--red': getIndicatorForCategory(quickscanCategory) === 'red', 'badge--green': getIndicatorForCategory(quickscanCategory) === 'green'}"> {{ 'CLASSIFICATION.' + getIndicatorForCategory(quickscanCategory) | translate }} </vwui-badge>
                        <vwui-badge class="badge">{{ getRedFlagsForCategory(quickscanCategory) }}</vwui-badge>
                    </ng-container>
                </vwui-table-column>
                <vwui-table-column class="table-col table-col--score">{{ getWeightedScoreForCategory(quickscanCategory) }}</vwui-table-column>
            </vwui-table-row>

        </vwui-table>
        <div class="grid-spacer grid-spacer--large"></div>
    </div>
</ng-container>
