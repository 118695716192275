import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
    selector: 'vwui-table:not([breakpoint])'
})
export class VwuiTableDefaultBreakpointDirective implements OnInit {

    constructor(
        private elementRef: ElementRef<HTMLVwuiTableElement>
    ) {
    }

    ngOnInit() {
        this.elementRef.nativeElement.setAttribute('breakpoint', 'xs');
    }

}
