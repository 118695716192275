export class PageUtil {

    public static getDocumentElement(): HTMLElement {
        return document.documentElement;
    }

    public static scrollToTop(): void {
        PageUtil.getDocumentElement().scrollLeft = 0;
        PageUtil.getDocumentElement().scrollTop = 0;
    }

    public static addCssClasses(classes: string[]): void {
        PageUtil.getDocumentElement().classList.add(...classes);
    }

    public static removeCssClasses(classes: string[]): void {
        PageUtil.getDocumentElement().classList.remove(...classes);
    }

}
