import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IDepartment} from '../../models/department/department';
import {HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {
    constructor(private apiService: ApiService) {
    }

    public async getDepartments(projectId: number): Promise<IDepartment[]> {
        return (await this.apiService.get<HttpResponse<IDepartment[]>>(`/project/${projectId}/department`)).body;
    }
}
