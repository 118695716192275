export class SearchUtil {

    public static hasMatch(term: string, search: string): boolean {
        return SearchUtil.normalize(search).includes(SearchUtil.normalize(term));
    }

    public static normalize(searchString: string): string {
        return searchString
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s+/g, ' ')
            .trim();
    }

}
