import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yAutoFocus]'
})
export class AutoFocusDirective implements OnInit, OnDestroy {

    private previouslyFocussedHtmlElement;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.previouslyFocussedHtmlElement = document.activeElement;
        setTimeout(() => {
            const focusableElements = FocusUtil.findCoarseFocusableElements(this.elementRef.nativeElement);
            if (focusableElements) {

                // Make sure to remove elements that have the tabindex set to -1 for regular interactive elements
                // Like a tags that we do not want to be focusable
                const firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

                if (firstFocusableElement) {
                    (firstFocusableElement as HTMLElement).focus();
                }
            }

        // TODO AC-109 - IMPROVE THIS SO THAT FOCUS IS GAINED AFTER PROPER INITIALISATION?
        }, 500);
    }

    ngOnDestroy(): void {
        // Refocus on previous focus element if it still exists to make sure the user does not lose previous focus state
        if (this.previouslyFocussedHtmlElement) {
            this.previouslyFocussedHtmlElement.focus();
        }
    }

}
