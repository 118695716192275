import {Directive, ElementRef, HostListener} from '@angular/core';
import {AccessibilityService, HotkeyType} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yFocusBlock]'
})
export class FocusBlockDirective {
    // Gives this block the ability to go to the start and the end of the focus block using HOME and END ( 2.4.1 )
    constructor(
        private elementRef: ElementRef,
        private accessibilityService: AccessibilityService
    ) {
    }

    protected onHomeOrEnd($event) {
        const isHomePressed = this.accessibilityService.checkHotkey($event, HotkeyType.FOCUS_FIRST);
        const isEndPressed = this.accessibilityService.checkHotkey($event, HotkeyType.FOCUS_LAST);
        if (!isHomePressed && !isEndPressed) {
            return;
        } else {
            const focusableElements = FocusUtil.findCoarseFocusableElements(this.elementRef.nativeElement);
            if (isHomePressed) {
                const firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

                if (firstFocusableElement) {
                    (firstFocusableElement as HTMLElement).focus();
                    $event.preventDefault();
                }
            } else if (isEndPressed) {
                const lastFocusableElement = Array.from(focusableElements).reverse().find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

                if (lastFocusableElement) {
                    (lastFocusableElement as HTMLElement).focus();
                    $event.preventDefault();
                }
            }
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown($event){
        this.onHomeOrEnd($event);
    }
}
