import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IQuestionData, IQuestionType, TisDefinition} from '../../models';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';
import ITisPossibleAnswerData from '../../models/definition/tis-possible-answer-data';
import {Question} from '../../models/definition/question';
import {TisCalculationOperator} from '../../models/definition/tis-calculation-operator';
import {ITisCalculation} from '../../models/definition/tis-calculation';

@Component({
    selector: 'app-quickscan-create-update-question-modal',
    templateUrl: './tis-create-update-question-modal.component.html',
    styleUrls: ['./tis-create-update-question-modal.component.scss']
})
export class TisCreateUpdateQuestionModalComponent {
    public readonly minimumMultipleChoiceAnswers = 2;

    public readonly maxTextAnswerLength = 16_384;
    public readonly defaultTextAnswerLength = 1_024;
    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: IQuestionData;
    public questionTypes: { label: string; value: string }[];

    private tisDefinition: TisDefinition;

    get possibleAnswersFormArray(): FormArray {
        return this.form.controls.possibleTisAnswers as FormArray;
    }

    get isMultipleChoice(): boolean {
        return this.form.value.type === IQuestionType.MULTIPLECHOICE;
    }

    get isCalculation(): boolean {
        return this.form.value.type === IQuestionType.CALCULATION;
    }

    get isText(): boolean {
        return this.form.value.type === IQuestionType.TEXT;
    }

    get possibleDependentQuestions(): Question[] {
        return this.tisDefinition
            .questions
            .filter(q => q.tempId !== this.form.value.tempId)
            .filter(({type}) =>
                type === IQuestionType.MULTIPLECHOICE ||
                type === IQuestionType.BOOL);
    }

    get integerQuestions(): Question[] {
        return this.tisDefinition
            .questions
            .filter(q => q.type === IQuestionType.INTEGER);
    }

    get showDependentValueSelect(): boolean {
        return this.form.value.dependentQuestion.tempId != null;
    }

    get dependentQuestion(): Question | null {
        const tempId = this.form.value.dependentQuestion.tempId as string | null;
        return this.tisDefinition
            .questions
            .find(q => q.tempId === tempId) ?? null;
    }

    get dependentQuestionOptions(): ITisPossibleAnswerData[] {
        return this.dependentQuestion?.possibleTisAnswers ?? [];
    }

    get dependentQuestionControl(): FormGroup {
        return this.form.controls.dependentQuestion as FormGroup;
    }

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; data?: IQuestionData; saveText: string; tisDefinition: TisDefinition }>,
        private translateService: TranslateService,
        private trackingService: TrackingService,
        private formBuilder: FormBuilder
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;
        this.tisDefinition = modalConfig.data.tisDefinition;
        const nameValue = this.data ? this.data.name : '';
        const supportedQuestionTypes = [
            IQuestionType.TEXT,
            IQuestionType.DATE,
            IQuestionType.INTEGER,
            IQuestionType.BOOL,
            IQuestionType.PERCENTAGE,
            IQuestionType.MULTIPLECHOICE,
            IQuestionType.DEPARTMENT,
            IQuestionType.CALCULATION
        ];

        const questionTypeValue = this.data && this.data.type ? this.data.type : IQuestionType.TEXT;
        const allowAttachmentsValue = this.data && (this.data.allowAttachments ?? false);
        const isRequiredValue = this.data && (this.data.isRequired ?? true);
        const tempIdValue = this.data.tempId;
        let textAnswerLengthValue = this.data?.textAnswerLength;
        const calculationValue = this.data?.calculation;
        const dependentQuestionValue = this.data.dependentQuestion;

        if (questionTypeValue === IQuestionType.TEXT && textAnswerLengthValue == null) {
            textAnswerLengthValue = this.defaultTextAnswerLength;
        }

        this.questionTypes = supportedQuestionTypes.map((type) => ({
            label: this.translateService.instant('COMPONENT.tis.modal.create_question.type.' + type),
            value: type
        }));

        this.form = this.formBuilder.group({
            tempId: [tempIdValue, Validators.required],
            name: [nameValue, Validators.required],
            type: [questionTypeValue, Validators.required],
            allowAttachments: allowAttachmentsValue,
            isOptional: !isRequiredValue,
            textAnswerLength: [textAnswerLengthValue],
            dependentQuestion: this.formBuilder.group({
                tempId: [dependentQuestionValue?.tempId],
                answerTempId: [dependentQuestionValue?.answerTempId, Validators.required],
                boolValue: [dependentQuestionValue?.boolValue, Validators.required]
            }),
            // Only for multiple choice answers
            possibleTisAnswers: this.formBuilder.array([])
        });

        this.toggleCalculationControl(questionTypeValue, calculationValue);
        this.updateTextAnswerLengthValidators(questionTypeValue);
        this.toggleDependentQuestionControls(dependentQuestionValue?.tempId ?? null);

        for (const answer of this.data?.possibleTisAnswers ?? []) {
            this.addAnswer(answer);
        }

        this.form.controls.type.valueChanges.subscribe((newType: IQuestionType) => {
            if (newType === IQuestionType.MULTIPLECHOICE) {
                for (let i = 0; i < this.minimumMultipleChoiceAnswers; i++) {
                    this.addAnswer();
                }
            } else {
                this.possibleAnswersFormArray.clear();
            }

            this.toggleCalculationControl(newType);
            this.updateTextAnswerLengthValidators(newType);
        });

        this.dependentQuestionControl.controls.tempId.valueChanges.subscribe(tempId => {
            this.dependentQuestionControl.patchValue({boolValue: null, answerTempId: null});
            this.toggleDependentQuestionControls(tempId);
        });
    }

    private toggleDependentQuestionControls(dependentQuestionTempId: string | null): void {
        const question = this.tisDefinition.questions.find(q => q.tempId === dependentQuestionTempId);
        if (question == null) {
            this.dependentQuestionControl.controls.answerTempId.disable();
            this.dependentQuestionControl.controls.boolValue.disable();
        } else if (question.type === IQuestionType.BOOL) {
            this.dependentQuestionControl.controls.answerTempId.disable();
            this.dependentQuestionControl.controls.boolValue.enable();
        } else if (question.type === IQuestionType.MULTIPLECHOICE) {
            this.dependentQuestionControl.controls.answerTempId.enable();
            this.dependentQuestionControl.controls.boolValue.disable();
        }
    }

    private toggleCalculationControl(type: IQuestionType, initialValue: ITisCalculation | null = null): void {
        const optionalControl = this.form.controls.isOptional;
        if (type === IQuestionType.CALCULATION) {
            this.form.addControl('calculation', this.formBuilder.group({
                // Currently we only support subtracting
                operator: [initialValue?.operator ?? TisCalculationOperator.SUBTRACT, Validators.required],
                leftQuestionTempId: [initialValue?.leftQuestionTempId ?? null, Validators.required],
                rightQuestionTempId: [initialValue?.rightQuestionTempId ?? null, Validators.required]
            }));
            optionalControl.patchValue(false);
            optionalControl.disable();
        } else {
            this.form.removeControl('calculation');
            optionalControl.enable();
        }
    }

    private updateTextAnswerLengthValidators(newType: IQuestionType): void {
        if (newType === IQuestionType.TEXT) {
            this.form.controls.textAnswerLength.addValidators([Validators.required, Validators.min(1), Validators.max(this.maxTextAnswerLength)]);
        } else {
            this.form.controls.textAnswerLength.clearValidators();
        }
    }

    public submit(): void {
        try {
            FormUtil.validate(this.form);

            // User fills in optionality, but we persist requiredness in the database. So we flip it here.
            const {isOptional, ...formValue} = this.form.value;
            if (this.form.valid) {
                this.modalRef.close({...this.data, ...formValue, isRequired: !isOptional});
            }
        } catch (e) {
            console.error('Creating question failed: ', e);
            this.trackingService.exception(e);
        }
    }

    public addAnswer(answer: ITisPossibleAnswerData | null = null): void {
        this.possibleAnswersFormArray.push(this.formBuilder.group({
            tempId: [answer?.tempId ?? crypto.randomUUID(), Validators.required],
            id: [answer?.id],
            answer: [answer?.answer ?? '', Validators.required]
        }));
    }

    public removeAnswer(index: number): void {
        this.possibleAnswersFormArray.removeAt(index);
    }

    protected readonly IQuestionType = IQuestionType;
}
