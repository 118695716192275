import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IBlockSignalConfig} from './block-signal.data';

@Component({
    selector: 'app-block-signal',
    templateUrl: './block-signal.component.html',
    styleUrls: ['./block-signal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BlockSignalComponent {

    @Input() config: IBlockSignalConfig;

    constructor() {
    }

}
