import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services';
import {INotificationData, Notification} from '../../models';
import {Observable} from 'rxjs';
import {UpdateNotificationModalComponent} from '../../components';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-page-notifications',
    templateUrl: './page-notifications.component.html',
    styleUrls: ['./page-notifications.component.scss']
})
export class PageNotificationsComponent implements OnInit {

    public notifications$: Observable<Notification[]>;

    constructor(
        private notificationService: NotificationService,
        private modalService: VwuiModalService
    ) {
    }

    ngOnInit(): void {
        const showUnpublishedVersions = true;
        this.notifications$ = this.notificationService.getNotifications(showUnpublishedVersions);
    }

    public openUpdateNotificationModal(notification: Notification): void {
        const modal = this.modalService.open(UpdateNotificationModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'medium-modal',
                data: notification.getData()
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe(() => {
                const showUnpublishedVersions = true;
                this.notificationService.updateNotifications(showUnpublishedVersions);
            });
    }

}
