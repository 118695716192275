import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PdfService, ToastService} from '../../services';
import {PdfDetails} from '../../models/pdf/pdf-details';
import {TranslateService} from '@ngx-translate/core';
import {LibraryItemFilters} from '../../models';

@Component({
    selector: 'app-pdf-page-risks-and-opportunities',
    templateUrl: './pdf-page-risks-and-opportunities.component.html',
    styleUrls: ['./pdf-page-risks-and-opportunities.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageRisksAndOpportunitiesComponent {

    public uuid: string;

    @Input()
    public pdfDetails: PdfDetails;
    @Input()
    public clientOnly = false;
    @Input()
    public options: string[];

    constructor(
        private translateService: TranslateService,
        private toastService: ToastService
    ) {
        // When we are on PDF pages, we do not want error tooltips in our PDF
        this.toastService.disableToasts();
    }

    public getFilters(): LibraryItemFilters {
        return new LibraryItemFilters(
            this.options.includes(PdfService.FILTERED_ONLY) ?
                this.pdfDetails.projectDetails.getDefaultLibraryItemProjectFilters(this.translateService) :
                this.pdfDetails.projectDetails.getDefaultLibraryItemFilters(this.translateService)
        );
    }
}
