import {PossibleAnswer} from './possibleanswer';
import {IQuestionData} from './question-data';
import {QuestionDefault} from './question-default';
import {ObjectUtil} from '../../utils';
import {Category} from './category';
import {IQuestionType} from './question-type';
import {TisPossibleAnswer} from './tis-possible-answer';
import {ITisCalculation} from './tis-calculation';
import {IDependentQuestion} from './dependent-question';
import {TisDefinition} from '../tisdefinition/tisdefinition';
import {QuickscanDefinition} from '../quickscandefinition/quickscandefinition';

export class Question {

    public id: number;
    public tempId: string;
    public name: string;
    public info: string;
    public type: IQuestionType;
    public allowAttachments: boolean;
    public isRequired: boolean;
    public textAnswerLength: number | null = null;
    public possibleAnswers: PossibleAnswer[];
    public possibleTisAnswers: TisPossibleAnswer[] = [];
    public calculation: ITisCalculation | null = null;
    public dependentQuestion: IDependentQuestion | null = null;

    public get isOptionalOrCalculated(): boolean {
        return !this.isRequired || this.type === IQuestionType.CALCULATION;
    }

    public get calculatesQuestions(): Question[] {
        return this.parent.questions
            .filter(q => q.calculation != null)
            .filter(({calculation}) =>
                calculation.leftQuestionTempId === this.tempId ||
                calculation.rightQuestionTempId === this.tempId);
    }

    public get definitionQuestions(): Question[] {
        const definition = this.parent.getDefinition();
        if (definition instanceof TisDefinition || definition instanceof QuickscanDefinition) {
            return definition.questions;
        } else {
            throw new Error('Unexpected definition type');
        }
    }

    constructor(
        config: IQuestionData,
        private parent: Category
    ) {
        this.setData(config, false);
    }

    public setData(data: IQuestionData, broadcast: boolean = true): void {
        const settings = ObjectUtil.mergeObjects<IQuestionData>([QuestionDefault, data]);

        const wasBooleanType = this.type === IQuestionType.BOOL && settings.type !== IQuestionType.BOOL;

        this.id = settings.id;
        this.type = settings.type;
        this.tempId = settings.tempId;
        this.name = settings.name;
        this.info = settings.info;
        this.allowAttachments = settings.allowAttachments;
        this.isRequired = settings.isRequired;
        this.textAnswerLength = settings.textAnswerLength;
        this.possibleAnswers = settings.possibleAnswers.map((possibleAnswer) => new PossibleAnswer(possibleAnswer));
        this.calculation = settings.calculation ?? null;
        this.dependentQuestion = settings.dependentQuestion;

        // Cleanup dependencies on boolean answers of this question, because the type is no longer boolean
        if (wasBooleanType) {
            this.definitionQuestions
                .filter(q => q.dependentQuestion?.tempId === this.tempId)
                .forEach(q => q.dependentQuestion = null);
        }

        if (settings.possibleTisAnswers != null) {
            const removedTempIds = this.possibleTisAnswers
                .map(answer => answer.tempId)
                .filter(tempId => !settings.possibleTisAnswers.some(answer => answer.tempId === tempId));

            // Cleanup dependencies on multiple choice answers of this question,
            // because the answer has been removed or the type is no longer multiple choice
            this.definitionQuestions
                .filter(q => removedTempIds.includes(q.dependentQuestion?.answerTempId))
                .forEach(q => q.dependentQuestion = null);
            this.possibleTisAnswers = settings.possibleTisAnswers.map((possibleAnswer) => new TisPossibleAnswer(possibleAnswer));
        }

        if (settings.categoryName && settings.categoryName !== this.getCategoryName()) {
            this.parent.changeCategory(this, settings.categoryName);
        }

        if (broadcast) {
            this.broadcastChanges();
        }
    }

    public broadcastChanges(): void {
        this.parent.broadcastChanges();
    }

    public getCategoryName(): string {
        return this.parent.getCategoryName();
    }

    public getParent(): Category {
        return this.parent;
    }

    public delete(): void {
        this.parent.deleteQuestion(this);
        this.deleteDependentQuestions();
    }

    private deleteDependentQuestions(): void {
        this.calculatesQuestions.forEach(q => q.delete());
        this.broadcastChanges();
    }

    public cloneData(): IQuestionData {
        return ObjectUtil.cloneObject(this);
    }

}
