import {Injectable} from '@angular/core';
import {INotificationData, Notification} from '../../models';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {BehaviorSubject, defer, Observable} from 'rxjs';

@Injectable()
export class NotificationService {

    private notifications$ = new BehaviorSubject<Notification[]>([]);

    constructor(
        private apiService: ApiService
    ) {
    }

    public getNotifications(latestVersions = false): Observable<Notification[]> {
        return defer(() => {
            this.updateNotifications(latestVersions);

            return this.notifications$.asObservable();
        });
    }

    public updateNotifications(latestVersions = false): void {
        this.getRemoteNotifications(latestVersions)
            .then((notifications) => {
                this.notifications$.next(notifications);
            });
    }

    public getRemoteNotifications(latestVersions = false): Promise<Notification[]> {
        return this.apiService.get(`/notification/all${latestVersions ? '?latestVersions=true' : ''}`)
            .then((response: HttpResponse<INotificationData[]>) => response.body.map((data) => new Notification(data)))
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    public dismissNotification(notification: Notification): Promise<Notification[]> {
        return this.apiService.put(`/notification/${notification.id}/dismiss`, {})
            .then((response: HttpResponse<INotificationData[]>) => response.body.map((data) => new Notification(data)))
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    public saveNotification(notificationData: INotificationData): Promise<unknown> {
        return this.apiService.put(`/notification/${notificationData.id}`, notificationData)
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

}
