<vwui-modal-header>
    {{"PAGE_QUICKSCAN_VERSIONS.modal.title" | translate}}
</vwui-modal-header>
<vwui-modal-body>
    {{"PAGE_QUICKSCAN_VERSIONS.modal.question" | translate}}
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-secondary" [loading]="loading" (click)="submit()">
        {{"PAGE_QUICKSCAN_VERSIONS.modal.publish" | translate}}
    </vwui-button>
</vwui-modal-footer>

