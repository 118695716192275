import {Component, Input} from '@angular/core';
import {Library, LibraryCategory, LibraryEntry} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {LibraryService, ToastService} from '../../services';
import {CreateUpdateLibraryCategoryModalComponent} from './create-update-library-category-modal/create-update-library-category-modal.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {CreateUpdateLibraryItemModalComponent} from './create-update-library-item-modal/create-update-library-item-modal.component';
import {DeleteItemModalComponent} from './delete-library-item-modal/delete-item-modal.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-library-mgmt',
    templateUrl: './library-mgmt.component.html',
    styleUrls: ['./library-mgmt.component.scss']
})
export class LibraryMgmtComponent {

    private libraryUnderMgmt: Library;
    public categories?: LibraryCategory[];
    private myCompanyTypeId: number;

    constructor(
        private translateService: TranslateService,
        private libraryService: LibraryService,
        private modalService: VwuiModalService,
        private toastService: ToastService
    ) {
    }

    get library(): Library {
        return this.libraryUnderMgmt;
    }

    @Input() set library(value: Library) {
        this.libraryUnderMgmt = value;
        this.updateTables();
    }

    @Input() set companyTypeId(id: number) {
        this.myCompanyTypeId = id;
    }

    public openCreateCategory() {
        const modal = this.modalService.open(
            CreateUpdateLibraryCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.library_mgmt.modal.create_category.title',
                    name: '',
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed.subscribe((data: LibraryCategory) => {
            if (data === undefined) {
                return;
            }
            this.libraryService.addCategory(this.myCompanyTypeId, this.library, data).then((library: Library) => {
                    this.library = library;
                    this.updateTables();
                }
            );
        });
    }

    public openCreateItemModal(category: LibraryCategory) {
        const modal = this.modalService.open(
            CreateUpdateLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: `COMPONENT.library_mgmt.library_type.${this.library.type}.create_item.title`,
                    libraryType: this.library.type,
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed.subscribe((data: LibraryEntry) => {
            if (data === undefined) {
                return;
            }
            this.libraryService.addItem(this.myCompanyTypeId, this.library, category, data).then((library: Library) => {
                    this.library = library;
                }
            );
        });
    }

    public openEditItemModal(category: LibraryCategory, event: LibraryEntry) {
        const modal = this.modalService.open(
            CreateUpdateLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: `COMPONENT.library_mgmt.library_type.${this.library.type}.update_item.title`,
                    libraryType: this.library.type,
                    saveText: 'BUTTON.update',
                    data: event
                }
            }
        );

        modal.afterClosed.subscribe((data: LibraryEntry) => {
            if (data === undefined) {
                return;
            }
            this.libraryService.updateItem(this.myCompanyTypeId, this.library, category, data).then((library: Library) => {
                    this.library = library;
                }
            );
        });
    }

    public openDeleteItemModal(category: LibraryCategory, entry: LibraryEntry) {
        const modal = this.modalService.open(DeleteItemModalComponent,
            {
                data: {
                    title: `COMPONENT.library_mgmt.library_type.${this.library.type}.delete_item.title`,
                    description: `COMPONENT.library_mgmt.library_type.${this.library.type}.delete_item.description`
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.libraryService.deleteItem(this.myCompanyTypeId, this.library, category, entry).then((library: Library) => {
                        this.library = library;
                        this.updateTables();
                    }
                ).catch((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.toastService.handleHttpError(error);
                    } else {
                        this.toastService.openError(error, this.translateService.instant('ERRORS.library_not_updated'));
                    }
                });
            }
        });
    }

    public openUpdateCategoryModal(data: LibraryCategory) {
        const modal = this.modalService.open(
            CreateUpdateLibraryCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.library_mgmt.modal.update_category.title',
                    data,
                    saveText: 'BUTTON.update'
                }
            }
        );

        modal.afterClosed.subscribe((newData: LibraryCategory) => {
            if (newData === undefined) {
                return;
            }
            this.libraryService.updateCategory(this.myCompanyTypeId, this.library, newData).then((library: Library) => {
                    this.library = library;
                    this.updateTables();
                }
            );
        });
    }

    public openDeleteCategoryModal(data: LibraryCategory) {
        const modal = this.modalService.open(DeleteItemModalComponent,
            {
                data: {
                    title: 'COMPONENT.library_mgmt.modal.delete_category.title',
                    description: 'COMPONENT.library_mgmt.modal.delete_category.description'
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.libraryService.deleteCategory(this.myCompanyTypeId, this.library, data).then((library: Library) => {
                        this.library = library;
                        this.updateTables();
                    }
                );
            }
        });
    }

    private updateTables() {
        if (this.library) {
            this.categories = this.library.categories;
        } else {
            this.categories = [];
        }
    }

}
