import {Component, Input} from '@angular/core';
import {PdfDetails} from '../../models/pdf/pdf-details';

@Component({
    selector: 'app-pdf-page-mandating-flow',
    templateUrl: './pdf-page-mandating-flow.component.html',
    styleUrls: ['./pdf-page-mandating-flow.component.scss']
})
export class PdfPageMandatingFlowComponent {
    @Input() pdfDetails: PdfDetails;

    constructor() {
    }

    public getStartDateMandate() {
        return this.pdfDetails.mandateDetails && this.pdfDetails.mandateDetails.mandateSteps[0].createdAt;
    }
}
