import {Directive, ElementRef, HostListener} from '@angular/core';
import {FocusBlockDirective} from './focus-block.directive';
import {AccessibilityService} from '../services/accessibility.service';
import {FocusUtil} from '../utils/focus.util';

@Directive({
    selector: '[a11yFocusTrap]'
})
export class FocusTrapDirective extends FocusBlockDirective {
    // Traps the keyboard focus inside of this element
    // This should only be used for modals ( WCAG Success Criterion 2.1.2 )
    constructor(
        private trapElementRef: ElementRef,
        private trapAccessibilityService: AccessibilityService
    ) {
        super(trapElementRef, trapAccessibilityService);
    }

    @HostListener('keydown', ['$event']) onKeyDown($event){
        // Example taken and mutated from: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
        const isTabPressed = $event.keyCode === 9;
        if (!isTabPressed) {
            this.onHomeOrEnd($event);
            return;
        }

        let firstFocusableElement = null;
        let lastFocusableElement = null;
        const focusableElements = FocusUtil.findCoarseFocusableElements(this.trapElementRef.nativeElement);
        if (focusableElements) {

            // Make sure to remove elements that have the tabindex set to -1 for regular interactive elements
            // Like a tags that we do not want to be focusable
            firstFocusableElement = Array.from(focusableElements).find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));

            lastFocusableElement = Array.from(focusableElements).reverse().find((focusableElement) => FocusUtil.isKeyboardFocusable((focusableElement as HTMLElement)));
        }

        if ($event.shiftKey) {
            if (document.activeElement === firstFocusableElement && lastFocusableElement) {
                lastFocusableElement.focus();
                $event.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableElement && firstFocusableElement) {
                firstFocusableElement.focus();
                $event.preventDefault();
            }
        }
    }
}
