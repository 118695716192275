import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[a11yKeyboardClickable]'
})
export class KeyboardClickableDirective implements OnChanges {

    @Input() a11yKeyboardClickable: string | boolean = true;
    private overrideEnterKey = false;
    private overrideSpaceKey = false;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.a11yKeyboardClickable.currentValue === false) {
            this.setElementTabIndex(false);
        } else if (changes.a11yKeyboardClickable.currentValue === true || changes.a11yKeyboardClickable.firstChange) {
            this.setElementTabIndex(true);
        }
    }

    private setElementTabIndex(enabled: boolean = true) {
        if (this.elementRef.nativeElement.nodeName) {
            const nodeName = this.elementRef.nativeElement.nodeName.toLowerCase();

            // Add the tabindex if it should be added because the element isn't focusable
            if (!['a', 'input', 'button', 'textarea', 'select'].includes(nodeName)) {
                const tabIndexAttr = document.createAttribute('tabIndex');

                if (enabled) {
                    tabIndexAttr.value = '0';
                } else if (!enabled) {
                    tabIndexAttr.value = '-1';
                }

                this.elementRef.nativeElement.attributes.setNamedItem(tabIndexAttr);
            }

            this.overrideEnterKey = !['input', 'textarea'].includes(nodeName);

            // Add space key override according to WAI-ARIA Authoring Practices 1.1
            const role = this.elementRef.nativeElement.attributes.getNamedItem('role');
            this.overrideSpaceKey = role && ['button', 'checkbox', 'radio', 'tab',
                'menuitem', 'menuitemcheckbox', 'menuitemradio'].includes(role.value);
        }
    }

    @HostListener('keydown', ['$event'])
    onActivation($event) {
        if (!$event.ctrlKey && ((this.overrideEnterKey && $event.keyCode === 13) ||
            (this.overrideSpaceKey && $event.keyCode === 32))) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            this.elementRef.nativeElement.click();
        }
    }
}
