import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

/**
 * Class for sending api requests to the server
 */
@Injectable()
export class ApiService {

    readonly requestUri: string;
    readonly pdfUri: string;
    readonly requestHeaders: HttpHeaders;

    constructor(
        private httpClient: HttpClient
    ) {
        this.requestUri = environment.apiUrl;
        this.pdfUri = window.location.hostname === 'localhost'
        && window.location.port === '4200' ? environment.apiUrl : environment.pdfUrl;

        this.requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    /**
     * Return the options for the http calls
     */
    private getOptions(responseType: string): Record<string, unknown> {
        return {
            observe: 'response',
            headers: this.requestHeaders,
            responseType
        };
    }

    /**
     * Send a get request to the api server
     */
    public get<T>(url: string, responseType: string = 'json'): Promise<T> {
        return this.httpClient.get<T>(`${this.requestUri}${url}`, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a get request to the api server
     */
    public getForPdf<T>(url: string, responseType: string = 'json'): Promise<T> {
        return this.httpClient.get<T>(`${this.pdfUri}${url}`, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a get request to the api server
     */
    public getAttachment(url: string, contentType: string = 'application/pdf'): Promise<Blob> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', contentType);
        return this.httpClient.get(`${this.requestUri}${url}`, {headers, responseType: 'blob'}).toPromise();
    }

    /**
     * Send a post request to the api server
     */
    public post<T, B = T>(url: string, body: B, responseType: string = 'json'): Promise<T> {
        return this.httpClient.post<T>(`${this.requestUri}${url}`, body, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a post request to the api server
     */
    public multipartPost<T>(url: string, file: FormData): Promise<T> {
        return this.httpClient.post<T>(`${this.requestUri}${url}`, file).toPromise();
    }

    /**
     * Send a put request to the api server
     */
    public put<T, B = T>(url: string, body: B, responseType: string = 'json'): Promise<T> {
        return this.httpClient.put<T>(`${this.requestUri}${url}`, body, this.getOptions(responseType)).toPromise();
    }

    /**
     * Send a delete request to the api server
     */
    public delete<T>(url: string, responseType: string = 'json'): Promise<T> {
        return this.httpClient.delete<T>(`${this.requestUri}${url}`, this.getOptions(responseType)).toPromise();
    }
}
