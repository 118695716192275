import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, CanActivate} from '@angular/router';
import {UserRoles} from '../../enums';
import {RouteUtil} from '../../utils';
import {UserService} from '../../services';

interface UserRedirect {
    roles: UserRoles[];
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserRedirectGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let hasRedirect = false;
        const userRedirects = RouteUtil.getRouteData<Array<UserRedirect>>(route, 'userRedirects', []);

        for (const redirect of userRedirects) {
            if (!hasRedirect) {
                for (const role of redirect.roles) {
                    const hasRole = await this.userService.userHasAnyRole([role]);
                    if (hasRole) {
                        hasRedirect = true;
                        this.router.navigate([redirect.url]);
                    }
                }

                if (redirect.roles.length === 0) {
                    const noRoles = await this.userService.userHasNoRoles();
                    if (noRoles) {
                        hasRedirect = true;
                        this.router.navigate([redirect.url]);
                    }

                }
            }
        }

        return hasRedirect;
    }

}
